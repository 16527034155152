var isBrowser=new Function("try {return this===window;}catch(e){ return false;}");

if(isBrowser()) {
	globalThis.Parse = require('parse');
}

export class GndCtrlObject extends Parse.Object {

}

export class GndCtrlUser extends Parse.User {}
