<div class="container page profile">
	<div class="row justify-content-center mt-5">
		<form class="col-6">
			<div class="card">
				<div class="card-body px-3">
					<div class="row mb-3">
						<h1 class="col-12">{{ 'profile.title' | translate }}</h1>
					</div>
					<div class="row mb-3" *ngIf="errorMessage">
						<div class="col-12">
							<ngb-alert type="danger" [dismissible]="false">
								{{ errorMessage }}
								<a href="mailto:majortom@togroundcontrol.com" target="_blank">majortom@togroundcontrol.com</a>
							</ngb-alert>
						</div>
					</div>
					<div class="row mb-3" *ngIf="resultMessage">
						<div class="col-12">
							<ngb-alert type="success" [dismissible]="false">{{ 'profile.savedAlert' | translate }}</ngb-alert>
						</div>
					</div>
					<div class="row align-items-center">
						<div class="col-6 col-md-3 form-group">
							<app-avatar [user]="user" [avatarSize]="5" [isPreview]="isPreview"></app-avatar>
						</div>
						<div class="col-6 col-md-9 form-group">
							<label for="fileInput" class="btn attachment"
								><i class="bi bi-camera"></i>{{ 'avatar.uploadNew' | translate }}
								<input
									type="file"
									class="form-control-file"
									id="fileInput"
									accept="image/*"
									(change)="onAvatarChange($event)"
									#logoInput
								/>
							</label>
							<p><ngb-progressbar type="primary" *ngIf="showProgress" [value]="progressUpload"></ngb-progressbar></p>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-md-6 form-group">
							<label
								>{{ 'user.firstName' | translate }}
								<input type="text" class="form-control" placeholder="" name="firstName" [(ngModel)]="user.firstName" />
							</label>
						</div>

						<div class="col-8 col-md-6 form-group">
							<label
								>{{ 'user.lastName' | translate }}
								<input type="text" class="form-control" placeholder="" name="lastName" [(ngModel)]="user.lastName" />
							</label>
						</div>
					</div>
					<div class="row">
						<div class="col-12 form-group">
							<label
								>{{ 'user.company' | translate }}
								<input type="text" class="form-control" placeholder="" name="company" [(ngModel)]="user.company" />
							</label>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-md-6 form-group">
							<label
								>{{ 'user.email' | translate }} *
								<input type="email" class="form-control" placeholder="" name="email" [(ngModel)]="user.email" required />
							</label>
						</div>
					</div>
					<!-- <div class="row">
						<div class="col-12 form-group">
							<label>Password *
								<input type="password" class="form-control" name="password" [(ngModel)]="user.password" required>
							</label>
						</div>
					</div> -->

					<div class="row align-items-center">
						<div class="col-4 form-group">
							<button type="submit" class="btn btn-primary" (click)="onSave()">{{ 'profile.save' | translate }}</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
