import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

import { NextTitleService } from 'app/next-title.service';

@Component({
	selector: 'app-new-tenant',
	templateUrl: './new-tenant.component.html',
	styleUrls: ['./new-tenant.component.scss'],
})
export class NewTenantComponent implements OnInit {
	private routeSubscription;
	public user = {
		email: '',
		password: '',
	};
	public errorMessage: string;
	public loginType = '';
	public success = false;

	constructor(
		private titleService: NextTitleService,
		private microsoftService: MsalService,
		private route: ActivatedRoute
	) { }

	ngOnInit() {
		this.titleService.setTitle('New Tenant');

		this.route.queryParams
			.subscribe(params => {
				console.log(params); // { orderby: "price" }
				
				this.success = params.admin_consent == "True"
			}
			);
	}

	async onMicrosoft() {
		document.location.href = 'https://login.microsoftonline.com/organizations/v2.0/adminconsent?client_id=1c8a0ac0-7a1e-4941-930e-a57cad0309ca&state=12345&redirect_uri=https://platform.togroundcontrol.com/new-tenant&scope=https://graph.microsoft.com/.default';
	}
}
