import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InlineEditorComponent } from './inline-editor.component';
import {
	InputCheckboxComponent, InputDateComponent,
	InputDatetimeComponent,
	InputNumberComponent,

	InputPasswordComponent, InputRangeComponent,

	InputSelectComponent,
	InputTextareaComponent,
	InputTextComponent, InputTimeComponent
} from './inputs/index';
import { InputBase } from './inputs/input-base';


export * from './configs';
export { InlineEditorComponent } from './inline-editor.component';
export * from './inputs/index';
export { InputBase } from './inputs/input-base';
export { InlineEditorEvent } from './types/inline-editor-events.class';

const EXPORTS = [
	InputBase,
	InputTextComponent,
	InputNumberComponent,
	InputPasswordComponent,
	InputRangeComponent,
	InputTextareaComponent,
	InputSelectComponent,
	InputDateComponent,
	InputTimeComponent,
	InputDatetimeComponent,
	InputCheckboxComponent,
	InlineEditorComponent,
];

@NgModule({
	imports: [CommonModule, FormsModule],
	declarations: EXPORTS,
	exports: [InlineEditorComponent],
})
export class InlineEditorModule {}
