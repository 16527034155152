<div *ngIf="portfolio" class="settings-content">
	<h5>{{ 'keyQuestion.plural' | translate }}</h5>
	<hr />
	<div class="custom-control custom-switch mb-3">
		<input type="checkbox" class="custom-control-input" id="switchDefaultQuestions" [(ngModel)]="portfolio.defaultKeyQuestions" (change)="savePortfolio()" />
		<label class="custom-control-label" for="switchDefaultQuestions">{{ 'keyQuestion.useDefault' | translate }}</label>
	</div>
	<ng-container *ngIf="!portfolio.defaultKeyQuestions">
		<div class="mt-5" *ngFor="let stage of stages">
			<h5>{{ portfolioService.getStageName(portfolio, stage) }}</h5>
			<div
				*ngIf="filteredQuestions[stage]"
				[id]="stage"
				class="question-container"
				cdkDropList
				[cdkDropListData]="stage"
				[cdkDropListConnectedTo]="stages"
				(cdkDropListDropped)="dropKeyQuestion($event)"
			>
				<div *ngFor="let keyQuestion of filteredQuestions[stage]" class="drag-cursor question-row" cdkDrag [cdkDragData]="keyQuestion">
					<div (click)="openKeyQuestion(keyQuestion)">
						<p>{{ keyQuestion.question }}</p>
					</div>
					<div *cdkDragPlaceholder matchSize="true" class="placeholderblock"></div>
				</div>
			</div>
		</div>
		<button class="btn btn-primary mt-3" name="add key question" (click)="addKeyQuestion()">{{ 'keyQuestion.add' | translate }}</button>
	</ng-container>
</div>
