import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Portfolio } from '@shared/models/portfolio';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Canvas } from '../../../../shared/models/canvas';
import { Sticky } from '../../../../shared/models/sticky';

@Component({
	selector: 'app-mini-next-canvas',
	templateUrl: './mini-next-canvas.component.html',
	styleUrls: ['./mini-next-canvas.component.scss'],
})
export class MiniNextCanvasComponent implements OnInit {
	@Input() stage: string;
	@Input() canvas: Canvas;
	@Input() allStickies: Sticky[];
	@Input() canSelectSegment: boolean;
	@Input() selectedSegment: string;
	@Input() showSegmentNames: boolean;
	@Input() portfolio: Portfolio;
	@Output() selectCanvas: EventEmitter<any> = new EventEmitter();
	public stages: string[];
	public segments: object;

	public busy = false;

	constructor(private portfolioService: PortfolioService) {}

	ngOnInit(): void {
		this.stages = this.portfolioService.getMainStages(this.portfolio);
		this.segments = this.portfolioService.segmentPerStage;
	}

	onClick(segment: string) {
		this.selectCanvas.emit({ segment: segment });
	}
}
