import { Component, Input, OnInit } from '@angular/core';
import { StickyService } from 'app/sticky/sticky.service';
import { Log } from '../../../../../shared/models/log';

@Component({
	selector: 'app-log-validated-sticky',
	templateUrl: './log-validated-sticky.component.html',
	styleUrls: ['./log-validated-sticky.component.scss'],
})
export class LogValidatedStickyComponent implements OnInit {
	@Input() log: Log;
	public translate: string;

	constructor(private stickyService: StickyService) {}

	ngOnInit(): void {
		this.translate = 'activity.actions.' + this.log.activity.replace(' ', '_');
	}

	open() {
		this.stickyService.openModal(this.log.sticky, this.log.sticky.canvas);
	}
}
