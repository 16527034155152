<div class="modal-content">
	<div class="modal-header">
		<p>{{ 'cockpit.edit' | translate }}</p>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body edit">
		<form>
			<div class="row mb-3">
				<div class="col-12">
					<label class="mb-0">{{ 'thesis.singular' | translate }}</label>
					<ng-select
						name="thesis"
						*ngIf="!!product && product.portfolio.theses?.length"
						[items]="product.portfolio.theses"
						[(ngModel)]="product.thesis"
					>
						<ng-template ng-label-tmp let-item="item">
							{{ item.title }}
						</ng-template>
						<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
							{{ item.title }}
						</ng-template>
					</ng-select>
					<p *ngIf="!!product && !product.portfolio.theses?.length">{{ 'productModalSettings.general.hintThesis' | translate }}</p>
				</div>
			</div>
			<div class="row mb-1">
				<div class="col-12 form-group">
					<label class="mb-3"
						>{{ 'product.pitch' | translate }}
						<input type="text" class="form-control" name="pitch" [(ngModel)]="product.pitch" />
					</label>

					<div class="form-group">
						<label class="mb-0" for="input-problem-statement">{{ 'description.singular' | translate }}</label>
						<quill-editor id="input-description" name="description" [(ngModel)]="product.description" [styles]="{ height: '10rem' }"></quill-editor>
					</div>

					<div class="form-group" *ngFor="let cockpit of cockpitFields">
						<label class="mb-0" [for]="cockpit.name">{{ cockpit.name }}</label>
								<quill-editor
									[id]="cockpit.name"
									[name]="cockpit.name"
									[(ngModel)]="product.cockpitValues[cockpit.id]"
									[styles]="{ height: '10rem' }"
									[placeholder]="cockpit.explanation"
								></quill-editor>
						
					</div>

					<div class="form-group" *ngFor="let lens of lenses">
						<label
							>{{ lens.name }}
							<div class="input-group">
								<div class="input-group-prepend" *ngIf="lens.value === LensValues.CURRENCY">
									<div class="input-group-text">{{ product.portfolio.currency | currencySymbol }}</div>
								</div>
								<div class="input-group-prepend" *ngIf="lens.value !== LensValues.CURRENCY">
									<div class="input-group-text">{{ lens.value }}</div>
								</div>
								<input type="number" class="form-control" [name]="lens.name" [(ngModel)]="product.lensValues[lens.id].value" />
							</div>
							</label>
							<div class="mt-1">
								<quill-editor
									[name]="lens.name + '.note'"
									[(ngModel)]="product.lensValues[lens.id].note"
									[styles]="{ height: '5rem' }"
									[placeholder]="'Here you can explain how you calculated the value for ' + lens.name"
								></quill-editor>
							</div>
					</div>
				</div>
			</div>
		</form>
	</div>

	<div class="modal-footer">
		<button ngbAutofocus type="button" class="btn btn-primary" (click)="saveCockpit()">
			{{ 'cockpit.save' | translate }}
		</button>
	</div>
</div>
