<div class="card" (click)="onSelect()">
	<div class="card-body">
		<div class="row align-items-center">
			<div class="col-12 col-md-3">
				<h5 class="name">{{ portfolio.name }}</h5>
				<p class="text-light">{{ portfolio.id }}</p>
			</div>
			<div class="col-12 col-md-3">
				<h5 class="info-header">{{ 'general.createdAt' | translate }}</h5>
				<p>{{ portfolio.createdAt | date }}</p>
			</div>
			<!-- <div class="col-12 col-md-2">
				<h5 class="info-header">{{ 'subscription.status' | translate }}</h5>
				<p>{{ portfolio.status }}</p>
			</div> -->
			<div class="col-12 col-md-1">
				<h5 class="info-header">{{ 'trial.singular' | translate }}</h5>
				<i *ngIf="portfolio.trial" class="bi bi-check"></i>
				<p *ngIf="!portfolio.trial">-</p>
			</div>
			<div class="col-12 col-md-3" *ngIf="portfolio.trial">
				<h5 class="info-header">{{ 'trial.endsAt' | translate }}</h5>
				<p>{{ portfolio.trialEndsAt | date }}</p>
			</div>
		</div>
	</div>
</div>

