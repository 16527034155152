import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-sticky-risk-modal',
	templateUrl: './sticky-risk-modal.component.html',
	styleUrls: ['./sticky-risk-modal.component.scss'],
})
export class StickyRiskModalComponent {
	constructor(public activeModal: NgbActiveModal) {}
}
