import { Injectable } from '@angular/core';
import { Object } from 'parse';
import { Experiment } from '../../../shared/models/experiment';
import { Log } from '../../../shared/models/log';
import { Product } from '../../../shared/models/product';
import { Sticky } from '../../../shared/models/sticky';
import { Portfolio } from '../../../shared/models/portfolio';
import { AnalyticsService } from 'app/shared/analytics.service';

@Injectable({
	providedIn: 'root',
})
export class LogService {
	constructor(
		private analytics: AnalyticsService
	) {}

	logActivity(
		activity: string,
		targetClass: string,
		targetId: string,
		product: Product,
		portfolio: Portfolio,
		options?: any
	) {
		let log = new Log();
		log.activity = activity;
		// log.targetClass = targetClass;
		// log.targetId = targetId;
		log.options = options;
		log.portfolio = portfolio;
		log.product = product;

		if (targetClass) {
			// forward compatible with backend
			const lowerTarget = targetClass.toLowerCase();
			const object = new Object(targetClass);
			object.id = targetId;

			log.set(lowerTarget, object);
		}

		log.save();

		if (!activity) {
			return;
		}

		const mixpanelActivity = activity.charAt(0).toUpperCase() + activity.slice(1);
		this.analytics.track(mixpanelActivity);

		// Couldn't find fromPointer function, but this works:
		// const query = new Query('Canvas');
		// query.equalTo('objectId', log.options.canvas.objectId);
		// const canvas = await query.find();
		// console.log(canvas);
	}

	logStickyActivity(activity: string, sticky: Sticky) {
		this.logActivity(activity, 'Sticky', sticky.id, sticky.canvas.product, sticky.canvas.product.portfolio, {
			canvas: sticky.canvas.toPointer(),
		});
	}

	logExperimentActivity(activity: string, experiment: Experiment) {
		this.logActivity(
			activity,
			'Experiment',
			experiment.id,
			experiment.canvas.product,
			experiment.canvas.product.portfolio,
			{
				canvas: experiment.canvas.toPointer(),
			}
		);
	}
}
