<div class="segment" [ngClass]="[location, id, type]">
	<p>{{ portfolioService.getSegmentName(canvas.product.portfolio, id, type) }}</p>

	<div class="cards scrollbar">
		<div class="stickies" cdkDropList [id]="id" [cdkDropListData]="id" [cdkDropListConnectedTo]="allIds" (cdkDropListDropped)="drop($event)">
			<ng-container *ngFor="let sticky of stickies">
				<div cdkDrag [cdkDragData]="sticky">
					<app-canvas-sticky [sticky]="sticky" [canvas]="canvas" [canOpen]="true" [canvasVersion]="canvasVersion"> </app-canvas-sticky>
					<div *cdkDragPlaceholder matchSize="true" class="placeholderblock"></div>
				</div>
			</ng-container>
		</div>
		<button (click)="addExperiment()" class="btn btn-link add-card" [disabled]="!canvas.product.isActive()">
			<i class="bi bi-eyedropper"></i>{{ 'segment.learnMore' | translate }} <app-spinner [show]="busy"></app-spinner>
		</button>
		<button (click)="addAssumption()" class="btn btn-link add-card" [disabled]="!canvas.product.isActive()">
			<i class="bi bi-plus"></i>{{ 'segment.addAssumption' | translate }} <app-spinner [show]="busy"></app-spinner>
		</button>
	</div>
</div>
