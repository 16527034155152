<div *ngIf="contentTool">
	<div class="mb-3">
		<h1>{{ contentTool.title }}</h1>
		<p>{{ contentTool.excerpt }}</p>
	</div>
	<div class="row mb-4">
		<div class="col-12 col-md-auto">
			<p class="header" *ngIf="contentTool.stages.length === 1">{{ 'stage.singular' | translate }}</p>
			<p class="header" *ngIf="contentTool.stages.length > 1">{{ 'stage.plural' | translate }}</p>
		</div>
		<div class="col-12 col-md">
			<p class="badge badge-secondary mr-2" *ngFor="let stage of contentTool.stages">{{ !!portfolio ? portfolioService.getStageName(portfolio, stage) : ('productStages.' + stage | translate) }}</p>
		</div>
	</div>
	<div *ngIf="contentTool.description" class="mb-4">
		<h3>{{ 'contentExperiment.whatIsIt' | translate }}</h3>
		<div class="editor-data" [innerHtml]="contentTool.description"></div>
	</div>
	<div *ngIf="contentTool.example" class="mb-4">
		<h3>{{ 'example.singular' | translate }}</h3>
		<div class="editor-data" [innerHtml]="contentTool.example"></div>
	</div>
	<div *ngIf="contentTool.readMore" class="mb-4">
		<h3>{{ 'contentExperiment.readMore' | translate }}</h3>
		<div class="editor-data" [innerHtml]="contentTool.readMore"></div>
	</div>
	<div *ngIf="contentTool.source" class="mt-5 mb-5">
		<p class="editor-data">{{ 'source.singular' | translate }}: {{ contentTool.source }}</p>
	</div>
</div>
