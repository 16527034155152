import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Experiment } from './experiment';
import { Product } from './product';

@ParseSubClass('Canvas')
export class Canvas extends Parse.Object {
	@ParseProperty() public name: string;
	@ParseProperty() public product: Product;
	@ParseProperty() public experiments: Array<Experiment>;
	@ParseProperty() public archived: boolean;
	@ParseProperty() public type: string;
	@ParseProperty() public versionDates: Array<Date>;

	constructor() {
		super('Canvas');
	}
}
