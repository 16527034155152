<div class="card" (click)="openExperiment()">
	<div class="card-body" [ngClass]="{ learnings: experiment.hasLearnings !== undefined }">
		<div class="color-bar" [class]="experiment.type"></div>
		<p *ngIf="experiment.type === 'learn'">{{ experiment.learninggoal || experiment.hypothesis }}</p>
		<p *ngIf="experiment.type === 'confirm'">Test "{{ experiment.sticky?.text }}"</p>
	</div>
	<div class="row align-items-end footer">
		<div class="col-12 col-md-8">
			<p class="analyzed" *ngIf="experiment.status === ExperimentStatus.DONE || experiment.status === ExperimentStatus.ENDED_DEPRECATED">
				<span *ngIf="experiment.getResult() === 'true'"><span class="bi bi-check-circle"></span> {{ 'experiment.success' | translate }}</span>
				<span *ngIf="experiment.getResult() === 'validated'"><span class="bi bi-check-circle"></span> {{ 'confirmStages.validated' | translate }}</span>
				<span *ngIf="experiment.getResult() === 'invalidated'"><span class="bi bi-x-circle success"></span> {{ 'confirmStages.invalidated' | translate }}</span>
				<span *ngIf="experiment.getResult() === 'failed'"><span class="bi bi-x-circle"></span> {{ 'experiment.biiled' | translate }}</span>
			</p>
		</div>
		<div class="col-12 col-md-4 text-right">
			<span class="identifier">{{ product.getAbbrev() }}-{{ experiment.number }}</span>
		</div>
	</div>
</div>
