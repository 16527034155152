import * as Parse from 'parse';

export function ParseSubClass(name): ClassDecorator {
	return (target: any) => {
		Parse.Object.registerSubclass(name, target);

		return target;
	};
}

export function ParseProperty(): PropertyDecorator {
	return (target, property) => {
		Object.defineProperty(target, property, {
			get: function () {
				return this.get(property);
			},
			set: function (value) {
				this.set(property, value);
			},
			configurable: true,
			enumerable: true,
		});
	};
}
