import { Component, OnInit } from '@angular/core';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Portfolio } from '../../../../shared/models/portfolio';

@Component({
	selector: 'app-portfolio-settings-canvas-design',
	templateUrl: './portfolio-settings-canvas-design.component.html',
	styleUrls: ['./portfolio-settings-canvas-design.component.scss'],
})
export class PortfolioSettingsCanvasDesignComponent implements OnInit {
	public portfolio: Portfolio;
	public stages: string[];
	public segments: object;

	constructor(public portfolioService: PortfolioService) {}

	async ngOnInit() {
		this.portfolio = await this.portfolioService.getActivePortfolio();
		this.stages = this.portfolioService.getMainStages(this.portfolio);
		this.segments = this.portfolioService.segmentPerStage;

		if (!this.portfolio.canvasSegmentNames) {
			this.portfolio.canvasSegmentNames = {
				'next-canvas': {},
				'pre-validation': {},
			};
		}
	}

	saveEditable() {
		this.portfolio.save();
	}
}
