<div class="modal-content">
	<div class="modal-header">
		<p>{{ 'canvas.new' | translate }}</p>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body edit">
		<div class="row">
			<div class="col-12 form-group">
				<label
					>{{ 'canvasModalAdd.canvasName' | translate }}
					<input type="text" class="form-control" name="name" [(ngModel)]="canvas.name" />
				</label>
			</div>
		</div>

		<div class="row">
			<p class="col-12">{{ 'canvasModalAdd.canvasType' | translate }}</p>
			<div class="col-12 col-md-4 mini-canvas-wrapper" (click)="onClick('next-canvas')">
				<div class="canvas-container">
					<app-canvas-mini type="next-canvas" [exampleStickies]="[]" [stage]="canvas.type === 'next-canvas' ? 'active' : ''" [portfolio]="product.portfolio"></app-canvas-mini>
				</div>
				<p class="text-center">{{ 'canvas.nextCanvas.name' | translate }}</p>
			</div>
			<div class="col-12 col-md-4 mini-canvas-wrapper" (click)="onClick('pre-validation')">
				<div class="canvas-container">
					<app-canvas-mini type="pre-validation" [exampleStickies]="[]" [stage]="canvas.type === 'pre-validation' ? 'active' : ''"></app-canvas-mini>
				</div>
				<p class="text-center">{{ 'canvas.prevalidationCanvas.name' | translate }}</p>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button ngbAutofocus type="button" class="btn btn-primary" (click)="onSave()">{{ 'canvas.add' | translate }}</button>
	</div>
</div>
