<div class="row">
	<div *ngIf="isReady && activeSprint" class="col-12 col-md product-content">
		<div class="row align-items-center">
			<div class="col-12 col-md-2">
				<div class="sprint-number" ngbDropdown #sprintDrop="ngbDropdown">
					<span *ngIf="activeSprint && sprints" id="sprintDropdown" ngbDropdownToggle class="hand">
						<h4>{{ 'sprint.singular' | translate }} {{ activeSprint.number }}</h4>
						<i class="bi bi-caret-down-fill"></i>
					</span>
					<div ngbDropdownMenu aria-labelledby="sprintDropdown">
						<a
							[routerLink]="['/portfolio', product.portfolio.id, 'startup', product.id, 'sprint', sprint.id]"
							ngbDropdownItem
							[ngClass]="{ activeSprint: activeSprint.id === sprint.id }"
							*ngFor="let sprint of sprints"
						>
							{{ 'sprint.singular' | translate }} {{ sprint.number }}
						</a>
					</div>
				</div>
			</div>
			<div class="col-3 header-info text-left">
				<span *ngIf="activeSprint.status === SprintStatus.RUNNING"><i class="bi bi-unlock"></i> {{ 'sprint.current' | translate }}</span>
				<span *ngIf="activeSprint.status === SprintStatus.DONE"><i class="bi bi-lock"></i> {{ 'sprint.completed' | translate }}</span>
			</div>
			<div
				class="col-3 header-info text-center hand"
				*ngIf="activeSprint.status === SprintStatus.RUNNING"
				[ngbPopover]="(activeSprint.startedAt | date: 'mediumDate') + ' - ' + (activeSprint.endedAt | date: 'mediumDate')"
				placement="bottom"
				triggers="mouseenter:mouseleave"
			>
				<span *ngIf="remainingDays >= 0"><i class="bi bi-clock"></i> {{ remainingDays }} {{ 'deadline.daysRemaining' | translate }}</span>
				<span *ngIf="activeSprint.status === SprintStatus.RUNNING && remainingDays < 0"><i class="bi bi-clock"></i> {{ 'deadline.passed' | translate }}</span>
			</div>

			<div class="col-4 header-info text-center" *ngIf="activeSprint.status === SprintStatus.DONE">
				<span><i class="bi bi-clock"></i> {{ (activeSprint.startedAt | date: 'mediumDate') + ' - ' + (activeSprint.endedAt | date: 'mediumDate') }}</span>
			</div>

			<div class="col-4 text-right" *ngIf="activeSprint.status === SprintStatus.RUNNING">
				<button class="btn btn-link mr-1" (click)="editSprint(activeSprint)">{{ 'general.edit-details' | translate }}</button>
				<button class="btn btn-outline-primary" (click)="completeSprint()" [disabled]="!isActiveSprint">
					{{ 'sprint.complete' | translate }}
				</button>
			</div>
			<div class="col-3 text-right" *ngIf="activeSprint.status === SprintStatus.DONE">
				<button class="btn btn-link mr-1" (click)="editSprint(activeSprint)">{{ 'general.edit-details' | translate }}</button>
			</div>
		</div>

		<div class="row" *ngIf="activeSprint.mostRisky">
			<div class="col-12 col-md-2">
				<p class="header">{{ 'sprint.detail.goal' | translate }}</p>
			</div>
			<div class="col-12 col-md-10">
				<p class="editor-data" [innerHtml]="activeSprint.mostRisky"></p>
			</div>
		</div>

		<div class="row" *ngIf="activeSprint.demo">
			<div class="col-12 col-md-2">
				<p class="header">{{ 'sprint.detail.demo' | translate }}</p>
			</div>
			<div class="col-12 col-md-10">
				<p class="editor-data" [innerHtml]="activeSprint.demo"></p>
			</div>
		</div>

		<div class="row">
			<div class="col-12 col-md-2">
				<p class="header">{{ 'experiment.plural' | translate }}</p>
			</div>
			<div class="col-12 col-md-10">
				<div class="row experiment-row">
					<div class="col-md-4" *ngFor="let experiment of activeSprint.experiments">
						<div class="experiment-card-container">
							<app-experiment-card [experiment]="experiment" [product]="product"> </app-experiment-card>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-2">
				<p class="header">{{ 'task.plural' | translate }}</p>
				<p class="task-option" *ngIf="!showingMine" (click)="toggleMyTasks()">{{ 'task.showMine' | translate }}</p>
				<p class="task-option" *ngIf="showingMine" (click)="toggleMyTasks()">{{ 'task.showAll' | translate }}</p>
				<!-- <p class="task-option">{{ 'assignee.singular' | translate }} <i class="bi bi-caret-right"></i></p> -->
			</div>
			<div class="col-12 col-md-10">
				<div class="row">
					<div class="col-12 col-md-4 px-2">
						<div class="task-box h100 pb-5">
							<p>{{ 'taskStage.toDo' | translate | uppercase }}</p>
							<div
								class="task-container scrollbar"
								cdkDropList
								id="toDo"
								[cdkDropListData]="TaskStatus.TODO"
								[cdkDropListConnectedTo]="['toDo', 'inProgress', 'done']"
								(cdkDropListDropped)="dropTask($event)"
							>
								<div *ngFor="let task of sortedTasks.todo">
									<div *ngIf="task.status === TaskStatus.TODO" class="task" cdkDrag [cdkDragData]="task" [cdkDragDisabled]="!isActiveSprint">
										<app-task-card [task]="task" (click)="openTask(task)"></app-task-card>
										<div *cdkDragPlaceholder matchSize="true" class="placeholderblock"></div>
									</div>
								</div>
							</div>
							<p class="new">
								<button class="btn btn-link p-0" (click)="createNewTask(activeSprint)" [disabled]="!isActiveSprint || !product.isActive()">
									<i class="bi bi-plus"></i>{{ 'task.createNew' | translate }}
								</button>
							</p>
						</div>
					</div>
					<div class="col-12 col-md-4 px-2">
						<div class="task-box h100">
							<p>{{ 'taskStage.inProgress' | translate | uppercase }}</p>
							<div
								class="task-container"
								cdkDropList
								id="inProgress"
								[cdkDropListData]="TaskStatus.DOING"
								[cdkDropListConnectedTo]="['toDo', 'inProgress', 'done']"
								(cdkDropListDropped)="dropTask($event)"
							>
								<div *ngFor="let task of sortedTasks.inProgress">
									<div *ngIf="task.status === TaskStatus.DOING" class="task" cdkDrag [cdkDragData]="task" [cdkDragDisabled]="!isActiveSprint">
										<app-task-card [task]="task" (click)="openTask(task)"></app-task-card>
										<div *cdkDragPlaceholder matchSize="true" class="placeholderblock"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-4 px-2">
						<div class="task-box h100">
							<p>{{ 'taskStage.done' | translate | uppercase }}</p>
							<div
								class="task-container"
								cdkDropList
								id="done"
								[cdkDropListData]="TaskStatus.DONE"
								[cdkDropListConnectedTo]="['toDo', 'inProgress', 'done']"
								(cdkDropListDropped)="dropTask($event)"
							>
								<div *ngFor="let task of sortedTasks.done">
									<div *ngIf="task.status === TaskStatus.DONE" class="task" cdkDrag [cdkDragData]="task" [cdkDragDisabled]="!isActiveSprint">
										<app-task-card [task]="task" (click)="openTask(task)"></app-task-card>
										<div *cdkDragPlaceholder matchSize="true" class="placeholderblock"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="isReady && !activeSprint" class="col-12 product-content">
		<div class="row justify-content-center align-items-center no-sprint-container">
			<div class="col-12 col-md-6 text-center">
				<img src="/assets/img/sprint/no-sprint.svg" class="img-fluid mb-3" />
				<h4>{{ 'sprint.noActive.title' | translate }}</h4>
				<p class="mb-3">{{ 'sprint.noActive.explanation' | translate }}</p>
				<a class="btn btn-primary" [routerLink]="['/portfolio', product.portfolio.id, 'startup', product.id, 'backlog']">{{ 'sprint.noActive.gotoBacklog' | translate }}</a>

				<div *ngIf="sprints && sprints.length > 0">
					<hr class="my-5" />
					<p class="mb-3">{{ 'sprint.noActive.selectPrevious' | translate }}</p>
					<div class="sprint-number" ngbDropdown #sprintDrop="ngbDropdown">
						<span id="headerDropdown" ngbDropdownToggle class="hand text-center">
							<h4 class="no-sprint">{{ 'sprint.selectPrevious' | translate }}</h4>
							<i class="bi bi-caret-down-fill"></i>
						</span>
						<div ngbDropdownMenu aria-labelledby="headerDropdown">
							<a [routerLink]="['/portfolio', product.portfolio.id, 'startup', product.id, 'sprint', sprint.id]" ngbDropdownItem *ngFor="let sprint of sprints"
								>{{ 'sprint.singular' | translate }} {{ sprint.number }}</a
							>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- side bar -->
	<div *ngIf="product && isReady && activeSprint" class="col-12 col-md product-side-bar right">
		<app-product-loop></app-product-loop>

		<h5 *ngIf="activeSprint.demo">{{ 'sprint.detail.demo' | translate }}</h5>
		<p *ngIf="activeSprint.demo" class="editor-data" [innerHtml]="activeSprint.demo"></p>

		<!-- <h5 *ngIf="activeSprint.hasLearnings !== undefined">{{ 'sprintInput.demo.success.question' | translate }}</h5>
		<p *ngIf="activeSprint.hasLearnings !== undefined">
			{{ (activeSprint.hasLearnings ? 'general.yes' : 'general.no') | translate }}
		</p>

		<h5 *ngIf="activeSprint.learnings">{{ 'sprintInput.noSuccess' | translate }}</h5>
		<p *ngIf="activeSprint.learnings">{{ activeSprint.learnings }}</p> -->

		<h5 *ngIf="activeSprint.teamHapiness">{{ 'sprint.detail.teamHappiness' | translate }}</h5>
		<p *ngIf="activeSprint.teamHapiness">
			<ngb-progressbar type="primary" [value]="teamHappiness" [max]="5" height=".5rem"></ngb-progressbar>
		</p>

		<h5 *ngIf="activeSprint.commentsTeam">{{ 'sprint.detail.teamComments' | translate }}</h5>
		<p *ngIf="activeSprint.commentsTeam" class="editor-data" [innerHtml]="activeSprint.commentsTeam"></p>

		<h5 *ngIf="activeSprint.teamEducation">{{ 'sprint.detail.teamEducation' | translate }}</h5>
		<p *ngIf="activeSprint.teamEducation">
			<ngb-progressbar type="primary" [value]="teamEducation" [max]="5" height=".5rem"></ngb-progressbar>
		</p>

		<h5 *ngIf="activeSprint.minutes?.length">{{ 'minute.plural' | translate }}</h5>
		<div class="mb-3" *ngFor="let minute of activeSprint.minutes">
			<app-minute [minute]="minute" (save)="onSaveMinute($event)"></app-minute>
		</div>

		<button class="btn btn-block btn-soft" (click)="addMinute()" [disabled]="!isActiveSprint || !product.isActive()">{{ 'minute.add' | translate }}</button>
	</div>
</div>
