import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RemoveModalComponent } from 'app/validating-modals/remove-modal/remove-modal.component';
import * as Parse from 'parse';
import { Experiment } from '../../../../shared/models/experiment';
import { FileMeta } from '../../../../shared/models/file-meta';

@Component({
	selector: 'app-attachment-card',
	templateUrl: './attachment.component.html',
	styleUrls: ['./attachment.component.scss'],
})
export class AttachmentCardComponent implements OnInit {
	@Input() fileMeta: FileMeta;
	@Input() size: string;
	@Output() delete: EventEmitter<any> = new EventEmitter<any>();
	public fileType: string;

	constructor(private modalService: NgbModal) {}

	ngOnInit() {
		if (!this.fileMeta.file) {
			return;
		}
		const fileNameSplit = this.fileMeta.file?.name().split('.');
		this.fileType = fileNameSplit[fileNameSplit?.length - 1];
	}

	async removeFile() {
		const modalRef = this.modalService.open(RemoveModalComponent, {
			size: 'lg',
		});
		modalRef.componentInstance.element = 'attachment';

		let shouldBeRemoved = await modalRef.result;
		if (shouldBeRemoved) {
			//remove attachment
			await this.fileMeta.destroy();
			this.delete.emit(this.fileMeta);
		}
	}
}
