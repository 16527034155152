<div class="container page">
	<div class="row">
		<div class="col-0 col-md-2"></div>
		<div class="col-12 col-md-8">
			<app-content-tool [contentTool]="contentTool"></app-content-tool>
			<ng-container *ngIf="relatedContentTools.length > 0">
				<h3 class="mb-3">{{ 'tool.related' | translate }}</h3>
				<div class="row">
					<div class="col-12 col-md-4 mb-3" *ngFor="let tool of relatedContentTools">
						<app-content-tool-card [contentTool]="tool" (cardClicked)="visitContentTool($event)"></app-content-tool-card>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
