import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Experiment } from './experiment';
import { Product } from './product';

@ParseSubClass('Insight')
export class Insight extends Parse.Object {
	@ParseProperty() public text: string;
	@ParseProperty() public decision: Insight.Decision;
	@ParseProperty() public createdBy: Parse.User;
	@ParseProperty() public product: Product;
	@ParseProperty() public experiment: Experiment;
	@ParseProperty() public archived: boolean;

	constructor() {
		super('Insight');
	}
}

export namespace Insight {
	export enum Decision {
		PERSEVERE = 'persevere',
		PIVOT = 'pivot',
		STOP = 'stop'
	}
}
