import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Portfolio } from '@shared/models/portfolio';
import { Query } from 'parse';
import { ContentExperiment } from '../../../shared/models/content-experiment';

@Injectable({
	providedIn: 'root',
})
export class ContentExperimentService {
	constructor(public translate: TranslateService) {}

	async getContentExperiments(portfolio: Portfolio) {
		let contentExperimentQuery = new Query(ContentExperiment);
		if (portfolio.defaultContentExperiments) {
			// query default content experiments
			contentExperimentQuery.equalTo('default', true);
		} else {
			// query portfolio specific (custom) experiments
			contentExperimentQuery.equalTo('portfolio', portfolio);
		}

		contentExperimentQuery.equalTo('archived', false);
		contentExperimentQuery.ascending('title');
		return await contentExperimentQuery.find();
	}

	async getRelatedContentExperiments(contentExperiment: ContentExperiment, portfolio: Portfolio) {
		let relatedQuery = new Query(ContentExperiment);
		relatedQuery.containedIn('stages', contentExperiment.stages);
		relatedQuery.notEqualTo('slug', contentExperiment.slug);
		if (portfolio.defaultContentExperiments) {
			// query default content experiments
			relatedQuery.equalTo('default', true);
		} else {
			// query portfolio specific (custom) experiments
			relatedQuery.equalTo('portfolio', portfolio);
		}
		relatedQuery.ascending('title');
		let allrelatedContentExperiments = await relatedQuery.find();

		let relContExpsPerSlug = {};
		allrelatedContentExperiments.forEach((contExp) => {
			if (!relContExpsPerSlug[contExp.slug]) {
				relContExpsPerSlug[contExp.slug] = {};
			}
			relContExpsPerSlug[contExp.slug][contExp.language] = contExp;
		});

		let relatedContentExperiments = [];
		const slugs = Object.keys(relContExpsPerSlug);
		slugs.forEach((slug) => {
			const translations = relContExpsPerSlug[slug];
			const langs = Object.keys(translations);

			if (langs.includes(this.translate.currentLang)) {
				relatedContentExperiments.push(translations[this.translate.currentLang]);
			} else if (langs.includes(this.translate.defaultLang)) {
				relatedContentExperiments.push(translations[this.translate.defaultLang]);
			} else if (langs.length > 0) {
				relatedContentExperiments.push(translations[langs[0]]);
			}
		});

		return relatedContentExperiments;
	}

	async getContentExperimentsPerTypeStage(stage: string, type: string, portfolio: Portfolio) {
		let contExpsQuery = new Query(ContentExperiment);
		contExpsQuery.equalTo('archived', false);
		contExpsQuery.equalTo('stages', stage);
		contExpsQuery.equalTo('types', type);
		contExpsQuery.ascending('title');
		if (portfolio.defaultContentExperiments) {
			contExpsQuery.equalTo('default', true);
		} else {
			contExpsQuery.equalTo('portfolio', portfolio);
		}
		let contExps = await contExpsQuery.find();

		let contExpsPerSlug = {};
		contExps.forEach((contExp) => {
			let slugs = Object.keys(contExpsPerSlug);
			if (!slugs.includes(contExp.slug)) {
				contExpsPerSlug[contExp.slug] = {};
			}
			contExpsPerSlug[contExp.slug][contExp.language] = contExp;
		});

		let contentExperiments = [];
		const slugs = Object.keys(contExpsPerSlug);
		slugs.forEach((slug) => {
			const translations = contExpsPerSlug[slug];
			const langs = Object.keys(translations);

			if (langs.includes(this.translate.currentLang)) {
				contentExperiments.push(translations[this.translate.currentLang]);
			} else if (langs.includes(this.translate.defaultLang)) {
				contentExperiments.push(translations[this.translate.defaultLang]);
			} else if (langs.length > 0) {
				contentExperiments.push(translations[langs[0]]);
			}
		});

		return contentExperiments;
	}
}
