import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Experiment } from '@shared/models/experiment';
import { ExperimentModalAddComponent } from 'app/experiment/experiment-modal-add/experiment-modal-add.component';
import { LogService } from 'app/log/log.service';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { StickyService } from 'app/sticky/sticky.service';
import { Cloud } from 'parse';
import { Canvas } from '../../../../shared/models/canvas';
import { Sticky } from '../../../../shared/models/sticky';
import { CanvasService } from '../canvas.service';

@Component({
	selector: 'app-canvas-segment',
	templateUrl: './segment.component.html',
	styleUrls: ['./segment.component.scss'],
})
export class CanvasSegmentComponent implements OnInit /*, OnChanges*/ {
	@Input() canvas: Canvas;
	@Input() allStickies: Sticky[];
	@Input() id: string;
	@Input() location: string;
	@Input() type: string;
	@Input() allIds: string[];
	@Input() canvasVersion: Date;

	public dropLists: string[];
	public busy = false;
	public stickies: Sticky[] = [];

	constructor(
		private canvasService: CanvasService,
		public logService: LogService,
		private stickyService: StickyService,
		public portfolioService: PortfolioService,
		public modalService: NgbModal,
		public router: Router,

	) { }

	async ngOnInit() {
		this.type = this.type || 'next-canvas';

		this.filterStickies();
	}

	ngOnChanges(changes: SimpleChanges) {
		this.filterStickies();
	}

	filterStickies() {
		if (!this.allStickies) {
			return;
		}

		this.stickies = [];

		for (let sticky of this.allStickies) {
			if (sticky.segment !== this.id) {
				continue;
			}

			this.stickies.push(sticky);
		}
	}

	async addAssumption() {
		const sticky = new Sticky();
		sticky.segment = this.id;
		sticky.canvas = this.canvas;
		sticky.product = this.canvas.product;
		sticky.canvas.type = this.type;

		// Open Modal
		const result = await this.stickyService.openModal(sticky, this.canvas);

		if (result) {
			this.canvasService.addAssumption(sticky);
			this.canvasService.canvasUpdated.emit();
		}
	}

	async addExperiment() {
		let experiment = new Experiment();
		experiment.product = this.canvas.product;
		experiment.segment = this.id;
		experiment.canvas = this.canvas;
		experiment.type = 'learn';

		const modalRef = this.modalService.open(ExperimentModalAddComponent, {
			size: 'md',
			centered: true,
		});
		modalRef.componentInstance.experiment = experiment;
		modalRef.componentInstance.product = experiment.product;
		modalRef.componentInstance.canvas = experiment.canvas;
		modalRef.componentInstance.startStep = 1;

		// add resulting experiment to backlog of product
		const result = (await modalRef.result) as Experiment;
		if (result) {
			result.product.backlog.add('experiments', result);
			result.product.backlog.save();
		}

		this.router.navigate(['portfolio', experiment.product.portfolio.id, 'startup', experiment.product.id, 'backlog']);
	}

	drop(event: CdkDragDrop<string[]>) {
		// set segment if changed to another segment
		if (event.previousContainer !== event.container) {
			event.item.data.segment = event.container.data;
		}

		Cloud.run('reOrderSticky', {
			stickyId: event.item.data.id,
			segment: event.container.data,
			newIndex: event.currentIndex,
		}).then(
			(result) => {
				// this.logService.logStickyActivity('moved sticky', event.item.data);
			},
			(err) => {
				console.error(err);
			}
		);

		// already update visuals
		moveItemInArray(this.stickies, event.previousIndex, event.currentIndex);
	}
}
