import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'app/product/product.service';
import { Query } from 'parse';
import { ProductDecision } from '../../../../shared/models/product-decision';
import { DecisionModalComponent } from '../decision-modal/decision-modal.component';
import html2canvas from 'html2canvas';
import pptxgen from 'pptxgenjs';

@Component({
	selector: 'app-board-meeting-preparation',
	templateUrl: './board-meeting-preparation.component.html',
	styleUrls: ['./board-meeting-preparation.component.scss'],
})
export class BoardMeetingPreparationComponent implements OnInit, OnDestroy {
	private routerSubscription;
	public product;
	public decision: ProductDecision;

	get DecisionStatus() {
		return ProductDecision.Status;
	}

	constructor(private route: ActivatedRoute, private modalService: NgbModal, private productService: ProductService) {}

	ngOnInit(): void {
		this.routerSubscription = this.route.params.subscribe(async (params) => {
			this.product = await this.productService.getActiveProduct();

			await this.fetchData(params.decision);
		});
	}

	ngOnDestroy() {
		if (this.routerSubscription) {
			this.routerSubscription.unsubscribe();
		}
	}

	async fetchData(id) {
		let decisionQuery = new Query('Decision');
		decisionQuery.include(['attachments', 'product', 'product.portfolio', 'product.activeProgress']);
		this.decision = (await decisionQuery.get(id)) as ProductDecision;
	}

	editDecision(turnPitchIntoDecision) {
		const modalRef = this.modalService.open(DecisionModalComponent, {
			size: 'md',
			centered: true,
		});
		modalRef.componentInstance.decision = this.decision;

		if (turnPitchIntoDecision) {
			modalRef.componentInstance.turnPitchIntoDecision = turnPitchIntoDecision;
		} else {
			modalRef.componentInstance.editable = true;
		}
	}

	finalizeDecision() {
		this.decision.status = ProductDecision.Status.DONE;
		this.editDecision(true);
	}

	async downloadPitch() {
		// Create a Presentation
		let presentation = new pptxgen();

		// Apply ppt-specific css to containers
		let cockpitElement = document.querySelector('#cockpit-info');
		let innovationAccountingElement = document.querySelector('#innovation-accounting');
		let keyQuestionsElement = document.querySelector('#key-questions');
		let askElement = document.querySelector('#ask');
		cockpitElement.classList.add('ppt');
		innovationAccountingElement.classList.add('ppt');
		keyQuestionsElement.classList.add('ppt');
		askElement.classList.add('ppt');

		// create canvases from HTML elements and add them as images to a slide on the powerpoint
		await this.addImageSlide(presentation, cockpitElement);
		await this.addImageSlide(presentation, innovationAccountingElement);
		await this.addImageSlide(presentation, keyQuestionsElement);
		await this.addImageSlide(presentation, askElement);

		// Remove css from containers
		cockpitElement.classList.remove('ppt');
		innovationAccountingElement.classList.remove('ppt');
		keyQuestionsElement.classList.remove('ppt');
		askElement.classList.remove('ppt');

		// Save and open ppt
		const fileName = 'Pitch ' + this.product.name + ' ' + this.decision.decisionDate.getFullYear() + '-' + (this.decision.decisionDate.getMonth() + 1) + '-' + this.decision.decisionDate.getDate();
		presentation.writeFile({ fileName: fileName });
	}

	async addImageSlide(presentation, htmlElement) {
		const slideWidth = 10;
		const slideHeight = 5.625;
		const marginX = 0.75;
		const marginY = 0.5;

		const imgCanvas = await html2canvas(htmlElement);
		const cockpitImg = imgCanvas.toDataURL();

		let slide = presentation.addSlide();
		slide.addImage({
			data: cockpitImg,
			x: marginX,
			y: marginY,
			w: imgCanvas.width, // the actual width and height of the image, keeping the ratio of the canvas
			h: imgCanvas.height,
			sizing: {
				type: 'contain',
				w: slideWidth - 2 * marginX, // the area the image should be contained in
				h: slideHeight - 2 * marginY,
			},
		});

		// add logo to slide
		const imgWidth = 2.405; // hardcoded from the actual dimensions of the img (481 x 50)
		const imgHeight = 0.25;
		slide.addImage({
			path: window.location.origin + '/assets/img/logo.png',
			x: slideWidth - imgWidth - marginX / 2,
			y: slideHeight - imgHeight - marginY / 2,
			w: imgWidth,
			h: imgHeight,
		});
	}
}
