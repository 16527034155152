<div class="canvas" [ngClass]="{ 'smaller-stages': stages.length > 4 }">
	<div class="top" style="display: flex; overflow-x: scroll">
		<div class="md-col-6 col-lg-3 col-xl segment" *ngFor="let stage of stages">
			<app-canvas-segment [canvas]="canvas" [allStickies]="allStickies" [id]="segments[stage][0]" [allIds]="allIds" location="top" [name]="segments[stage][0]" [canvasVersion]="canvasVersion">
			</app-canvas-segment>
			<!-- </div> -->

			<div class="align-items-center align-content-stretch stages">
				<div class="wrapper" [ngClass]="{ active: canvas.product.stage === stage }">
					<p class="text-center">{{ portfolioService.getStageName(canvas.product.portfolio, stage) }}</p>
				</div>
			</div>

			<!-- <div class="row bottom"> -->
			<app-canvas-segment [canvas]="canvas" [allStickies]="allStickies" [id]="segments[stage][1]" [allIds]="allIds" location="bottom" [name]="segments[stage][1]" [canvasVersion]="canvasVersion">
			</app-canvas-segment>
		</div>
	</div>
</div>
