<div class="container-fluid product" *ngIf="!!product">
	<div class="row">
		<div class="col-12 col-md product-side-bar left" [ngClass]="{ collapsed: this.menuCollapsed }">
			<button class="btn btn-outline-primary toggle-btn" (click)="toggleMenu()"><i class="bi bi-caret-left"></i></button>

			<div class="row header">
				<div class="col-12">
					<div class="product-name" ngbDropdown #productDrop="ngbDropdown">
						<span *ngIf="product && products" id="productDropdown" ngbDropdownToggle class="hand">
							<h4 class="tab">
								<span class="hide-collapsed">{{ product.name }}</span>
							</h4>
							<i class="bi bi-caret-down-fill"></i>
						</span>
						<div ngbDropdownMenu aria-labelledby="productDropdown">
							<a
								[routerLink]="['/portfolio', otherProduct.portfolio.id, 'startup', otherProduct.id]"
								ngbDropdownItem
								[ngClass]="{ 'active-product': product.id === otherProduct.id }"
								*ngFor="let otherProduct of products"
								>{{ otherProduct.name }}</a
							>
						</div>
					</div>
				</div>
				<div class="col-12 mt-2">
					<div *ngIf="product && product.isDemo" class="tab mb-0 badge demo">
						<h5>{{ 'product.demoStartup' | translate | uppercase }}</h5>
						<a [routerLink]="['settings']">{{ 'general.archive' | translate }}</a>
					</div>
				</div>
			</div>

			<div class="tab-container">
				<h5 class="tab" routerLinkActive="active">
					<a [routerLink]="['cockpit']"
						><span class="menu-icon cockpit"></span><span class="hide-collapsed">{{ 'cockpit.singular' | translate }}</span></a
					>
				</h5>
				<h5 class="tab" routerLinkActive="active">
					<a [routerLink]="['progress']"
						><span class="menu-icon progress"></span><span class="hide-collapsed">{{ 'progress.singular' | translate }}</span></a
					>
				</h5>
				<h5 class="tab" routerLinkActive="active">
					<a [routerLink]="['canvas']"
						><span class="menu-icon canvas"></span><span class="hide-collapsed">{{ 'sticky.plural' | translate }}</span></a
					>
				</h5>
				<h5
					class="tab"
					routerLinkActive="active"
				>
					<a [routerLink]="['backlog']">
						<span class="menu-icon backlog"></span>
						<span class="hide-collapsed">{{ 'backlog.singular' | translate }}</span>
					</a>
				</h5>
				<h5
					class="tab"
					routerLinkActive="active"
				>
					<a [routerLink]="['sprint']">
						<span class="menu-icon sprint"></span>
						<span class="hide-collapsed">{{ 'sprint.active' | translate }}</span>
					</a>
				</h5>
				
				<h5 class="tab" routerLinkActive="active">
					<a [routerLink]="['accounting']"
						><span class="menu-icon accounting"></span><span class="hide-collapsed">{{ 'accounting.singular' | translate }}</span></a
					>
				</h5>
			</div>
		</div>
		<div class="col content" [ngClass]="{ 'collapsed-left': this.menuCollapsed }">
			<app-product-not-started [product]="product" *ngIf="!product.isActive()"></app-product-not-started>
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
