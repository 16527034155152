import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Product } from '@shared/models/product';
import { slugifyFile } from '@shared/slugify';
import { LogService } from 'app/log/log.service';
import { ProductService } from 'app/product/product.service';
import { Query } from 'parse';

@Component({
	selector: 'app-product-settings-general',
	templateUrl: './product-settings-general.component.html',
	styleUrls: ['./product-settings-general.component.scss'],
})
export class ProductSettingsGeneralComponent implements OnInit, OnDestroy {
	public product: Product;
	public showProgress = false;
	public progressUpload = 0;
	public errorMessage;
	public resultMessage;
	private routeSubscription;

	constructor(
		private productService: ProductService,
		private logService: LogService,
		private route: ActivatedRoute,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.routeSubscription = this.route.parent.url.subscribe(async (url) => {
			const productId = url[1].path;
			const productquery = new Query(Product);
			this.product = await productquery.get(productId);
		});
	}

	ngOnDestroy() {
		this.routeSubscription?.unsubscribe();
	}

	async onLogoChange(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			const parseFile = new Parse.File(slugifyFile(file.name), file);

			this.showProgress = true;

			await parseFile.save({
				progress: (progressValue, loaded, total, { type }) => {
					if (type === 'upload' && progressValue !== null) {
						// Update the UI using progressValue
						this.progressUpload = progressValue * 100;
					}
				},
			} as any);

			this.product.logo = parseFile;
		}
	}

	downloadData() {
		this.productService.exportData([this.product]);
	}

	async onSave() {
		const dirtyKeys = this.product.dirtyKeys();

		for (let key of dirtyKeys) {
			if (key === 'archived' && this.product.archived != this.product.archived && this.product.archived) {
				this.logService.logActivity('archived startup', 'Product', this.product.id, this.product, this.product.portfolio);
			}
		}

		try {
			await this.product.save();

			this.errorMessage = '';
			this.resultMessage = this.translate.instant('product.successSave');
		} catch (err) {
			this.errorMessage = err.message;
			this.resultMessage = '';
		}
	}
}
