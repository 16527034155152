<div>
	<div class="card hand mb-3 p-3" (click)="openDecision()">
		<div class="row align-items-center">
			<div class="col-2 col-md-1">
				<img *ngIf="product.logo" class="logo" [src]="product.logo.url()" />
			</div>
			<div class="col-10 col-md">
				<h5>{{ product.name }}</h5>
				<p>{{ product.pitch }}</p>
			</div>
			<div class="col-6 col-md-4">
				<p class="header">{{ 'decision.ask' | translate }}</p>
				<p>{{ portfolioService.getDecisionLabel(product.portfolio, decision.decisionMade, decision.fromStage, decision.toStage) }}</p>
			</div>
			<div class="col-6 col-md-2" *ngIf="decision.moneyGranted">
				<p class="header">{{ 'decision.investment' | translate }}</p>
				<p>{{ decision.moneyGranted | currency: product.portfolio.currency:'symbol':'3.0' }}</p>
			</div>
			<!-- <div class="col-1 collapse-icon">
				<i *ngIf="collapsed" class="fal fa-angle-right"></i>
				<i *ngIf="!collapsed" class="fal fa-angle-down"></i>
			</div> -->
		</div>
	</div>
</div>
