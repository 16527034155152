<div class="modal-content">
	<div class="modal-header">
		<p>{{ canvas.name }} {{ 'canvasModalSettings.settings' | translate }}</p>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="this.activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="row form-group">
			<div class="col-6">
				<label
					>{{ 'canvasModalAdd.canvasName' | translate }}
					<input type="text" class="form-control" name="name" [(ngModel)]="shadowCanvas.name" />
				</label>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button class="btn btn-link-danger mr-auto" (click)="deleteCanvas()">{{ 'general.remove' | translate }}</button>
		<button type="button" class="btn btn-primary" (click)="onSave()">{{ 'general.save' | translate }}</button>
	</div>
</div>
