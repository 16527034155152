<div class="modal-header">
	<p class="modal-title" *ngIf="!editable">{{ 'minute.singular' | translate }}</p>
	<p class="modal-title" *ngIf="editable && minute.isNew()">{{ 'minute.new' | translate }}</p>
	<p class="modal-title" *ngIf="editable && !minute.isNew()">{{ 'minute.edit' | translate }}</p>

	<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
		<span aria-hidden="true">&times;</span>
	</button>

	<button class="btn btn-link-white ml-auto" *ngIf="!editable" (click)="makeEditable()">{{ 'general.edit' | translate }}</button>
</div>
<div class="modal-body" *ngIf="!editable">
	<div class="meta">
		<p>{{ minute.createdAt | date }} {{ 'general.by' | translate }} {{ minute.createdBy?.firstName }} {{ minute.createdBy?.lastName }}</p>
	</div>
	<div class="body editor-data" [innerHTML]="minute.getBody()"></div>
</div>
<div class="modal-body" *ngIf="editable">
	<div class="row">
		<div class="col-12 form-group">
			<quill-editor id="notesEditor" [(ngModel)]="shadowMinute.bodyHtml" [styles]="{ height: '15rem' }" [placeholder]="'minuteModal.notes.placeholder' | translate"></quill-editor>
		</div>
	</div>
</div>
<div class="modal-footer">
	<!-- <button class="btn btn-link-danger mr-auto" *ngIf="!minute.isNew()" (click)="deleteTask()">{{ 'general.remove' | translate }}</button> -->

	<button class="btn btn-primary ml-auto" *ngIf="editable && minute.isNew()" (click)="onSave()" [disabled]="!shadowMinute.bodyHtml">
		{{ 'minute.create' | translate }}
	</button>
	<button class="btn btn-primary ml-auto" *ngIf="editable && !minute.isNew()" (click)="onSave()">{{ 'general.save' | translate }}</button>
	<button class="btn btn-primary ml-auto" *ngIf="!editable" (click)="activeModal.close()">{{ 'general.close' | translate }}</button>
</div>
