<div class="modal-content">
	<div class="modal-header align-items-center" [style.background]="sticky.color">
		<p class="modal-title" *ngIf="!editable">{{ 'sticky.singular' | translate }}</p>
		<p class="modal-title" *ngIf="editable && sticky.isNew()">{{ 'sticky.new' | translate }}</p>
		<p class="modal-title" *ngIf="editable && !sticky.isNew()">{{ 'sticky.edit' | translate }}</p>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="discard()">
			<span aria-hidden="true">&times;</span>
		</button>

		<button class="btn btn-link-white ml-auto" *ngIf="!editable" (click)="makeEditable()">{{ 'general.edit' | translate }}</button>
	</div>
	<!-- Edit -->
	<div class="modal-body edit" *ngIf="editable">
		<div class="container-fluid">
			<div class="row">
				<div class="col form-group">
					<label
						>{{ 'sticky.addText' | translate: { segment: getSegmentName() } }}
						<input type="text" class="form-control" [(ngModel)]="sticky.text" ngbAutoFocus />
					</label>
				</div>
			</div>

			<div class="row">
				<div class="col form-group quil-wrapper">
					<label>{{ 'sticky.addDescription' | translate }}</label>
					<quill-editor name="sticky-description" [(ngModel)]="sticky.description" [styles]="{ height: '10rem' }" placeholder=""></quill-editor>
				</div>
			</div>

			<div class="row">
				<div class="col form-group">
					<label for="selectAssumptionColor" class="mb-0">{{ 'color.singular' | translate }}</label>
					<ng-select id="selectAssumptionColor" name="color" [(ngModel)]="sticky.color" [items]="configService.colorAssumptions" class="mb-2">
						<ng-template ng-label-tmp let-item="item">
							<div class="color-block" [style.background]="item"></div>
						</ng-template>
						<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
							<div class="color-block" [style.background]="item"></div>
						</ng-template>
					</ng-select>
				</div>
			</div>

			<div class="row">
				<div class="col-12 form-group range-container">
					<label
						>{{ 'sticky.confidence.question' | translate }}
						<input type="range" min="1" max="10" class="custom-range" [(ngModel)]="sticky.confidenceScore" />
					</label>
					<p class="small left">{{ 'sticky.confidence.min' | translate }}</p>
					<p class="small right">{{ 'sticky.confidence.max' | translate }}</p>
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-12 form-group range-container">
					<label
						>{{ 'sticky.impact.question' | translate }}
						<input type="range" min="1" max="10" class="custom-range" [(ngModel)]="sticky.impactScore" />
					</label>
					<p class="small left">{{ 'sticky.impact.min' | translate }}</p>
					<p class="small right">{{ 'sticky.impact.max' | translate }}</p>
				</div>
			</div>
			<div class="row" *ngIf="!sticky.isNew()">
				<div class="col-12">
					<h4 class="modal-body-header">{{ 'experiment.outcome' | translate }}</h4>
				</div>
				<div class="col">
					<div class="block-container btn-group-toggle" data-toggle="buttons">
						<label class="btn card option" [ngClass]="{ success: sticky.validated === true }">
							<input type="radio" name="validated" id="validated" [(ngModel)]="sticky.validated" [value]="true" />
							<p>{{ 'confirmStages.validated' | translate }}</p>
							<p class="description">{{ 'assumption.validated' | translate }}</p>
							<i class="description" [ngClass]="{ 'bi bi-circle': sticky.validated !== true, 'bi bi-check-circle': sticky.validated === true }"></i>
						</label>
						<label class="btn card option" [ngClass]="{ success: sticky.validated === false }">
							<input type="radio" name="validated" id="invalidated" [(ngModel)]="sticky.validated" [value]="false" />
							<p>{{ 'confirmStages.invalidated' | translate }}</p>
							<p class="description">{{ 'assumption.invalidated' | translate }}</p>
							<i
								class="description"
								[ngClass]="{
									'bi bi-circle': sticky.validated !== false,
									'bi bi-x-circle success': sticky.validated === false
								}"
							></i>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Display -->
	<div class="modal-body" *ngIf="!editable">
		<div class="container-fluid">
			<div class="row mb-2">
				<h2 class="col modal-body-title">{{ sticky.text }}</h2>
			</div>
			<div class="row" *ngIf="!!sticky.description">
				<p class="col editor-data" [innerHtml]="sticky.description"></p>
			</div>
			<div class="row">
				<div class="col-12 col-md-4">
					<h4 class="modal-body-header">{{ 'segment.singular' | translate }}</h4>
					<p>{{ portfolioService.getSegmentName(sticky.canvas, sticky.segment, canvas.type) }}</p>
				</div>
				<div class="col-12 col-md-4">
					<h4 class="modal-body-header hand" (click)="openRiskModal()">{{ 'sticky.riskyness' | translate }} <i class="bi bi-question-circle"></i></h4>
					<p>{{ 'sticky.risk.' + sticky.getRiskyness() | translate }}</p>
				</div>
				<div class="col-12 col-md-4" *ngIf="!!sticky.createdBy">
					<h4 class="modal-body-header">{{ 'general.createdBy' | translate }}</h4>
					<p>
						<app-avatar *ngIf="sticky.createdBy" [user]="sticky.createdBy" [avatarSize]="1"></app-avatar>
						{{ sticky.createdBy?.firstName }} {{ sticky.createdBy?.lastName }}
					</p>
				</div>
			</div>
			<!-- <div class="row" *ngIf="sticky.color">
				<div class="col-12 col-md-6">
					<h4 class="modal-body-header">{{ 'color.singular' | translate }}</h4>
					<div class="color-bar" [style.background]="sticky.color"></div>
				</div>
			</div> -->

			<div class="row mb-1">
				<div class="col-12 form-group range-container">
					<label
						>{{ 'sticky.confidence.question' | translate }}
						<ngb-progressbar [value]="sticky.confidenceScore * 10" height=".25rem" style="margin-top: 4px" title="{{ 'sticky.confidence.question' | translate }}"></ngb-progressbar>
					</label>
					<p class="small left">{{ 'sticky.confidence.min' | translate }}</p>
					<p class="small right">{{ 'sticky.confidence.max' | translate }}</p>
				</div>
			</div>

			<div class="row">
				<div class="col-12 form-group range-container">
					<label
						>{{ 'sticky.impact.question' | translate }}
						<ngb-progressbar [value]="sticky.impactScore * 10" height=".25rem" style="margin-top: 4px" title="{{ 'sticky.impact.question' | translate }}"></ngb-progressbar>
					</label>
					<p class="small left">{{ 'sticky.impact.min' | translate }}</p>
					<p class="small right">{{ 'sticky.impact.max' | translate }}</p>
				</div>
			</div>

			<div class="row" *ngIf="experiments?.length > 0">
				<div class="col-12">
					<h4 class="modal-body-header">{{ 'experiment.linked' | translate }}</h4>
					<ng-container *ngFor="let experiment of experiments">
						<span class="badge badge-secondary light-blue hand mr-2" (click)="openExperiment(experiment)"
							><i class="bi bi-eyedropper"></i> {{ sticky.product.getAbbrev() }}-{{ experiment.number }}</span
						>
					</ng-container>
				</div>
			</div>
			<div class="row" *ngIf="sticky.validated !== true && sticky.validated !== false">
				<div class="col-12">
					<button class="btn btn-outline-primary create-experiment" (click)="newExperiment()">
						<img src="/assets/img/experiment/confirm.svg" />
						{{ 'sticky.test' | translate }}
					</button>
				</div>
			</div>

			<div class="row" *ngIf="sticky.validated === true || sticky.validated === false">
				<div class="col-12">
					<h4 class="modal-body-header">{{ 'experiment.outcome' | translate }}</h4>
				</div>
				<div class="col">
					<div class="block-container btn-group-toggle" data-toggle="buttons">
						<label class="btn card option success" *ngIf="sticky.validated === true">
							<input type="radio" name="validated" id="validated" [(ngModel)]="sticky.validated" [value]="true" />
							<p>{{ 'confirmStages.validated' | translate }}</p>
							<p class="description">{{ 'assumption.validated' | translate }}</p>
							<i class="description" [ngClass]="{ 'bi bi-circle': sticky.validated !== true, 'bi bi-check-circle': sticky.validated === true }"></i>
						</label>
						<label class="btn card option" *ngIf="sticky.validated === false">
							<input type="radio" name="validated" id="invalidated" [(ngModel)]="sticky.validated" [value]="false" />
							<p>{{ 'confirmStages.invalidated' | translate }}</p>
							<p class="description">{{ 'assumption.invalidated' | translate }}</p>
							<i
								class="description"
								[ngClass]="{
									'bi bi-circle': sticky.validated !== false,
									'bi bi-x-circle success': sticky.validated === false
								}"
							></i>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer mt-5">
		<button class="btn btn-link-danger mr-auto" *ngIf="!sticky.isNew()" (click)="deleteSticky()">
			{{ 'general.remove' | translate }}
		</button>

		<button class="btn btn-primary ml-auto" *ngIf="editable && sticky.isNew()" (click)="createSticky()" [disabled]="!isComplete()">
			{{ 'sticky.create' | translate }}
		</button>
		<button class="btn btn-primary ml-auto" *ngIf="editable && !sticky.isNew()" (click)="saveEditable()" [disabled]="!isComplete()">
			{{ 'general.save' | translate }}
		</button>
		<button class="btn btn-primary ml-auto" *ngIf="!editable" (click)="activeModal.close()" ngbAutoFocus>
			{{ 'general.close' | translate }}
		</button>
	</div>
</div>
