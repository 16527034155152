<div class="container page">
	<div class="row" *ngIf="!!decisions">
		<!-- Portfolio Board decisions -->
		<div class="col-12 col-md-8 pr-5">
			<div class="row title align-items-center">
				<div class="col-12">
					<h1>{{ 'decision.pitch.plural' | translate }}</h1>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<ng-container *ngFor="let decision of preparedDecisions">
						<app-portfolio-board-row [product]="decision.product" [decision]="decision"></app-portfolio-board-row>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="col-12 col-md-4 .portfolio-sidebar">
			<div class="d-flex flex-row justify-content-between align-items-center title">
				<h3>{{ 'decision.all' | translate }}</h3>
				<button class="btn btn-outline-primary new-decision circle" (click)="newDecision()"><i class="bi bi-plus"></i></button>
			</div>
			<div class="row">
				<div class="col decision-container">
					<div *ngFor="let decision of latestDecisions" class="d-flex flex-row justify-content-left align-items-center decision-row hand" (click)="openDecision(decision)">
						<div *ngIf="decision.decisionMade === DecisionType.MOVEFWD" class="circle icon forward"></div>
						<div *ngIf="decision.decisionMade === DecisionType.REMAIN" class="circle icon remain"></div>
						<div *ngIf="decision.decisionMade === DecisionType.START" class="circle icon start"></div>
						<div *ngIf="decision.decisionMade === DecisionType.STOP" class="circle icon stop"></div>
						<div *ngIf="decision.decisionMade === DecisionType.MOVEBACK" class="circle icon back"></div>

						<div class="decision-content">
							<p class="decision-title">
								<strong>{{ decision.product.name }}:</strong>
								{{ portfolioService.getDecisionLabel(decision.product.portfolio, decision.decisionMade, decision.fromStage, decision.toStage) }}
							</p>
							<div class="d-flex flex-row justify-content-left decision-info">
								<p><i class="bi bi-calendar3"></i>{{ decision.decisionDate | date }}</p>
								<p *ngIf="decision.nextMeetingDate"><i class="bi bi-calendar3"></i>{{ decision.nextMeetingDate | date }}</p>
								<p *ngIf="decision.moneyGranted"><i class="bi bi-piggy-bank"></i>{{ decision.moneyGranted | currency: decision.product.portfolio.currency:'symbol':'3.0' }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
