import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Portfolio } from '@shared/models/portfolio';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Cloud, LiveQuerySubscription, Query } from 'parse';
import { Cockpit } from '@shared/models/cockpit';
import { CockpitFieldModalComponent } from 'app/cockpit/cockpit-field-modal/cockpit-field-modal.component';

@Component({
	selector: 'app-portfolio-settings-cockpit',
	templateUrl: './portfolio-settings-cockpit.component.html',
	styleUrls: ['./portfolio-settings-cockpit.component.scss'],
})
export class PortfolioSettingsCockpitComponent implements OnInit {
	public cockpits: Cockpit[];
	public portfolio: Portfolio;

	constructor(private portfolioService: PortfolioService, private modalService: NgbModal) {}

	async ngOnInit() {
		this.portfolio = await this.portfolioService.getActivePortfolio();
		const query = new Query(Cockpit);
		query.equalTo('portfolio', this.portfolio);
		query.equalTo('archived', false);
		query.ascending('orderIndex');
		this.cockpits = await query.find();
	}

	async openModal(cockpit: Cockpit) {
		const modalRef = this.modalService.open(CockpitFieldModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.cockpit = cockpit;

		const result = await modalRef.result;
		if (result == 'deleted') {
			// remove lens from lenses
			this.cockpits.splice(
				this.cockpits.findIndex((l) => l.id == cockpit.id),
				1
			);
		}
	}

	async addCockpit() {
		let cockpit = new Cockpit();
		cockpit.portfolio = this.portfolio;
		const modalRef = this.modalService.open(CockpitFieldModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.cockpit = cockpit;
		modalRef.componentInstance.editable = true;

		const result = await modalRef.result;
		if (result) {
			this.cockpits.push(result);
		}
	}

	dropItem(event: CdkDragDrop<any[]>) {
		let object = event.item.data;
		
		Cloud.run('reorderObjects', {
			objectName: 'Cockpit',
			objectId: object.id,
			newIndex: event.currentIndex,
		});

		// update visuals
		moveItemInArray(this.cockpits, this.cockpits.indexOf(object), event.currentIndex);
	}
}
