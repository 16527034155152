<div class="container login">
	<div class="row justify-content-center mt-5">
		<form class="col-12 col-md-4">
			<div class="row mb-3">
				<h1 view-title class="col-12">{{ 'validateEmail.title' | translate }}</h1>
				<p class="col-12">{{ 'validateEmail.description' | translate }}</p>
			</div>
			<div class="row mb-3" *ngIf="errorMessage">
				<div class="col-12">
					<ngb-alert type="danger" [dismissible]="false">
						{{ errorMessage }}
					</ngb-alert>
				</div>
			</div>
			<div class="row mb-3" *ngIf="resultMessage">
				<div class="col-12">
					<ngb-alert type="success" [dismissible]="false">
						{{ resultMessage }}
					</ngb-alert>
				</div>
			</div>
			<div class="row">
				<div class="col-12 form-group">
					<label
						>{{ 'login.email' | translate }}
						<input type="email" class="form-control" placeholder="" name="email" [(ngModel)]="email" />
					</label>
				</div>
			</div>

			<div class="row align-content-center">
				<div class="col-12">
					<button type="submit" class="btn btn-primary" (click)="onRequest()">{{ 'validateEmail.requestNew' | translate }}</button>
				</div>
			</div>
		</form>
	</div>
</div>
