import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Lens } from '@shared/models/lens';
import { Product } from '@shared/models/product';
import { Query } from 'parse';

@Component({
	selector: 'app-product-settings-cockpit',
	templateUrl: './product-settings-cockpit.component.html',
	styleUrls: ['./product-settings-cockpit.component.scss'],
})
export class ProductSettingsCockpitComponent implements OnInit, OnDestroy {
	public product: Product;
	private routeSubscription;
	public errorMessage: string;
	public resultMessage: string;
	public lenses: Lens[];

	get LensValues() {
		return Lens.LensValues;
	}

	constructor(private route: ActivatedRoute, private translate: TranslateService) {}

	ngOnInit(): void {
		this.routeSubscription = this.route.parent.url.subscribe(async (url) => {
			const productId = url[1].path;
			const productquery = new Query(Product);
			this.product = await productquery.get(productId);
			this.getLenses();
		});
	}

	ngOnDestroy() {
		this.routeSubscription?.unsubscribe();
	}

	async onSave() {
		try {
			await this.product.save();

			this.errorMessage = '';
			this.resultMessage = this.translate.instant('product.successSave');
		} catch (err) {
			this.errorMessage = err.message;
			this.resultMessage = '';
		}
	}

	async getLenses() {
		const lensQuery = new Query(Lens);
		lensQuery.equalTo('archived', false);
		lensQuery.equalTo('portfolio', this.product.portfolio);
		this.lenses = await lensQuery.findAll();
	}
}
