<ng-container *ngIf="!!currentQuarter">
	<!-- cockpit info -->
	<app-product-info [product]="decision.product"></app-product-info>

	<!-- Innovation accounting -->
	<div class="row" id="innovation-accounting">
		<div class="col-12 col-md-2">
			<p class="header">{{ 'accounting.innovation' | translate }}</p>
		</div>
		<div class="col-12 col-md-10">
			<div class="row">
				<div class="col-12 card-container" *ngIf="!!stagesSummaryIA">
					<app-summary-card
						[product]="decision.product"
						[totalTimeSpent]="stagesSummaryIA['totalTimeSpent']"
						[totalMoneySpent]="stagesSummaryIA['totalMoneySpent']"
						[totalMoneyReceived]="stagesSummaryIA['totalMoneyReceived']"
					></app-summary-card>
				</div>
			</div>
			<div class="row">
				<div class="col card-container" *ngIf="!!stagesSummaryIA">
					<app-stages-card
						[product]="decision.product"
						[timeSpentStages]="stagesSummaryIA['timeSpent']"
						[moneyReceivedStages]="stagesSummaryIA['moneyReceived']"
						[moneySpentStages]="stagesSummaryIA['moneySpent']"
					></app-stages-card>
				</div>
			</div>
			<!-- <div class="row">
				<div class="col-12 col-md-6 card-container">
					<app-team-happiness-card [happiness]="latestHappiness" [data]="sprints" (openChart)="openHappinessModal()"></app-team-happiness-card>
				</div>
				<div class="col-12 col-md-6 card-container">
					<app-team-knowledge-card [knowledge]="latestEducation" [data]="sprints" (openChart)="openKnowledgeModal()"></app-team-knowledge-card>
				</div>
			</div> -->
			<div class="row">
				<div class="col-12 col-md-4 card-container">
					<app-velocity-card [title]="'innovationAccounting.experimentVelocity' | translate" [fraction]="currentQuarter?.experimentVelocity / currentQuarter?.weeks || 0"></app-velocity-card>
				</div>
				<div class="col-12 col-md-4 card-container">
					<app-velocity-card
						[title]="'innovationAccounting.learningRatio' | translate"
						[fraction]="currentQuarter?.learningVelocity / currentQuarter?.experimentVelocity || 0"
					></app-velocity-card>
				</div>
				<div class="col-12 col-md-4 card-container">

					<app-learning-cost-card
						[title]="'innovationAccounting.costPerLearning' | translate"
						[cost]="currentQuarter?.costsMade / currentQuarter?.learningVelocity || 0"
						[currency]="decision.product.portfolio.currency"
					></app-learning-cost-card>
				</div>
			</div>
		</div>
	</div>

	<!-- experiments-->
	<div class="row">
		<div class="col-12 col-md-2">
			<p class="header">{{ 'experiment.plural' | translate }}</p>
		</div>
		<div class="col-12 col-md-10">
			<div class="row">
				<div *ngFor="let experiment of experiments" class="col-4 mb-4">
					<app-experiment-card [experiment]="experiment" [product]="decision.product"></app-experiment-card>
				</div>
			</div>
		</div>
	</div>

	
	<!-- key questions -->
	<div class="row" id="key-questions">
		<div class="col-12 col-md-2">
			<p class="header">{{ 'keyQuestion.plural' | translate }}</p>
		</div>
		<div class="col-12 col-md-10">
			<ng-container *ngFor="let object of learningsPerQuestion">
				<div class="row align-items-center mb-2">
					<p class="col-12 col-md-9 header mb-0" *ngIf="object.question.default">
						{{ portfolioService.getStageName(decision.product.portfolio, object.question.stage) }}: {{ 'stageGateQuestions.' + object.question.stage + '.' + object.question.question | translate }}
					</p>
					<p class="col-12 col-md-9 header mb-0" *ngIf="!object.question.default">{{ object.question.question }}</p>
					<div class="col-12 col-md-3 range-container">
						<div class="progress">
							<div
								class="progress-bar"
								role="progressbar"
								[style.width.%]="((decision.product.activeProgress.questions[object.question.id] - 1) / 4) * 100 || 0"
								aria-valuemin="1"
								aria-valuemax="5"
							></div>
						</div>
						<p class="left">{{ 'progress.notConfident' | translate }}</p>
						<p class="right">{{ 'progress.confident' | translate }}</p>
					</div>
				</div>
				<div class="row">
					<div *ngFor="let learning of object.learnings" class="col-4 learning-container mb-4">
						<app-learning-card [learning]="learning" [showExperimentTag]="true"></app-learning-card>
					</div>
				</div>
			</ng-container>
		</div>
	</div>

	<!-- most risky assumptions-->
	<div class="row">
		<div class="col-12 col-md-2">
			<p class="header">{{ 'sticky.risk.most-risky' | translate }} {{ 'sticky.plural' | translate }}</p>
		</div>
		<div class="col-12 col-md-10">
			<div class="row">
				<div *ngFor="let sticky of riskyStickies" class="col-4 mb-4">
					<app-canvas-sticky [sticky]="sticky" [canvas]="sticky.canvas" [canOpen]="true"></app-canvas-sticky>		
				</div>
			</div>
		</div>
	</div>

	<!-- supporting documents -->
	<div class="row">
		<div class="col-12 col-md-2">
			<p class="header">{{ 'attachment.supporting' | translate }}</p>
		</div>
		<div class="col-12 col-md-10">
			<div *ngIf="decision.attachments.length > 0" class="row mt-2">
				<div *ngFor="let file of decision.attachments" class="col-12 col-md-4 mb-2">
					<app-attachment-card-big [fileMeta]="file" (delete)="removeFile($event)"></app-attachment-card-big>
				</div>
			</div>
			<p *ngIf="decision.attachments?.length === 0">-</p>
		</div>
	</div>

	<!-- decision -->
	<div class="row" id="ask">
		<div class="col-12 col-md-2">
			<p class="header">{{ 'decision.ask' | translate }}</p>
		</div>
		<div class="col-12 col-md-10">
			<div class="row mb-3">
				<div class="col-12 col-md-6">
					<div class="row mb-0 decision-row align-items-center">
						<div class="col-12 col-md-2">
							<div *ngIf="decision.decisionMade === DecisionType.MOVEFWD" class="circle icon forward"></div>
							<div *ngIf="decision.decisionMade === DecisionType.REMAIN" class="circle icon remain"></div>
							<div *ngIf="decision.decisionMade === DecisionType.START" class="circle icon start"></div>
							<div *ngIf="decision.decisionMade === DecisionType.STOP" class="circle icon stop"></div>
							<div *ngIf="decision.decisionMade === DecisionType.MOVEBACK" class="circle icon back"></div>
						</div>
						<div class="col-12 col-md-10">
							<div class="decision-content">
								<p class="decision-title">
									{{ portfolioService.getDecisionLabel(decision.product.portfolio, decision.decisionMade, decision.fromStage, decision.toStage) }}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-6 col-md-3">
					<p class="header">{{ 'decision.investment' | translate }}</p>
					<p>{{ decision.moneyGranted | currency: decision.product.portfolio.currency:'symbol':'3.0' }}</p>
				</div>
				<div class="col-6 col-md-3">
					<p class="header">{{ 'decision.duration' | translate }}</p>
					<p>{{ duration }} {{ 'months.plural' | translate }}</p>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<p class="header">{{ 'decision.goal' | translate }}</p>
					<p>{{ decision.goal }}</p>
				</div>
			</div>
		</div>
	</div>
</ng-container>
