<div class="container-fluid">
	<div class="row">
		<div class="col-12 col-md-3 nav-container">
			<nav class="nav flex-column">
				<h1>{{ 'adminNav.title' | translate }}</h1>
				<a class="nav-link" [routerLink]="['portfolios']" [ngClass]="{ active: activeTab === 'portfolios' }">{{
					'portfolio.plural' | translate
				}}</a>
				<a class="nav-link" [routerLink]="['admin-users']" [ngClass]="{ active: activeTab === 'admin-users' }">{{
					'admin.users' | translate
				}}</a>
				<a class="nav-link" [routerLink]="['experiments']" [ngClass]="{ active: activeTab === 'experiments' }">{{
					'experiment.overview' | translate
				}}</a>
				<a class="nav-link" [routerLink]="['tools']" [ngClass]="{ active: activeTab === 'tools' }">{{ 'tool.overview' | translate }}</a>
			</nav>
		</div>
		<div class="col-12 col-lg-6 col-md-9 pt-3 pl-3">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
