import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Canvas } from './canvas';
import { ContentExperiment } from './content-experiment';
import { FileMeta } from './file-meta';
import { Insight } from './insight';
import { Learning } from './learning';
import { Product } from './product';
import { Sprint } from './sprint';
import { Sticky } from './sticky';
import { User } from './user';

@ParseSubClass('Experiment')
export class Experiment extends Parse.Object {
	@ParseProperty() public description: string;
	@ParseProperty() public canvas: Canvas;
	@ParseProperty() public product: Product;

	@ParseProperty() public sticky: Sticky;
	@ParseProperty() public segment: string;
	@ParseProperty() public number: number;

	@ParseProperty() public type: string;
	@ParseProperty() public status: Experiment.Status;

	@ParseProperty() public hypothesis: string;
	@ParseProperty() public learninggoal: string;
	@ParseProperty() public riskyassumption: string;
	@ParseProperty() public metric: string;
	@ParseProperty() public successcriteria: string;
	@ParseProperty() public nextsteps: string;
	@ParseProperty() public why_outcome: string;

	@ParseProperty() public validated: boolean;
	@ParseProperty() public validatedAt: Date;

	@ParseProperty() public startAt: Date;
	@ParseProperty() public startSprint: Sprint;

	@ParseProperty() public endAt: Date;
	@ParseProperty() public endSprint: Sprint;

	@ParseProperty() public createdBy: User;
	@ParseProperty() public updatedBy: User;

	@ParseProperty() public hasLearnings: boolean;
	@ParseProperty() public learnings: Learning[];
	@ParseProperty() public insights: Insight[];

	@ParseProperty() public archived: boolean;
	@ParseProperty() public attachments: FileMeta[];
	@ParseProperty() public contentExperiment: ContentExperiment;
	@ParseProperty() public cost: number;

	constructor() {
		super('Experiment');
	}

	getResult() {
		// setup experiment result
		if (this.type === 'learn') {
			if (this.hasLearnings === true) {
				return 'true';
			} else if (this.hasLearnings === false) {
				return 'failed';
			}
		} else {
			if (this.hasLearnings === false) {
				return 'failed';
			} else {
				if (this.validated === true) {
					return 'validated';
				} else if (this.validated === false) {
					return 'invalidated';
				}
			}
		}
	}

	setResult(result: string) {
		if (result === 'failed') {
			this.hasLearnings = false;
		} else {
			this.hasLearnings = true;
		}

		if (this.type === 'confirm') {
			if (result === 'validated') {
				this.validated = true;
			} else if (result == 'invalidated') {
				this.validated = false;
			}
		}
	}

	getStage() {
		switch (this.segment) {
			case 'customersegment':
			case 'problem':
				return Product.Stage.PROBLEM;
			case 'valueproposition':
			case 'solution':
				return Product.Stage.SOLUTION;
			case 'metrics':
			case 'revenuemodel':
				return Product.Stage.REVENUE;
			case 'channels':
			case 'growthengine':
				return Product.Stage.SCALE;
			case 'prevalidation':
				return Product.Stage.KICKBOX;
			default:
				return undefined;
		}
	}
}

export namespace Experiment {
	export enum Status {
		PREPARING = 'prepared',
		RUNNING = 'started',
		ENDED_DEPRECATED = 'ended', // DEPRECATED
		DONE = 'analyzed',
	}
}
