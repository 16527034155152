import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NextTitleService } from 'app/next-title.service';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import * as Parse from 'parse';
import { Product } from '../../../../shared/models/product';
import { ProductService } from '../product.service';
import { Portfolio } from '../../../../shared/models/portfolio';

@Component({
	selector: 'app-product-detail',
	templateUrl: './product-detail.component.html',
	styleUrls: ['./product-detail.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ProductDetailComponent implements OnInit, OnDestroy {
	public product: Product;
	public products: Product[];
	public menuCollapsed = false;
	private routeSubscription;
	private productsSubscription: Parse.LiveQuerySubscription;

	get PortfolioSprintType() {
		return Portfolio.SprintType;
	}

	get ProductStage() {
		return Product.Stage;
	}

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		public productService: ProductService,
		private titleService: NextTitleService,
		public modalService: NgbModal,
		public portfolioService: PortfolioService
	) {}

	async ngOnInit() {
		if (localStorage.getItem('left-bar-collapsed') === 'true') {
			this.menuCollapsed = true;
		}

		this.routeSubscription = this.route.params.subscribe((params) => {
			this.getProduct(params['id']);
		});

		const portfolio = await this.portfolioService.getActivePortfolio();

		let productsQuery = new Parse.Query(Product);
		productsQuery.equalTo('portfolio', portfolio);
		productsQuery.equalTo('archived', false);
		productsQuery.ascending('name');

		this.productsSubscription = await productsQuery.subscribe();

		this.products = await productsQuery.find();
	}

	ngOnDestroy() {
		this.routeSubscription?.unsubscribe();
		this.productsSubscription?.unsubscribe();
	}

	async getProduct(id) {
		// we need to create empty product and set it
		// to prevent race conditions in child components
		this.product = new Product();
		this.product.id = id;

		await this.productService.setActiveProduct(this.product);

		if (!this.product) {
			this.router.navigate(['/']);
		}

		// Set page title
		this.titleService.setTitle(this.product.name);
	}

	toggleMenu() {
		this.menuCollapsed = !this.menuCollapsed;

		localStorage.setItem('left-bar-collapsed', '' + this.menuCollapsed);
	}

	async createProduct() {
		const portfolio = await this.portfolioService.getActivePortfolio();

		this.productService.createProduct(portfolio);
	}
}
