<div class="wrapper hand" (click)="openMinute()">
	<div class="meta">
		<p>{{ minute.createdAt | date }} {{ 'general.by' | translate }} {{ minute.createdBy?.firstName }} {{ minute.createdBy?.lastName }}</p>
	</div>
	<div class="edit" *ngIf="!!minute.actionables">
		<h5>{{ 'minute.actionables' | translate }}</h5>
		<p class="editor-data" [innerHTML]="minute.getActionables()"></p>
	</div>
	<div class="body edit editor-data" [innerHTML]="minute.getBody()"></div>
</div>
