import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanvasModalSettingsComponent } from 'app/canvas/canvas-modal-settings/canvas-modal-settings.component';
import { CanvasService } from 'app/canvas/canvas.service';
import { ExperimentModalAddComponent } from 'app/experiment/experiment-modal-add/experiment-modal-add.component';
import { NextTitleService } from 'app/next-title.service';
import * as Parse from 'parse';
import { Canvas } from '../../../../shared/models/canvas';
import { Experiment } from '../../../../shared/models/experiment';
import { Product } from '../../../../shared/models/product';
import { Sticky } from '../../../../shared/models/sticky';
import { ProductService } from '../product.service';
import { AnalyticsService } from 'app/shared/analytics.service';
import { StickyRiskModalComponent } from 'app/sticky/sticky-risk-modal/sticky-risk-modal.component';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { StickyService } from 'app/sticky/sticky.service';

@Component({
	selector: 'app-product-canvas-detail',
	templateUrl: './canvas-detail.component.html',
	styleUrls: ['./canvas-detail.component.scss'],
})
export class ProductCanvasDetailComponent implements OnInit, OnDestroy {
	@Input() product: Product;
	public canvas: Canvas;
	public canvases: Canvas[];
	public activeVersion: Date;
	public versionIndex;
	public stickies: Sticky[];
	public mostRiskyStickies: Sticky[] = [];
	public riskyStickies: Sticky[] = [];
	public confirmStickies: Sticky[] = [];
	public menuCollapsed = false;
	public stages = [];

	private stickySubscription: Parse.LiveQuerySubscription;
	private routerSubscription;
	private productSubscription: Parse.LiveQuerySubscription;
	private canvasSubscription: Parse.LiveQuerySubscription;

	constructor(
		private route: ActivatedRoute,
		private productService: ProductService,
		private portfolioService: PortfolioService,
		private canvasService: CanvasService,
		private modalService: NgbModal,
		private stickyService: StickyService,
		private titleService: NextTitleService,
		private analytics: AnalyticsService
	) {}

	ngOnInit() {
		// this.analytics.track('Opened canvas');

		this.routerSubscription = this.route.params.subscribe((params) => {
			this.fetchData(params.canvas);
		});
	}

	ngOnDestroy() {
		this.unsubscribe();
		this.routerSubscription?.unsubscribe();
	}

	async fetchData(id?: string) {
		this.unsubscribe();

		this.product = await this.productService.getActiveProduct();

		const productQuery = new Parse.Query(Product);
		productQuery.equalTo('objectId', this.product.id);
		productQuery.include('canvases');
		this.productSubscription = await productQuery.subscribe();
		this.productSubscription.on('update', (product: Product) => {
			this.product = product;
			this.loadCanvases();
		});

		this.stages = this.portfolioService.getMainStages(this.product.portfolio);

		this.loadCanvases();

		if (this.canvases.length === 0) {
			return;
		}

		if (id) {
			for (let canvas of this.canvases) {
				if (canvas.id === id) {
					this.canvas = canvas;
					this.canvasService.setActiveCanvas(canvas);
				}
			}
		} else {
			this.canvas = await this.canvasService.getActiveCanvas(this.product);
		}

		this.displayCanvas();
	}

	async loadCanvases() {
		// Show newest canvases first
		// We have to do it this way, since reverse, reverses the original array as well
		this.canvases = [...this.product.canvases].reverse();
	}

	async displayCanvas() {
		if (this.canvas.versionDates !== undefined && this.canvas.versionDates.length > 0) {
			this.activeVersion = this.canvas.versionDates[this.canvas.versionDates.length - 1];
			this.versionIndex = this.canvas.versionDates.length - 1;
		}

		// Set page title
		this.titleService.setTitle(this.canvas.name + ' - ' + this.product.name);

		// subscribe canvas
		const canvasQuery = new Parse.Query(Canvas);
		canvasQuery.equalTo('objectId', this.canvas.id);
		this.canvasSubscription = await canvasQuery.subscribe();
		this.canvasSubscription.on('update', (updatedCanvas: Canvas) => {
			this.canvas = updatedCanvas;
			this.activeVersion = this.canvas.versionDates[this.canvas.versionDates.length - 1];
			this.versionIndex = this.canvas.versionDates.length - 1;
		});

		// listen to sticky updates
		const stickyQuery = new Parse.Query(Sticky);
		stickyQuery.equalTo('canvas', this.canvas);
		stickyQuery.include('createdBy');
		stickyQuery.ascending('orderIndex');

		this.stickySubscription = await stickyQuery.subscribe();

		this.stickySubscription.on('create', (sticky) => {
			// created stickies always get highest index so ordering is not changed.
			this.stickies.push(sticky as Sticky);

			this.filterRiskyness();
		});

		this.stickySubscription.on('update', (sticky: Sticky) => {
			let newStickies = [...this.stickies];

			// update sticky
			// newStickies[this.stickies.findIndex((s) => s.id == sticky.id)] = sticky;

			newStickies.sort((a, b) => {
				if (a.orderIndex > b.orderIndex) {
					return 1;
				} else if (a.orderIndex < b.orderIndex) {
					return -1;
				}

				return 0;
			});

			this.stickies = newStickies;

			this.filterRiskyness();
		});

		// query all stickies
		this.queryStickies();
	}

	filterRiskyness() {
		const riskyness = this.stickyService.filterRiskyness(this.stickies, this.product);

		this.mostRiskyStickies = riskyness['most-risky'];
		this.riskyStickies = riskyness['risky'];
		this.confirmStickies = riskyness['confirm'];
	}

	async queryStickies() {
		const stickyQuery = new Parse.Query(Sticky);
		stickyQuery.equalTo('canvas', this.canvas);
		stickyQuery.equalTo('archived', false);
		stickyQuery.include('createdBy');
		stickyQuery.ascending('orderIndex');
		stickyQuery.limit(999);
		this.stickies = await stickyQuery.find();

		this.filterRiskyness();
	}

	isOldest() {
		return this.versionIndex === 0;
	}

	isNewest() {
		return this.versionIndex === this.canvas.versionDates.length - 1;
	}

	isCurrent() {
		return this.versionIndex === this.canvas.versionDates.length - 1;
	}

	showNextVersion() {
		if (this.versionIndex < this.canvas.versionDates.length - 1) {
			this.versionIndex++;
			this.activeVersion = this.canvas.versionDates[this.versionIndex];
		}
	}

	showPreviousVersion() {
		if (this.versionIndex > 0) {
			this.versionIndex--;
			this.activeVersion = this.canvas.versionDates[this.versionIndex];
		}
	}

	addExperiment() {
		const experiment = new Experiment();

		const modalRef = this.modalService.open(ExperimentModalAddComponent, {
			size: 'md',
		});
		modalRef.componentInstance.experiment = experiment;
		modalRef.componentInstance.canvas = this.canvas;
	}

	saveEditable() {
		this.canvas.save();

		// Set page title
		this.titleService.setTitle(this.canvas.name);
	}

	openSettings() {
		const modalRef = this.modalService.open(CanvasModalSettingsComponent, {
			size: 'md',
		});
		modalRef.componentInstance.canvas = this.canvas;
		modalRef.componentInstance.save.subscribe(($e) => {
			this.canvas.save();
		});
	}

	unsubscribe() {
		if (this.stickySubscription) {
			this.stickySubscription.unsubscribe();
		}

		if (this.productSubscription) {
			this.productSubscription.unsubscribe();
		}

		if (this.canvasSubscription) {
			this.canvasSubscription.unsubscribe();
		}
	}

	switchCanvas(canvas) {
		this.canvas = canvas;

		this.unsubscribe();
		this.displayCanvas();
	}

	toggleMenu() {
		this.menuCollapsed = !this.menuCollapsed;

		localStorage.setItem('left-bar-collapsed', '' + this.menuCollapsed);
	}

	async createCanvas() {
		this.canvasService.createCanvas(this.product);
	}

	openRiskModal() {
		this.modalService.open(StickyRiskModalComponent, {
			size: 'sm',
		});
	}
}
