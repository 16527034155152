import { Component, Input } from '@angular/core';
import { Product } from '../../../../shared/models/product';
import { Task } from '../../../../shared/models/task';

@Component({
	selector: 'app-task-row',
	templateUrl: './task-row.component.html',
	styleUrls: ['./task-row.component.scss'],
})
export class TaskRowComponent {
	@Input() task: Task;
	@Input() product: Product;

	constructor() {}
}
