import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { File } from 'parse';
import { Portfolio } from '../../../../shared/models/portfolio';
import { Product } from '../../../../shared/models/product';

@Component({
	selector: 'app-product-modal-add',
	templateUrl: './product-modal-add.component.html',
	styleUrls: ['./product-modal-add.component.scss'],
})
export class ProductModalAddComponent implements OnInit {
	@Input() product: Product;
	@Output() save: EventEmitter<any> = new EventEmitter();
	public portfolio: Portfolio;
	public showProgress = false;
	public progressUpload = 0;

	constructor(public activeModal: NgbActiveModal, private portfolioService: PortfolioService) {}

	async ngOnInit() {
		this.product.stage = Product.Stage.KICKBOX;

		this.portfolio = await this.portfolioService.getActivePortfolio();
	}

	onSave() {
		this.save.emit({ product: this.product });
		this.activeModal.close(true);
	}

	async onLogoChange(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			const parseFile = new File(file.name, file);

			this.showProgress = true;

			await await parseFile.save({
				progress: (progressValue, loaded, total, { type }) => {
					if (type === 'upload' && progressValue !== null) {
						// Update the UI using progressValue
						this.progressUpload = progressValue * 100;
					}
				},
			} as any);

			this.product.logo = parseFile;
		}
	}
}
