import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartsModule } from 'ng2-charts';
import { QuillModule } from 'ngx-quill';
import { MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

import Appsignal from '@appsignal/javascript';
import { createErrorHandlerFactory } from '@appsignal/angular';

import { plugin as pluginBreadcrumbsNetwork } from '@appsignal/plugin-breadcrumbs-network';
import { plugin as pluginBreadcrumbsConsole } from '@appsignal/plugin-breadcrumbs-console';
import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator';
import { plugin as pluginWindowEvents } from '@appsignal/plugin-window-events';

import { AppRoutingModule } from './app-routing.module';

import { ParseService } from './parse.service';

import { AppComponent } from './app.component';
import { LoginComponent } from './authenticate/login/login.component';
import { NewTenantComponent } from './authenticate/new-tenant/new-tenant.component';
import { SignupComponent } from './authenticate/signup/signup.component';
import { HeaderComponent } from './layout/header/header.component';

import { KeysPipe } from './shared/keys.pipe';
import { AnalyticsService } from './shared/analytics.service';
import { AccessGuard } from './authenticate/access.guard';
import { ProductService } from './product/product.service';
import { ProductCardComponent } from './product/card/card.component';
import { CanvasComponent } from './canvas/canvas/canvas.component';
import { CanvasSegmentComponent } from './canvas/segment/segment.component';
import { CanvasStickyComponent } from './sticky/sticky/sticky.component';
import { CanvasMiniComponent } from './canvas/mini/mini.component';
import { SprintService } from './sprint/sprint.service';
import { ProductCanvasDetailComponent } from './product/canvas-detail/canvas-detail.component';
import { CanvasService } from './canvas/canvas.service';
import { StickyModalComponent } from './sticky/sticky-modal/sticky-modal.component';
import { ProductDetailComponent } from './product/product-detail/product-detail.component';
import { InsightModalComponent } from './insight/insight-modal/insight-modal.component';
import { InsightCardComponent } from './insight/insight-card/insight-card.component';
import { LearningModalComponent } from './learning/learning-modal/learning-modal.component';
import { LearningCardComponent } from './learning/learning-card/learning-card.component';
import { InlineEditorModule } from './shared/inline-editor/inline-editor.module';
import { ExperimentCardComponent } from './experiment/experiment-card/experiment-card.component';
import { ExperimentModalAddComponent } from './experiment/experiment-modal-add/experiment-modal-add.component';
import { ExperimentModalComponent } from './experiment/experiment-modal/experiment-modal.component';
import { ProductModalAddComponent } from './product/product-modal-add/product-modal-add.component';
import { NltobrPipe } from './shared/nltobr.pipe';
import { ProductSettingsComponent } from './product-settings/product-settings/product-settings.component';
import { ProfileComponent } from './user/profile/profile.component';
import { HomeComponent } from './layout/home/home.component';
import { PortfolioDetailComponent } from './portfolio/portfolio-detail/portfolio-detail.component';
import { FunnelComponent } from './portfolio/funnel/funnel.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { PreValidationComponent } from './canvas/pre-validation/pre-validation.component';
import { MiniPreValidationComponent } from './canvas/mini-pre-validation/mini-pre-validation.component';
import { MiniNextCanvasComponent } from './canvas/mini-next-canvas/mini-next-canvas.component';
import { CanvasModalAddComponent } from './canvas/canvas-modal-add/canvas-modal-add.component';
import { PortfolioProductsComponent } from './portfolio/portfolio-products/portfolio-products.component';
import { StageProductComponent } from './charts/stage-product/stage-product.component';
import { StageAverageInvestmentComponent } from './charts/stage-average-investment/stage-average-investment.component';
import { StageTimeSpentComponent } from './charts/stage-time-spent/stage-time-spent.component';
import { MinuteComponent } from './minutes/minute/minute.component';
import { MinuteModalComponent } from './minutes/minute-modal/minute-modal.component';
import { AdminGuard } from './authenticate/admin.guard';
import { AdminComponent } from './admin/admin/admin.component';
import { PortfolioSettingsComponent } from './portfolio-settings/portfolio-settings/portfolio-settings.component';
import { PortfolioModalAddComponent } from './portfolio/portfolio-modal-add/portfolio-modal-add.component';
import { ForgottenPasswordComponent } from './authenticate/forgotten-password/forgotten-password.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CanvasModalSettingsComponent } from './canvas/canvas-modal-settings/canvas-modal-settings.component';
import { MiniSegmentComponent } from './canvas/mini-segment/mini-segment.component';
import { PortfolioCardComponent } from './portfolio/portfolio-card/portfolio-card.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FunnelCardComponent } from './portfolio/funnel-card/funnel-card.component';
import { ProductStagesSelectionComponent } from './product/product-stages-selection/product-stages-selection.component';
import { ThesisProductComponent } from './charts/thesis-product/thesis-product.component';
import { StageStoppedComponent } from './charts/stage-stopped/stage-stopped.component';
import { StoppedProductComponent } from './charts/stopped-product/stopped-product.component';
import { RemoveModalComponent } from './validating-modals/remove-modal/remove-modal.component';
import { LogService } from './log/log.service';
import { TaskService } from './task/task.service';
import { SprintDetailComponent } from './sprint/detail/detail.component';
import { TaskCardComponent } from './task/task-card/task-card.component';
import { BacklogComponent } from './sprint/backlog/backlog.component';
import { TaskModalAddComponent } from './task/task-modal-add/task-modal-add.component';
import { AttachmentCardComponent } from './attachment/attachment-card/attachment.component';
import { ExperimentTasksDragModalComponent } from './experiment/experiment-tasks-drag-modal/experiment-tasks-drag-modal.component';
import { PortfolioBoardComponent } from './portfolio/portfolio-board/portfolio-board.component';
import { PortfolioAccountingComponent } from './portfolio/portfolio-accounting/portfolio-accounting.component';
import { SprintModalCompleteComponent } from './sprint/sprint-modal-complete/sprint-modal-complete.component';
import { SprintModalStartComponent } from './sprint/sprint-modal-start/sprint-modal-start.component';
import { SprintModalRetrospectComponent } from './sprint/sprint-modal-retrospect/sprint-modal-retrospect.component';
import { ProgressDetailComponent } from './progress/progress-detail/progress-detail.component';
import { LearningModalOverviewComponent } from './learning/learning-modal-overview/learning-modal-overview.component';
import { InsightModalOverviewComponent } from './insight/insight-modal-overview/insight-modal-overview.component';
import { ExperimentRowComponent } from './experiment/experiment-row/experiment-row.component';
import { TaskRowComponent } from './task/task-row/task-row.component';
import { ProductAccountingOverviewComponent } from './product/product-accounting-overview/product-accounting-overview.component';
import { ProductCockpitComponent } from './product/product-cockpit/product-cockpit.component';
import { YourWorkComponent } from './sprint/your-work/your-work.component';
import { LogAddExperimentComponent } from './log/actions/log-add-experiment/log-add-experiment.component';
import { LogValidatedStickyComponent } from './log/actions/log-validated-sticky/log-validated-sticky.component';
import { LogSprintComponent } from './log/actions/log-sprint/log-sprint.component';
import { LogTaskComponent } from './log/actions/log-task/log-task.component';
import { ChartHappinessModalComponent } from './accounting/chart-happiness-modal/chart-happiness-modal.component';
import { ChartKnowledgeModalComponent } from './accounting/chart-knowledge-modal/chart-knowledge-modal.component';
import { DecisionModalComponent } from './decision/decision-modal/decision-modal.component';
import { StickyRiskModalComponent } from './sticky/sticky-risk-modal/sticky-risk-modal.component';
import { LocaleDatePipe } from './shared/date.pipe';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { AvatarRowComponent } from './layout/avatar-row/avatar-row.component';
import { VelocityCardComponent } from './accounting/velocity-card/velocity-card.component';
import { TeamHappinessCardComponent } from './accounting/team-happiness-card/team-happiness-card.component';
import { TeamKnowledgeCardComponent } from './accounting/team-knowledge-card/team-knowledge-card.component';
import { RadialProgressComponent } from './accounting/radial-progress/radial-progress.component';
import { VelocityChartComponent } from './charts/velocity-chart/velocity-chart.component';
import { PortfolioChartHappinessModalComponent } from './accounting/portfolio-chart-happiness-modal/portfolio-chart-happiness-modal.component';
import { PortfolioChartKnowledgeModalComponent } from './accounting/portfolio-chart-knowledge-modal/portfolio-chart-knowledge-modal.component';
import { CustomNgbDateAdapter } from './shared/ngb-date-adapter.service';
import { CustomNgbDateParserFormatter } from './shared/ngb-date-parser-formatter.service';
import { NotfoundComponent } from './layout/notfound/notfound.component';
import { PortfolioChartVelocityModalComponent } from './accounting/portfolio-chart-velocity-modal/portfolio-chart-velocity-modal.component';
import { ThesisModalComponent } from './thesis/thesis-modal/thesis-modal.component';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { AdminPortfoliosComponent } from './admin/admin-portfolios/admin-portfolios.component';
import { UserCardComponent } from './user/user-card/user-card.component';
import { ExperimentModalTasksDoneComponent } from './experiment/experiment-modal-tasks-done/experiment-modal-tasks-done.component';
import { PortfolioAccessGuard } from './portfolio/portfolio-access.guard';
import { ContentExperimentToolOverviewComponent } from './content-experiment/content-experiment-tool-overview/content-experiment-tool-overview.component';
import { AdminContentExperimentOverviewComponent } from './admin/admin-content-experiment-overview/admin-content-experiment-overview.component';
import { ContentExperimentCardComponent } from './content-experiment/content-experiment-card/content-experiment-card.component';
import { ContentExperimentModalComponent } from './content-experiment/content-experiment-modal/content-experiment-modal.component';
import { LogoComponent } from './layout/logo/logo.component';

import Quill from 'quill';
import MagicUrl from 'quill-magic-url';
import { ColorPickerModule } from 'ngx-color-picker';
import { PortfolioBoardRowComponent } from './portfolio/portfolio-board-row/portfolio-board-row.component';
import { ProductDecisionsComponent } from './decision/product-decisions/product-decisions.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ValidateEmailComponent } from './authenticate/validate-email/validate-email.component';
import { SignupThankyouComponent } from './authenticate/signup-thankyou/signup-thankyou.component';
import { ResetPasswordComponent } from './authenticate/reset-password/reset-password.component';
import { ResetPasswordThankyouComponent } from './authenticate/reset-password-thankyou/reset-password-thankyou.component';
import { UnsavedModalComponent } from './validating-modals/unsaved-modal/unsaved-modal.component';
import { ContentExperimentDetailComponent } from './content-experiment/content-experiment-detail/content-experiment-detail.component';
import { ContentExperimentComponent } from './content-experiment/content-experiment/content-experiment.component';
import { AdminContentToolOverviewComponent } from './admin/admin-content-tool-overview/admin-content-tool-overview.component';
import { ContentToolCardComponent } from './content-tool/content-tool-card/content-tool-card.component';
import { ContentToolModalComponent } from './content-tool/content-tool-modal/content-tool-modal.component';
import { ContentToolDetailComponent } from './content-tool/content-tool-detail/content-tool-detail.component';
import { ContentToolComponent } from './content-tool/content-tool/content-tool.component';
import { CreateSubscriptionComponent } from './subscription/create-subscription/create-subscription.component';
import { TrialBarComponent } from './layout/trial-bar/trial-bar.component';
import { SubscriptionAccessGuard } from './subscription/subscription-access.guard';

import { environment } from '../environments/environment';
import { AvatarComponent } from './user/avatar/avatar.component';

import { revision } from '../../git-revision';
import { BillingComponent } from './subscription/billing/billing.component';
import { CardDetailsModalComponent } from './subscription/card-details-modal/card-details-modal.component';
import { BillingDetailsModalComponent } from './subscription/billing-details-modal/billing-details-modal.component';
import { PortfolioModalInfoComponent } from './portfolio/portfolio-modal-info/portfolio-modal-info.component';
import { KeyQuestionsOverviewComponent } from './key-question/key-question-overview/key-question-overview.component';
import { KeyQuestionModalComponent } from './key-question/key-question-modal/key-question-modal.component';
import { PortfolioSettingsGeneralComponent } from './portfolio-settings/portfolio-settings-general/portfolio-settings-general.component';
import { PortfolioSettingsStagenamesComponent } from './portfolio-settings/portfolio-settings-stagenames/portfolio-settings-stagenames.component';
import { PortfolioSettingsCanvasDesignComponent } from './portfolio-settings/portfolio-settings-canvas-design/portfolio-settings-canvas-design.component';
import { PortfolioSettingsThesisComponent } from './portfolio-settings/portfolio-settings-thesis/portfolio-settings-thesis.component';
import { PortfolioSettingsCollaboratorsComponent } from './portfolio-settings/portfolio-settings-collaborators/portfolio-settings-collaborators.component';
import { ProgressKeyquestionsModalOverviewComponent } from './progress/progress-keyquestions-modal-overview/progress-keyquestions-modal-overview.component';
import { CurrencySymbolPipe } from './shared/currency-symbol.pipe';
import { AttachmentCardBigComponent } from './attachment/attachment-card-big/attachment-card-big.component';
import { BoardMeetingPreparationComponent } from './decision/board-meeting-preparation/board-meeting-preparation.component';
import { ProductInfoComponent } from './product/product-info/product-info.component';
import { StagesCardComponent } from './accounting/stages-card/stages-card.component';
import { PreparedDecisionInfoComponent } from './decision/prepared-decision-info/prepared-decision-info.component';
import { AttachmentButtonAddComponent } from './attachment/attachment-button-add/attachment-button-add.component';
import { ProductSettingsGeneralComponent } from './product-settings/product-settings-general/product-settings-general.component';
import { ProductSettingsCockpitComponent } from './product-settings/product-settings-cockpit/product-settings-cockpit.component';
import { ProductSettingsPortfolioComponent } from './product-settings/product-settings-portfolio/product-settings-portfolio.component';
import { ProductSettingsCollaboratorsComponent } from './product-settings/product-settings-collaborators/product-settings-collaborators.component';
import { PortfolioLearningsComponent } from './portfolio/portfolio-learnings/portfolio-learnings.component';
import { LearningCostLineChartComponent } from './charts/learning-cost-line-chart/learning-cost-line-chart.component';
import { LearningCostCardComponent } from './accounting/learning-cost-card/learning-cost-card.component';
import { StageAverageCostComponent } from './charts/stage-average-cost/stage-average-cost.component';
import { StageAverageCostLearningComponent } from './charts/stage-average-cost-learning/stage-average-cost-learning.component';
import { PortfolioSettingsLensesComponent } from './portfolio-settings/portfolio-settings-lenses/portfolio-settings-lenses.component';
import { LensModalComponent } from './lens/lens-modal/lens-modal.component';
import { SummaryCardComponent } from './accounting/summary-card/summary-card.component';
import { ProductNotStartedComponent } from './product/product-not-started/product-not-started.component';
import { PortfolioSettingsContentExperimentsComponent } from './portfolio-settings/portfolio-settings-content-experiments/portfolio-settings-content-experiments.component';
import { ContentExperimentOverviewComponent } from './content-experiment/content-experiment-overview/content-experiment-overview.component';
import { CockpitModalComponent } from './cockpit/cockpit-modal/cockpit-modal.component';
import { MicrosoftService } from './sso/microsoft.service';
import { ProductLoopComponent } from './product/product-loop/product-loop.component';
import { PortfolioSettingsCockpitComponent } from './portfolio-settings/portfolio-settings-cockpit/portfolio-settings-cockpit.component';
import { CockpitFieldModalComponent } from './cockpit/cockpit-field-modal/cockpit-field-modal.component';

Quill.register('modules/magicUrl', MagicUrl);

const appsignal = new Appsignal({
	key: environment.appSignalKey,
	revision: revision,
});
appsignal.use(pluginBreadcrumbsNetwork());
appsignal.use(pluginPathDecorator());
appsignal.use(pluginBreadcrumbsConsole());
appsignal.use(pluginWindowEvents());

@NgModule({
	declarations: [
		AppComponent,
		SignupComponent,
		LoginComponent,
		NewTenantComponent,
		HeaderComponent,
		KeysPipe,
		LocaleDatePipe,
		NltobrPipe,
		ProductCardComponent,
		CanvasComponent,
		CanvasSegmentComponent,
		CanvasStickyComponent,
		CanvasMiniComponent,
		ProductCanvasDetailComponent,
		StickyModalComponent,
		ProductDetailComponent,
		InsightModalComponent,
		InsightCardComponent,
		LearningModalComponent,
		LearningCardComponent,
		ExperimentCardComponent,
		ExperimentModalAddComponent,
		ExperimentModalComponent,
		ContentExperimentToolOverviewComponent,
		ProductModalAddComponent,
		ProductSettingsComponent,
		ProfileComponent,
		HomeComponent,
		PortfolioDetailComponent,
		FunnelComponent,
		SpinnerComponent,
		PreValidationComponent,
		MiniPreValidationComponent,
		MiniNextCanvasComponent,
		CanvasModalAddComponent,
		PortfolioProductsComponent,
		StageProductComponent,
		StageAverageInvestmentComponent,
		StageTimeSpentComponent,
		MinuteComponent,
		MinuteModalComponent,
		AdminComponent,
		PortfolioSettingsComponent,
		PortfolioModalAddComponent,
		ForgottenPasswordComponent,
		CanvasModalSettingsComponent,
		MiniSegmentComponent,
		PortfolioCardComponent,
		FunnelCardComponent,
		ProductStagesSelectionComponent,
		ThesisProductComponent,
		StageStoppedComponent,
		StoppedProductComponent,
		RemoveModalComponent,
		SprintDetailComponent,
		TaskCardComponent,
		BacklogComponent,
		TaskModalAddComponent,
		AttachmentCardComponent,
		ExperimentTasksDragModalComponent,
		PortfolioBoardComponent,
		PortfolioAccountingComponent,
		SprintModalCompleteComponent,
		SprintModalStartComponent,
		SprintModalRetrospectComponent,
		ProgressDetailComponent,
		LearningModalOverviewComponent,
		InsightModalOverviewComponent,
		ExperimentRowComponent,
		TaskRowComponent,
		ProductAccountingOverviewComponent,
		ProductCockpitComponent,
		YourWorkComponent,
		LogAddExperimentComponent,
		LogValidatedStickyComponent,
		LogSprintComponent,
		LogTaskComponent,
		ChartHappinessModalComponent,
		ChartKnowledgeModalComponent,
		DecisionModalComponent,
		StickyRiskModalComponent,
		AvatarRowComponent,
		VelocityCardComponent,
		TeamHappinessCardComponent,
		TeamKnowledgeCardComponent,
		RadialProgressComponent,
		VelocityChartComponent,
		PortfolioChartHappinessModalComponent,
		PortfolioChartKnowledgeModalComponent,
		NotfoundComponent,
		PortfolioChartVelocityModalComponent,
		AdminUsersComponent,
		AdminPortfoliosComponent,
		ThesisModalComponent,
		UserCardComponent,
		ExperimentModalTasksDoneComponent,
		PortfolioBoardRowComponent,
		ProductDecisionsComponent,
		ValidateEmailComponent,
		SignupThankyouComponent,
		ResetPasswordComponent,
		ResetPasswordThankyouComponent,
		AdminContentExperimentOverviewComponent,
		ContentExperimentCardComponent,
		ContentExperimentModalComponent,
		ContentExperimentDetailComponent,
		ContentExperimentComponent,
		UnsavedModalComponent,
		AdminContentToolOverviewComponent,
		ContentToolCardComponent,
		ContentToolModalComponent,
		ContentToolDetailComponent,
		ContentToolComponent,
		CreateSubscriptionComponent,
		TrialBarComponent,
		LogoComponent,
		AvatarComponent,
		BillingComponent,
		CardDetailsModalComponent,
		BillingDetailsModalComponent,
		PortfolioModalInfoComponent,
		KeyQuestionsOverviewComponent,
		KeyQuestionModalComponent,
		PortfolioSettingsGeneralComponent,
		PortfolioSettingsStagenamesComponent,
		PortfolioSettingsCanvasDesignComponent,
		PortfolioSettingsThesisComponent,
		PortfolioSettingsCollaboratorsComponent,
		ProgressKeyquestionsModalOverviewComponent,
		CurrencySymbolPipe,
		AttachmentCardBigComponent,
		BoardMeetingPreparationComponent,
		ProductInfoComponent,
		StagesCardComponent,
		PreparedDecisionInfoComponent,
		AttachmentButtonAddComponent,
		ProductSettingsGeneralComponent,
		ProductSettingsCockpitComponent,
		ProductSettingsPortfolioComponent,
		ProductSettingsCollaboratorsComponent,
		PortfolioLearningsComponent,
		LearningCostLineChartComponent,
		LearningCostCardComponent,
		StageAverageCostComponent,
		StageAverageCostLearningComponent,
		PortfolioSettingsLensesComponent,
		PortfolioSettingsCockpitComponent,
		LensModalComponent,
		SummaryCardComponent,
		ProductNotStartedComponent,
		PortfolioSettingsContentExperimentsComponent,
		ContentExperimentOverviewComponent,
		CockpitModalComponent,
  ProductLoopComponent,
  CockpitFieldModalComponent,
	],
	imports: [
		BrowserModule,
		NgSelectModule,
		FormsModule,
		AppRoutingModule,
		ClipboardModule,
		NgbModule,
		InlineEditorModule,
		ChartsModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		QuillModule.forRoot({
			modules: {
				toolbar: [
					['bold', 'italic', 'underline', 'strike'], // toggled buttons
					// [{ header: 1 }, { header: 2 }], // custom button values
					[{ list: 'ordered' }, { list: 'bullet' }],
					// [{ 'color': [] }/*, { 'background': [] }*/],          // dropdown with defaults from theme
					// [{ 'align': [] }],
					['link'],
				],
				magicUrl: true,
			},
		}),
		DragDropModule,
		ColorPickerModule,
		MsalModule.forRoot(new PublicClientApplication({
			auth: {
				clientId: environment.microsoft.clientId, // Application (client) ID from the app registration
				authority: 'https://login.microsoftonline.com/organizations', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
				redirectUri: environment.microsoft.redirectUrl // This is your redirect URI
			},
			cache: {
				cacheLocation: 'localStorage',
				storeAuthStateInCookie: false, // Set to true for Internet Explorer 11
			}
		}), null, null)
	],
	// exports: [StickyModalComponent],
	entryComponents: [
		StickyModalComponent,
		LearningModalComponent,
		InsightModalComponent,
		ExperimentModalComponent,
		ExperimentModalAddComponent,
		ProductModalAddComponent,
		CanvasModalAddComponent,
		MinuteModalComponent,
		PortfolioModalAddComponent,
		CanvasModalSettingsComponent,
		RemoveModalComponent,
		TaskModalAddComponent,
		ExperimentTasksDragModalComponent,
		SprintModalCompleteComponent,
		SprintModalStartComponent,
		SprintModalRetrospectComponent,
		LearningModalOverviewComponent,
		InsightModalOverviewComponent,
		ChartHappinessModalComponent,
		DecisionModalComponent,
		StickyRiskModalComponent,
		ThesisModalComponent,
		ExperimentModalTasksDoneComponent,
		ContentExperimentModalComponent,
		ContentToolModalComponent,
		UnsavedModalComponent,
		CardDetailsModalComponent,
		BillingDetailsModalComponent,
		CockpitModalComponent,
		CockpitFieldModalComponent
	],
	providers: [
		ParseService,
		AnalyticsService,
		CanvasService,
		ProductService,
		SprintService,
		AccessGuard,
		AdminGuard,
		LogService,
		TaskService,
		MicrosoftService,
		DatePipe,
		PortfolioAccessGuard,
		SubscriptionAccessGuard,
		CurrencyPipe,

		{ provide: NgbDateAdapter, useClass: CustomNgbDateAdapter },
		{ provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter },
		{
			provide: ErrorHandler,
			useFactory: createErrorHandlerFactory(appsignal),
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

// needed for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}
