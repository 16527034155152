<div class="modal-content">
	<div class="modal-header">
		<p>{{ 'insight.all' | translate }}</p>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 input-group">
					<input
						type="text"
						class="form-control"
						[(ngModel)]="searchTerms"
						[placeholder]="'general.search' | translate"
						aria-label="search"
						aria-describedby="button-search"
						(keydown.enter)="queryInsights()"
					/>
					<div class="input-group-append">
						<button class="btn btn-outline-primary" type="button" id="button-search" (click)="queryInsights()">
							<i class="bi bi-search"></i>
						</button>
					</div>
				</div>
			</div>
			<div *ngFor="let month of insightsPerMonth" class="row">
				<p class="col-12 header">{{ month.date | date: 'LLLL y' }}</p>
				<div *ngFor="let insight of month.insights" class="col-4 insight-container">
					<app-insight-card [insight]="insight" [showExperimentTag]="true"></app-insight-card>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="activeModal.close()">{{ 'general.close' | translate }}</button>
	</div>
</div>
