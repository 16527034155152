<div class="modal-content">
	<div class="modal-header align-items-center">
		<p class="modal-title">{{ 'portfolio.info' | translate }}</p>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="text-center mt-3" *ngIf="loading">
			<div class="spinner-border spinner-border-md text-primary" role="status" >
				<span class="sr-only">Loading...</span>
			</div>
		</div>
		
		<div class="container-fluid" *ngIf="!loading">
			<div class="row">
				<div class="col-12">
					<h2 class="modal-header-title">{{ portfolio.name }} <span class="text-light">({{ portfolio.id}})</span></h2>
					<p>{{ 'general.createdAt' | translate }}: {{ portfolio.createdAt | date }}</p>
					<p>{{ productsCount }} {{ 'product.plural' | translate }}</p>
				</div>
			</div>

			<div class="row form">
				<div class="col-12">
					<h4 class="modal-body-header">{{ 'trial.singular' | translate }}</h4>
					<div class="form-row align-items-center">
						<div class="col-12">
							<div class="custom-control custom-switch">
								<input type="checkbox" class="custom-control-input" id="customSwitch1" [(ngModel)]="portfolioShadow.trial">
								<label class="custom-control-label" for="customSwitch1">{{ 'trial.singular' | translate }}</label>
							</div>
						</div>
					</div>
					<div class="form-row mb-2 align-items-center" *ngIf="portfolioShadow.trial">
						<div class="col-12 col-md-4"><p>{{ 'trial.endsAt' | translate }}</p></div>
						<div class="col-12 col-md-8">
							<div class="input-group" >
								<input class="form-control" placeholder="dd/mm/yyyy" id="fromDate" name="fromDate" [(ngModel)]="portfolioShadow.trialEndsAt" ngbDatepicker #dFrom="ngbDatepicker"/>
								<div class="input-group-append">
									<button class="btn btn-outline-primary" (click)="dFrom.toggle()" type="button"><i class="bi bi-calendar3"></i></button>
								</div>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="col-12 text-right">
							<button *ngIf="!trialSaved" class="btn btn-primary" type="submit" (click)="saveTrial()">{{ 'trial.save' | translate }}</button>
							<button *ngIf="trialSaved" class="btn btn-primary" type="submit" (click)="saveTrial()"><i class="bi bi-check mr-2"></i>{{ 'trial.saved' | translate }}</button>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="row">
				<div class="col-12">
					<p>{{ 'status.singular' | translate }}: {{ portfolio.status }}</p>
				</div>
			</div> -->

			<ng-container *ngIf="portfolioInfo && portfolioInfo['billingInfo']">
				<div class="row">
					<div class="col-12">
						<h4 class="modal-body-header">{{ 'subscription.singular' | translate }}</h4>
						<p *ngIf="!portfolioInfo.cancel_at_period_end">{{ 'subscription.willRenewShort' | translate:{date: renewDate | date} }}</p>
						<ng-container *ngIf="portfolioInfo['subscriptionInfo'] && portfolioInfo['subscriptionInfo']['upcoming_invoice']">
							<p>{{ 'invoice.upcomingDescriptionShort' | translate:{date: portfolioInfo['subscriptionInfo']['upcoming_invoice']['next_payment_attempt'] | date, amount: portfolioInfo['subscriptionInfo']['upcoming_invoice']['total'] | currency:'EUR' } }}</p>
						</ng-container>
					</div>
				</div>
				
				<h4 class="modal-body-header">{{ 'billing.details' | translate }}</h4>
				<div class="row mb-0">
					<div class="col-12 col-md-4"><p>{{ 'user.billingEmail' | translate }}</p></div>
					<div class="col-12 col-md-8"><p>{{ portfolioInfo['billingInfo']['email'] }}</p></div>
				</div>
				<div class="row mb-0">
					<div class="col-12 col-md-4"><p>{{ 'user.address' | translate }}</p></div>
					<div class="col-12 col-md-8"><p>{{ portfolioInfo['billingInfo']['address1'] }}, {{ portfolioInfo['billingInfo']['address2'] }}</p></div>
				</div>
				<div class="row mb-0">
					<div class="col-12 col-md-4"><p>{{ 'user.postalCode' | translate }}</p></div>
					<div class="col-12 col-md-8"><p>{{ portfolioInfo['billingInfo']['postalCode'] }}</p></div>
				</div>
				<div class="row mb-0">
					<div class="col-12 col-md-4"><p>{{ 'user.city' | translate }}</p></div>
					<div class="col-12 col-md-8"><p>{{ portfolioInfo['billingInfo']['city'] }}</p></div>
				</div>
				<div class="row mb-0">
					<div class="col-12 col-md-4"><p>{{ 'user.country' | translate }}</p></div>
					<div class="col-12 col-md-8"><p>{{ portfolioInfo['billingInfo']['country'] }}</p></div>
				</div>
				<div class="row mb-0" *ngIf="portfolioInfo['billingInfo']['taxId']">
					<div class="col-12 col-md-4"><p>{{ 'user.vatId' | translate }}</p></div>
					<div class="col-12 col-md-8"><p>{{ portfolioInfo['billingInfo']['taxId'] }}</p></div>
				</div>
			</ng-container>

			<ng-container *ngIf="portfolioInfo && portfolioInfo['subscriptionInfo']">	
				<h4 class="modal-body-header mt-4">{{ 'card.info' | translate }}</h4>
				<div class="row mb-0">
					<div class="col-12 col-md-4"><p>{{ 'user.cardName' | translate }}</p></div>
					<div class="col-12 col-md-8"><p>{{ portfolioInfo['subscriptionInfo']['billing_details']['name'] }}</p></div>
				</div>
				<div class="row mb-0">
					<div class="col-12 col-md-4"><p>{{ 'card.number' | translate }}</p></div>
					<div class="col-12 col-md-8"><p><span class="text-secondary">XXXX XXXX XXXX</span> {{ portfolioInfo['subscriptionInfo']['card']['last4'] }}</p></div>
				</div>
				<div class="row mb-0">
					<div class="col-12 col-md-4"><p>{{ 'card.expirationDate' | translate }}</p></div>
					<div class="col-12 col-md-8"><p>{{ portfolioInfo['subscriptionInfo']['card']['exp_month']}}/{{ portfolioInfo['subscriptionInfo']['card']['exp_year'] }}</p></div>
				</div>
			</ng-container>

			<ng-container *ngIf="portfolioInfo && portfolioInfo['invoices']?.length > 0">
				<h4 class="mt-4 modal-body-header">{{ 'invoice.plural' | translate }}</h4>
				<p *ngIf="invoices.length === 0" class="text-secondary">{{ 'invoice.noHistory' | translate }}</p>
				<div *ngFor="let invoice of portfolioInfo['invoices']" class="row align-items-center">
					<div class="col-12 col-md-4">
						<p>{{ invoice.amount | currency:invoice.currency }}</p>
					</div>
					<div class="col-12 col-md-4">
						<p>{{ invoice.invoiceDate | date }}</p>
					</div>
					<div class="col-12 col-md-4 text-right">
						<button class="btn btn-primary" (click)="openInvoice(invoice.id)">{{ 'invoice.download' | translate }} <i class="bi bi-cloud-download ml-2"></i></button>
					</div>
				</div>
			</ng-container>
		</div>
	</div>

	<div class="modal-footer">
		<button class="btn btn-primary ml-auto" (click)="activeModal.close()">{{ 'general.close' | translate }}</button>
	</div>
</div>
