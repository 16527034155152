import { Component, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NextConfigService } from 'app/next-config.service';
import { Product } from '../../../../shared/models/product';

@Component({
	selector: 'app-chart-stopped-product',
	templateUrl: './stopped-product.component.html',
})
export class StoppedProductComponent implements OnChanges {
	@Input() products: Array<Product>;

	public stages: any;
	public chartColors: Array<any> = [
		{
			backgroundColor: [],
		},
	];
	public chartOptions: any = {
		legend: {
			position: 'left',
		},
		responsive: true,
	};
	public isReady = false;

	constructor(private configService: NextConfigService, private translateService: TranslateService) {
		this.chartColors[0].backgroundColor = [this.configService.colors[0], this.configService.colors[2]];
	}

	ngOnChanges(changes: any) {
		if (!changes.products) {
			return;
		}

		this.products = changes.products.currentValue;

		this.stages = {
			data: [0, 0],
			labels: [
				this.translateService.instant('portfolioOverview.products.activeProducts'),
				this.translateService.instant('portfolioOverview.products.stoppedProducts'),
			],
		};

		this.products.forEach((product) => {
			if (product.archived) {
				return;
			}

			if (product.stopped) {
				this.stages.data[1]++;
				return;
			}

			this.stages.data[0]++;
		});

		this.isReady = true;
	}
}
