<div class="container page">
	<h1 class="page-title">{{ 'experiment.andTools' | translate }}</h1>

	<h2>{{ 'experiment.vsTools' | translate }}</h2>
	<div class="row mb-5">
		<div class="col-12 col-lg-8">
			<p [innerHtml]="'experiment.vsToolsExplanation' | translate"></p>
		</div>
	</div>

	<ng-container *ngFor="let stage of stages">
		<div class="content-container" [ngClass]="stage" *ngIf="(!!stageExperiments && stageExperiments[stage].length > 0) || (!!stageTools && stageTools[stage].length > 0)">
			<h2 class="mb-3">{{ portfolioService.getStageName(portfolio, stage) }}</h2>
			<ng-container *ngIf="stageExperiments && stageExperiments[stage].length > 0">
				<h4>{{ 'experiment.plural' | translate }}</h4>
				<div class="row mb-3">
					<div class="col-12 col-md-4 mb-3" *ngFor="let contentExperiment of stageExperiments[stage]">
						<app-content-experiment-card [contentExperiment]="contentExperiment" (cardClicked)="openContentExperiment($event)"></app-content-experiment-card>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="stageTools && stageTools[stage].length > 0">
				<h4>{{ 'tool.plural' | translate }}</h4>
				<div class="row mb-5">
					<div class="col-12 col-md-4 mb-3" *ngFor="let contentTool of stageTools[stage]">
						<app-content-tool-card [contentTool]="contentTool" (cardClicked)="openContentTool($event)"></app-content-tool-card>
					</div>
				</div>
			</ng-container>
		</div>
	</ng-container>
</div>
