import { Component, OnInit } from '@angular/core';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { ProductService } from 'app/product/product.service';
import { Portfolio } from '../../../../shared/models/portfolio';
import currencyData from '../../../assets/currency/currency.json';

@Component({
  selector: 'app-portfolio-settings-general',
  templateUrl: './portfolio-settings-general.component.html',
  styleUrls: ['./portfolio-settings-general.component.scss']
})
export class PortfolioSettingsGeneralComponent implements OnInit {
  	public portfolio: Portfolio;
	public errorMessage: string;
	public resultMessage: string;
	public currencies

	constructor(
		private portfolioService: PortfolioService,
		private productService: ProductService
	) { }

	async ngOnInit() {
		this.currencies = currencyData;
		this.portfolio = await this.portfolioService.getActivePortfolio();
	}

 	async onSave() {
		try {
			await this.portfolio.save();

			this.errorMessage = '';
			this.resultMessage = 'Portfolio successfully saved';
		}
		catch(err) {
			this.errorMessage = err.message;
			this.resultMessage = '';
		}
	  }
	
	async downloadData() {
		const products = await this.productService.getProducts();

		this.productService.exportData(products);
	}

	searchCurrency(term: string, item: any) {
		term = term.toLocaleLowerCase();
		return item.code.toLocaleLowerCase().indexOf(term) > -1 || item.name.toLocaleLowerCase().indexOf(term) > -1;
	}
}
