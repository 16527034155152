import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ContentExperiment } from '@shared/models/content-experiment';
import { Portfolio } from '@shared/models/portfolio';
import { ContentExperimentModalComponent } from 'app/content-experiment/content-experiment-modal/content-experiment-modal.component';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Query } from 'parse';

@Component({
	selector: 'app-portfolio-settings-content-experiments',
	templateUrl: './portfolio-settings-content-experiments.component.html',
	styleUrls: ['./portfolio-settings-content-experiments.component.scss'],
})
export class PortfolioSettingsContentExperimentsComponent implements OnInit, OnDestroy {
	public contentExperiments: ContentExperiment[];
	private contentExperimentSubscription: Parse.LiveQuerySubscription;
	public portfolio: Portfolio;

	constructor(private portfolioService: PortfolioService, private translate: TranslateService, private modalService: NgbModal) {}

	async ngOnInit() {
		this.portfolio = await this.portfolioService.getActivePortfolio();

		if (this.portfolio.defaultContentExperiments) {
			return;
		}

		this.subscribeContentExperiments();
	}

	async subscribeContentExperiments() {
		let contentExperimentQuery = new Query(ContentExperiment);
		// query portfolio specific (custom) experiments
		contentExperimentQuery.equalTo('portfolio', this.portfolio);
		contentExperimentQuery.equalTo('archived', false);
		contentExperimentQuery.ascending('title');
		this.contentExperiments = await contentExperimentQuery.find();

		this.contentExperimentSubscription = await contentExperimentQuery.subscribe();
		this.contentExperimentSubscription.on('create', (contentExperiment: ContentExperiment) => {
			this.contentExperiments.push(contentExperiment);
		});
		this.contentExperimentSubscription.on('update', (contentExperiment: ContentExperiment) => {
			const index = this.contentExperiments.findIndex((experiment) => experiment.id === contentExperiment.id);
			this.contentExperiments[index] = contentExperiment;
		});
		this.contentExperimentSubscription.on('leave', (contentExperiment: ContentExperiment) => {
			const index = this.contentExperiments.findIndex((experiment) => experiment.id === contentExperiment.id);
			this.contentExperiments.splice(index, 1);
		});
	}

	ngOnDestroy() {
		if (this.contentExperimentSubscription) {
			this.contentExperimentSubscription.unsubscribe();
		}
	}

	async changeContentExperimentSetting() {
		await this.portfolio.save();

		if (!this.portfolio.defaultContentExperiments) {
			this.subscribeContentExperiments();
		} else {
			this.contentExperiments = undefined;
		}
	}

	createContentExperiment() {
		const contentExperiment = new ContentExperiment();
		contentExperiment.language = this.translate.defaultLang;
		contentExperiment.portfolio = this.portfolio;
		const modalRef = this.modalService.open(ContentExperimentModalComponent, {
			size: 'md',
		});

		let newContentExperiments = {};
		newContentExperiments[contentExperiment.language] = contentExperiment;
		modalRef.componentInstance.contentExperiments = newContentExperiments;
		modalRef.componentInstance.editable = true;
		modalRef.componentInstance.portfolio = this.portfolio;
	}
}
