import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NextConfigService } from 'app/next-config.service';

@Component({
	selector: 'app-learning-cost-line-chart',
	templateUrl: './learning-cost-line-chart.component.html',
	styleUrls: ['./learning-cost-line-chart.component.scss'],
})
export class LearningCostLineChartComponent implements OnInit {
	@Input() labels: string[];
	@Input() datasets: Object[];
	@Input() currency: string;

	public chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		legend: false,
		scales: {
			yAxes: [
				{
					scaleLabel: {
						display: false,
						labelString: '',
					},
					ticks: {
						callback: (value, index, values) => {
							return this.getYAxisLabel(value);
						},
						min: 0,
						maxTicksLimit: 5,
					},
				},
			],
		},
		tooltips: {
			callbacks: {
				label: (tooltipItem, data) => {
					return this.getTooltipLabel(tooltipItem.yLabel);
				},
			},
		},
	};

	public chartColors: Array<any> = [
		{
			backgroundColor: [],
		},
	];

	public isReady: boolean = false;

	constructor(private configService: NextConfigService, private currencyPipe: CurrencyPipe, private translate: TranslateService) {}

	ngOnInit(): void {
		// setup colors
		this.chartColors[0].borderColor = this.configService.colorPrimary[0];
		this.chartColors[0].backgroundColor = this.configService.colorPrimary[1];

		this.isReady = true;
	}

	getYAxisLabel(value: number) {
		return this.currencyPipe.transform(value, this.currency, 'symbol', '1.0-0');
	}

	getTooltipLabel(value: number) {
		return (
			this.translate.instant('innovationAccounting.costPerLearning') +
			': ' +
			this.currencyPipe.transform(Math.round(value), this.currency, 'symbol', '1.0-0')
		);
	}
}
