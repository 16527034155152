import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from 'app/log/log.service';
import { NextConfigService } from 'app/next-config.service';
import { ProductService } from 'app/product/product.service';
import { RemoveModalComponent } from 'app/validating-modals/remove-modal/remove-modal.component';
import { FileMeta } from '../../../../shared/models/file-meta';
import { Product } from '../../../../shared/models/product';
import { ProductDecision } from '../../../../shared/models/product-decision';
import { slugifyFile } from '../../../../shared/slugify';
import { Progress } from '@shared/models/progress';
import { Lens } from '@shared/models/lens';
import { Query } from 'parse';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Cockpit } from '@shared/models/cockpit';

@Component({
	selector: 'app-decision-modal',
	templateUrl: './decision-modal.component.html',
	styleUrls: ['./decision-modal.component.scss'],
})
export class DecisionModalComponent implements OnInit, OnDestroy {
	@Input() decision: ProductDecision;
	@Input() turnPitchIntoDecision = false;

	public editable = false;
	public isNew: boolean;
	public deleted: boolean;
	public showProgress = false;
	public progressUpload;
	public fileErrorMessage;
	public products: Product[];
	public step: number;
	public learningsPerQuestion;
	public newProgress: Progress;
	public lenses: Lens[];
	public cockpitFields: Cockpit[];

	public nextStage;
	public previousStage;

	get DecisionStatus() {
		return ProductDecision.Status;
	}

	get LensValues() {
		return Lens.LensValues;
	}

	get DecisionType() {
		return ProductDecision.DecisionType;
	}

	constructor(
		public activeModal: NgbActiveModal,
		private productService: ProductService,
		public modalService: NgbModal,
		public logService: LogService,
		public configService: NextConfigService,
		public translate: TranslateService,
		public portfolioService: PortfolioService
	) {}

	async ngOnInit() {
		this.isNew = this.decision.isNew();
		this.step = 2;

		if (this.isNew || this.turnPitchIntoDecision) {
			this.makeEditable();
		}

		if (!this.decision.product) {
			this.products = await this.productService.getProducts();
			return;
		}

		this.setDefaults();
	}

	ngOnDestroy() {
		if (!this.deleted) {
			this.decision.revert();
			this.decision.product?.revert();
		}
	}

	async setDefaults() {
		// When the product is not started, always make a start decision
		if (this.decision.product && !this.decision.product.isStarted()) {
			this.decision.decisionMade = ProductDecision.DecisionType.START;
			this.decision.status = ProductDecision.Status.DONE;
			this.decision.fromStage = Product.Stage.PROBLEM;
			this.decision.toStage = Product.Stage.PROBLEM;
		}

		// Set date, of none is set
		if (!this.decision.decisionDate) {
			this.decision.decisionDate = new Date();
		}

		if (!this.decision.fromStage) {
			this.decision.fromStage = this.decision.product.stage;
		}

		

		this.nextStage = this.getNextStage(this.decision.fromStage);
		this.previousStage = this.getPreviousStage(this.decision.fromStage);

		if (!this.decision.toStage) {
			this.decision.toStage = this.nextStage;
		}

		if (!this.decision.attachments) {
			this.decision.attachments = [];
		}

		this.learningsPerQuestion = await this.productService.getLearningsPerKeyQuestion(this.decision.product);
		this.newProgress = await this.productService.createShadowProgress(this.decision.product);

		this.lenses = await new Query(Lens)
			.equalTo('archived', false)
			.equalTo('portfolio', this.decision.product.portfolio)
			.ascending('orderIndex')
			.find();

		for (let lens of this.lenses) {
			if (!this.decision.product.lensValues[lens.id]) {
				this.decision.product.lensValues[lens.id] = {
					value: '',
					note: ''
				}
			}
		}

		this.cockpitFields = await new Query(Cockpit)
			.equalTo('portfolio', this.decision.product.portfolio)
			.equalTo('archived', false)
			.ascending('orderIndex')
			.find();
	}

	onSelectProduct() {
		this.setDefaults();
	}

	makeEditable() {
		this.editable = true;
	}

	async saveEditable() {
		if (this.decision.decisionMade == 'moveFwd') {
			this.decision.toStage = this.nextStage;
		}
		else if (this.decision.decisionMade == 'moveBack') {
			this.decision.toStage = this.previousStage;
		}
		else {
			this.decision.toStage = this.decision.fromStage;
		}

		console.log(this.decision.decisionMade, this.decision.toStage)

		await this.decision.save();

		if (this.isNew) {
			this.logService.logActivity('added Portfolio Board decision', 'Decision', this.decision.id, this.decision.product, this.decision.product.portfolio);
		}

		// if (this.decision.status === ProductDecision.Status.PREPARED) {
		this.activeModal.close(true);
		// } else {
		// 	this.editable = false;
		// }
	}

	isComplete() {
		return !!this.decision.decisionDate && !!this.decision.decisionMade;
	}

	async deleteDecision() {
		// A start decision can not be deleted
		if (this.decision.decisionMade === ProductDecision.DecisionType.START) {
			return;
		}

		const modalRef = this.modalService.open(RemoveModalComponent, {
			size: 'md',
		});
		modalRef.componentInstance.element = 'decision';

		let removed = await modalRef.result;

		if (removed) {
			this.deleted = true;
			// decision is removed from product in backend
			this.decision.archived = true;
			this.decision.save();
		}

		this.activeModal.close(true);
	}

	getNextStage(currentStage) {
		const stages = this.portfolioService.getAllStages(this.decision.product.portfolio);
		const currentIndex = stages.indexOf(currentStage);

		if (currentIndex >= stages.length - 1) {
			return currentStage;
		} else {
			return stages[currentIndex + 1];
		}
	}

	getPreviousStage(currentStage) {
		const stages = this.portfolioService.getAllStages(this.decision.product.portfolio);
		const currentIndex = stages.indexOf(currentStage);

		if (currentIndex == 0) {
			return currentStage;
		} else {
			return stages[currentIndex - 1];
		}
	}

	async uploadFiles(fileMetas) {
		// event is filemeta already, since file already has been uploaded at upload component.
		for (let file of fileMetas) {
			this.decision.addUnique('attachments', file);
		}
	}

	async removeFile(file) {
		this.decision.remove('attachments', file);
		this.decision.save();
	}

	next() {
		if (this.decision.status === this.DecisionStatus.DONE && this.step === 2) {
			return;
		}

		this.step++;
	}

	previous() {
		if (this.step === 1) {
			return;
		}

		this.step--;
	}

	async saveCockpit() {
		await this.decision.product.save();
		this.next();
	}

	async saveProgress() {
		this.productService.updateProgress(this.decision.product, this.newProgress);
		this.newProgress = await this.productService.createShadowProgress(this.decision.product);
		this.next();
	}
}
