import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Portfolio } from '../../../../shared/models/portfolio';

@Component({
	selector: 'app-portfolio-card',
	templateUrl: './portfolio-card.component.html',
	styleUrls: ['./portfolio-card.component.scss'],
})
export class PortfolioCardComponent {
	@Input() portfolio: Portfolio;
	@Output() selectPortfolio: EventEmitter<any> = new EventEmitter();

	constructor() {}

	onSelect() {
		this.selectPortfolio.emit(this.portfolio);
	}
}
