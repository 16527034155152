<div class="container-fluid">
	<div class="row">
		<nav class="col-3 tab-container">
			<div class="tabs">
				<a class="nav-link" [routerLink]="['general']" routerLinkActive="active">{{ 'general.singular' | translate }}</a>
				<a class="nav-link" [routerLink]="['stage-names']" routerLinkActive="active">{{ 'portfolioModalSettings.stageNames.title' | translate }}</a>
				<a class="nav-link" [routerLink]="['canvas-design']" routerLinkActive="active">{{ 'portfolioModalSettings.canvasDesigns.title' | translate }}</a>
				<a class="nav-link" [routerLink]="['experiments']" routerLinkActive="active">{{ 'experiment.plural' | translate }}</a>
				<a class="nav-link" [routerLink]="['key-questions']" routerLinkActive="active">{{ 'keyQuestion.plural' | translate }}</a>
				<a class="nav-link" [routerLink]="['theses']" routerLinkActive="active">{{ 'thesis.innovation' | translate }}</a>
				<a class="nav-link" [routerLink]="['cockpit']" routerLinkActive="active">{{ 'cockpitField.plural' | translate }}</a>
				<a class="nav-link" [routerLink]="['lenses']" routerLinkActive="active">{{ 'lens.plural' | translate }}</a>
				<a class="nav-link" *ngIf="userInPortfolio" routerLinkActive="active" [routerLink]="['access']">{{ 'portfolioModalSettings.users.title' | translate }}</a>
				<a class="nav-link" *ngIf="userIsOwner" routerLinkActive="active" [routerLink]="['billing']">{{ 'billing.title' | translate }}</a>
				<a class="nav-link" *ngIf="userInPortfolio" routerLinkActive="active" [routerLink]="['product-settings']">{{ 'product.settings' | translate }}</a>
			</div>
		</nav>
		<div class="col-9">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
