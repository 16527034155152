import { Component, OnInit } from '@angular/core';
import { NextConfigService } from 'app/next-config.service';
import { ProductService } from 'app/product/product.service';
import { Portfolio } from '../../../../shared/models/portfolio';
import { PortfolioService } from '../portfolio.service';
import { Product } from '../../../../shared/models/product';
import { NextTitleService } from 'app/next-title.service';
import { Lens } from '../../../../shared/models/lens';
import { Query } from 'parse';

type Bucket = {
	value: string;
	products: Product[];
};

@Component({
	selector: 'app-funnel',
	templateUrl: './funnel.component.html',
	styleUrls: ['./funnel.component.scss'],
})
export class FunnelComponent implements OnInit {
	public products: Array<Product>;
	public portfolio: Portfolio;
	public populatedProducts: Product[];

	public fullscreen = false;

	public lenses: Lens[];
	public selectedLens: Lens;
	public defaultLens: Lens;
	public stoppedLens: Lens;

	public bucketHeight: number;
	public buckets: Bucket[];
	public stages: string[];

	get ProductStage() {
		return Product.Stage;
	}

	constructor(public configService: NextConfigService, private productService: ProductService, public portfolioService: PortfolioService, private titleService: NextTitleService) {}

	async ngOnInit() {
		this.defaultLens = new Lens();
		this.defaultLens.name = 'lens.default.confidence';

		this.stoppedLens = new Lens();
		this.stoppedLens.name = 'lens.default.stopped';

		this.selectedLens = this.defaultLens;

		this.portfolio = await this.portfolioService.getActivePortfolio();


		const query = new Parse.Query(Product);
		query.equalTo('portfolio', this.portfolio);
		query.include(['activeProgress']);
		query.equalTo('archived', false);
		query.ascending('name');
		this.products = await query.find();

		// this.products = await this.productService.getProducts();

		this.titleService.setTitle('Funnel - ' + this.portfolio.name);

		this.stages = this.portfolioService.getMainStages(this.portfolio);
		await this.getLenses();
		this.populateProducts();
	}

	populateProducts() {
		this.populatedProducts = [];
		this.products.forEach((product) => {
			// Do not show archived products
			if (product.archived) {
				return;
			}

			// Switch between stopped and active startups, based on Lens
			if (this.selectedLens.name !== 'lens.default.stopped' && product.stopped) {
				return;
			}
			if (this.selectedLens.name === 'lens.default.stopped' && !product.stopped) {
				return;
			}

			this.populatedProducts.push(product);
		});
	}

	updateFunnel($event) {
		// update heights of all cols:
		if (this.selectedLens.name === 'lens.default.confidence' || this.selectedLens.name === 'lens.default.stopped') {
			this.populateProducts();
			return;
		}

		this.bucketHeight = 70 / (this.selectedLens.labels.length + 1);
		this.loadBuckets();
	}

	loadBuckets() {
		this.buckets = [{ value: '', products: [] }];

		for (let i = 0; i < this.selectedLens.labels.length; i++) {
			this.buckets[i + 1] = {
				value: this.selectedLens.labels[i].toString(),
				products: [],
			};
		}

		for (let product of this.products) {
			if (product.stopped) {
				continue;
			}

			const lensValue = product.lensValues[this.selectedLens.id]?.value || 0;
			let bucket = 0;

			for (let i = 0; i < this.selectedLens.labels.length; i++) {
				if (lensValue <= this.selectedLens.labels[i]) {
					bucket = i + 1;
				}
			}

			this.buckets[bucket].products.push(product);
		}
	}

	async getLenses() {
		const lensQuery = new Query(Lens);
		lensQuery.equalTo('archived', false);
		lensQuery.equalTo('portfolio', this.portfolio);
		this.lenses = await lensQuery.findAll();
	}
}
