import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'date' })
export class LocaleDatePipe implements PipeTransform {
	constructor(public angularDatePipe: DatePipe, public translation: TranslateService) {}
	transform(value: any, format = 'mediumDate', timezone?: string, notusedLocale?: string): string | null {
		const lang = this.translation.currentLang;
		let locale = 'en-en';

		if (lang === 'nl') {
			locale = 'nl-nl';
		}

		return this.angularDatePipe.transform(value, format, timezone, locale);
	}
}
