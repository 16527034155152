<div *ngIf="portfolio" class="settings-content">
	<h5>{{ 'thesis.innovation' | translate }}</h5>
	<hr />
	<div class="theses-container">
		<div *ngFor="let thesis of portfolio.theses" class="row hand" (click)="openModal(thesis)">
			<div class="col thesis-row">
				<p>{{ thesis.title }}</p>
			</div>
		</div>
	</div>
	<button class="btn btn-primary mt-2" (click)="addThesis()">{{ 'thesis.add' | translate }}</button>
</div>
