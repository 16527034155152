import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExperimentService } from 'app/experiment/experiment.service';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Learning } from '../../../../shared/models/learning';
import { LearningModalComponent } from '../learning-modal/learning-modal.component';

@Component({
	selector: 'app-learning-card',
	templateUrl: './learning-card.component.html',
	styleUrls: ['./learning-card.component.scss'],
})
export class LearningCardComponent implements OnInit {
	@Input() learning: Learning;
	@Input() showExperimentTag: boolean;
	@Input() showProductTag: boolean;
	public abbrev: string;

	constructor(public modalService: NgbModal, public portfolioService: PortfolioService, private experimentService: ExperimentService) {}

	ngOnInit() {
		if (this.showExperimentTag) {
			this.abbrev = this.learning.product.getAbbrev();
		}
	}

	openExperiment() {
		this.experimentService.openModal(this.learning.experiment, this.learning.product);
	}

	openLearning() {
		const modalRef = this.modalService.open(LearningModalComponent, {
			size: 'md',
		});

		modalRef.componentInstance.learning = this.learning;
	}
}
