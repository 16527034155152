import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { User } from './user';

@ParseSubClass('ContentTool')
export class ContentTool extends Parse.Object {
	@ParseProperty() title: string;
	@ParseProperty() excerpt: string;
	@ParseProperty() stages: string[];
	@ParseProperty() description: string;
	@ParseProperty() readMore: string;
	@ParseProperty() example: string;
	@ParseProperty() source: string;
	@ParseProperty() createdBy: User;
	@ParseProperty() archived: boolean;
	@ParseProperty() slug: string;
	@ParseProperty() language: string;

	constructor() {
		super('ContentTool');
	}
}
