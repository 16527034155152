import { Component, OnInit, Input } from '@angular/core';
import { ContentExperiment } from '../../../../shared/models/content-experiment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Portfolio } from '../../../../shared/models/portfolio';
import { TranslateService } from '@ngx-translate/core';
import { RemoveModalComponent } from 'app/validating-modals/remove-modal/remove-modal.component';
import { QuillConfigService } from 'app/quill.service';
import { NextConfigService } from 'app/next-config.service';
import { UnsavedModalComponent } from 'app/validating-modals/unsaved-modal/unsaved-modal.component';
import { PortfolioService } from 'app/portfolio/portfolio.service';

@Component({
	selector: 'app-content-experiment-modal',
	templateUrl: './content-experiment-modal.component.html',
	styleUrls: ['./content-experiment-modal.component.scss'],
})
export class ContentExperimentModalComponent implements OnInit {
	@Input() contentExperiments: object;
	@Input() editable?: boolean;
	@Input() translatable?: boolean;
	@Input() portfolio?: Portfolio;
	public stages;
	public types;
	public leftoverLanguages: string[] = [];
	public contentExperiment: ContentExperiment;
	public currentLanguages: string[];

	constructor(
		public activeModal: NgbActiveModal,
		public translate: TranslateService,
		public modalService: NgbModal,
		public quillConfigService: QuillConfigService,
		public configService: NextConfigService,
		public portfolioService: PortfolioService
	) {}

	async ngOnInit() {
		if (this.portfolio) {
			// portfolio? then is used in portfolio, use portfolio stages
			this.stages = this.portfolioService.getMainAndKickboxStages(this.portfolio);
		} else {
			// no portfolio? then is used in admin, use default stages
			this.stages = this.portfolioService.getDefaultStagesWithKickbox();
		}

		this.types = [ContentExperiment.Type.LEARN, ContentExperiment.Type.CONFIRM];

		if (this.contentExperiments[this.translate.currentLang]) {
			this.contentExperiment = this.contentExperiments[this.translate.currentLang];
		} else if (this.contentExperiments[this.translate.defaultLang]) {
			this.contentExperiment = this.contentExperiments[this.translate.defaultLang];
		} else if (Object.keys(this.contentExperiments).length > 0) {
			this.contentExperiment = this.contentExperiments[Object.keys(this.contentExperiments)[0]];
		}

		this.getLeftoverLanguages();
	}

	getLeftoverLanguages() {
		this.leftoverLanguages = [...this.configService.languages];
		this.currentLanguages = Object.keys(this.contentExperiments);
		this.currentLanguages.sort();
		for (let language of this.currentLanguages) {
			let index = this.leftoverLanguages.findIndex((lan) => language == lan);
			if (index >= 0) {
				this.leftoverLanguages.splice(index, 1);
			}
		}
	}

	async addTranslation(language) {
		if (!this.translatable) {
			return;
		}
		let newContExp = new ContentExperiment();
		newContExp.language = language;
		newContExp.slug = this.contentExperiment.slug;
		newContExp.stages = this.contentExperiment.stages;
		newContExp.default = true;
		this.contentExperiments[language] = newContExp;
		this.switchTranslation(newContExp);
	}

	async switchTranslation(contExp) {
		if (contExp.language == this.contentExperiment.language) {
			return;
		}

		if (this.contentExperiment.dirty() && this.contentExperiment.language) {
			// current contentExperiment was changed
			const modal = this.modalService.open(UnsavedModalComponent, {
				size: 'sm',
				centered: true,
			});
			const result = await modal.result;

			if (!result) {
				// wants to cancel and continue working on the changes
				if (contExp.isNew()) {
					// delete newly created language
					delete this.contentExperiments[contExp.language];
				}
				return false;
			}

			// wants to revert changes
			if (this.contentExperiment.isNew()) {
				// delete new translation from contentExperiments
				delete this.contentExperiments[this.contentExperiment.language];
			} else {
				// revert changes
				this.contentExperiment.revert();
			}
		} else {
			// current experiment was not changed
			if (this.contentExperiment.isNew()) {
				// delete new translation from contentExperiments
				delete this.contentExperiments[this.contentExperiment.language];
			}
		}
		// switch to translation
		this.contentExperiment = contExp;
		this.getLeftoverLanguages();
	}

	makeEditable() {
		this.editable = true;
	}

	async deleteContentExperiment() {
		const modalRef = this.modalService.open(RemoveModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.element = 'contentExperiment';

		let removed = await modalRef.result;

		// if close button was clicked
		if (!removed) {
			return;
		}

		const langs = Object.keys(this.contentExperiments);
		for (let lang of langs) {
			// remove all translations of experiment
			this.contentExperiments[lang].archived = true;
			this.contentExperiments[lang].save();
		}
		this.activeModal.close();
	}

	async deleteTranslation(language) {
		const modalRef = this.modalService.open(RemoveModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.element = 'translate';

		let removed = await modalRef.result;

		// if close button was clicked
		if (!removed) {
			return;
		}

		if (!this.contentExperiments[language].isNew()) {
			this.contentExperiments[language].archived = true;
			this.contentExperiments[language].save();
		}

		const langs = Object.keys(this.contentExperiments);
		if (langs.length == 1 && langs[0] == language) {
			this.contentExperiment = new ContentExperiment();
			this.contentExperiments = {};
		} else {
			delete this.contentExperiments[language];
			this.contentExperiment = this.contentExperiments[Object.keys(this.contentExperiments)[0]];
		}

		this.getLeftoverLanguages();
	}

	onSave() {
		this.contentExperiment.save();
		this.editable = false;
	}
}
