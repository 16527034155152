<div class="modal-content">
	<div class="modal-content">
		<div class="modal-header">
			<p class="modal-title">{{ 'discard.title' | translate }}</p>
			<button type="button" class="close" (click)="activeModal.close(false)">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<p>{{ 'discard.message' | translate }}</p>
		</div>
		<div class="modal-footer">
			<button class="btn btn-outline-secondary mr-auto" ngbAutofocus (click)="activeModal.close(false)">
				{{ 'general.cancel' | translate }}
			</button>
			<button class="btn btn-danger ml-auto" (click)="activeModal.close(true)">{{ 'general.discard' | translate }}</button>
		</div>
	</div>
</div>
