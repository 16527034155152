import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Portfolio } from './portfolio';
import { User } from './user';

@ParseSubClass('Lens')
export class Lens extends Parse.Object {
	@ParseProperty() public portfolio: Portfolio;
	@ParseProperty() public name: string;
	@ParseProperty() public createdBy: User;
	@ParseProperty() public updatedBy: User;
	@ParseProperty() public labels: number[];
	@ParseProperty() public archived: boolean;
	@ParseProperty() public value: string;
	@ParseProperty() public orderIndex: number;

	constructor() {
		super('Lens');
	}
}

export namespace Lens {
	export enum LensValues {
		CURRENCY = 'Currency',
	}
}
