<div class="row">
	<div class="col-12 col-md product-content" *ngIf="isReady">
		<h4>{{ 'accounting.innovation' | translate }}</h4>
		<div class="row">
			<div class="col-12">
				<div class="row team">
					<div class="col-12 card-container" *ngIf="!!stagesSummaryIA">
						<app-summary-card
							*ngIf="product.isStarted()"
							[product]="product"
							[totalTimeSpent]="stagesSummaryIA['totalTimeSpent']"
							[totalMoneySpent]="stagesSummaryIA['totalMoneySpent']"
							[totalMoneyReceived]="stagesSummaryIA['totalMoneyReceived']"
						></app-summary-card>
					</div>
				</div>
				<div class="row team">
					<div class="col card-container">
						<app-team-happiness-card [happiness]="latestHappiness" [data]="sprints" (openChart)="openHappinessModal()"></app-team-happiness-card>
					</div>
					<div class="col card-container">
						<app-team-knowledge-card [knowledge]="latestEducation" [data]="sprints" (openChart)="openKnowledgeModal()"></app-team-knowledge-card>
					</div>
				</div>
				<div class="row team">
					<div class="col-12 card-container" *ngIf="!!stagesSummaryIA">
						<app-stages-card
							[product]="product"
							[timeSpentStages]="stagesSummaryIA['timeSpent']"
							[moneyReceivedStages]="stagesSummaryIA['moneyReceived']"
							[moneySpentStages]="stagesSummaryIA['moneySpent']"
						></app-stages-card>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="row team">
					<div class="col-12 col-md-4 card-container">
						<app-velocity-card [title]="'innovationAccounting.experimentVelocity' | translate" [fraction]="currentQuarter?.experimentVelocity / currentQuarter?.weeks || 0"></app-velocity-card>
					</div>
					<div class="col-12 col-md-8">
						<app-velocity-chart [datasets]="experimentVelocityData" [labels]="quarterLabels"></app-velocity-chart>
					</div>
				</div>
				<div class="row team">
					<div class="col-12 col-md-4 card-container">
						<app-velocity-card
							[title]="'innovationAccounting.learningRatio' | translate"
							[fraction]="currentQuarter?.learningVelocity / currentQuarter?.experimentVelocity || 0"
						></app-velocity-card>
					</div>
					<div class="col-12 col-md-8">
						<app-velocity-chart [datasets]="learningVelocityData" [labels]="quarterLabels"></app-velocity-chart>
					</div>
				</div>
				<div class="row team">
					<div class="col-12 col-md-4 card-container">
						<app-learning-cost-card
							[title]="'innovationAccounting.costPerLearning' | translate"
							[cost]="currentQuarter?.costsMade / currentQuarter?.learningVelocity || 0"
							[currency]="product.portfolio.currency"
						></app-learning-cost-card>
					</div>
					<div class="col-12 col-md-8">
						<app-learning-cost-line-chart [datasets]="learningCostData" [labels]="quarterLabels" [currency]="product.portfolio.currency"></app-learning-cost-line-chart>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="!!product" class="col-12 col-md product-side-bar right">
		<div class="d-flex flex-row justify-content-between align-items-center decision-header">
			<h5>{{ 'decision.plural' | translate }}</h5>
			<button class="btn btn-outline-primary new-decision circle" (click)="newDecision()" [disabled]="!product.isActive()">
				<i class="bi bi-plus"></i>
			</button>
		</div>
		<!-- decisions -->
		<app-product-decisions *ngIf="decisions && decisions.length > 0" [decisions]="decisions"></app-product-decisions>
		<p *ngIf="!decisions || (decisions && decisions.length === 0)">
			<i>{{ 'decision.none' | translate: { name: product.name } }}</i>
		</p>
	</div>
</div>
