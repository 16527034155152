<nav class="navbar navbar-expand-lg navbar-light bg-white">
	<div class="container-fluid">
		<a class="navbar-brand" routerLink="/" *ngIf="authenticated">
			<app-logo *ngIf="!activePortfolio?.logo"></app-logo>
			<div class="logo" *ngIf="activePortfolio?.logo">
				<img src="/assets/img/icon.png" class="icon" />
				<img [src]="activePortfolio.logo.url()" class="portfolio-logo" />
			</div>
		</a>

		<a class="navbar-brand" href="https://togroundcontrol.com/" *ngIf="!authenticated">
			<app-logo=></app-logo>
		</a>

		<button
			class="navbar-toggler navbar-toggler-right"
			type="button"
			data-toggle="collapse"
			data-target="#navbarSupportedContent"
			aria-controls="navbarSupportedContent"
			aria-expanded="false"
			aria-label="Toggle navigation"
			(click)="toggleMenu()"
			[attr.aria-expanded]="!isCollapsed"
		>
			<span class="navbar-toggler-icon"></span>
		</button>

		<div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="!isCollapsed">
			<ul
				class="navbar-nav ml-0 mr-auto"
				*ngIf="authenticated && !!activePortfolio"
				ngbDropdown
				display="dynamic"
				placement="bottom-left"
			>
				<li class="nav-item">
					<a class="nav-link" [routerLink]="['/portfolio', activePortfolio.id, 'your-work']" routerLinkActive="active">{{
						'your-work.singular' | translate
					}}</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [routerLink]="['/portfolio', activePortfolio.id, 'startups']" routerLinkActive="active">{{
						'portfolioOverview.tabs.products' | translate
					}}</a>
				</li>
				<li class="nav-item" *ngIf="userInPortfolio">
					<a class="nav-link" [routerLink]="['/portfolio', activePortfolio.id, 'funnel']" routerLinkActive="active">{{
						'portfolioOverview.tabs.funnel' | translate
					}}</a>
				</li>
				<li class="nav-item" *ngIf="userInPortfolio">
					<a class="nav-link" [routerLink]="['/portfolio', activePortfolio.id, 'learnings']" routerLinkActive="active">{{
						'learning.plural' | translate
					}}</a>
				</li>
				<li class="nav-item" *ngIf="userInPortfolio">
					<a class="nav-link" [routerLink]="['/portfolio', activePortfolio.id, 'board']" routerLinkActive="active">{{
						'portfolioOverview.tabs.board' | translate
					}}</a>
				</li>
				<li class="nav-item" *ngIf="userInPortfolio">
					<a class="nav-link" [routerLink]="['/portfolio', activePortfolio.id, 'accounting']" routerLinkActive="active">{{
						'portfolioOverview.tabs.accounting' | translate
					}}</a>
				</li>
			</ul>

			<div class="navbar-nav ml-auto mr-0 align-items-center" *ngIf="authenticated">
				<!-- Active portfolio dropdown -->
				<div ngbDropdown display="dynamic" placement="bottom-right" *ngIf="activePortfolio && portfolios && portfolios.length > 1">
					<a class="nav-link hand" ngbDropdownToggle id="portfolioDropdown" role="button">
						{{ activePortfolio.name }}
						<i class="bi bi-caret-down-fill"></i>
					</a>
					<div ngbDropdownMenu aria-labelledby="portfolioDropdown">
						<div class="hand" (click)="switchPortfolio(activePortfolio)" ngbDropdownItem>
							<strong>{{ activePortfolio.name }}</strong>
						</div>
						<hr />
						<div class="hand" (click)="switchPortfolio(portfolio)" ngbDropdownItem *ngFor="let portfolio of portfolios">
							{{ portfolio.name }}
						</div>
					</div>
				</div>

				<a
					class="nav-link hand"
					*ngIf="userInPortfolio"
					[routerLink]="['/portfolio', activePortfolio.id, 'settings']"
					routerLinkActive="active"
					>{{ 'general.settings' | translate }}</a
				>

				<!-- <span class="nav-link header-changelog">{{ 'general.release_notes' | translate }}</span> -->

				<!-- Profile dropdown -->
				<div ngbDropdown display="dynamic" placement="bottom-right">
					<a class="nav-link nav-avatar hand" ngbDropdownToggle id="userDropdown" role="button">
						<app-avatar [user]="user" [avatarSize]="2"></app-avatar>
						<i class="bi bi-caret-down-fill"></i>
					</a>
					<div ngbDropdownMenu aria-labelledby="userDropdown">
						<a class="dropdown-item" routerLink="/profile">{{ 'header.profile' | translate }}</a>
						<hr />
						<a href="https://togroundcontrol.com/privacy-platform/" class="dropdown-item hand" target="_blank">{{
							'general.privacy' | translate
						}}</a>
						<hr />
						<a *ngFor="let lang of configService.languages" class="dropdown-item hand" (click)="setLanguage(lang)">{{
							configService.fullLanguages[lang]
						}}</a>

						<hr />
						<a class="dropdown-item hand" (click)="onLogout()">{{ 'header.logout' | translate }}</a>
					</div>
				</div>
			</div>

			<!-- Loggedout menu -->
			<div class="navbar-nav ml-auto mr-0" *ngIf="!authenticated">
				<!-- <a class="nav-link" routerLink="/login" routerLinkActive="active" [innerHtml]="'header.login' | translate"></a>
				<a class="nav-link" routerLink="/signup" routerLinkActive="active" [innerHtml]="'header.signup' | translate"></a> -->

				<div ngbDropdown display="dynamic" placement="bottom-right">
					<a class="nav-link hand" ngbDropdownToggle id="navbarDropdown" role="button">
						{{ translate.currentLang === 'en' ? 'English' : 'Nederlands' }}
					</a>
					<div ngbDropdownMenu aria-labelledby="navbarDropdown" class="dropdown-menu">
						<a
							*ngFor="let lang of translate.getLangs()"
							class="dropdown-item"
							href="#"
							(click)="$event.preventDefault(); setLanguage(lang)"
							>{{ lang === 'en' ? 'English' : 'Nederlands' }}</a
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</nav>
