<div class="modal-content">
	<div class="modal-header align-items-center">
		<p class="modal-title" *ngIf="!editable">{{ 'thesis.singular' | translate }}</p>
		<p class="modal-title" *ngIf="editable && !thesis.isNew()">{{ 'thesis.edit' | translate }}</p>
		<p class="modal-title" *ngIf="editable && thesis.isNew()">{{ 'thesis.new' | translate }}</p>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>

		<button class="btn btn-link-white ml-auto" *ngIf="!editable" (click)="makeEditable()">{{ 'general.edit' | translate }}</button>
	</div>
	<div class="modal-body edit" *ngIf="editable">
		<label>
			{{ 'title.singular' | translate }}
			<input type="text" class="form-control" [(ngModel)]="thesis.title" name="title" ngbAutoFocus />
		</label>

		<label>
			{{ 'color.singular' | translate }}
			<input class="form-control col-6" [(colorPicker)]="thesis.color" [style.background]="thesis.color" />
		</label>

		<div class="quil-wrapper">
			<label>{{ 'description.singular' | translate }}</label>
			<quill-editor name="mostRisky" [(ngModel)]="thesis.description" [styles]="{ height: '10rem' }"></quill-editor>
		</div>
	</div>
	<div class="modal-body" *ngIf="!editable">
		<div class="container-fluid">
			<div class="row">
				<h2 class="col modal-header-title">{{ thesis.title }}</h2>
			</div>

			<div class="row">
				<div class="col-6">
					<h4 class="modal-body-header">{{ 'color.singular' | translate }}</h4>
					<div class="color-bar" [style.background]="thesis.color"></div>
				</div>
			</div>

			<div class="row" *ngIf="thesis.description">
				<div class="col">
					<h4 class="modal-body-header">{{ 'description.singular' | translate }}</h4>
					<p class="editor-data" [innerHtml]="thesis.description"></p>
				</div>
			</div>
		</div>
	</div>

	<div class="modal-footer">
		<button class="btn btn-link-danger mr-auto" *ngIf="!thesis.isNew()" (click)="deleteThesis()">{{ 'general.remove' | translate }}</button>

		<button class="btn btn-primary ml-auto" *ngIf="editable && thesis.isNew()" (click)="createThesis()" [disabled]="!thesis.title">{{ 'thesis.create' | translate }}</button>
		<button class="btn btn-primary ml-auto" *ngIf="editable && !thesis.isNew()" (click)="saveEditable()">{{ 'general.save' | translate }}</button>
		<button class="btn btn-primary ml-auto" *ngIf="!editable" (click)="activeModal.close()">{{ 'general.close' | translate }}</button>
	</div>
</div>
