import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { User } from '@shared/models/user';
import { AnalyticsService } from '../shared/analytics.service';

@Injectable()
export class MicrosoftService {
	constructor(
		private router: Router,
		private analytics: AnalyticsService,
		private microsoftService: MsalService
	) { }

	loginWithMicrosoft() {
		this.microsoftService.loginPopup()
			.subscribe({
				next: async (result) => {
					if (!result) {
						return;
					}

					console.log(result);

					const user = new User();
					user.email = result.account.username;
					user.username = user.email;
					user.firstName = result.account.name;
					user.acceptedTerms = true;

					const signupResult = await user.linkWith('microsoft', {
						authData: {
							"id": result.account.localAccountId, // required
							"access_token": result.accessToken,
							"mail": result.account.username
						}
					});
					this.analytics.login(user);

					// Do stuff after successful login.
					this.router.navigate(['/']);
				},
				error: (error) => {
					console.log(error);

					throw error;
				}
			});
	}

	giveAdminConsent() {
		this.microsoftService.loginPopup({
			scopes: ['User.Read'],
			prompt: 'consent'
		})
			.subscribe({
				next: async (result) => {
					if (!result) {
						return;
					}

					console.log(result);

					

				},
				error: (error) => {
					console.log(error);

					throw error;
				}
			});
	}
}
