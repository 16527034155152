import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Canvas } from '../../../../shared/models/canvas';
import { RemoveModalComponent } from 'app/validating-modals/remove-modal/remove-modal.component';
import { CanvasService } from '../canvas.service';

@Component({
	selector: 'app-canvas-modal-settings',
	templateUrl: './canvas-modal-settings.component.html',
	styleUrls: ['./canvas-modal-settings.component.scss'],
})
export class CanvasModalSettingsComponent implements OnInit {
	@Input() canvas: Canvas;
	@Output() save: EventEmitter<any> = new EventEmitter();
	public shadowCanvas: Canvas;

	constructor(
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private canvasService: CanvasService
	) { }

	ngOnInit() {
		this.shadowCanvas = this.canvas.clone();
	}

	onSave() {
		const dirtyKeys = this.shadowCanvas.dirtyKeys();

		dirtyKeys.forEach((key) => {
			this.canvas.set(key, this.shadowCanvas.get(key));
		});

		this.save.emit({ canvas: this.canvas });

		this.activeModal.close();
	}

	async deleteCanvas() {
		const modalRef = this.modalService.open(RemoveModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.element = 'canvas';

		let removed = await modalRef.result;
		if (removed) {
			this.canvasService.deleteCanvas(this.canvas);
			this.activeModal.close();
		}
	}
}
