import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ContentExperiment } from '../../../../shared/models/content-experiment';

@Component({
	selector: 'app-content-experiment-card',
	templateUrl: './content-experiment-card.component.html',
	styleUrls: ['./content-experiment-card.component.scss'],
})
export class ContentExperimentCardComponent {
	@Input() contentExperiment: ContentExperiment;
	@Output() cardClicked?: EventEmitter<any> = new EventEmitter<any>();

	get ContExpType() {
		return ContentExperiment.Type;
	}

	constructor() {}

	clickedCard() {
		this.cardClicked.emit(this.contentExperiment);
	}
}
