<div class="container login">
	<div class="row justify-content-center mt-5">
		<form id="Login" name="Login Platform" class="col-4">
			<div class="row mb-3">
				<div class="col-12">
					<h1 view-title>{{ 'login.title' | translate }}</h1>
					<p *ngIf="loginType == ''">
						{{ 'login.noAccount' | translate }} <a routerLink="/signup" class="no-account">{{ 'signup.title' | translate }}</a>
					</p>

					<ngb-alert class="mt-3" *ngIf="errorMessage" type="danger" [dismissible]="false">{{ errorMessage }}</ngb-alert>
				</div>
			</div>
			<div class="row" *ngIf="loginType != 'sso'">
				<div class="col-12 form-group">
					<label for="email">{{ 'user.email' | translate }}</label>
					<input
						type="email"
						id="email"
						class="form-control"
						placeholder=""
						name="email"
						[(ngModel)]="user.email"
						required
						autocomplete
					/>
				</div>
			</div>

			<div class="row" *ngIf="loginType == 'sso' && user.email">
				<div class="col-12 form-group">
					<label for="email">{{ 'user.email' | translate }}</label>
					<p><strong>{{ user.email }}</strong></p>
				</div>
			</div>

			<div class="row" *ngIf="loginType == 'password'">
				<div class="col-12 form-group">
					<label for="password">{{ 'user.password' | translate }}</label>
					<div class="input-group">
						<input
							type="password"
							id="password"
							class="form-control"
							name="password"
							[(ngModel)]="user.password"
							required
							autocomplete
						/>
					</div>
				</div>
			</div>
			<div class="row align-content-center" *ngIf="loginType == 'password'">
				<div class="col-12 col-md-4">
					<button type="submit" class="btn btn-primary" (click)="onLogin()">{{ 'login.title' | translate }}</button>
				</div>
				<div class="col-12 col-md-8 form-group text-right small">
					<a [routerLink]="['/forgottenPassword']" class="no-account">{{ 'login.forgotPassword' | translate }}</a>
				</div>
			</div>
			<div class="row align-content-center" *ngIf="loginType == 'sso'">
				<div class="col-12">
					<button type="submit" class="btn btn-primary" (click)="onMicrosoft()"><i class="bi bi-windows mr-3"></i>{{ 'login.microsoft' | translate }}</button>
				</div>
			</div>
			<div class="row align-content-center" *ngIf="loginType == ''">
				<div class="col-12">
					<button type="submit" class="btn btn-primary" (click)="onCheckSSO()">{{ 'general.next' | translate }}</button>
				</div>
				
			</div>
		</form>
	</div>
</div>
