import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DecisionModalComponent } from 'app/decision/decision-modal/decision-modal.component';
import { ProductService } from 'app/product/product.service';
import { Cloud, Query, LiveQuerySubscription } from 'parse';
import { Product } from '../../../../shared/models/product';
import { ProductDecision } from '../../../../shared/models/product-decision';
import { Sprint } from '../../../../shared/models/sprint';
import { TranslateService } from '@ngx-translate/core';
import { ChartHappinessModalComponent } from 'app/accounting/chart-happiness-modal/chart-happiness-modal.component';
import { ChartKnowledgeModalComponent } from 'app/accounting/chart-knowledge-modal/chart-knowledge-modal.component';
import { AnalyticsService } from 'app/shared/analytics.service';
import { NextTitleService } from 'app/next-title.service';

@Component({
	selector: 'app-product-accounting-overview',
	templateUrl: './product-accounting-overview.component.html',
	styleUrls: ['./product-accounting-overview.component.scss'],
})
export class ProductAccountingOverviewComponent implements OnInit, OnDestroy {
	public product: Product;
	public productIA: Object;
	public currentQuarter: any;
	public decisions: ProductDecision[];
	public isReady: boolean;
	public sprints: Sprint[];
	public latestHappiness: number;
	public latestEducation: number;
	public experimentVelocityData: Object[];
	public learningVelocityData: Object[];
	public learningCostData: Object[];
	public quarterLabels: string[];
	public stagesSummaryIA: Object;

	private decisionSubscription: LiveQuerySubscription;

	constructor(
		public productService: ProductService,
		private modalService: NgbModal,
		private translate: TranslateService,
		private analytics: AnalyticsService,
		private titleService: NextTitleService
	) {}

	async ngOnInit() {
		// this.analytics.track('Opened accounting');

		this.product = await this.productService.getActiveProduct();
		await this.subscribeDecisions();

		this.titleService.setTitle('Innovation Accounting - ' + this.product.name);

		this.getLatestSprintInfo();
		this.getInnovationAccounting();
		await this.getInnovationStagesAndSummary();
	}

	ngOnDestroy() {
		if (this.decisionSubscription) {
			this.decisionSubscription.unsubscribe();
		}
	}

	async getLatestSprintInfo() {
		this.sprints = await this.productService.getStartedSprints(this.product);
		// check if current sprint is finished, otherwise get previous sprint
		if (this.sprints[0]?.status === Sprint.Status.DONE) {
			this.latestHappiness = parseInt(this.sprints[0].teamHapiness);
			this.latestEducation = parseInt(this.sprints[0].teamEducation);
		} else {
			if (this.sprints.length > 1) {
				this.latestHappiness = parseInt(this.sprints[1].teamHapiness);
				this.latestEducation = parseInt(this.sprints[1].teamEducation);
				return;
			}

			this.latestHappiness = 0;
			this.latestEducation = 0;
		}
	}

	async getInnovationAccounting() {
		this.productIA = await Cloud.run('getInnovationAccountingProduct', {
			productId: this.product.id,
		});

		const keys = Object.keys(this.productIA);
		this.currentQuarter = this.productIA[keys[keys.length - 1]];

		this.getGraphData();
		this.isReady = true;
	}

	getGraphData() {
		const keys = Object.keys(this.productIA);

		let experimentCharData = [];
		let learningCharData = [];
		let learningCostCharData = [];

		this.quarterLabels = [];

		keys.forEach((key) => {
			if (this.productIA[key].experimentVelocity) {
				const percentage = Math.round((this.productIA[key].experimentVelocity / this.productIA[key].weeks) * 100);
				experimentCharData.push(percentage);
			} else {
				experimentCharData.push(0);
			}

			if (this.productIA[key].learningVelocity) {
				const percentage = Math.round((this.productIA[key].learningVelocity / this.productIA[key].experimentVelocity) * 100) || 0;
				learningCharData.push(percentage);
			} else {
				learningCharData.push(0);
			}

			if (this.productIA[key].costsMade) {
				const successfulExperiments = this.productIA[key].learningVelocity || 1;
				const costPerLearning = Math.round(this.productIA[key].costsMade / successfulExperiments);
				learningCostCharData.push(costPerLearning);
			} else {
				learningCostCharData.push(0);
			}

			const quarter = this.productIA[key].quarter + 1;
			const label = 'Q' + quarter + ' ' + this.productIA[key].year;

			this.quarterLabels.push(label);
		});

		this.experimentVelocityData = [
			{
				data: experimentCharData,
				label: this.translate.instant('innovationAccounting.experimentVelocity'),
			},
		];

		this.learningVelocityData = [
			{
				data: learningCharData,
				label: this.translate.instant('innovationAccounting.learningRatio'),
			},
		];

		this.learningCostData = [
			{
				data: learningCostCharData,
				label: this.translate.instant('innovationAccounting.costPerLearning'),
			},
		];
	}

	async subscribeDecisions() {
		const decisionsQuery = new Query(ProductDecision);
		decisionsQuery.include(['minutes', 'attachments']);
		decisionsQuery.equalTo('product', this.product);
		decisionsQuery.equalTo('archived', false);
		decisionsQuery.descending('decisionDate');
		this.decisions = await decisionsQuery.find();
		this.decisionSubscription = await decisionsQuery.subscribe();

		this.decisionSubscription.on('create', (decision: ProductDecision) => {
			this.decisions.splice(0, 0, decision);
			this.decisions.sort(this.decisionSortDescending);
			this.getInnovationStagesAndSummary();
		});
		this.decisionSubscription.on('update', (decision: ProductDecision) => {
			const index = this.decisions.findIndex((d) => d.id === decision.id);
			this.decisions[index] = decision;
			this.decisions.sort(this.decisionSortDescending);
			this.getInnovationStagesAndSummary();
		});
		this.decisionSubscription.on('leave', (decision: ProductDecision) => {
			const index = this.decisions.findIndex((d) => d.id === decision.id);
			this.decisions.splice(index, 1);
			this.getInnovationStagesAndSummary();
		});
	}

	decisionSortDescending(a, b): number {
		if (a.decisionDate < b.decisionDate) {
			return 1;
		} else if (a.decisionDate > b.decisionDate) {
			return -1;
		} else {
			return 0;
		}
	}

	openDecision(decision) {
		const modalRef = this.modalService.open(DecisionModalComponent, {
			size: 'md',
		});
		modalRef.componentInstance.decision = decision;
	}

	newDecision() {
		let decision = new ProductDecision();
		decision.product = this.product;

		this.openDecision(decision);
	}

	openHappinessModal() {
		const modalRef = this.modalService.open(ChartHappinessModalComponent, {
			size: 'md',
		});
		modalRef.componentInstance.sprints = this.sprints as Sprint[];
	}

	openKnowledgeModal() {
		const modalRef = this.modalService.open(ChartKnowledgeModalComponent, {
			size: 'md',
		});
		modalRef.componentInstance.sprints = this.sprints as Sprint[];
	}

	async getInnovationStagesAndSummary() {
		this.stagesSummaryIA = await Cloud.run('getInnovationAccountingStages', {
			productId: this.product.id,
		});
	}
}
