import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Product } from '../../../../shared/models/product';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-summary-card',
	templateUrl: './summary-card.component.html',
	styleUrls: ['./summary-card.component.scss'],
})
export class SummaryCardComponent implements OnInit {
	@Input() product: Product;
	@Input() totalTimeSpent: number;
	@Input() totalMoneySpent: number;
	@Input() totalMoneyReceived: number;

	public ago: string;

	constructor(private translate: TranslateService) {
		dayjs.extend(relativeTime);
	}

	ngOnInit() {
		this.translate.onLangChange.subscribe(async () => {
			this.setTranslation();
		});
		this.setTranslation();
	}

	setTranslation() {
		if (this.translate.currentLang === 'en') {
			this.ago = dayjs(this.product.startedAt).fromNow();
		} else {
			if (this.totalTimeSpent > 0 && this.totalTimeSpent < 2) {
				this.ago = this.translate.instant('month.singularAgo', { ago: this.totalTimeSpent });
			} else {
				this.ago = this.translate.instant('month.pluralAgo', { ago: this.totalTimeSpent });
			}
		}
	}
}
