import { Component, Input, OnChanges } from '@angular/core';
import { NextConfigService } from 'app/next-config.service';
import dayjs from 'dayjs';
import { ProductDecision } from '../../../../shared/models/product-decision';
import { Portfolio } from '../../../../shared/models/portfolio';
import { PortfolioService } from 'app/portfolio/portfolio.service';

@Component({
	selector: 'app-stage-time-spent',
	templateUrl: './stage-time-spent.component.html',
})
export class StageTimeSpentComponent implements OnChanges {
	@Input() decisions: Array<ProductDecision>;
	@Input() portfolio: Portfolio;

	public stages = {
		datasets: [],
		labels: [],
	};
	public datasets = [];
	public chartColors = [{ backgroundColor: '' }];
	public chartOptions = {
		responsive: true,
		legend: false,
		scales: {
			// xAxes: [{
			//    ticks: {
			//       callback: (value, index, values) => {
			//          return this.stages.labels[value];
			//       }
			//    }
			// }],
			yAxes: [
				{
					scaleLabel: {
						display: false,
						labelString: 'Months',
					},
					ticks: {
						precision: 3,
						maxTicksLimit: 5,
						min: 0,
					},
				},
			],
		},
		tooltips: {
			callbacks: {
				label: (tooltipItem, data) => {
					return tooltipItem.yLabel + ' months';
				},
			},
		},
	};
	public isReady = false;

	constructor(private configService: NextConfigService, private portfolioService: PortfolioService) {
		this.chartColors[0].backgroundColor = this.configService.colorPrimary[0];
	}

	ngOnChanges(changes: any) {
		const portfolioStages = this.portfolioService.getMainStages(this.portfolio);
		this.stages.labels = this.portfolioService.getStageNames(this.portfolio, portfolioStages);

		const chartData = [];
		portfolioStages.forEach((stage) => {
			chartData.push(0);
		});

		const decisionPerProduct = {};
		const timeSpent = {};

		// Sort the decsions by time
		// Sort a clone of the array, since we use this array in other parts of the app
		// and this array then gets sorted there as well!!!
		const sortedDecisions = this.decisions.slice(0);
		sortedDecisions.sort(this.decisionSort);

		// Group decisions by product
		sortedDecisions.forEach((decision) => {
			if (!decisionPerProduct[decision.product.id]) {
				decisionPerProduct[decision.product.id] = [];

				// Create decision to start project, to properly populate first stage
				// If there is a start date
				// if (decision.product.startedAt) {
				// 	const startDecision = new ProductDecision();
				// 	startDecision.product = decision.product;
				// 	startDecision.decisionDate = startDecision.product.startedAt;
				// 	startDecision.decisionMade = 'moveFwd';
				// 	startDecision.toStage = startDecision.product.startStage || 'kickbox';

				// 	decisionPerProduct[decision.product.id].push(startDecision);
				// }
			}

			decisionPerProduct[decision.product.id].push(decision);
		});

		// Loop through all decisions to determine time between stages
		for (const productId in decisionPerProduct) {
			let prevDecision;

			decisionPerProduct[productId].forEach((decision) => {
				if (!prevDecision) {
					prevDecision = decision;
					return;
				}

				if (!timeSpent[prevDecision.toStage]) {
					timeSpent[prevDecision.toStage] = [];
				}

				const a = dayjs(decision.decisionDate);
				const b = dayjs(prevDecision.decisionDate);

				// Calculate difference in months
				const diff = a.diff(b, 'months', true);

				timeSpent[prevDecision.toStage].push(diff);

				prevDecision = decision;
			});
		}

		// Convert timeSpent to chart data array
		portfolioStages.forEach((stage, index) => {
			if (!timeSpent[stage]) {
				return;
			}

			// Calculate average from elements of each stage (array)
			const avg =
				timeSpent[stage].reduce(function (p, c) {
					return p + c;
				}) / timeSpent[stage].length;

			// Round to 1 digit precision
			chartData[index] = Math.round(avg * 10) / 10;
		});

		this.datasets = [
			{
				label: 'Average time per stage',
				data: chartData,
			},
		];

		this.isReady = true;
	}

	decisionSort(a, b): number {
		if (a.decisionDate < b.decisionDate) {
			return -1;
		} else if (a.decisionDate < b.decisionDate) {
			return 1;
		} else {
			return 0;
		}
	}
}
