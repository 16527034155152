import { Component, OnInit } from '@angular/core';
import * as Parse from 'parse';
import { TranslateService } from '@ngx-translate/core';
import { NextTitleService } from 'app/next-title.service';

@Component({
	selector: 'app-forgotten-password',
	templateUrl: './forgotten-password.component.html',
	styleUrls: ['./forgotten-password.component.scss'],
})
export class ForgottenPasswordComponent implements OnInit {
	public email: string;
	public errorMessage: string;
	public resultMessage: string;

	constructor(
		private translate: TranslateService,
		private titleService: NextTitleService
	) {}

	ngOnInit() {
		this.titleService.setTitle('Forgotten Password');
	}

	onRequest() {
		Parse.User.requestPasswordReset(this.email)
			.then((result) => {
				this.resultMessage = this.translate.instant('forgottenPassword.result', { 'email': this.email });
				this.errorMessage = '';
			})
			.catch((error) => {
				this.errorMessage = error.message;
				this.resultMessage = '';
			});
	}
}
