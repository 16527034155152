import { Component, Input, OnChanges } from '@angular/core';
import { NextConfigService } from 'app/next-config.service';
import { Product } from '../../../../shared/models/product';
import { Portfolio } from '../../../../shared/models/portfolio';
import { TranslateService } from '@ngx-translate/core';
import { PortfolioService } from 'app/portfolio/portfolio.service';

@Component({
	selector: 'app-chart-stage-stopped',
	templateUrl: './stage-stopped.component.html',
})
export class StageStoppedComponent implements OnChanges {
	@Input() products: Array<Product>;
	@Input() portfolio: Portfolio;

	public stages = {
		datasets: [],
		labels: ['Pre-Validation', 'Problem', 'Solution', 'Revenue', 'Scale'],
	};
	public datasets = [];
	public chartColors = [{ backgroundColor: '' }];
	public chartOptions = {
		responsive: true,
		legend: false,
		scales: {
			// xAxes: [{
			//    ticks: {
			//       callback: (value, index, values) => {
			//          return this.stages.labels[value];
			//       }
			//    }
			// }],
			yAxes: [
				{
					scaleLabel: {
						display: false,
						labelString: 'Amount',
					},
					ticks: {
						precision: 1,
						maxTicksLimit: 5,
						min: 0,
					},
				},
			],
		},
		tooltips: {
			callbacks: {
				label: (tooltipItem, data) => {
					if (tooltipItem.yLabel == 1) {
						return tooltipItem.yLabel + ' ' + this.translate.instant('product.singular');
					}

					return tooltipItem.yLabel + ' ' + this.translate.instant('product.plural');
				},
			},
		},
	};
	public isReady = false;

	constructor(private configService: NextConfigService, private translate: TranslateService, private portfolioService: PortfolioService) {
		this.chartColors[0].backgroundColor = this.configService.colorPrimary[0];
	}

	ngOnChanges(changes: any) {
		if (!changes.products) {
			return;
		}

		this.products = changes.products.currentValue;
		const portfolioStages = this.portfolioService.getMainStages(this.portfolio);
		const chartData = [0, 0, 0, 0, 0];
		this.stages.labels = this.portfolioService.getStageNames(this.portfolio, portfolioStages);

		this.products.forEach((product) => {
			if (product.archived) {
				return;
			}

			if (!product.stopped) {
				return;
			}

			const index = portfolioStages.indexOf(product.stage);

			chartData[index]++;
		});

		this.datasets = [
			{
				label: 'Average time per stage',
				data: chartData,
			},
		];

		this.isReady = true;
	}
}
