<div class="container">
	<div class="row">
		<div class="col text-center mt-5">
			<h1>{{ 'notFound.title' | translate }}</h1>
			<h2>{{ 'notFound.lostInSpace' | translate }}</h2>
			<p class="mt-3">{{ 'notFound.page' | translate }}</p>
			<p class="countdown mt-5">
				<strong>{{ countdown }}</strong>
			</p>
		</div>
	</div>
</div>
