import { Component, Input, OnInit } from '@angular/core';
import { Lens } from '@shared/models/lens';
import { NextConfigService } from 'app/next-config.service';
import { Portfolio } from '../../../../shared/models/portfolio';
import { Product } from '../../../../shared/models/product';
import { Progress } from '../../../../shared/models/progress';

@Component({
	selector: 'app-funnel-card',
	templateUrl: './funnel-card.component.html',
	styleUrls: ['./funnel-card.component.scss'],
})
export class FunnelCardComponent implements OnInit {
	@Input() product: Product;
	@Input() portfolio: Portfolio;
	@Input() lens?: Lens;
	public progress: Progress;
	public progressbarType = 'secondary';

	public color = 'transparent';

	get LensValues() {
		return Lens.LensValues;
	}

	constructor(private configService: NextConfigService) {}

	ngOnInit() {
		// set color based on thesis
		const index = this.indexOf();

		if (index > -1) {
			this.color = this.product.thesis?.color || this.configService.colors[index];
		}

		if (!!this.lens) {
			return;
		}
		// set progress bar
		if (this.product.activeProgress && this.product.activeProgress.stage == this.product.stage) {
			this.progress = this.product.activeProgress;

			if (this.progress.getAverageScore() >= 80) {
				this.progressbarType = 'success';
			}
		}
	}

	indexOf() {
		let index = -1;

		if (!this.product.thesis) {
			return index;
		}

		if (!this.portfolio) {
			return index;
		}

		for (let thesis of this.portfolio.theses) {
			if (thesis.id === this.product.thesis.id) {
				return index + 1;
			}

			index++;
		}

		// nothing found
		return -1;
	}
}
