<div class="row no-gutters align-items-center">
	<div class="col">
		<p class="task-text">{{ task.title }}</p>
	</div>
	<div class="col-auto mr-1">
		<app-avatar-row [users]="task.assignedUsers" direction="right" [avatarSize]="1"></app-avatar-row>
	</div>
	<div class="col-auto">
		<span *ngIf="task.experiment" class="badge badge-secondary light-blue"><i class="bi bi-eyedropper"></i> {{ task.product.getAbbrev() }}-{{ task.experiment.number }}</span>
	</div>
	<div class="col-auto">
		<span class="identifier">{{ product.getAbbrev() }}-{{ task.number }}</span>
	</div>
</div>
