<div class="modal-content">
	<div class="modal-header align-items-center">
		<p class="modal-title">{{ 'experiment.tasksCompleted' | translate }}</p>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<p>{{ 'experiment.tasksCompletedCongrats' | translate }}</p>
		<div class="experiment-row-container">
			<app-experiment-row [experiment]="experiment"></app-experiment-row>
		</div>
		<p>{{ 'experiment.askToAnalyze' | translate }}</p>
	</div>

	<div class="modal-footer">
		<button class="btn btn-light mr-auto" (click)="activeModal.close()">{{ 'experiment.analyzeLater' | translate }}</button>
		<button class="btn btn-primary ml-auto" (click)="analyze()">{{ 'experiment.analyze' | translate }}</button>
	</div>
</div>
