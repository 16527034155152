<div class="funnel-container settings-content" *ngIf="portfolio">
	<h5>{{ 'portfolioModalSettings.stageNames.title' | translate }}</h5>

	<ngb-alert class="bottom col-8" type="info" [dismissible]="false" *ngIf="portfolio.amountStages > 4">
		<p class="mb-2">{{ 'portfolioModalSettings.amountStages.updateHint' | translate }}:</p>
		<ul>
			<li>{{ 'portfolioModalSettings.amountStages.hintNameStages' | translate }}</li>
			<li>
				<a [routerLink]="['/portfolio', portfolio.id, 'settings', 'canvas-design']">{{ 'portfolioModalSettings.canvasDesigns.title' | translate }}</a>
			</li>
			<li *ngIf="!portfolio.defaultContentExperiments">
				<a [routerLink]="['/portfolio', portfolio.id, 'settings', 'experiments']">{{ 'experiment.plural' | translate }}</a>
			</li>
			<li *ngIf="!portfolio.defaultKeyQuestions">
				<a [routerLink]="['/portfolio', portfolio.id, 'settings', 'key-questions']">{{ 'keyQuestion.plural' | translate }}</a>
			</li>
		</ul>
	</ngb-alert>

	<div class="form-group">
		<p>
			{{ 'portfolioModalSettings.amountStages.description' | translate }} ({{
				'portfolioModalSettings.amountStages.hint'
					| translate
						: {
								prevalidation: (this.portfolio.stageNames['kickbox'] || 'productStages.kickbox' | translate),
								validated: (this.portfolio.stageNames['validated'] || 'productStages.validated' | translate)
						  }
			}})
		</p>

		<div class="row">
			<div class="col-12 col-md-3 input-group">
				<label class="text text-danger" *ngIf="showWarning">{{ 'portfolioModalSettings.amountStages.warning' | translate }}</label>
				<input type="number" id="amountStages" min="1" max="6" class="form-control" name="amountStages" [(ngModel)]="portfolio.amountStages" />
				<div class="input-group-addon">
					<input type="button" class="btn btn-primary" [value]="'portfolioModalSettings.amountStages.update' | translate" [disabled]="!portfolio.dirty()" (click)="saveEditable()" />
				</div>
			</div>
		</div>
	</div>

	<p class="mt-3">{{ 'portfolioModalSettings.stageNames.description' | translate }}</p>

	<div class="row funnel no-gutters mt-3">
		<div class="col funnel-stage" *ngFor="let stage of stages" [ngClass]="stage">
			<inline-editor type="text" size="10" [(ngModel)]="this.portfolio.stageNames[stage]" [empty]="'productStages.' + stage | translate" (onSave)="saveEditable()"></inline-editor>
		</div>
	</div>
</div>
