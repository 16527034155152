<div class="container page">
	<div class="row">
		<div class="col-12">
			<div class="row">
				<div class="col-12">
					<h1>{{ 'accounting.innovation' | translate }}</h1>
				</div>
			</div>
			<div class="row mb-5">
				<div class="col-lg-2">
					<p class="header">{{ 'product.plural' | translate }}</p>
				</div>
				<div class="col-lg-10">
					<div class="row">
						<div class="col card-container">
							<app-team-happiness-card
								[happiness]="currentQuarter?.totalTeamHapiness / currentQuarter?.totalRecordedHapiness"
								[data]="iA"
								showPortfolioData="true"
								(openChart)="openHappinessModal()"
							></app-team-happiness-card>
						</div>
						<div class="col card-container">
							<app-team-knowledge-card
								[knowledge]="currentQuarter?.totalTeamKnowledgibility / currentQuarter?.totalRecordedKnowledgibility"
								[data]="iA"
								showPortfolioData="true"
								(openChart)="openKnowledgeModal()"
							></app-team-knowledge-card>
						</div>
					</div>
				</div>
			</div>
			<div class="row mb-5">
				<div class="col-lg-2"></div>
				<div class="col-lg-10">
					<div class="row mb-4">
						<div class="col-lg-3">
							<app-velocity-card
								[title]="'innovationAccounting.experimentVelocity' | translate"
								[fraction]="currentQuarter?.totalExperimentVelocity / currentQuarter?.totalWeeks || 0"
								(cardClicked)="openExperimentVelocityModal()"
								clickable="true"
							></app-velocity-card>
						</div>
						<div class="col-lg-9">
							<app-velocity-chart *ngIf="experimentVelocityData" [datasets]="experimentVelocityData" [labels]="labels"></app-velocity-chart>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-3">
							<app-velocity-card
								[title]="'innovationAccounting.learningRatio' | translate"
								[fraction]="currentQuarter?.totalLearningVelocity / currentQuarter?.totalExperimentVelocity || 0"
								(cardClicked)="openLearningVelocityModal()"
								clickable="true"
							></app-velocity-card>
						</div>
						<div class="col-lg-9">
							<app-velocity-chart *ngIf="learningVelocityData" [datasets]="learningVelocityData" [labels]="labels"></app-velocity-chart>
						</div>
					</div>
				</div>
			</div>

			<div class="row mb-0">
				<div class="col-lg-2">
					<p class="header">{{ 'portfolio.singular' | translate }}</p>
				</div>
				<div class="col-lg-10">
					<div class="row mb-5">
						<div class="col-lg-6 pr-lg-5" *ngIf="!!products">
							<!-- Product / Stage chart -->
							<h3>{{ 'charts.stageProduct.title' | translate }}</h3>
							<p class="small mb-3">{{ 'charts.stageProduct.info' | translate }}</p>
							<app-chart-stage-product [products]="products" [portfolio]="portfolio"></app-chart-stage-product>
						</div>
						<div class="col-lg-6 pl-lg-5" *ngIf="!!products">
							<!-- Stopped per stage chart -->
							<h3>{{ 'charts.stoppedPerStage.title' | translate }}</h3>
							<p class="small mb-3">{{ 'charts.stoppedPerStage.info' | translate }}</p>
							<app-chart-stage-stopped [products]="products" [portfolio]="portfolio"></app-chart-stage-stopped>
						</div>
					</div>
					<div class="row mb-5">
						<div class="col-lg-6 pr-lg-5" *ngIf="!!decisions">
							<!-- Time per stage chart -->
							<h3>{{ 'charts.timeSpent.title' | translate }}</h3>
							<p class="small mb-3">{{ 'charts.timeSpent.info' | translate }}</p>
							<app-stage-time-spent [decisions]="decisions" [portfolio]="portfolio"></app-stage-time-spent>
						</div>

						<div class="col-lg-6 pl-lg-5" *ngIf="!!decisions">
							<!-- Investment per stage chart -->
							<h3>{{ 'charts.averageInvestments.title' | translate }}</h3>
							<p class="small mb-3">{{ 'charts.averageInvestments.info' | translate }}</p>
							<app-chart-stage-average-investment [decisions]="decisions" [portfolio]="portfolio"></app-chart-stage-average-investment>
						</div>
					</div>
					<div class="row mb-5">
						<div class="col-lg-6 pr-lg-5" *ngIf="!!decisions">
							<!-- average cost per stage chart -->
							<h3>{{ 'charts.averageCost.title' | translate }}</h3>
							<p class="small mb-3">{{ 'charts.averageCost.info' | translate }}</p>
							<app-stage-average-cost [costData]="costData" [portfolio]="portfolio"></app-stage-average-cost>
						</div>
						<div class="col-lg-6 pl-lg-5" *ngIf="!!decisions">
							<!-- average cost per learning per stage chart -->
							<h3>{{ 'charts.averageCostPerLearning.title' | translate }}</h3>
							<p class="small mb-3">{{ 'charts.averageCostPerLearning.info' | translate }}</p>
							<app-stage-average-cost-learning [costData]="costData" [portfolio]="portfolio"></app-stage-average-cost-learning>
						</div>
					</div>
					<div class="row mb-0">
						<div class="col-lg-6 pr-lg-5" *ngIf="!!products">
							<!-- Product / Thesis chart -->
							<h3>{{ 'charts.productsThesis' | translate }}</h3>
							<app-chart-thesis-product [products]="products" [portfolio]="portfolio"></app-chart-thesis-product>
						</div>
						<div class="col-lg-6 pl-lg-5" *ngIf="!!products">
							<!-- Products stopped chart -->
							<h3>{{ 'charts.productStopped.title' | translate }}</h3>
							<app-chart-stopped-product [products]="products"></app-chart-stopped-product>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
