import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Product } from './product';
import { Portfolio } from './portfolio';
import { User } from './user';

@ParseSubClass('Invite')
export class Invite extends Parse.Object {
	@ParseProperty() public email: string;
	@ParseProperty() public product: Product;
	@ParseProperty() public portfolio: Portfolio;
	@ParseProperty() public pending: boolean;
	@ParseProperty() public createdBy: User;

	constructor() {
		super('Invite');
	}
}
