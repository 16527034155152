<div class="modal-content">
	<div class="modal-header">
		<p>{{ 'experiment.move' | translate }}</p>

		<button type="button" class="close" (click)="activeModal.close('cancel')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<p>{{ 'experiment.backlog.draggedLinkedTasks' | translate: { source: sourceName, destination: destinationName } }}</p>
		<div class="tasks">
			<app-task-row [task]="task" [product]="product" *ngFor="let task of tasks"></app-task-row>
		</div>
		<!-- list tasks -->
		<p *ngIf="shouldAskMovement">{{ 'experiment.backlog.whatToDoWith' | translate }}</p>
		<p *ngIf="!shouldAskMovement">{{ 'experiment.backlog.willBeMoved' | translate: { destination: destinationName } }}</p>
	</div>
	<div class="modal-footer">
		<div class="d-flex flex-row justify-content-around m-0 mr-auto">
			<button *ngIf="shouldAskMovement" class="btn btn-outline-secondary" (click)="activeModal.close('moveExperimentOnly')">{{ 'experiment.backlog.moveOnlyExperiment' | translate }}</button>
			<button *ngIf="!shouldAskMovement" class="btn btn-outline-secondary" (click)="activeModal.close('cancel')">{{ 'general.cancel' | translate }}</button>
		</div>
		<div class="d-flex flex-row justify-content-around m-0 ml-auto">
			<button *ngIf="shouldAskMovement" ngbAutoFocus class="btn btn-primary" (click)="activeModal.close('addToSprint')">{{ 'experiment.backlog.moveTasks' | translate: { destination: destinationName } }}</button>
			<button *ngIf="!shouldAskMovement" ngbAutoFocus class="btn btn-primary" (click)="activeModal.close('addToSprint')">{{ 'experiment.backlog.moveTasks' | translate }}</button>
		</div>
	</div>
</div>
