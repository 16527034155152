<div class="modal-content">
	<div class="modal-header align-items-center">
		<p class="modal-title align-items-center">
			<span class="badge badge-light" *ngIf="experiment.archived">{{ 'general.archived' | translate }}</span>
			<span *ngIf="experiment.status !== ExperimentStatus.RUNNING">{{ 'experiment.singular' | translate }}</span>
			<span *ngIf="experiment.status === ExperimentStatus.RUNNING">{{ 'experiment.analyze' | translate }}</span>

			<span class="abbrev">&nbsp;{{ product.getAbbrev() }}-{{ experiment.number }} </span>
		</p>

		<button type="button" class="close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>

		<button class="btn btn-link-white ml-auto" (click)="editExperiment()">{{ 'general.edit' | translate }}</button>
	</div>
	<div class="modal-body">
		<!-- All statuses-->
		<div class="container-fluid">

			<div class="row" *ngIf="experiment.type === 'learn'">
				<div class="col-12">
					<h4 class="modal-body-header">
						{{ 'experiment.typeExperiment.learn.goal' | translate }}
					</h4>
					<h2 class="modal-body-title">{{ experiment.learninggoal }}</h2>
				</div>
			</div>


			<div *ngIf="experiment.type === 'confirm'" class="row">
				<div class="col-6">
					<h4 class="modal-body-header">{{ 'assumption.singular' | translate }}</h4>
					<app-canvas-sticky *ngIf="!!experiment.sticky" [sticky]="experiment.sticky" [canvas]="experiment.canvas" [canOpen]="true"> </app-canvas-sticky>
					<p *ngIf="!!experiment.riskyassumption">{{ experiment.riskyassumption }}</p>
				</div>
			</div>

			<div class="row">
				<div class="col">
					<h4 class="modal-body-header">{{ 'type.singular' | translate }}</h4>
					<p>
						<span *ngIf="experiment.type === 'learn'" class="badge badge-secondary learn">{{ 'experiment.typeExperiment.learn.title' | translate }}</span>
					</p>
					<p>
						<span *ngIf="experiment.type === 'confirm'" class="badge badge-secondary confirm">{{ 'experiment.typeExperiment.confirm.title' | translate }}</span>
					</p>
				</div>
				<div class="col">
					<h4 class="modal-body-header">{{ 'segment.singular' | translate }}</h4>
					<p>
						<span class="badge badge-light mb-1">{{ portfolioService.getSegmentName(experiment.product.portfolio, experiment.segment) }}</span>
					</p>
				</div>
				
				<div class="col">
					<h4 class="modal-body-header">{{ 'general.createdBy' | translate }}</h4>
					<p>
						<app-avatar *ngIf="experiment.createdBy" [user]="experiment.createdBy" [avatarSize]="1"></app-avatar>
						{{ experiment.createdBy?.firstName }} {{ experiment.createdBy?.lastName }}
					</p>
				</div>
			</div>

			<div *ngIf="experiment.contentExperiment" class="row">
				<div class="col-12">
					<h4 class="modal-body-header">{{ 'contentExperiment.singular' | translate }}</h4>
					<a [routerLink]="['portfolio/', product.portfolio.id, 'experiment', experiment.contentExperiment.slug]" target="_blank">{{ experiment.contentExperiment.title }}</a>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<h4 class="modal-body-header">{{ 'experiment.experimentPlan' | translate }}</h4>
					<p class="editor-data" [innerHtml]="experiment.description"></p>
				</div>
			</div>

			<div class="row" *ngIf="experiment.type === 'confirm'">
				<div class="col-12">
					<h4 class="modal-body-header">{{ 'experiment.typeExperiment.confirm.biilCriteria' | translate }}</h4>
					<p>{{ experiment.successcriteria }}</p>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<h4 class="modal-body-header">{{ 'task.plural' | translate }}</h4>
					<div class="progress my-2" *ngIf="tasks && tasks.length > 0">
						<div
							class="progress-bar"
							[ngClass]="{ 'bg-success': taskPercentage === 100 }"
							role="progressbar"
							[style]="'width: ' + taskPercentage + '%'"
							aria-valuenow="taskPercentage"
							aria-valuemin="0"
							aria-valuemax="100"
						>
							{{ taskPercentage }}%
						</div>
					</div>
					<p *ngFor="let task of tasks" class="task-row hand form-check">
						<input class="form-check-input" type="checkbox" [checked]="task.status === TaskStatus.DONE" (change)="changeTaskStatus($event, task)" />
						<app-task-row [task]="task" [product]="product" (click)="openTask(task)"></app-task-row>
					</p>
					<p class="new-task" (click)="createNewTask()"><i class="bi bi-plus"></i>{{ 'task.createNew' | translate }}</p>
				</div>
			</div>
		</div>
		<!-- RUNNING experiments-->
		<div class="container-fluid" *ngIf="experiment.status === ExperimentStatus.RUNNING || experiment.status === ExperimentStatus.DONE || experiment.status === ExperimentStatus.ENDED_DEPRECATED">
			<hr />
			<div *ngIf="experiment.status === ExperimentStatus.RUNNING" class="row">
				<div class="col-12">
					<h4 class="modal-body-header">{{ 'experiment.costs' | translate }}</h4>
					<div class="input-group">
						<div class="input-group-append">
							<div class="input-group-text">{{ product.portfolio.currency | currencySymbol }}</div>
						</div>
						<input type="number" class="form-control" [(ngModel)]="experiment.cost" name="cost" />
					</div>
				</div>
			</div>

			<div *ngIf="experiment.status === ExperimentStatus.DONE" class="row">
				<div class="col-12">
					<h4 class="modal-body-header">{{ 'experiment.costs' | translate }}</h4>
					<p>{{ (experiment.cost | currency: product.portfolio.currency:'symbol':'3.0') || '-' }}</p>
				</div>
			</div>

			<div class="row no-gutters">
				<div class="col-12">
					<h4 class="modal-body-header">{{ 'experiment.learnings' | translate }}</h4>
				</div>
				<div class="col-4 pr-2 mb-2" *ngIf="experiment.status === ExperimentStatus.RUNNING">
					<div class="card h100 new" (click)="addLearning()"><i class="bi bi-plus"></i></div>
				</div>
				<app-learning-card class="col-4 pr-2 mb-2" *ngFor="let learning of experiment.learnings" [learning]="learning"></app-learning-card>
			</div>

			<div class="row">
				<div class="col-12">
					<h4 class="modal-body-header">{{ 'experiment.outcome' | translate }}</h4>

					<div *ngIf="experiment.type === 'learn'" class="block-container btn-group-toggle" data-toggle="buttons">
						<label class="btn card option" [ngClass]="{ success: experimentResult === 'true' }" *ngIf="experiment.status === ExperimentStatus.RUNNING || experimentResult === 'true'">
							<input type="radio" name="success" id="success" [(ngModel)]="experimentResult" value="true" />
							<p>{{ 'experiment.success' | translate }}</p>
							<p class="description">{{ 'experiment.typeExperiment.learn.success' | translate }}</p>
							<i class="description" [ngClass]="{ 'bi bi-circle': experimentResult !== 'true', 'bi bi-check-circle': experimentResult === 'true' }"></i>
						</label>
						<label class="btn card option" [ngClass]="{ fail: experimentResult === 'failed' }" *ngIf="experiment.status === ExperimentStatus.RUNNING || experimentResult === 'failed'">
							<input type="radio" name="failed" id="failed" [(ngModel)]="experimentResult" value="failed" />
							<p>{{ 'experiment.biiled' | translate }}</p>
							<p class="description">{{ 'experiment.typeExperiment.learn.biiled' | translate }}</p>
							<i class="description" [ngClass]="{ 'bi bi-circle': experimentResult !== 'failed', 'bi bi-x-circle': experimentResult === 'failed' }"></i>
						</label>
					</div>

					<div *ngIf="experiment.type === 'confirm'" class="block-container btn-group-toggle" data-toggle="buttons">
						<label class="btn card option" [ngClass]="{ success: experimentResult === 'validated' }" *ngIf="experiment.status === ExperimentStatus.RUNNING || experimentResult === 'validated'">
							<input type="radio" name="validated" id="validated" [(ngModel)]="experimentResult" value="validated" />
							<p>{{ 'confirmStages.validated' | translate }}</p>
							<p class="description">{{ 'assumption.validated' | translate }}</p>
							<i
								class="description"
								[ngClass]="{
									'bi bi-circle': experimentResult !== 'validated',
									'bi bi-check-circle': experimentResult === 'validated'
								}"
							></i>
						</label>
						<label
							class="btn card option"
							[ngClass]="{ success: experimentResult === 'invalidated' }"
							*ngIf="experiment.status === ExperimentStatus.RUNNING || experimentResult === 'invalidated'"
						>
							<input type="radio" name="validated" id="invalidated" [(ngModel)]="experimentResult" value="invalidated" />
							<p>{{ 'confirmStages.invalidated' | translate }}</p>
							<p class="description">{{ 'assumption.invalidated' | translate }}</p>
							<i
								class="description"
								[ngClass]="{
									'bi bi-circle': experimentResult !== 'invalidated',
									'bi bi-x-circle success': experimentResult === 'invalidated'
								}"
							></i>
						</label>
						<label class="btn card option" [ngClass]="{ fail: experimentResult === 'failed' }" *ngIf="experiment.status === ExperimentStatus.RUNNING || experimentResult === 'failed'">
							<input type="radio" name="validated" id="failed" [(ngModel)]="experimentResult" value="failed" />
							<p>{{ 'experiment.biiled' | translate }}</p>
							<p class="description">{{ 'experiment.typeExperiment.confirm.noSuccess' | translate }}</p>
							<i class="description" [ngClass]="{ 'bi bi-circle': experimentResult !== 'failed', 'bi bi-x-circle': experimentResult === 'failed' }"></i>
						</label>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<h4 class="modal-body-header">{{ 'experiment.conclusions' | translate }}</h4>
				</div>
				<div class="col-6 pr-2 mb-2" *ngIf="experiment.status === ExperimentStatus.RUNNING">
					<div class="card h100 new" (click)="addInsight()"><i class="bi bi-plus"></i></div>
				</div>
				<app-insight-card class="col-6 pr-2 mb-2" *ngFor="let insight of experiment.insights" [insight]="insight"></app-insight-card>
			</div>

			<div class="row">
				<div class="col-12">
					<h4 class="modal-body-header">{{ 'attachment.plural' | translate }}</h4>
					<ngb-alert type="danger" [dismissible]="false" *ngIf="fileErrorMessage">
						{{ fileErrorMessage }}
						<a href="mailto:majortom@togroundcontrol.com" target="_blank">majortom@togroundcontrol.com</a>
					</ngb-alert>
					<ng-container *ngIf="experiment.attachments && experiment.attachments.length">
						<app-attachment-card *ngFor="let file of experiment.attachments" [fileMeta]="file" (delete)="removeFile($event)"></app-attachment-card>
					</ng-container>
					<p *ngIf="experiment.status !== ExperimentStatus.RUNNING && !experiment.attachments?.length">-</p>
				</div>
				<div class="col-12 col-md-4 mt-2">
					<app-attachment-button-add [product]="product" (fileUpload)="uploadFiles($event)"></app-attachment-button-add>
				</div>
			</div>
		</div>

		<!-- ANALYZED and ENDED experiments-->
		<div class="container-fluid">
			<ngb-alert class="col-12" type="success" [dismissible]="false" *ngIf="resultMessage">
				{{ resultMessage }}
			</ngb-alert>
		</div>
	</div>

	<div class="modal-footer"></div>
	<div class="modal-footer">
		<div class="mr-auto" *ngIf="!experiment.isNew()">
			<button class="btn btn-link-danger mr-auto" (click)="deleteExperiment()">{{ 'general.remove' | translate }}</button>
		</div>
		<div class="ml-auto" *ngIf="experiment.status === ExperimentStatus.PREPARING">
			<button class="btn btn-primary" (click)="setStatus(ExperimentStatus.RUNNING)">{{ 'experiment.markRunning' | translate }}</button>
		</div>
		<div class="ml-auto" *ngIf="experiment.status === ExperimentStatus.RUNNING">
			<button class="btn btn-light" (click)="submitEdit()">{{ 'draft.save' | translate }}</button>
			<button class="btn btn-primary" (click)="setStatus(ExperimentStatus.DONE)">{{ 'experiment.markAnalyzed' | translate }}</button>
		</div>
		<div class="ml-auto" *ngIf="experiment.status === ExperimentStatus.DONE">
			<button class="btn btn-primary" (click)="activeModal.close()">{{ 'general.close' | translate }}</button>
		</div>
	</div>
</div>
