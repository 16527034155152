import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContentTool } from '../../../../shared/models/content-tool';
import { Query } from 'parse';
import { ContentToolService } from 'app/content-tool/content-tool.service';
import { NextTitleService } from 'app/next-title.service';
import { Portfolio } from '@shared/models/portfolio';
import { PortfolioService } from 'app/portfolio/portfolio.service';

@Component({
	selector: 'app-content-tool-detail',
	templateUrl: './content-tool-detail.component.html',
	styleUrls: ['./content-tool-detail.component.scss'],
})
export class ContentToolDetailComponent implements OnInit, OnDestroy {
	public portfolio: Portfolio;
	public contentTool: ContentTool;
	private routeSubscription;
	public relatedContentTools: ContentTool[];

	constructor(
		public route: ActivatedRoute,
		public translate: TranslateService,
		public router: Router,
		private titleService: NextTitleService,
		private contToolService: ContentToolService,
		private portfolioService: PortfolioService
	) {}

	async ngOnInit() {
		this.relatedContentTools = [];
		this.routeSubscription = this.route.params.subscribe(async (params) => {
			await this.getContentTool(params['slug']);
			this.getRelatedTools();
		});

		this.translate.onLangChange.subscribe(async () => {
			await this.getContentTool(this.contentTool.slug);
			this.getRelatedTools();
		});

		this.portfolio = await this.portfolioService.getActivePortfolio();
	}

	ngOnDestroy() {
		this.routeSubscription?.unsubscribe();
	}

	async getContentTool(slug) {
		let lang = this.translate.currentLang;
		let contentToolQuery = new Query(ContentTool);
		contentToolQuery.equalTo('slug', slug);
		contentToolQuery.equalTo('language', lang);
		contentToolQuery.equalTo('archived', false);
		this.contentTool = await contentToolQuery.first();

		if (!this.contentTool) {
			contentToolQuery.equalTo('language', this.translate.defaultLang);
			this.contentTool = await contentToolQuery.first();

			if (!this.contentTool) {
				this.router.navigate(['/portfolio', this.portfolio.id, 'experiment-tool']);
			}
		}

		this.titleService.setTitle(this.contentTool.title);
	}

	async getRelatedTools() {
		this.relatedContentTools = await this.contToolService.getRelatedContentTools(this.contentTool);
	}

	visitContentTool(tool) {
		this.router.navigate(['/portfolio', this.portfolio.id, 'tool', tool.slug]);
	}
}
