import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Cloud } from 'parse';
import { Portfolio } from '../../../../shared/models/portfolio';

@Component({
	selector: 'app-portfolio-modal-info',
	templateUrl: './portfolio-modal-info.component.html',
	styleUrls: ['./portfolio-modal-info.component.scss']
})
export class PortfolioModalInfoComponent implements OnInit {
	@Input() portfolio: Portfolio;
	@Input() accountId: string;
	public portfolioInfo: object;
	public renewDate: Date;
	public loading: boolean;
	public productsCount: number;
	public portfolioShadow: object;
	public trialSaved: boolean;

	constructor(public activeModal: NgbActiveModal) { }

	async ngOnInit() {
		this.loading = true;
		await this.getPortfolioInfo();
		this.portfolioShadow = {
			trial: this.portfolio.trial,
			trialEndsAt: this.portfolio.trialEndsAt
		};
		this.loading = false;
	}

	async getPortfolioInfo() {
		this.productsCount = await Cloud.run('billableProductsCountAsAdmin', {
			portfolioId: this.portfolio.id
		})

		if (!this.accountId) {
			return;
		}
		
		try {
			this.portfolioInfo = await Cloud.run('getAccountInfoAsAdmin', {
				accountId: this.accountId
			});
		} catch(error) {
			// account exists but probably has no stripeId, don't show billing data
			return;
		}

		const endDate = this.portfolioInfo['subscriptionInfo']['current_period_end'];
		endDate.setDate(endDate.getDate() + 1);
		this.renewDate = endDate;
	}

	async openInvoice(invoiceId) {
		const invoiceUrl = await Cloud.run('getMoneybirdInvoiceURL', {
			invoiceId: invoiceId
		});
		window.open(invoiceUrl, "_blank");
	}

	async saveTrial() {
		this.trialSaved = false;
		this.portfolio = await Cloud.run('updateTrial', {
			trialEndsAt: this.portfolioShadow['trialEndsAt'],
			trial: this.portfolioShadow['trial'],
			portfolioId: this.portfolio.id
		});
		this.trialSaved = true;
	}
}
