import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExperimentService } from '../experiment.service';
import { Experiment } from '../../../../shared/models/experiment';

@Component({
	selector: 'app-experiment-modal-tasks-done',
	templateUrl: './experiment-modal-tasks-done.component.html',
	styleUrls: ['./experiment-modal-tasks-done.component.scss'],
})
export class ExperimentModalTasksDoneComponent {
	@Input() experiment: Experiment;

	constructor(public activeModal: NgbActiveModal, private experimentService: ExperimentService) {}

	analyze() {
		this.activeModal.close();
		this.experimentService.openModal(this.experiment, this.experiment.product);
	}
}
