import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentTool } from '../../../shared/models/content-tool';
import { Query } from 'parse';

@Injectable({
  providedIn: 'root'
})
export class ContentToolService {

  	constructor(public translate: TranslateService) { }
    
	async getContentTools() {
		let contentToolQuery = new Query(ContentTool);
		contentToolQuery.equalTo('archived', false);
		contentToolQuery.ascending('title');
		return await contentToolQuery.find();
	}

	async getRelatedContentTools(contentTool) {
		let relatedQuery = new Query(ContentTool);
		relatedQuery.containedIn('stages', contentTool.stages);
		relatedQuery.notEqualTo('slug', contentTool.slug);
		relatedQuery.ascending('title');
		let allRelatedContentTools = await relatedQuery.find();
		
		let relContToolsPerSlug = {};
		allRelatedContentTools.forEach(contTool => {
			if (!relContToolsPerSlug[contTool.slug]) {
				relContToolsPerSlug[contTool.slug] = {};
			}
			relContToolsPerSlug[contTool.slug][contTool.language] = contTool;
		});

		let relatedContentTools = [];
		const slugs = Object.keys(relContToolsPerSlug);
		slugs.forEach(slug => {
			const translations = relContToolsPerSlug[slug];
			const langs = Object.keys(translations);

			if (langs.includes(this.translate.currentLang)) {
				relatedContentTools.push(translations[this.translate.currentLang]);
			} else if (langs.includes(this.translate.defaultLang)) {
				relatedContentTools.push(translations[this.translate.defaultLang]);
			} else if (langs.length >= 0) {
				relatedContentTools.push(translations[langs[0]]);
			}
		});

		return relatedContentTools;
	}
}
