import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Query, LiveQuerySubscription } from 'parse';
import { Insight } from '../../../../shared/models/insight';
import { Product } from '../../../../shared/models/product';

@Component({
	selector: 'app-insight-modal-overview',
	templateUrl: './insight-modal-overview.component.html',
	styleUrls: ['./insight-modal-overview.component.scss'],
})
export class InsightModalOverviewComponent implements OnInit, OnDestroy {
	@Input() product: Product;
	@Input() type: string;
	public insights: Insight[];
	public insightsPerMonth: any[];
	public searchTerms: string;
	private insightSubscription: LiveQuerySubscription;

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {
		this.queryInsights();
	}

	ngOnDestroy() {
		if (this.insightSubscription) {
			this.insightSubscription.unsubscribe();
		}
	}

	async queryInsights() {
		const insightQuery = new Query(Insight);
		insightQuery.equalTo('product', this.product);
		insightQuery.include('experiment');
		insightQuery.containedIn('archived', [null, undefined, false]);

		if (!!this.searchTerms) {
			insightQuery.fullText('text', this.searchTerms);
		}

		insightQuery.descending('createdAt');
		this.insights = await insightQuery.find();
		this.sortInsightsPerMonth();

		// make sure insights update on edit/delete/enter
		this.insightSubscription = await insightQuery.subscribe();
		this.insightSubscription.on('create', (insight: Insight) => {
			this.insights.splice(0, 0, insight);
			this.sortInsightsPerMonth();
		});

		this.insightSubscription.on('update', (insight: Insight) => {
			const index = this.insights.findIndex((element) => element.id === insight.id);
			this.insights[index] = insight;
			this.sortInsightsPerMonth();
		});

		this.insightSubscription.on('leave', (insight: Insight) => {
			const index = this.insights.findIndex((element) => element.id === insight.id);
			this.insights.splice(index, 1);
			this.sortInsightsPerMonth();
		});
	}

	sortInsightsPerMonth() {
		this.insightsPerMonth = [];
		let previousMonth;
		let monthIndex = -1;
		this.insights.forEach((insight) => {
			if (previousMonth === 'undefined' || insight.createdAt.getMonth() !== previousMonth) {
				previousMonth = insight.createdAt.getMonth();
				let newMonthObject = {
					date: insight.createdAt,
					insights: [insight],
				};
				this.insightsPerMonth.push(newMonthObject);
				monthIndex++;
			} else {
				this.insightsPerMonth[monthIndex]['insights'].push(insight);
			}
		});
	}
}
