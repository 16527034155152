import { Component, Input } from '@angular/core';
import { Sticky } from '../../../../shared/models/sticky';

@Component({
	selector: 'app-mini-segment',
	templateUrl: './mini-segment.component.html',
	styleUrls: ['./mini-segment.component.scss'],
})
export class MiniSegmentComponent {
	@Input() id: string;
	@Input() allStickies: Sticky[];
	@Input() selectedSegment: string;
	@Input() segmentName: string;

	constructor() {}
}
