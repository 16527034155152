<div class="row">
	<div *ngIf="!!product" class="col-12 col-md product-content">
		<div class="row">
			<div class="col-12 col-md-8">
				<h4>{{ 'cockpit.singular' | translate }}</h4>
			</div>
			<div class="col-12 col-md-4 text-right">
				<button type="button" class="btn btn-link" (click)="editCockpit()">{{ 'cockpit.edit' | translate }}</button>
			</div>
		</div>
		<app-product-info [product]="product"></app-product-info>
	</div>

	<div *ngIf="!product" class="col-12 col-lg-10 col-xl-9"></div>

	<!-- side bar -->
	<div *ngIf="product" class="col-12 col-md product-side-bar right activity-log">
		<h5>{{ 'activity.singular' | translate }}</h5>

		<ng-container *ngFor="let row of activityLog">
			<p class="date">{{ row.date }}</p>
			<div class="row align-items-center log-row" *ngFor="let log of row.logs">
				<div class="col-2">
					<app-avatar [user]="log.createdBy" [avatarSize]="1.5"></app-avatar>
				</div>
				<div class="col-10">
					<p>
						<strong>{{ log.createdBy.firstName }}</strong>
						<app-log-add-experiment *ngIf="log.activity === 'added experiment'" [log]="log"></app-log-add-experiment>
						<app-log-add-experiment *ngIf="log.activity === 'analyzed experiment'" [log]="log"></app-log-add-experiment>
						<app-log-validated-sticky *ngIf="log.activity === 'validated sticky'" [log]="log"></app-log-validated-sticky>
						<app-log-validated-sticky *ngIf="log.activity === 'invalidated sticky'" [log]="log"></app-log-validated-sticky>
						<app-log-sprint *ngIf="log.activity === 'started sprint'" [log]="log"></app-log-sprint>
						<app-log-sprint *ngIf="log.activity === 'completed sprint'" [log]="log"></app-log-sprint>
						<app-log-task *ngIf="log.activity === 'completed task'" [log]="log"></app-log-task>
					</p>
					<p class="time">{{ log.createdAt | date: 'shortTime' }}</p>
				</div>
			</div>
		</ng-container>
	</div>
</div>
