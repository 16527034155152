import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Portfolio } from '@shared/models/portfolio';
import { Product } from '@shared/models/product';
import { LogService } from 'app/log/log.service';
import { Query, Role, Cloud } from 'parse';

@Component({
	selector: 'app-product-settings-portfolio',
	templateUrl: './product-settings-portfolio.component.html',
	styleUrls: ['./product-settings-portfolio.component.scss'],
})
export class ProductSettingsPortfolioComponent implements OnInit, OnDestroy {
	public portfolios: Portfolio[];
	public portfoliosReady: boolean;
	public product: Product;
	private routeSubscription;
	public errorMessage: string;
	public resultMessage: string;

	constructor(
		private route: ActivatedRoute,
		private logService: LogService,
		private translate: TranslateService,
		private router: Router
	) {}

	async ngOnInit() {
		this.routeSubscription = this.route.parent.url.subscribe(async (url) => {
			const productId = url[1].path;
			const productquery = new Query(Product);
			this.product = await productquery.get(productId);
		});

		await this.getPortfolios();
	}

	ngOnDestroy() {
		this.routeSubscription?.unsubscribe();
	}

	private async getPortfolios() {
		const portfolioQuery = new Parse.Query(Portfolio);
		let allPortfolios = await portfolioQuery.find();

		this.portfolios = [];

		// only show portfolios that the user has access to:
		for (let portfolio of allPortfolios) {
			const roleQuery = new Query(Role);
			roleQuery.equalTo('users', Parse.User.current());
			roleQuery.equalTo('name', 'portfolio-' + portfolio.id);

			const role = await roleQuery.first();
			if (!!role) {
				this.portfolios.push(portfolio);
			}
		}
		this.portfoliosReady = true;
	}

	async onSave() {
		const dirtyKeys = this.product.dirtyKeys();

		for (let key of dirtyKeys) {
			if (key === 'portfolio' && this.product.portfolio.id != this.product.portfolio.id) {
				await Cloud.run('moveToPortfolio', {
					productId: this.product.id,
					portfolioId: this.product.portfolio.id,
				});

				// log activity of moving startup to portfolio
				this.logService.logActivity('moved startup to portfolio', 'Product', this.product.id, this.product, this.product.portfolio, {
					portfolio: this.product.portfolio,
				});
				this.logService.logActivity('moved startup from portfolio', 'Product', this.product.id, this.product, this.product.portfolio, {
					portfolio: this.product.portfolio,
				});
				this.product.portfolio = this.product.portfolio;
			} else if (
				key === 'thesis' &&
				((this.product.thesis && !this.product.thesis) ||
					(this.product.thesis && this.product.thesis && this.product.thesis.id != this.product.thesis.id))
			) {
				this.logService.logActivity('added startup to thesis', 'Product', this.product.id, this.product, this.product.portfolio, {
					thesis: this.product.thesis,
				});
			}
		}

		try {
			await this.product.save();

			this.errorMessage = '';
			this.resultMessage = this.translate.instant('product.successSave');
		} catch (err) {
			this.errorMessage = err.message;
			this.resultMessage = '';
		}
	}
}
