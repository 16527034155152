import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-remove-modal',
	templateUrl: './remove-modal.component.html',
	styleUrls: ['./remove-modal.component.scss'],
})
export class RemoveModalComponent implements OnInit {
	@Input() element: string;

	public message: string;
	public elementTranslation: string;

	constructor(public translate: TranslateService, public activeModal: NgbActiveModal) {}

	ngOnInit() {
		this.message = this.translate.instant(this.element + '.removeMessage');
		this.elementTranslation = this.translate.instant(this.element + '.singular').toLowerCase();
	}
}
