<div class="modal-content">
	<div class="modal-header">
		<p class="modal-title" *ngIf="experiment.isNew()">{{ 'experiment.new' | translate }}</p>
		<p class="modal-title" *ngIf="!experiment.isNew()">{{ 'experiment.edit' | translate }}</p>

		<button type="button" class="close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body edit">
		<div class="container-fluid">
			<div class="row align-items-center" *ngIf="step === 0 && canvasWasNotGiven">
				<div class="col-12 col-md-2"></div>
				<div class="col-12 col-md-9 pr-0">
					<app-spinner [show]="canvasWasNotGiven && !canvases" class="spinner"></app-spinner>

					<ng-select
						*ngIf="!!canvases"
						class="custom"
						name="canvas"
						[searchable]="false"
						[(ngModel)]="canvas"
						[items]="canvases"
						[placeholder]="'canvas.pleaseSelect' | translate"
						(clear)="onClearCanvas()"
						(change)="onChangeCanvas()"
					>
						<ng-template ng-label-tmp let-item="item">
							{{ item.name }}
						</ng-template>
						<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
							<div class="canvas-container">
								<app-canvas-mini [type]="item.type" [canvas]="item" [portfolio]="product.portfolio"></app-canvas-mini>
							</div>
							<p class="canvas-select-template">{{ item.name }}</p>
						</ng-template>
					</ng-select>
				</div>
			</div>

			<div class="row" *ngIf="step === 0">
				<div class="col-12 col-md-2 pt-3">
					<h4 class="modal-body-header">{{ 'segment.singular' | translate }}</h4>
				</div>
				<div class="col-12 col-md-9 pr-0 segment-container">
					<div *ngIf="!canvas" class="segment-placeholder">
						<i></i>
						<p>{{ 'canvas.selectFirst' | translate }}</p>
					</div>
					<div *ngIf="canvas" class="segment-canvas-container">
						<app-canvas-mini
							[type]="canvas.type"
							[showSegmentNames]="true"
							[selectedSegment]="experiment.segment"
							[canSelectSegment]="true"
							[exampleStickies]="exampleStickies"
							[canvas]="canvas"
							(selectCanvas)="onSelectSegment($event)"
							[portfolio]="product.portfolio"
						>
						</app-canvas-mini>
						<p>{{ 'experiment.selectSegment' | translate }}</p>
					</div>
				</div>
			</div>

			<div *ngIf="step === 0" class="row">
				<div class="col-12 col-md-2">
					<h4 class="modal-body-header">{{ 'experiment.typeExperiment.title' | translate }}</h4>
				</div>
				<div class="col-12 col-md-9 pr-0">
					<div class="row mx-0 btn-group-toggle select-experiment-type" data-toggle="buttons">
						<label class="col-lg-6 p-0 pr-lg-2 pr-sm-0 mb-sm-3 mb-lg-0 btn">
							<div class="card option" [ngClass]="{ learn: experiment.type === 'learn' }">
								<i class="learn"></i>
								<input type="radio" name="learn" id="learn" [(ngModel)]="experiment.type" value="learn" />
								<h6>{{ 'experiment.typeExperiment.learn.title' | translate }}</h6>
								<p>
									{{ 'experiment.typeExperiment.learn.elaboration' | translate: { segment: (canvas && experiment.segment && getSegmentName()) || 'general.specific' | translate } }}
								</p>
							</div>
						</label>
						<label class="col-lg-6 p-0 pl-lg-2 pl-sm-0 btn">
							<div class="card option" [ngClass]="{ confirm: experiment.type === 'confirm' }">
								<i class="confirm"></i>
								<input type="radio" name="confirm" id="confirm" [(ngModel)]="experiment.type" value="confirm" />
								<h6>{{ 'experiment.typeExperiment.confirm.title' | translate }}</h6>
								<p>{{ 'experiment.typeExperiment.confirm.elaboration' | translate }}</p>
							</div>
						</label>
					</div>
				</div>
			</div>

			<div *ngIf="step === 0 && !experiment.isNew()" class="row mt-5">
				<div class="col-12 col-md-2 pt-1">
					<h4 class="modal-body-header">{{ 'experiment.status.title' | translate }}</h4>
				</div>
				<div class="col-12 col-md-10">
					<!-- <p>{{ 'sprint.completeModal.selectMove' | translate }}</p> -->
					<label>
						<select class="custom-select" name="status" [(ngModel)]="experiment.status">
							<option [ngValue]="ExperimentStatus.PREPARING">
								{{ 'experiment.status.' + ExperimentStatus.PREPARING | translate }}
							</option>
							<option [ngValue]="ExperimentStatus.RUNNING">{{ 'experiment.status.' + ExperimentStatus.RUNNING | translate }}</option>
							<option [ngValue]="ExperimentStatus.DONE">{{ 'experiment.status.' + ExperimentStatus.DONE | translate }}</option>
						</select>
					</label>
				</div>
				<div class="col-12 col-md-2"></div>
				<div class="col-12 col-md-5">
					<label class="mb-0" for="fromDate">{{ 'sprint.startModal.startDate' | translate }}</label>
					<div class="input-group">
						<input class="form-control" placeholder="dd/mm/yyyy" id="fromDate" name="fromDate" [(ngModel)]="experiment.startAt" ngbDatepicker #dFrom="ngbDatepicker" />
						<div class="input-group-append">
							<button class="btn btn-outline-primary" (click)="dFrom.toggle()" type="button">
								<i class="bi bi-calendar3"></i>
							</button>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-5">
					<label class="mb-0" for="toDate">{{ 'sprint.startModal.endDate' | translate }}</label>
					<div class="input-group">
						<input
							class="form-control"
							placeholder="dd/mm/yyyy"
							id="toDate"
							name="toDate"
							[(ngModel)]="experiment.endAt"
							ngbDatepicker
							#dTo="ngbDatepicker"
							[disabled]="experiment.status === ExperimentStatus.PREPARING"
						/>
						<div class="input-group-append">
							<button class="btn btn-outline-primary" (click)="dTo.toggle()" type="button">
								<i class="bi bi-calendar3"></i>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="step === 1">
				<div class="row" *ngIf="experiment.type === 'learn'">
					<div class="col-12 form-group">
						<label
							>{{ 'experiment.typeExperiment.learn.question' | translate: { segment: getSegmentName() } }}
							<input type="text" class="form-control" [placeholder]="'experiment.typeExperiment.learn.goal' | translate" [(ngModel)]="experiment.learninggoal" />
						</label>
					</div>
				</div>

				<div class="row" *ngIf="experiment.type === 'confirm'">
					<div class="col-12 form-group">
						<label
							>{{ 'experiment.typeExperiment.confirm.question' | translate }}
							<ng-select
								class="custom"
								name="assumption"
								[(ngModel)]="experiment.sticky"
								[searchable]="false"
								[items]="segmentStickies"
								[placeholder]="'experiment.typeExperiment.confirm.questionPlaceholder' | translate: { segment: getSegmentName() }"
								(focus)="getAssumptions()"
							>
								<ng-template ng-label-tmp let-item="item">
									{{ item.text || 'general.untitled' | translate }}
								</ng-template>
								<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
									<app-canvas-sticky [sticky]="item" [canvas]="canvas" [canvasVersion]="canvas.versionDates[canvas.versionDates.length - 1]"></app-canvas-sticky>
								</ng-template>
							</ng-select>
						</label>
					</div>
				</div>

				<!-- <div class="row" *ngIf="experiment.type === 'confirm'">
					<div class="col-12 form-group">
						<label
							>{{ 'experiment.typeExperiment.confirm.hypothesisQuestion' | translate: { segment: getSegmentName() } }}
							<input type="text" class="form-control" [placeholder]="'experiment.typeExperiment.confirm.hypothesisPlaceholder' | translate" [(ngModel)]="experiment.learninggoal" />
						</label>
					</div>
				</div> -->

				<div class="row">
					<div class="col-12 form-group">
						<label
							>{{ 'contentExperiment.choose' | translate }}
							<div class="row">
								<div class="col-12">
									<ng-select
										class="custom"
										name="contentExperiment"
										[(ngModel)]="experiment.contentExperiment"
										[searchable]="false"
										[items]="contentExperiments"
										(change)="selectContentExperiment($event)"
									>
										<ng-template ng-label-tmp let-item="item">
											{{ item.title }}
										</ng-template>
										<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
											{{ item.title }}
										</ng-template>
									</ng-select>
								</div>
							</div>
						</label>
						<p
							*ngIf="experiment.contentExperiment"
							class="info"
							[innerHtml]="
								'contentExperiment.readMoreAbout'
									| translate: { route: '/portfolio/' + product.portfolio.id + '/experiment/' + experiment.contentExperiment.slug, title: experiment.contentExperiment.title }
							"
						></p>
					</div>
				</div>

				<div class="row">
					<div class="col-12 form-group quil-wrapper">
						<label>
							<span *ngIf="experiment.type === 'learn'">{{ 'experiment.typeExperiment.learn.describe' | translate }}:</span>
							<span *ngIf="experiment.type === 'confirm'">{{ 'experiment.typeExperiment.confirm.describe' | translate }}:</span>
						</label>

						<quill-editor name="experiment-plan" [(ngModel)]="experiment.description" [styles]="{ height: '10rem' }" placeholder=""></quill-editor>
					</div>
				</div>

				<div class="row" *ngIf="experiment.type === 'confirm'">
					<div class="col-12 form-group">
						<label
							>{{ 'experiment.typeExperiment.confirm.biilCriteriaQuestion' | translate }}
							<input type="text" class="form-control" [placeholder]="'experiment.typeExperiment.confirm.biilCriteriaPlaceholder' | translate" [(ngModel)]="experiment.successcriteria" />
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<div class="mr-auto" *ngIf="!experiment.isNew()">
			<button class="btn btn-link-danger mr-auto" (click)="deleteExperiment()">{{ 'general.remove' | translate }}</button>
		</div>

		<div class="ml-auto">
			<button class="btn btn-link" (click)="backStep()" *ngIf="step > 0">{{ 'general.back' | translate }}</button>
			<button ngbAutofocus class="btn btn-primary" (click)="nextStep()" *ngIf="step < 1" [disabled]="!canvas || !experiment.segment || !experiment.type">
				{{ 'general.next' | translate }}
			</button>

			<button class="btn btn-primary" (click)="createExperiment()" *ngIf="step === 1 && experiment.isNew()" [disabled]="!isComplete()">
				{{ 'experiment.create' | translate }}
			</button>
			<button ngbAutofocus class="btn btn-primary" (click)="saveExperiment()" *ngIf="step === 1 && !experiment.isNew()" [disabled]="!isComplete()">
				{{ 'experiment.save' | translate }}
			</button>
		</div>
	</div>
</div>
