import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { NextConfigService } from 'app/next-config.service';

@Component({
	selector: 'app-radial-progress',
	templateUrl: './radial-progress.component.html',
	styleUrls: ['./radial-progress.component.scss'],
})
export class RadialProgressComponent implements OnChanges {
	// created using tutorial: https://malcoded.com/posts/angular-progress-bars/

	@Input() value: number = 0;
	public circumference: number;
	public strokeDashOffset: number;

	constructor(public configService: NextConfigService) {
		this.circumference = 2 * Math.PI * 48;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!this.strokeDashOffset) {
			this.strokeDashOffset = 0;
		}
		if (changes['value']) {
			this.onPercentageChanged(changes['value'].currentValue);
		}
	}

	onPercentageChanged(value: number) {
		let percentage = Math.round(value * 100);
		if (percentage > 100) {
			percentage = 100;
		}
		const offset = this.circumference - (percentage / 100) * this.circumference;
		this.strokeDashOffset = offset;
	}
}
