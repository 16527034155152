<div class="card mb-3" [routerLink]="['/portfolio', portfolio.id, 'startup', product.id]" [style]="'border-top-color: ' + color">
	<div class="card-body">
		<div class="row align-items-center">
			<div class="col-12">
				<p>{{ product.name }}</p>
			</div>
			<!-- <div class="col-12 col-md-4" *ngIf="product.teamMembers?.length > 0">
				<app-avatar-row [users]="product.teamMembers" direction="right" [avatarSize]="1"></app-avatar-row>
			</div> -->
		</div>
		<div class="row mt-1" *ngIf="!!lens">
			<div class="col-12">
				<p class="lens-info" *ngIf="lens.value === LensValues.CURRENCY">
					{{ product.lensValues[lens.id]?.value || 0 | currency: product.portfolio.currency:'symbol':'.0' }}
				</p>
				<p class="lens-info" *ngIf="lens.value !== LensValues.CURRENCY">{{ product.lensValues[lens.id]?.value || 0 }} {{ lens.value }}</p>
			</div>
		</div>
		<div class="row progress-container" *ngIf="progress && !lens">
			<div class="col-12">
				<ngb-progressbar [type]="progressbarType" [value]="progress.getAverageScore()" height=".25rem" title="{{ 'guide.progress.question' | translate }}"></ngb-progressbar>
			</div>
		</div>
	</div>
</div>

