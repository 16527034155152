import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NextTitleService } from 'app/next-title.service';
import { ProductService } from 'app/product/product.service';
import { NextTranslateService } from 'app/next-translate.service';
import { LiveQuerySubscription, Query } from 'parse';
import { Subscription } from 'rxjs';
import { Portfolio } from '../../../../shared/models/portfolio';
import { PortfolioService } from '../portfolio.service';

@Component({
	selector: 'app-detail-overview',
	templateUrl: './portfolio-detail.component.html',
	styleUrls: ['./portfolio-detail.component.scss'],
})
export class PortfolioDetailComponent implements OnInit, OnDestroy {
	public portfolio: Portfolio;
	public trialExpired = false;

	private subscription: Subscription;
	private portfolioSubscription: LiveQuerySubscription;

	constructor(private route: ActivatedRoute, private titleService: NextTitleService, public productService: ProductService, public portfolioService: PortfolioService, private translate: TranslateService, private nextTranslate: NextTranslateService) {}

	async ngOnInit() {
		this.subscription = this.route.params.subscribe(async (params) => {
			const query = new Query(Portfolio);
			query.include(['theses']);
			this.portfolioSubscription = await query.subscribe();
			this.portfolio = await query.get(params['portfolio']);

			this.portfolioService.setActivePortfolio(this.portfolio);

			// Set page title
			this.titleService.setTitle(this.portfolio.name);

			// Determine trial status
			if (this.portfolio.trial && this.portfolio.trialEndsAt < new Date()) {
				this.trialExpired = true;
			}

			// set language
			if (this.portfolio.id == 'Z3vu00BByt') {
				this.translate.use('aes');
			} else {
				this.translate.use(this.nextTranslate.getPreferredLanguage(this.translate));
			}
		});
	}

	ngOnDestroy() {
		this.subscription?.unsubscribe();

		if (this.portfolioSubscription) {
			this.portfolioSubscription.unsubscribe();
		}
	}
}
