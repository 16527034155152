import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../../../../shared/models/product';
import { ProductDecision } from '../../../../shared/models/product-decision';
import { DecisionModalComponent } from '../../decision/decision-modal/decision-modal.component';

@Component({
	selector: 'app-product-not-started',
	templateUrl: './product-not-started.component.html',
	styleUrls: ['./product-not-started.component.scss'],
})
export class ProductNotStartedComponent {
	@Input() product: Product;

	constructor(private modalService: NgbModal) {}

	async newDecision() {
		let decision = new ProductDecision();
		decision.product = this.product;

		const modalRef = this.modalService.open(DecisionModalComponent, {
			size: 'md',
		});
		modalRef.componentInstance.decision = decision;

		const result = await modalRef.result;

		if (result) {
			this.product.stage = Product.Stage.PROBLEM;
		}
	}
}
