import { Component, OnInit } from '@angular/core';
import { ContentExperiment } from '../../../../shared/models/content-experiment';
import { Product } from '../../../../shared/models/product';
import { Router } from '@angular/router';
import { ContentTool } from '../../../../shared/models/content-tool';
import { TranslateService } from '@ngx-translate/core';
import { ContentExperimentService } from 'app/content-experiment/content-experiment.service';
import { ContentToolService } from 'app/content-tool/content-tool.service';
import { NextTitleService } from 'app/next-title.service';
import { Portfolio } from '@shared/models/portfolio';
import { PortfolioService } from 'app/portfolio/portfolio.service';

@Component({
	selector: 'app-content-experiment-tool-overview',
	templateUrl: './content-experiment-tool-overview.component.html',
	styleUrls: ['./content-experiment-tool-overview.component.scss'],
})
export class ContentExperimentToolOverviewComponent implements OnInit {
	public portfolio: Portfolio;

	public stages: string[];

	public stageExperiments: object;
	public contentExperiments: ContentExperiment[] = [];

	public stageTools: object;
	public contentTools: ContentTool[] = [];

	public contExpsPerSlug: object;
	public contToolsPerSlug: object;

	constructor(
		public router: Router,
		public translate: TranslateService,
		private contExpService: ContentExperimentService,
		private contToolsService: ContentToolService,
		private titleService: NextTitleService,
		private portfolioService: PortfolioService
	) {}

	async ngOnInit() {
		this.titleService.setTitle('Experiments & Tools');
		this.portfolio = await this.portfolioService.getActivePortfolio();
		this.stages = this.portfolioService.getMainAndKickboxStages(this.portfolio);

		this.translate.onLangChange.subscribe(async () => {
			this.populateContentExperiments();
			this.populateContentTools();
		});

		this.contentExperiments = await this.contExpService.getContentExperiments(this.portfolio);
		this.populateContentExperiments();

		this.contentTools = await this.contToolsService.getContentTools();
		this.populateContentTools();
	}

	// XexpPerSlug structure:
	// XexpPerSlug = {
	// 	slug: {
	// 		lang1: ContentExperiment,
	// 		lang2: ContentExperiment,
	// 	},
	// 	slug2: {
	// 		lang2: ContentExperiment,
	// 		lang1: ContentExperiment
	// 	},
	// 	slug3: {
	// 		lang1: ContentExperiment
	// 	}
	// }
	populateContentExperiments() {
		this.stageExperiments = {};
		this.contExpsPerSlug = {};
		this.contentExperiments.forEach((contentExperiment) => {
			if (!this.contExpsPerSlug[contentExperiment.slug]) {
				this.contExpsPerSlug[contentExperiment.slug] = {};
			}
			this.contExpsPerSlug[contentExperiment.slug][contentExperiment.language] = contentExperiment;
		});

		this.stages.forEach((stage) => {
			this.stageExperiments[stage] = [];
		});

		const slugs = Object.keys(this.contExpsPerSlug);
		for (let slug of slugs) {
			const langs = Object.keys(this.contExpsPerSlug[slug]);
			langs.sort();
			let translation;
			if (langs.includes(this.translate.currentLang)) {
				translation = this.contExpsPerSlug[slug][this.translate.currentLang];
			} else if (langs.includes(this.translate.defaultLang)) {
				translation = this.contExpsPerSlug[slug][this.translate.defaultLang];
			} else if (langs.length >= 0) {
				translation = this.contExpsPerSlug[slug][langs[0]];
			}

			for (let stage of this.stages) {
				if (translation.stages.includes(stage)) {
					this.stageExperiments[stage].push(translation);
				}
			}
		}
	}

	// XToolsPerSlug structure:
	// XToolsPerSlug = {
	// 	slug: {
	// 		lang1: ContentTool,
	// 		lang2: ContentTool,
	// 	},
	// 	slug2: {
	// 		lang2: ContentTool,
	// 		lang1: ContentTool
	// 	},
	// 	slug3: {
	// 		lang1: ContentTool
	// 	}
	// }
	populateContentTools() {
		this.stageTools = {};
		this.contToolsPerSlug = {};
		this.contentTools.forEach((contentTool) => {
			if (!this.contToolsPerSlug[contentTool.slug]) {
				this.contToolsPerSlug[contentTool.slug] = {};
			}
			this.contToolsPerSlug[contentTool.slug][contentTool.language] = contentTool;
		});

		this.stages.forEach((stage) => {
			this.stageTools[stage] = [];
		});

		const slugs = Object.keys(this.contToolsPerSlug);
		for (let slug of slugs) {
			const langs = Object.keys(this.contToolsPerSlug[slug]);
			langs.sort();
			let translation;
			if (langs.includes(this.translate.currentLang)) {
				translation = this.contToolsPerSlug[slug][this.translate.currentLang];
			} else if (langs.includes(this.translate.defaultLang)) {
				translation = this.contToolsPerSlug[slug][this.translate.defaultLang];
			} else if (langs.length >= 0) {
				translation = this.contToolsPerSlug[slug][langs[0]];
			}

			for (let stage of this.stages) {
				if (translation.stages.includes(stage)) {
					this.stageTools[stage].push(translation);
				}
			}
		}
	}

	openContentExperiment(contentExperiment) {
		this.router.navigate(['/portfolio', this.portfolio.id, 'experiment', contentExperiment.slug]);
	}

	openContentTool(contentTool) {
		this.router.navigate(['/portfolio', this.portfolio.id, 'tool', contentTool.slug]);
	}
}
