<div class="modal-content">
	<div class="modal-header">
		<p class="modal-title" *ngIf="sprint.status === SprintStatus.PREPARING">{{ 'sprint.start' | translate }} {{ sprint.number }}</p>
		<p class="modal-title" *ngIf="sprint.status !== SprintStatus.PREPARING">{{ 'sprint.edit' | translate }} {{ sprint.number }}</p>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 form-group">
					<label>{{ 'sprint.startModal.goal' | translate }}</label>
					<quill-editor name="sprint-goal" [(ngModel)]="sprint.mostRisky" [styles]="{ height: '8rem' }"></quill-editor>
				</div>

				<!-- <div class="col-12 form-group">
					<label
						>{{ 'sprint.startModal.shortImpediments' | translate }}
						<input type="text" name="shortImpediments" class="form-control" placeholder="" [(ngModel)]="sprint.shortImpediments" />
					</label>
				</div>
				<div class="col-12 form-group">
					<label
						>{{ 'sprint.startModal.longImpediments' | translate }}
						<input type="text" name="longImpediments" class="form-control" placeholder="" [(ngModel)]="sprint.longImpediments" />
					</label>
				</div> -->

				<div class="col-12 mt-4" *ngIf="sprint.status === SprintStatus.PREPARING">
					<!-- <p>{{ 'sprint.completeModal.selectMove' | translate }}</p> -->
					<label
						>{{ 'sprint.startModal.duration' | translate }}
						<select class="custom-select" name="destination" [(ngModel)]="duration" (change)="calcDates()">
							<option [ngValue]="1">1 {{ 'week.singular' | translate }}</option>
							<option [ngValue]="2">2 {{ 'week.plural' | translate }}</option>
							<option [ngValue]="3">3 {{ 'week.plural' | translate }}</option>
							<option [ngValue]="4">4 {{ 'week.plural' | translate }}</option>
							<option [ngValue]="0">{{ 'custom' | translate }}</option>
						</select>
					</label>
				</div>
				<div class="col-12 col-md-6">
					<label for="fromDate">{{ 'sprint.startModal.startDate' | translate }}</label>
					<div class="input-group">
						<input class="form-control" placeholder="dd/mm/yyyy" id="fromDate" name="fromDate" [(ngModel)]="sprint.startedAt" ngbDatepicker #dFrom="ngbDatepicker" />
						<div class="input-group-append">
							<button class="btn btn-outline-primary" (click)="dFrom.toggle()" type="button"><i class="bi bi-calendar3"></i></button>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<label for="toDate">{{ 'sprint.startModal.endDate' | translate }}</label>
					<div class="input-group">
						<input class="form-control" placeholder="dd/mm/yyyy" id="toDate" name="toDate" [(ngModel)]="sprint.endedAt" ngbDatepicker #dTo="ngbDatepicker" [disabled]="duration !== 0" />
						<div class="input-group-append">
							<button class="btn btn-outline-primary" (click)="dTo.toggle()" type="button"><i class="bi bi-calendar3"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button class="btn btn-primary ml-auto" (click)="startSprint()" *ngIf="sprint.status === SprintStatus.PREPARING">{{ 'sprint.start' | translate }}</button>
		<button class="btn btn-primary ml-auto" (click)="startSprint()" *ngIf="sprint.status === SprintStatus.RUNNING">{{ 'general.save' | translate }}</button>
		<button class="btn btn-primary ml-auto" (click)="startSprint()" *ngIf="sprint.status === SprintStatus.DONE">{{ 'general.next' | translate }}</button>
	</div>
</div>
