import { Component, OnInit } from '@angular/core';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Portfolio } from '../../../../shared/models/portfolio';

@Component({
	selector: 'app-portfolio-settings-stagenames',
	templateUrl: './portfolio-settings-stagenames.component.html',
	styleUrls: ['./portfolio-settings-stagenames.component.scss'],
})
export class PortfolioSettingsStagenamesComponent implements OnInit {
	public portfolio: Portfolio;
	public showWarning: Boolean;
	public stages: string[];

	constructor(private portfolioService: PortfolioService) {}

	async ngOnInit() {
		this.portfolio = await this.portfolioService.getActivePortfolio();
		this.stages = this.portfolioService.getAllStages(this.portfolio);

		if (!this.portfolio.stageNames) {
			this.portfolio.stageNames = {};
		}
	}

	async saveEditable() {
		if (!(this.portfolio.amountStages >= 1 && this.portfolio.amountStages <= 6)) {
			this.showWarning = true;
			return;
		}
		this.showWarning = false;
		await this.portfolio.save();
		this.stages = this.portfolioService.getAllStages(this.portfolio);
	}
}
