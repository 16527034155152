<div class="modal-content">
	<div class="modal-header">
		<p class="modal-title">{{ title }}</p>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="row chart-row">
			<div class="col-12">
				<app-velocity-chart
					[labels]="labels"
					[datasets]="datasets"
					(clickedPoint)="clickedDataPoint($event)"
					isClickable="true"
				></app-velocity-chart>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-1">
				<p class="explanation" *ngIf="isExperimentVelocity">{{ 'innovationAccounting.experimentVelocityExplanation' | translate }}</p>
				<p class="explanation" *ngIf="!isExperimentVelocity">{{ 'innovationAccounting.learningRatioExplanation' | translate }}</p>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<h4 class="modal-body-header">
					{{ title }} {{ 'innovationAccountingDetail.perStartup' | translate }}, Q{{ quarter + 1 }} {{ year }}
				</h4>
			</div>

			<div class="col-12">
				<div *ngFor="let iAP of iAPs" class="d-flex flex-row justify-content-between align-items-center startup-row">
					<p>{{ iAP.name }}</p>
					<div class="progress">
						<div
							*ngIf="iAP.weeks !== 0 && isExperimentVelocity"
							class="progress-bar"
							role="progressbar"
							[style.width.%]="(iAP.experimentVelocity / iAP.weeks) * 100 || 0"
							aria-valuemin="0"
							aria-valuemax="100"
							[ngbPopover]="
								'innovationAccountingDetail.outOfVelocity' | translate: { experiments: iAP.experimentVelocity, total: iAP.weeks }
							"
							[closeDelay]="300"
							triggers="mouseenter:mouseleave"
						></div>
						<div
							*ngIf="iAP.weeks !== 0 && !isExperimentVelocity"
							class="progress-bar"
							role="progressbar"
							[style.width.%]="(iAP.learningVelocity / iAP.experimentVelocity) * 100 || 0"
							aria-valuemin="0"
							aria-valuemax="100"
							[ngbPopover]="
								'innovationAccountingDetail.outOfRatio'
									| translate: { experiments: iAP.learningVelocity, total: iAP.experimentVelocity }
							"
							[closeDelay]="300"
							triggers="mouseenter:mouseleave"
						></div>

						<!-- <p *ngIf="iAP.weeks !== 0 && isExperimentVelocity">{{ iAP.experimentVelocity }} {{ 'innovationAccountingDetail.outOf' | translate }} {{ iAP.weeks }}: {{ iAP.experimentVelocity / iAP.weeks | percent }}</p>
						<p *ngIf="iAP.weeks !== 0 && !isExperimentVelocity">{{ iAP.learningVelocity }} {{ 'innovationAccountingDetail.outOf' | translate }} {{ iAP.experimentVelocity }}: {{ iAP.learningVelocity / iAP.experimentVelocity || 0 | percent }}</p>
						<p *ngIf="iAP.weeks === 0">-</p> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
