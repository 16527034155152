import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { GndCtrlObject } from '../gndctrl-object';
import { User } from './user';

@ParseSubClass('Account')
export class Account extends GndCtrlObject {
	@ParseProperty() public title: string;
	@ParseProperty() public createdBy: User;
	@ParseProperty() public ownedBy: User;

	@ParseProperty() public stripeCustomerId: string;
	@ParseProperty() public stripeSubscriptionId: string;
	@ParseProperty() public stripePriceId: string;
	@ParseProperty() public stripeProductId: string;
	@ParseProperty() public billingEmail: string;
	@ParseProperty() public billingAddress1: string;
	@ParseProperty() public billingAddress2: string;
	@ParseProperty() public billingPostalCode: string;
	@ParseProperty() public billingCountry: string;
	@ParseProperty() public billingCity: string;
	@ParseProperty() public billingTaxId: string;

	@ParseProperty() public moneybirdCustomerId: string;

	constructor() {
		super('Account');
	}
}
