import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Insight } from '../../../../shared/models/insight';
import { RemoveModalComponent } from 'app/validating-modals/remove-modal/remove-modal.component';
import { LogService } from 'app/log/log.service';
import { ProductService } from 'app/product/product.service';
import { ExperimentService } from 'app/experiment/experiment.service';

@Component({
	selector: 'app-insight-modal',
	templateUrl: './insight-modal.component.html',
	styleUrls: ['./insight-modal.component.scss'],
})
export class InsightModalComponent {
	@Input() insight: Insight;
	@Input() editable: boolean;

	get InsightDecision() {
		return Insight.Decision;
	}

	constructor(
		public activeModal: NgbActiveModal,
		private experimentService: ExperimentService,
		private modalService: NgbModal,
		private logService: LogService,
		private productService: ProductService
	) {}

	onSave() {
		const isNew = this.insight.isNew();

		this.insight.save().then(() => {
			if (isNew) {
				this.logService.logActivity('added insight', 'Insight', this.insight.id, this.insight.product, this.insight.product.portfolio, {
					experiment: this.insight.experiment.toPointer(),
				});

				this.productService.productUpdated.emit(this.insight.product);
			} else {
				this.logService.logActivity(
					'edited insight',
					'Insight',
					this.insight.id,
					this.insight.product,
					this.insight.product.portfolio,
					{
						experiment: this.insight.experiment.toPointer(),
					}
				);
			}
		});

		this.activeModal.close();
	}

	makeEditable() {
		this.editable = true;
	}

	openExperiment() {
		this.experimentService.openModal(this.insight.experiment, this.insight.product);
	}

	async deleteInsight() {
		const modalRef = this.modalService.open(RemoveModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.element = 'insight';

		let removed = await modalRef.result;
		if (removed) {
			this.insight.archived = true;
			this.insight.save();
			this.activeModal.close();
		}
	}
}
