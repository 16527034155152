import { Component, Input } from '@angular/core';
import { Portfolio } from '../../../../shared/models/portfolio';

@Component({
	selector: 'app-trial-bar',
	templateUrl: './trial-bar.component.html',
	styleUrls: ['./trial-bar.component.scss'],
})
export class TrialBarComponent {
	@Input() portfolio: Portfolio;

	constructor() {}
}
