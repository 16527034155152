<div class="card h-100" (click)="clickedCard()">
	<p class="mb-2">
		<span *ngIf="contentExperiment.types?.includes(ContExpType.CONFIRM)" class="badge badge-secondary confirm mr-2">
			{{ 'type.confirm' | translate }}
		</span>
		<span *ngIf="contentExperiment.types?.includes(ContExpType.LEARN)" class="badge badge-secondary learn mr-2">
			{{ 'type.learn' | translate }}
		</span>
	</p>
	<h3>{{ contentExperiment.title }}</h3>
	<p>{{ contentExperiment.excerpt }}</p>
</div>
