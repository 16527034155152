import { Component, NgModule } from '@angular/core';
import { ParseService } from './parse.service';
import { NgbModalConfig, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './shared/analytics.service';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	title = 'app';

	constructor(private config: NgbModalConfig, public translate: TranslateService, private analytics: AnalyticsService) {
		const languages = ['en', 'nl', 'aes'];

		translate.addLangs(languages);

		// require languages so they are included in the bundle
		// and not loaded via httpLoader.
		// this prevents caching issues
		for (const lang of languages) {
			this.translate.setTranslation(lang, require('../assets/i18n/' + lang + '.json'));
		}

		translate.setDefaultLang('en');

		let useLang = 'en';
		let storedLanguage = localStorage.getItem('language');

		if (storedLanguage) {
			useLang = storedLanguage;
		} else {
			const browserLang = translate.getBrowserLang();
			useLang = browserLang.match(/en|nl/) ? browserLang : languages[0];
		}

		translate.use(useLang);
		registerLocaleData(localeNl);

		// Initialze Parse
		ParseService.initialize();

		// track App Session
		this.analytics.identify();
		this.analytics.track('App Session');
	}
}
