import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ContentTool } from '../../../../shared/models/content-tool';

@Component({
	selector: 'app-content-tool-card',
	templateUrl: './content-tool-card.component.html',
	styleUrls: ['./content-tool-card.component.scss'],
})
export class ContentToolCardComponent {
	@Input() contentTool: ContentTool;
	@Output() cardClicked?: EventEmitter<any> = new EventEmitter<any>();

	constructor() {}

	clickedCard() {
		this.cardClicked.emit(this.contentTool);
	}
}
