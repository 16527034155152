import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NextTitleService } from 'app/next-title.service';

@Component({
	selector: 'app-notfound',
	templateUrl: './notfound.component.html',
	styleUrls: ['./notfound.component.scss'],
})
export class NotfoundComponent implements OnInit, OnDestroy {
	public countdown: number = 5;
	private subscription;

	constructor(private router: Router, private titleService: NextTitleService) {}

	ngOnInit(): void {
		this.titleService.setTitle('Lost in Space - 404');

		this.subscription = setInterval(() => {
			this.countdown--;

			if (this.countdown < 1) {
				clearInterval(this.subscription);

				this.router.navigate(['/']);
			}
		}, 1000);
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			clearInterval(this.subscription);
		}
	}
}
