import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Experiment } from './experiment';
import { Product } from './product';
import { Sprint } from './sprint';

@ParseSubClass('Task')
export class Task extends Parse.Object {
	@ParseProperty() public title: string;
	@ParseProperty() public description: string;
	@ParseProperty() public status: Task.Status;
	@ParseProperty() public number: number;
	@ParseProperty() public sprint: Sprint;
	@ParseProperty() public experiment: Experiment;
	@ParseProperty() public product: Product;
	@ParseProperty() public assignedUsers: Parse.User[];
	@ParseProperty() public archived: boolean;
	@ParseProperty() public orderIndex: number;

	constructor() {
		super('Task');
	}
}

export namespace Task {
	export enum Status {
		TODO = 'todo',
		DOING = 'inProgress',
		DONE = 'done',
	}
}
