<div
	class="card hand"
	*ngIf="isShown()"
	[ngClass]="{ risky: isRisky(), invalidated: isInvalidated(), color: !!sticky.color }"
	[style.border-left-color]="sticky.color"
	(click)="canOpen && enableEditMode()"
>
	<div class="card-body row align-items-end">
		<div class="col" [ngClass]="{ 'pr-1': sticky.description }">
			<p>{{ sticky.text || ('general.untitled' | translate) }}</p>
		</div>
		<div *ngIf="sticky.description" class="col-auto pl-1">
			<i class="bi bi-chat-left-lines description-icon"></i>
		</div>
		<div *ngIf="isValidated()" class="col-auto pl-0">
			<i class="bi bi-check-circle validated"></i>
		</div>
		<div *ngIf="isInvalidated()" class="col-auto pl-0">
			<i class="bi bi-x-circle description-icon"></i>
		</div>
		<div *ngIf="sticky.getRiskyness() === 'most-risky'" class="col-auto pl-0">
			<i class="bi bi-exclamation-triangle most-risky"></i>
		</div>
	</div>
</div>
