import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CanvasService } from 'app/canvas/canvas.service';
import { Experiment } from '../../../../shared/models/experiment';
import { Product } from '../../../../shared/models/product';
import { ExperimentService } from '../experiment.service';

@Component({
	selector: 'app-experiment-card',
	templateUrl: './experiment-card.component.html',
	styleUrls: ['./experiment-card.component.scss'],
})
export class ExperimentCardComponent {
	@Input() experiment: Experiment;
	@Input() product: Product;
	@Input() openModal?: boolean;
	@Output() changeExperiment: EventEmitter<any> = new EventEmitter();
	get ExperimentStatus() {
		return Experiment.Status;
	}

	constructor(private experimentService: ExperimentService, public canvasService: CanvasService) { }
	
	async ngOnInit() {
		if (this.experiment.type == 'confirm') {
			this.experiment.sticky.fetch();
		}
	}

	async openExperiment() {
		if (this.openModal === false) {
			return;
		}

		await this.experimentService.openModal(this.experiment, this.product);

		this.changeExperiment.emit({ experiment: this.experiment });
	}
}
