import { Component, Input, OnDestroy } from '@angular/core';
import { Thesis } from '../../../../shared/models/thesis';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Portfolio } from '../../../../shared/models/portfolio';
import { LogService } from 'app/log/log.service';
import { RemoveModalComponent } from 'app/validating-modals/remove-modal/remove-modal.component';

@Component({
	selector: 'app-thesis-modal',
	templateUrl: './thesis-modal.component.html',
	styleUrls: ['./thesis-modal.component.scss'],
})
export class ThesisModalComponent implements OnDestroy {
	@Input() thesis: Thesis;
	@Input() editable: boolean;
	@Input() portfolio: Portfolio;

	constructor(public activeModal: NgbActiveModal, private logService: LogService, private modalService: NgbModal) {}

	ngOnDestroy() {
		this.thesis.revert();
	}

	async createThesis() {
		if (!this.thesis.title || !this.thesis.color || !this.thesis.isNew()) {
			return;
		}

		this.thesis.set('portfolio', this.portfolio); // for the ACL!
		await this.thesis.save();
		this.portfolio.addUnique('theses', this.thesis);
		this.portfolio.save();
		this.logService.logActivity('added thesis', 'Thesis', this.thesis.id, undefined, this.portfolio);

		this.activeModal.close(this.thesis);
	}

	async deleteThesis() {
		const modalRef = this.modalService.open(RemoveModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.element = 'thesis';

		let removed = await modalRef.result;
		if (removed) {
			this.portfolio.remove('theses', this.thesis);
			this.portfolio.save();
			this.logService.logActivity('removed thesis', 'Thesis', this.thesis.id, undefined, this.portfolio);
			this.activeModal.close();
		}
	}

	makeEditable() {
		this.editable = true;
	}

	saveEditable() {
		this.thesis.save();
		this.editable = false;
	}
}
