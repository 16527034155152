import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Portfolio } from '../../../../shared/models/portfolio';

@Component({
	selector: 'app-portfolio-modal-add',
	templateUrl: './portfolio-modal-add.component.html',
	styleUrls: ['./portfolio-modal-add.component.scss'],
})
export class PortfolioModalAddComponent {
	@Input() portfolio: Portfolio;
	@Output() save: EventEmitter<any> = new EventEmitter();
	public addDemoStartups: boolean;

	constructor(public activeModal: NgbActiveModal) {}

	onSave() {
		this.save.emit({
			portfolio: this.portfolio,
			addDemoStartups: this.addDemoStartups,
		});
		this.activeModal.close();
	}
}
