<div class="col-12 form-group element-group settings-content" *ngIf="product">
	<h5>{{ 'product.teamMembers' | translate }}</h5>
	<hr />

	<div class="row" *ngIf="errorMessage">
		<div class="col-12">
			<ngb-alert type="danger" [dismissible]="false">
				{{ errorMessage }}
			</ngb-alert>
		</div>
	</div>

	<div class="row mb-1">
		<div class="col-6 col-md-3" *ngFor="let user of productUsers">
			<app-user-card
				[user]="user"
				(remove)="removeAccess($event)"
				[removeMessage]="'product.removeUser' | translate: { userName: user.firstName, productName: product.name }"
			></app-user-card>
		</div>
	</div>

	<h5 class="mt-5">{{ 'invite.toTeam' | translate }}</h5>
	<hr />
	<div class="row">
		<div class="col-6">
			{{ 'invite.external' | translate }}
			<div class="input-group form-group">
				<input type="email" class="form-control" [placeholder]="'user.email' | translate" name="email" [(ngModel)]="email" />
				<div class="input-group-addon">
					<input type="button" class="btn btn-primary" [value]="'invite.verb' | translate" (click)="inviteUser()" />
				</div>
			</div>
			<ngb-alert *ngIf="inviteResultMessage" type="success" [innerHtml]="inviteResultMessage"></ngb-alert>
		</div>
		<div class="col-6" *ngIf="portfolioUsers?.length">
			{{ 'invite.fromPortfolio' | translate }}
			<ng-select
				name="assignees"
				class="custom"
				[items]="portfolioUsers"
				[(ngModel)]="inviteUsers"
				(change)="onAddUser($event)"
				[placeholder]="'assignee.choose' | translate"
			>
				<ng-template ng-label-tmp let-item="item" let-clear="clear">
					<span class="ng-value-label">
						<app-avatar [user]="item" [avatarSize]="1"></app-avatar>
						{{ item.firstName }} {{ item.lastName }}
					</span>
				</ng-template>
				<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
					<app-avatar [user]="item" [avatarSize]="1"></app-avatar>
					{{ item.firstName }} {{ item.lastName }}
				</ng-template>
			</ng-select>
		</div>
	</div>
	<div *ngIf="pendingInvites?.length" class="row">
		<div class="col-12 col-lg-8">
			<p class="header">{{ 'invite.pending' | translate }}</p>
			<ul>
				<li *ngFor="let invite of pendingInvites">
					<span class="row align-items-center mb-1">
						<p class="col">{{ invite.email }}</p>
						<span class="col-auto">
							<button
								*ngIf="!invite.copied"
								class="btn btn-primary"
								[cdkCopyToClipboard]="signupLink + invite.id"
								(click)="invite.copied = true"
							>
								{{ 'copy.invite' | translate }}
							</button>
							<button *ngIf="invite.copied" class="btn btn-primary" disabled>
								<i class="bi bi-check"></i>
								{{ 'copy.success' | translate }}
							</button>
						</span>
					</span>
				</li>
			</ul>
		</div>
	</div>
</div>
