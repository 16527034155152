<div class="modal-content">
	<div class="modal-header">
		<p class="modal-title" *ngIf="!editable">{{ 'decision.singular' | translate }}</p>
		<p class="modal-title" *ngIf="(editable && decision.isNew()) || turnPitchIntoDecision">{{ 'decision.new' | translate }}</p>
		<p class="modal-title" *ngIf="editable && !decision.isNew() && decision.status === DecisionStatus.DONE && !turnPitchIntoDecision">{{ 'decision.edit' | translate }}</p>
		<p class="modal-title" *ngIf="editable && !decision.isNew() && decision.status === DecisionStatus.PREPARED && !turnPitchIntoDecision">{{ 'decision.editPitch' | translate }}</p>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>

		<button class="btn btn-link-white ml-auto" *ngIf="!editable" (click)="makeEditable()">{{ 'general.edit' | translate }}</button>
	</div>
	<!-- Display -->
	<div class="modal-body" *ngIf="!editable">
		<div class="row">
			<div class="col">
				<h2>{{ decision.product.name }}</h2>
				<p>{{ portfolioService.getDecisionLabel(decision.product.portfolio, decision.decisionMade, decision.fromStage, decision.toStage) }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<p class="header">{{ 'decision.meetingDate' | translate }}</p>
				<p>{{ decision.decisionDate | date }}</p>
			</div>
			<div class="col">
				<p class="header">{{ 'decision.investment' | translate }}</p>
				<p>{{ (decision.moneyGranted | currency: decision.product.portfolio.currency:'symbol':'3.0') || '-' }}</p>
			</div>
			<div class="col">
				<p class="header">{{ 'deadline.singular' | translate }}</p>
				<p>{{ (decision.nextMeetingDate | date) || '-' }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<p class="header">{{ 'decision.goal' | translate }}</p>
				<p>{{ decision.goal || '-' }}</p>
			</div>
		</div>
		<div class="row" *ngIf="decision.status === DecisionStatus.DONE">
			<div class="col">
				<p class="header">{{ 'minute.plural' | translate }}</p>
				<div class="editor-data" [innerHtml]="decision.description"></div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<p class="header">{{ 'attachment.supporting' | translate }}</p>
				<div *ngIf="decision.attachments.length > 0" class="row mt-2">
					<div *ngFor="let file of decision.attachments" class="col-12 col-md-4 mb-2">
						<app-attachment-card-big [fileMeta]="file" (delete)="removeFile($event)"></app-attachment-card-big>
					</div>
				</div>
				<p *ngIf="decision.attachments?.length === 0">-</p>
			</div>
		</div>
	</div>

	<!-- Edit -->
	<div class="modal-body" *ngIf="editable">
		<form #productForm="ngForm">
			<!-- Select product when none is set-->
			<div *ngIf="!decision.product">
				<label>{{ 'decision.selectProduct' | translate }}</label>
				<ng-select id="selectProduct" name="product" [(ngModel)]="decision.product" [items]="products" (change)="onSelectProduct()">
					<ng-template ng-label-tmp let-item="item">
						<p>{{ product.name }}</p>
					</ng-template>
					<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
						<ng-container>{{ item.name }}</ng-container>
					</ng-template>
				</ng-select>
			</div>
			<div *ngIf="decision.product">
				<!-- Choose between creating pitch and making decision -->
				<ng-container *ngIf="!decision.status">
					<div class="form-group">
						<label class="mb-0">{{ 'decision.selectStatus' | translate }}</label>
						<ng-select [(ngModel)]="decision.status" name="status">
							<ng-option [value]="DecisionStatus.PREPARED">{{ 'decision.prepare' | translate }}</ng-option>
							<ng-option [value]="DecisionStatus.DONE">{{ 'decision.done' | translate }}</ng-option>
						</ng-select>
					</div>
				</ng-container>

				<!-- Preparing pitch -->
				<ng-container *ngIf="decision.status === DecisionStatus.PREPARED">
					<!-- Cockpit -->
					<ng-container *ngIf="step === 2">
						<ngb-alert class="wizard mb-4" [dismissible]="false">{{ 'cockpit.upToDateInfo' | translate }}</ngb-alert>
						<label class="mb-3"
							>{{ 'product.pitch' | translate }}
							<input type="text" class="form-control" name="pitch" [(ngModel)]="decision.product.pitch" />
						</label>

						<div class="form-group" *ngFor="let cockpit of cockpitFields">
						<label class="mb-0" [for]="cockpit.name">{{ cockpit.name }}</label>
								<quill-editor
									[id]="cockpit.name"
									[name]="cockpit.name"
									[(ngModel)]="decision.product.cockpitValues[cockpit.id]"
									[styles]="{ height: '10rem' }"
									[placeholder]="cockpit.explanation"
								></quill-editor>
						
						</div>

						<div class="form-group" *ngFor="let lens of lenses">
							<label
								>{{ lens.name }}
								<div class="input-group">
									<div class="input-group-prepend" *ngIf="lens.value === LensValues.CURRENCY">
										<div class="input-group-text">{{ decision.product.portfolio.currency | currencySymbol }}</div>
									</div>
									<div class="input-group-prepend" *ngIf="lens.value !== LensValues.CURRENCY">
										<div class="input-group-text">{{ lens.value }}</div>
									</div>
									<input type="number" class="form-control" [name]="lens.name" [(ngModel)]="decision.product.lensValues[lens.id].value" />
								</div>
							</label>
							<div class="mt-1 mb-3">
								<quill-editor
									[name]="lens.name + '.note'"
									[(ngModel)]="decision.product.lensValues[lens.id].note"
									[styles]="{ height: '5rem' }"
									[placeholder]="'Here you can explain how you calculated the value for ' + lens.name"
								></quill-editor>
							</div>
						</div>
					</ng-container>

					<!-- Preparing pitch: Confidence -->
					<ng-container *ngIf="step === 3">
						<ngb-alert class="wizard mb-4" [dismissible]="false">{{ 'progress.upToDateInfo' | translate }}</ngb-alert>
						<ng-container *ngFor="let object of learningsPerQuestion">
							<div class="row align-items-center mb-2">
								<p class="col-12 col-md-7 header mb-0" *ngIf="object.question.default">
									{{ 'stageGateQuestions.' + decision.product.stage + '.' + object.question.question | translate }}
								</p>
								<p class="col-12 col-md-7 header mb-0" *ngIf="!object.question.default">{{ object.question.question }}</p>
								<div class="col-12 col-md-5 range-container">
									<input type="range" min="1" max="5" class="custom-range" [name]="'question-' + object.question.id" [(ngModel)]="newProgress.questions[object.question.id]" />
									<p class="left">{{ 'progress.notConfident' | translate }}</p>
									<p class="right">{{ 'progress.confident' | translate }}</p>
								</div>
							</div>
							<div class="row">
								<div *ngFor="let learning of object.learnings" class="col-4 learning-container">
									<app-learning-card [learning]="learning" [showExperimentTag]="true"></app-learning-card>
								</div>
							</div>
						</ng-container>
					</ng-container>
				</ng-container>

				<!-- Details -->
				<ng-container *ngIf="decision.status === DecisionStatus.DONE || (decision.status === DecisionStatus.PREPARED && step === 4)">
					<!-- You can only pick a decision when the decision is new OR a pitch -->
					<div class="form-group mb-4" *ngIf="(isNew && decision.decisionMade !== DecisionType.START) || turnPitchIntoDecision">
						<label class="form-check-label mb-0">
							<span *ngIf="decision.status === DecisionStatus.PREPARED">{{ 'decision.ask' | translate }}</span>
							<span *ngIf="decision.status === DecisionStatus.DONE">{{ 'decision.singular' | translate }}</span>
						</label>

						<div class="form-check">
							<label class="form-check-label">
								<input class="form-check-input" type="radio" name="decision" value="moveFwd" [(ngModel)]="decision.decisionMade" />
								{{ portfolioService.getDecisionLabel(decision.product.portfolio, DecisionType.MOVEFWD, decision.fromStage, nextStage) }}
							</label>
						</div>
						<div class="form-check">
							<label class="form-check-label">
								<input class="form-check-input" type="radio" name="decision" value="remain" [(ngModel)]="decision.decisionMade" />
								{{ portfolioService.getDecisionLabel(decision.product.portfolio, DecisionType.REMAIN, decision.fromStage) }}
							</label>
						</div>
						<div class="form-check">
							<label class="form-check-label">
								<input class="form-check-input" type="radio" name="decision" value="stop" [(ngModel)]="decision.decisionMade" />
								{{ portfolioService.getDecisionLabel(decision.product.portfolio, DecisionType.STOP, decision.fromStage) }}
							</label>
						</div>
						<div class="form-check">
							<label class="form-check-label">
								<input class="form-check-input" type="radio" name="decision" value="moveBack" [(ngModel)]="decision.decisionMade" />
								{{ portfolioService.getDecisionLabel(decision.product.portfolio, DecisionType.MOVEBACK, decision.fromStage, previousStage) }}
							</label>
						</div>
					</div>

					<div class="form-group">
						<label>
							<span *ngIf="decision.status === DecisionStatus.PREPARED">{{ 'decision.pitch.createdDate' | translate }}</span>
							<span *ngIf="decision.status === DecisionStatus.DONE">{{ 'decision.meetingDate' | translate }}</span>
							<div class="input-group">
								<input class="form-control" placeholder="dd/mm/yyyy" id="fromDate" name="meeting date" [(ngModel)]="decision.decisionDate" ngbDatepicker #dFrom="ngbDatepicker" />
								<div class="input-group-append">
									<button class="btn btn-outline-primary" (click)="dFrom.toggle()" type="button">
										<i class="bi bi-calendar3"></i>
									</button>
								</div>
							</div>
						</label>
					</div>

					<div class="form-group" *ngIf="!(decision.decisionMade === DecisionType.MOVEFWD && decision.toStage === 'validated') && decision.decisionMade !== DecisionType.STOP">
						<label
							>{{ 'decision.investment' | translate }}
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">{{ decision.product.portfolio.currency | currencySymbol }}</div>
								</div>
								<input type="number" class="form-control" placeholder="" name="moneyRequested" [(ngModel)]="decision.moneyGranted" />
							</div>
						</label>
					</div>

					<div class="form-group" *ngIf="!(decision.decisionMade === DecisionType.MOVEFWD && decision.toStage === 'validated') && decision.decisionMade !== DecisionType.STOP">
						<label
							>{{ 'decision.goalDescription' | translate }}
							<input type="text" class="form-control" placeholder="" name="goal" [(ngModel)]="decision.goal" />
						</label>
					</div>

					<div class="form-group" *ngIf="!(decision.decisionMade === DecisionType.MOVEFWD && decision.toStage === 'validated') && decision.decisionMade !== DecisionType.STOP">
						<label>
							<span *ngIf="decision.status === DecisionStatus.PREPARED">{{ 'decision.pitch.howlong' | translate }}</span>
							<span *ngIf="decision.status === DecisionStatus.DONE">{{ 'decision.finalMeeting' | translate }}</span>
							<div class="input-group">
								<input class="form-control" placeholder="dd/mm/yyyy" id="fromDate" name="next meeting date" [(ngModel)]="decision.nextMeetingDate" ngbDatepicker #dTo="ngbDatepicker" />
								<div class="input-group-append">
									<button class="btn btn-outline-primary" (click)="dTo.toggle()" type="button">
										<i class="bi bi-calendar3"></i>
									</button>
								</div>
							</div>
						</label>
					</div>

					<div class="form-group" *ngIf="decision.status === DecisionStatus.DONE">
						<label class="mb-0">{{ 'minute.singular' | translate }}</label>
						<quill-editor name="notesEditor" [(ngModel)]="decision.description" [styles]="{ height: '10rem' }" [placeholder]="'minuteModal.notes.placeholder' | translate"></quill-editor>
					</div>

					<div class="row">
						<div class="col-12">
							<h4 class="modal-body-header">{{ 'attachment.supporting' | translate }}</h4>
							<ngb-alert type="danger" [dismissible]="false" *ngIf="fileErrorMessage">
								{{ fileErrorMessage }}
								<a href="mailto:majortom@togroundcontrol.com" target="_blank">majortom@togroundcontrol.com</a>
							</ngb-alert>
							<div *ngIf="decision.attachments.length > 0" class="row mt-2">
								<div *ngFor="let file of decision.attachments" class="col-12 col-md-4 mb-2">
									<app-attachment-card-big [fileMeta]="file" (delete)="removeFile($event)"></app-attachment-card-big>
								</div>
								<!-- <p *ngIf="decision.attachments?.length === 0">-</p> -->
							</div>
							<!-- <p *ngIf="decision.attachments?.length === 0">-</p> -->
						</div>
						<div class="col-12 col-md-4 pr-2 mt-2">
							<app-attachment-button-add [product]="decision.product" (fileUpload)="uploadFiles($event)"></app-attachment-button-add>
						</div>
					</div>
				</ng-container>
			</div>
		</form>
	</div>
	<!-- Show license notice when a product is started -->
	<div class="modal-body" *ngIf="decision.isNew() && decision.decisionMade === DecisionType.START">
		<ngb-alert class="wizard" [dismissible]="false"><i class="bi bi-credit-card mr-3"></i> {{ 'general.addLicenseNotice' | translate }}</ngb-alert>
	</div>

	<!-- Footer -->
	<div class="modal-footer" *ngIf="decision.product">
		<!-- Show delete for non-new and non-start -->
		<button class="btn btn-link-danger mr-auto" *ngIf="!decision.isNew() && decision.decisionMade !== DecisionType.START" (click)="deleteDecision()">
			{{ 'general.remove' | translate }}
		</button>

		<button class="btn btn-link ml-auto" *ngIf="editable && this.step > 1 && decision.status === DecisionStatus.PREPARED" (click)="previous()">
			{{ 'general.back' | translate }}
		</button>

		<!-- <button class="btn btn-primary" *ngIf="editable && this.step < 2 && decision.status === DecisionStatus.PREPARED" (click)="next()">{{ 'general.next' | translate }}</button> -->

		<button class="btn btn-primary" *ngIf="(editable && decision.isNew() && decision.status === DecisionStatus.DONE) || turnPitchIntoDecision" (click)="saveEditable()" [disabled]="!isComplete()">
			{{ 'decision.add' | translate }}
		</button>
		<button class="btn btn-primary" *ngIf="editable && decision.isNew() && decision.status === DecisionStatus.PREPARED && step === 4" (click)="saveEditable()" [disabled]="!isComplete()">
			{{ 'decision.addPitch' | translate }}
		</button>

		<button
			class="btn btn-primary"
			*ngIf="editable && !decision.isNew() && (decision.status === DecisionStatus.DONE || (decision.status === DecisionStatus.PREPARED && step === 4)) && !turnPitchIntoDecision"
			(click)="saveEditable()"
			[disabled]="!isComplete()"
		>
			{{ 'general.save' | translate }}
		</button>

		<button class="btn btn-primary" *ngIf="editable && decision.status === DecisionStatus.PREPARED && step === 2" (click)="saveCockpit()">
			{{ 'cockpit.saveAndNext' | translate }}
		</button>
		<button class="btn btn-primary" *ngIf="editable && decision.status === DecisionStatus.PREPARED && step === 3" (click)="saveProgress()">
			{{ 'progress.saveAndNext' | translate }}
		</button>

		<button class="btn btn-primary ml-auto" *ngIf="!editable" (click)="activeModal.close()">{{ 'general.close' | translate }}</button>
	</div>
</div>
