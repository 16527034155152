import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Sprint } from '../../../../shared/models/sprint';

@Component({
	selector: 'app-sprint-modal-retrospect',
	templateUrl: './sprint-modal-retrospect.component.html',
	styleUrls: ['./sprint-modal-retrospect.component.scss'],
})
export class SprintModalRetrospectComponent {
	@Input() sprint: Sprint;

	constructor(public activeModal: NgbActiveModal) {}

	saveSprint() {
		// Convert to strings for backwards compatibility
		this.sprint.teamHapiness = '' + this.sprint.teamHapiness;
		this.sprint.teamEducation = '' + this.sprint.teamEducation;

		this.sprint.save();

		this.activeModal.close(true);
	}
}
