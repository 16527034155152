<div class="container page">
	<div class="row">
		<div class="col-12 col-md-6">
			<h1>{{ 'product.plural' | translate }}</h1>
		</div>
		<div class="col-12 col-md-6 text-right">
			<button class="btn btn-primary ml-2" (click)="createProduct()">{{ 'product.new' | translate }}</button>
		</div>
	</div>
	<div class="row" *ngIf="viewActive">
		<!-- Active -->
		<div class="col-12 col-md-2">
			<p class="header">{{ 'portfolioOverview.products.activeProducts' | translate }}</p>
		</div>
		<div class="col-12 col-md-10">
			<div class="product-wrapper mb-3" *ngFor="let product of productsActive">
				<app-product-card
					[product]="product"
					(selectProduct)="onSelect($event)"
					(selectAccounting)="onSelectAccounting($event)"
					[productIA]="productsIA[product.id]"
					[currentQuarter]="currentQuarter"
					[previousQuarter]="previousQuarter"
				></app-product-card>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="viewActive && productsNew.length">
		<!-- New Ideas -->
		<div class="col-12 col-md-2">
			<p class="header">{{ portfolioService.getStageName(portfolio, 'kickbox') }}</p>
		</div>
		<div class="col-12 col-md-10">
			<div class="product-wrapper mb-3" *ngFor="let product of productsNew">
				<app-product-card [product]="product" (selectProduct)="onSelect($event)"></app-product-card>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="viewActive && productsValidated.length">
		<!-- Validated -->
		<div class="col-12 col-md-2">
			<p class="header">{{ portfolioService.getStageName(portfolio, 'validated') }}</p>
		</div>
		<div class="col-12 col-md-10">
			<div class="product-wrapper mb-3" *ngFor="let product of productsValidated">
				<app-product-card [product]="product" (selectProduct)="onSelect($event)"></app-product-card>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="viewActive && productsStopped.length">
		<!-- Stopped -->
		<div class="col-12 col-md-2">
			<p class="header">{{ 'portfolioOverview.products.stoppedProducts' | translate }}</p>
		</div>
		<div class="col-12 col-md-10">
			<div class="product-wrapper mb-3" *ngFor="let product of productsStopped">
				<app-product-card [product]="product" (selectProduct)="onSelect($event)"></app-product-card>
			</div>
		</div>
	</div>
</div>
