import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NextConfigService } from 'app/next-config.service';
import { ProductService } from 'app/product/product.service';
import { MinuteModalComponent } from 'app/minutes/minute-modal/minute-modal.component';
import { TaskService } from 'app/task/task.service';
import { Cloud, LiveQuerySubscription, Query } from 'parse';
import { Product } from '../../../../shared/models/product';
import { Minute } from '../../../../shared/models/minute';
import { Sprint } from '../../../../shared/models/sprint';
import { Task } from '../../../../shared/models/task';
import { User } from '../../../../shared/models/user';
import { SprintModalCompleteComponent } from '../sprint-modal-complete/sprint-modal-complete.component';
import { SprintService } from '../sprint.service';
import { SprintModalStartComponent } from '../sprint-modal-start/sprint-modal-start.component';
import { SprintModalRetrospectComponent } from '../sprint-modal-retrospect/sprint-modal-retrospect.component';
import { ExperimentService } from 'app/experiment/experiment.service';
import { Experiment } from '../../../../shared/models/experiment';
import { ExperimentModalTasksDoneComponent } from 'app/experiment/experiment-modal-tasks-done/experiment-modal-tasks-done.component';
import { AnalyticsService } from 'app/shared/analytics.service';
import { NextTitleService } from 'app/next-title.service';
import { Portfolio } from '../../../../shared/models/portfolio';

@Component({
	selector: 'app-sprint-detail',
	templateUrl: './detail.component.html',
	styleUrls: ['./detail.component.scss'],
})
export class SprintDetailComponent implements OnInit, OnDestroy {
	public product: Product;
	public activeSprint: Sprint;
	public sprints: Sprint[];
	public tasks: Task[];
	public sortedTasks = {};
	public toDos: Task[];
	public inProgress: Task[];
	public done: Task[];
	public showingMine: boolean;
	public remainingDays: number;
	public isActiveSprint = false;
	public teamHappiness: number;
	public teamEducation: number;
	public routerSubscription;
	public isReady = false;

	get PortfolioSprintType() {
		return Portfolio.SprintType;
	}
	get SprintStatus() {
		return Sprint.Status;
	}
	get TaskStatus() {
		return Task.Status;
	}

	private activeSprintSubscription: LiveQuerySubscription;
	private taskSubscription: LiveQuerySubscription;

	constructor(
		public route: ActivatedRoute,
		public productService: ProductService,
		public sprintService: SprintService,
		public taskService: TaskService,
		public experimentService: ExperimentService,
		public configService: NextConfigService,
		public router: Router,
		public modalService: NgbModal,
		private analytics: AnalyticsService,
		private titleService: NextTitleService
	) {}

	ngOnInit() {
		// this.analytics.track('Opened sprints');

		this.routerSubscription = this.route.params.subscribe((params) => {
			this.fetchData(params.sprint);
		});
	}

	ngOnDestroy() {
		if (this.activeSprintSubscription) {
			this.activeSprintSubscription.unsubscribe();
		}

		if (this.taskSubscription) {
			this.taskSubscription.unsubscribe();
		}

		this.routerSubscription?.unsubscribe();
	}

	async fetchData(id) {
		this.product = await this.productService.getActiveProduct();
		this.sprints = await this.productService.getStartedSprints(this.product);

		if (id) {
			const index = this.sprints.findIndex((s) => s.id === id);
			this.activeSprint = this.sprints[index];
		} else if (this.product.activeSprint) {
			this.activeSprint = this.product.activeSprint;
		}

		this.isReady = true;

		if (!this.activeSprint) {
			// No active sprint
			return;
		}

		this.titleService.setTitle('Active Sprint - ' + this.product.name);

		// subscribe to activeSprint updates
		const activeQuery = new Query(Sprint);
		activeQuery.equalTo('objectId', this.activeSprint.id);
		this.activeSprintSubscription = await activeQuery.subscribe();

		// Convert teamHapiness and knwowledgibility
		if (this.activeSprint.teamHapiness) {
			this.teamHappiness = parseInt(this.activeSprint.teamHapiness);
		}

		if (this.activeSprint.teamEducation) {
			this.teamEducation = parseInt(this.activeSprint.teamEducation);
		}

		this.remainingDays = this.sprintService.getRemainingDays(this.activeSprint);

		this.fetchTasks();

		this.isActiveSprint = this.product.activeSprint && this.product.activeSprint.id == this.activeSprint.id;
	}

	async fetchTasks(user?: User) {
		// reset subscription
		if (this.taskSubscription) {
			this.taskSubscription.unsubscribe();
		}

		if (!!user) {
			this.tasks = await this.taskService.getTasksPerUser(this.activeSprint, user);
		} else {
			this.tasks = await this.taskService.getTasks(this.activeSprint);
		}

		this.sortTasks(this.tasks);

		// Setup live query for Tasks
		const taskQuery = new Query(Task);
		taskQuery.equalTo('sprint', this.activeSprint);
		taskQuery.equalTo('archived', false);
		taskQuery.ascending('orderIndex');
		this.taskSubscription = await taskQuery.subscribe();
		this.taskSubscription.on('create', (task: Task) => {
			this.tasks.push(task);
			this.sortTasks(this.tasks);
		});
		this.taskSubscription.on('update', async (task: Task) => {
			const oldIndex = this.tasks.findIndex((t) => t.id == task.id);
			this.tasks[oldIndex] = task;

			this.sortTasks(this.tasks);
		});
		this.taskSubscription.on('enter', (task: Task) => {
			this.tasks.push(task);

			this.sortTasks(this.tasks);
		});
		this.taskSubscription.on('leave', (task: Task) => {
			const index = this.tasks.findIndex((t) => t.id === task.id);
			this.tasks.splice(index, 1);

			this.sortTasks(this.tasks);
		});

		// update sprint
		this.activeSprintSubscription.on('update', (sprint: Sprint) => {
			this.activeSprint = sprint;
			this.remainingDays = this.sprintService.getRemainingDays(this.activeSprint);
		});
	}

	sortTasks(tasks) {
		this.sortedTasks = {
			todo: [] as Task[],
			inProgress: [] as Task[],
			done: [] as Task[],
		};

		tasks.sort((a, b) => {
			if (a.orderIndex < b.orderIndex) {
				return -1;
			}

			if (a.orderIndex > b.orderIndex) {
				return 1;
			}

			if (a.orderIndex == b.orderIndex) {
				return 0;
			}
		});

		for (let task of tasks) {
			this.sortedTasks[task.status].push(task);
		}
	}

	switchSprint(sprint) {
		this.activeSprint = sprint;
		this.fetchTasks();
	}

	async dropTask(event: CdkDragDrop<string[]>) {
		// When moving status
		if (event.previousContainer !== event.container) {
			event.item.data.status = event.container.data;

			this.sortTasks(this.tasks);
		}

		let task = event.item.data;
		let status = event.container.data as any;

		Cloud.run('reOrderTask', {
			taskId: task.id,
			status: status,
			newIndex: event.currentIndex,
		});

		// update visuals
		moveItemInArray(this.sortedTasks[status], this.sortedTasks[status].indexOf(task), event.currentIndex);

		if (event.previousContainer !== event.container) {
			// check if all tasks for this experiment are done
			if (status === Task.Status.DONE && task.experiment && task.experiment.status !== Experiment.Status.DONE) {
				const leftTodo = await this.experimentService.tasksLeftTodo(task.experiment);

				if (leftTodo) {
					return;
				}

				// open modal to ask if wanting to analyze experiment
				this.askToAnalyze(task.experiment);
			}
		}
	}

	askToAnalyze(experiment: Experiment) {
		const modalRef = this.modalService.open(ExperimentModalTasksDoneComponent, {
			size: 'md',
			centered: true,
		});
		modalRef.componentInstance.experiment = experiment;
	}

	async toggleMyTasks() {
		if (!this.showingMine) {
			this.showingMine = true;
			let user = User.current() as User;

			this.fetchTasks(user);
		} else {
			this.showingMine = false;

			this.fetchTasks();
		}
	}

	completeSprint() {
		const modalRef = this.modalService.open(SprintModalCompleteComponent, {
			size: 'md',
		});
		modalRef.componentInstance.sprint = this.activeSprint;
		modalRef.componentInstance.product = this.product;
	}

	openTask(task) {
		this.taskService.openModal(task, this.activeSprint, this.product);
	}

	addMinute() {
		const minute = new Minute();
		this.editMinute(minute);
	}

	editMinute(minute) {
		const modalRef = this.modalService.open(MinuteModalComponent, {
			size: 'lg',
		});

		modalRef.componentInstance.minute = minute;
		const subscription = modalRef.componentInstance.save.subscribe(($e) => {
			this.onSaveMinute($e);

			subscription.unsubscribe();
		});
	}

	async onSaveMinute(event) {
		const minute = event.minute;
		const isNew = minute.isNew();

		minute.product = this.product;
		await minute.save();

		if (isNew) {
			this.activeSprint.addUnique('minutes', minute);
			this.activeSprint.save();

			// this.sprint.minutes.unshift(minute);
		}
	}

	async editSprint(sprint: Sprint) {
		const modalRef = this.modalService.open(SprintModalStartComponent, {
			size: 'md',
		});
		modalRef.componentInstance.sprint = sprint;
		modalRef.componentInstance.product = this.product;

		const result = await modalRef.result;

		// Check whether the close button of the modal was pressed
		if (!result) {
			return;
		}

		// save sprint
		await sprint.save();

		if (sprint.status === Sprint.Status.DONE) {
			const modalRef = this.modalService.open(SprintModalRetrospectComponent, {
				size: 'md',
			});
			modalRef.componentInstance.sprint = sprint;
			modalRef.componentInstance.product = this.product;

			await modalRef.result;
		}
	}

	async createNewTask(sprint: Sprint) {
		const task = new Task();
		task.status = Task.Status.TODO;

		this.taskService.openModal(task, sprint, this.product);
	}
}
