import { Component, Input, OnInit } from '@angular/core';
import { ProductService } from 'app/product/product.service';
import { Experiment } from '../../../../shared/models/experiment';

@Component({
	selector: 'app-experiment-row',
	templateUrl: './experiment-row.component.html',
	styleUrls: ['./experiment-row.component.scss'],
})
export class ExperimentRowComponent implements OnInit {
	@Input() experiment: Experiment;
	public abbrev: string;

	constructor(public productService: ProductService) {}

	async ngOnInit() {
		const product = await this.productService.getActiveProduct();
		this.abbrev = product.getAbbrev();

		if (this.experiment.type == 'confirm') {
			this.experiment.sticky.fetch();	
		}
	}
}
