import { Component, OnInit } from '@angular/core';
import { DecisionService } from 'app/decision/decision.service';
import { Portfolio } from '../../../../shared/models/portfolio';
import { ProductDecision } from '../../../../shared/models/product-decision';
import { ProductService } from 'app/product/product.service';
import { Product } from '../../../../shared/models/product';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DecisionModalComponent } from 'app/decision/decision-modal/decision-modal.component';
import { AnalyticsService } from 'app/shared/analytics.service';
import { NextTitleService } from 'app/next-title.service';
import { PortfolioService } from '../portfolio.service';
import { Query } from 'parse';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-portfolio-board',
	templateUrl: './portfolio-board.component.html',
	styleUrls: ['./portfolio-board.component.scss'],
})
export class PortfolioBoardComponent implements OnInit {
	public decisions: Array<ProductDecision> = [];
	public latestDecisions: Array<ProductDecision> = [];
	public portfolio: Portfolio;
	public products: Product[];
	public sortedOn: string;
	public latestDecisionDatePerProduct: Object;
	public preparedDecisions: ProductDecision[];
	public preparedDecisionSubscription;

	get DecisionType() {
		return ProductDecision.DecisionType;
	}

	constructor(
		private decisionService: DecisionService,
		public portfolioService: PortfolioService,
		private productService: ProductService,
		private modalService: NgbModal,
		private analytics: AnalyticsService,
		private titleService: NextTitleService,
		private translate: TranslateService
	) {
		// weird syntax needed in order for function to recognize this.latestDecisionDatePerProduct
		// this.productSortDecisionDateAscending = this.productSortDecisionDateAscending.bind(this);
		// this.productSortDecisionDateDescending = this.productSortDecisionDateDescending.bind(this);
	}

	async ngOnInit() {
		// this.analytics.track('Opened portfolio board');

		// this.sortedOn = 'nameAscending';
		this.products = await this.productService.getProducts();
		this.decisions = await this.decisionService.getDecisions();
		this.latestDecisions = this.decisions.slice(0, 10);
		this.portfolio = await this.portfolioService.getActivePortfolio();

		await this.getPreparedDecisions();
		this.titleService.setTitle(this.translate.instant('ventureBoard.singular') + ' - ' + this.portfolio.name);

	}

	async getPreparedDecisions() {
		const preparedDecisionQuery = new Query(ProductDecision);
		preparedDecisionQuery.equalTo('status', ProductDecision.Status.PREPARED);
		preparedDecisionQuery.equalTo('archived', false);
		preparedDecisionQuery.containedIn('product', this.products);
		preparedDecisionQuery.include(['attachments', 'product', 'product.portfolio', 'minutes']);
		preparedDecisionQuery.descending('createdAt');
		this.preparedDecisions = await preparedDecisionQuery.find();

		this.preparedDecisionSubscription = await preparedDecisionQuery.subscribe();
		this.preparedDecisionSubscription.on('create', async (decision) => {
			this.preparedDecisions.unshift(decision);
			this.decisions = await this.decisionService.getDecisions();
		});
		this.preparedDecisionSubscription.on('enter', async (decision) => {
			this.preparedDecisions.unshift(decision);
			this.decisions = await this.decisionService.getDecisions();
		});
		this.preparedDecisionSubscription.on('update', async (decision) => {
			if (decision.status === ProductDecision.Status.DONE) {
				const index = this.preparedDecisions.findIndex((d) => d.id === decision.id);
				this.preparedDecisions.splice(index, 1);
			} else {
				const index = this.preparedDecisions.findIndex((d) => d.id === decision.id);
				this.decisions[index] = decision;
			}
			this.decisions = await this.decisionService.getDecisions();
		});
	}

	openDecision(decision) {
		const modalRef = this.modalService.open(DecisionModalComponent, {
			size: 'md',
		});
		modalRef.componentInstance.decision = decision;
	}

	newDecision() {
		let decision = new ProductDecision();

		this.openDecision(decision);
	}
}
