import { Component, OnInit, Input } from '@angular/core';
import { Portfolio } from '../../../../shared/models/portfolio';
import { Cloud } from 'parse';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-portfolio-chart-velocity-modal',
  templateUrl: './portfolio-chart-velocity-modal.component.html',
  styleUrls: ['./portfolio-chart-velocity-modal.component.scss']
})
export class PortfolioChartVelocityModalComponent implements OnInit {
	@Input() portfolio: Portfolio;
	@Input() datasets: Object[];
	@Input() labels: string[];
	@Input() title: string;
	@Input() isExperimentVelocity: boolean;

	// innovation Accounting
	public year: number;
	public quarter: number;
	public iAPs: any[] = [];

	constructor(public activeModal: NgbActiveModal) { }

	ngOnInit(): void {
		this.quarter = Number(this.labels[this.labels.length - 1].slice(1, 2)) - 1;		
		this.year = Number(this.labels[this.labels.length - 1].slice(3, 7));
		this.getInnovationAccountingPerStartup();
	}

	getInnovationAccountingPerStartup() {
		Cloud.run('getInnovationAccountingQuarter', {
			portfolioId: this.portfolio.id,
			year: this.year,
			quarter: this.quarter,
		})
		.then((result) => {
			this.iAPs = result;
		})
		.catch((error) => {
			console.error(error);
		});
	}

	clickedDataPoint(label) {
		this.quarter = Number(label.slice(1, 2)) - 1;
		this.year = Number(label.slice(3, 7));
		this.getInnovationAccountingPerStartup();
	}
}
