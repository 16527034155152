<div class="form-group settings-content" *ngIf="portfolio">
	<h5>{{ 'portfolioModalSettings.canvasDesigns.title' | translate }}</h5>
	<p>{{ 'portfolioModalSettings.canvasDesigns.description' | translate }}</p>

	<div class="canvas mt-3">
		<div class="row no-gutters next-canvas">
			<div class="segment col" *ngFor="let stage of stages">
				<strong>
					<inline-editor
						type="text"
						size="9"
						[(ngModel)]="portfolio.canvasSegmentNames['next-canvas'][segments[stage][0]]"
						[empty]="'canvas.nextCanvas.' + segments[stage][0] | translate"
						(onSave)="saveEditable()"
					></inline-editor>
				</strong>
			</div>
		</div>
		<div class="row no-gutters align-items-center">
			<ng-container *ngFor="let stage of stages">
				<div class="col segment stage">
					<p class="text-center">{{ portfolioService.getStageName(portfolio, stage) }}</p>
				</div>
			</ng-container>
		</div>
		<div class="row no-gutters next-canvas">
			<div class="segment col" *ngFor="let stage of stages">
				<strong>
					<inline-editor
						type="text"
						size="9"
						[(ngModel)]="portfolio.canvasSegmentNames['next-canvas'][segments[stage][1]]"
						[empty]="'canvas.nextCanvas.' + segments[stage][1] | translate"
						(onSave)="saveEditable()"
					></inline-editor>
				</strong>
			</div>
		</div>
	</div>
</div>
