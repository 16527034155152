<div class="modal-content">
	<div class="modal-header">
		<p class="modal-title">{{ 'team.happiness' | translate }}</p>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="row chart-row">
			<div class="col-md-12">
				<canvas
					class="hand"
					baseChart
					[datasets]="datasets"
					[labels]="labels"
					[options]="chartOptions"
					[colors]="chartColors"
					chartType="line"
					*ngIf="isReady"
					(chartClick)="dataClicked($event)"
				></canvas>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-1">
				<p class="explanation">{{ 'innovationAccounting.teamHappinessExplanation' | translate }}</p>
			</div>
		</div>

		<div class="row">
			<div class="col-md-12 mb-3">
				<h4 class="modal-body-header">
					{{ 'team.happiness' | translate }} {{ 'innovationAccountingDetail.perStartup' | translate }}, Q{{ quarter + 1 }} {{ year }}
				</h4>
			</div>

			<div class="col-md-12" *ngIf="isReady">
				<div *ngFor="let iAP of iAPs" class="d-flex flex-row justify-content-between align-items-center startup-row">
					<p>{{ iAP.name }}</p>
					<div class="progress">
						<div
							*ngIf="iAP.weeks !== 0"
							class="progress-bar"
							role="progressbar"
							[style.width.%]="((iAP.teamHapiness - 1) / 4) * 100 || 0"
							aria-valuemin="1"
							aria-valuemax="5"
						></div>
						<p *ngIf="iAP.weeks === 0">-</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
