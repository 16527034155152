import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Portfolio } from './portfolio';
import { User } from './user';

@ParseSubClass('ContentExperiment')
export class ContentExperiment extends Parse.Object {
	@ParseProperty() title: string;
	@ParseProperty() excerpt: string;
	@ParseProperty() stages: string[];
	@ParseProperty() helpsAnswer: string;
	@ParseProperty() description: string;
	@ParseProperty() howToAnalyze: string;
	@ParseProperty() readMore: string;
	@ParseProperty() example: string;
	@ParseProperty() source: string;
	@ParseProperty() createdBy: User;
	@ParseProperty() archived: boolean;
	@ParseProperty() slug: string;
	@ParseProperty() standardExperimentPlan: string;
	@ParseProperty() language: string;
	@ParseProperty() types: string[];
	@ParseProperty() portfolio: Portfolio;
	@ParseProperty() default: boolean;

	constructor() {
		super('ContentExperiment');
	}
}

export namespace ContentExperiment {
	export enum Type {
		LEARN = 'learn',
		CONFIRM = 'confirm',
	}
}
