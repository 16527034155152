<ng-container *ngIf="type !== 'pre-validation'"
	><app-mini-next-canvas
		[allStickies]="stickies"
		[canvas]="canvas"
		[stage]="stage"
		(selectCanvas)="onClick($event)"
		[canSelectSegment]="canSelectSegment"
		[selectedSegment]="selectedSegment"
		[showSegmentNames]="showSegmentNames"
		[portfolio]="portfolio"
	></app-mini-next-canvas
></ng-container>
<ng-container *ngIf="type === 'pre-validation'"
	><app-mini-pre-validation
		[allStickies]="stickies"
		[canvas]="canvas"
		[stage]="stage"
		(selectCanvas)="onClick($event)"
		[canSelectSegment]="canSelectSegment"
		[selectedSegment]="selectedSegment"
		[showSegmentNames]="showSegmentNames"
	></app-mini-pre-validation
></ng-container>
