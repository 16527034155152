import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExperimentService } from 'app/experiment/experiment.service';
import { LogService } from 'app/log/log.service';
import { RemoveModalComponent } from 'app/validating-modals/remove-modal/remove-modal.component';
import { Product } from '../../../../shared/models/product';
import { Sprint } from '../../../../shared/models/sprint';
import { Task } from '../../../../shared/models/task';
import { User } from '../../../../shared/models/user';
import { TaskService } from '../task.service';

@Component({
	selector: 'app-task-modal-add',
	templateUrl: './task-modal-add.component.html',
	styleUrls: ['./task-modal-add.component.scss'],
})
export class TaskModalAddComponent implements OnInit {
	@Input() task: Task;
	@Input() sprint?: Sprint;
	@Input() product: Product;
	@Input() abbrev: string;
	public editable = false;
	public assignableMembers: User[];

	constructor(
		public activeModal: NgbActiveModal,
		private experimentService: ExperimentService,
		private taskService: TaskService,
		public modalService: NgbModal,
		public logService: LogService
	) {}

	async ngOnInit(): Promise<any> {
		if (this.task.isNew()) {
			this.editable = true;
		}

		this.assignableMembers = this.product.teamMembers;

		// If loggedin User is not part of team, add it to assignableMembers. Otherwise assignMe will break
		if(this.assignableMembers.findIndex((item) => item.id === User.current().id) == -1) {
			this.assignableMembers.push(User.current());
		}
	}

	assignMe() {
		if (!this.task.assignedUsers) {
			this.task.assignedUsers = [];
		} else if (this.task.assignedUsers.findIndex((item) => item.id === User.current().id) != -1) {
			// me is already assigned
			return;
		}
		// makes sure the ng-select detects that the user was added from this.users
		// if User.current is just directly added, you could add yourself twice --> this is a workaround
		// must also not be array.push(), see ng-select documentation (otherwise ngselect does not recognize changes)
		this.task.assignedUsers = [
			...this.task.assignedUsers,
			this.product.teamMembers[this.product.teamMembers.findIndex((item) => item.id === User.current().id)],
		];
	}

	async createTask() {
		if (!this.task.title) {
			return;
		}

		if (this.sprint) {
			this.task.product = this.product;
			this.task.sprint = this.sprint;
			await this.task.save();

			this.sprint.addUnique('tasks', this.task);
			await this.sprint.save();
		}

		this.activeModal.close(this.task);
	}

	async deleteTask() {
		const modalRef = this.modalService.open(RemoveModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.element = 'task';

		let removed = await modalRef.result;
		if (removed) {
			this.taskService.removeTask(this.task);
			this.activeModal.close();
		}
	}

	makeEditable() {
		this.editable = true;
	}

	saveEditable() {
		this.task.save();

		this.editable = false;
	}

	openExperiment(experiment) {
		this.experimentService.openModal(experiment, this.product);
	}
}
