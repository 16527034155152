import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { KeyQuestion } from '../../../../shared/models/key-question';
import { Product } from '../../../../shared/models/product';
import { Progress } from '../../../../shared/models/progress';
import { ProductService } from 'app/product/product.service';

@Component({
	selector: 'app-progress-keyquestions-modal-overview',
	templateUrl: './progress-keyquestions-modal-overview.component.html',
	styleUrls: ['./progress-keyquestions-modal-overview.component.scss'],
})
export class ProgressKeyquestionsModalOverviewComponent implements OnInit {
	@Input() product: Product;
	public learningsPerQuestion: any[];
	public progressHasChanged = false;
	public newProgress: Progress;

	constructor(public activeModal: NgbActiveModal, private productService: ProductService) {}

	async ngOnInit() {
		this.learningsPerQuestion = await this.productService.getLearningsPerKeyQuestion(this.product);
		this.newProgress = await this.productService.createShadowProgress(this.product);
	}

	async updateProgress() {
		this.productService.updateProgress(this.product, this.newProgress);
		this.progressHasChanged = false;
		this.activeModal.close(this.product.activeProgress);
	}

	onChange(evt) {
		this.progressHasChanged = true;
	}
}
