import { Component, OnInit } from '@angular/core';
import { Portfolio } from '../../../../shared/models/portfolio';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Router } from '@angular/router';
import { Cloud } from 'parse';
import { PortfolioModalAddComponent } from 'app/portfolio/portfolio-modal-add/portfolio-modal-add.component';
import { NextTitleService } from 'app/next-title.service';
import { PortfolioModalInfoComponent } from 'app/portfolio/portfolio-modal-info/portfolio-modal-info.component';

@Component({
  selector: 'app-admin-portfolios',
  templateUrl: './admin-portfolios.component.html',
  styleUrls: ['./admin-portfolios.component.scss']
})
export class AdminPortfoliosComponent implements OnInit {
	public portfolios: Array<Portfolio> = [];
	public searchKey: string;
	public accountIds: object;
	public waitMessage: boolean;

	constructor(
		private modalService: NgbModal,
		private portfolioService: PortfolioService,
		private router: Router,
		private titleService: NextTitleService) { }

	ngOnInit(): void {
		this.titleService.setTitle('Admin - Portfolios');
		this.getPortfolios();
	}

	async getPortfolios() {
		let portfolioResult = await Cloud.run('getPortfolios');
		this.portfolios = portfolioResult['portfolios'];
		this.accountIds = portfolioResult['accountIds'];
	}

	addPortfolio() {
		const portfolio = new Portfolio();

		const modalRef = this.modalService.open(PortfolioModalAddComponent, { size: 'lg' });
		modalRef.componentInstance.portfolio = portfolio;
		const subscription = modalRef.componentInstance.save.subscribe(($e) => {
			this.onCreatePortfolio($e.portfolio, $e.addDemoStartups);

			subscription.unsubscribe();
		});
	}

	async search() {
		if (!this.searchKey) {
			this.getPortfolios();
			return;
		}

		const result = await Cloud.run('searchPortfolios', {
			searchKey: this.searchKey
		});
		this.accountIds = result['accountIds'];
		this.portfolios = result['portfolios'];
	}

	async onCreatePortfolio(portfolio, addDemoStartups) {
		await portfolio.save();


		this.portfolios.push(portfolio);
		this.portfolioService.setActivePortfolio(portfolio);

		this.router.navigate(['/portfolio', portfolio.id]);
	}

	openPortfolio(portfolio) {
		const modalRef = this.modalService.open(PortfolioModalInfoComponent, {
			size: 'md',
		});
		modalRef.componentInstance.portfolio = portfolio;
		modalRef.componentInstance.accountId = this.accountIds[portfolio.id];
		return modalRef.result;
	}
}
