<div class="modal-content">
	<div class="modal-header align-items-center">
		<p class="modal-title">{{ 'sticky.riskyness' | translate }}</p>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body risk-scores">
		<p class="mb-3" [innerHtml]="'sticky.riskExplanation.explanation' | translate"></p>

		<p class="most-risky font-bold">{{ 'sticky.risk.most-risky' | translate }}</p>
		<p class="mb-3">{{ 'sticky.riskExplanation.most-risky' | translate }}</p>

		<p class="risky">{{ 'sticky.risk.risky' | translate }}</p>
		<p class="mb-3">{{ 'sticky.riskExplanation.risky' | translate }}</p>

		<p class="confirm">{{ 'sticky.risk.confirm' | translate }}</p>
		<p class="mb-3">{{ 'sticky.riskExplanation.confirm' | translate }}</p>

		<p class="ignore">{{ 'sticky.risk.ignore' | translate }}</p>
		<p>{{ 'sticky.riskExplanation.ignore' | translate }}</p>
	</div>
	<div class="modal-footer mt-5">
		<button class="btn btn-primary ml-auto" (click)="activeModal.close()">{{ 'general.close' | translate }}</button>
	</div>
</div>
