<div class="mini-canvas" [ngClass]="stage">
	<app-spinner [show]="busy" class="spinner"></app-spinner>
	<div class="row canvas-top mx-0">
		<ng-container *ngFor="let stage of stages">
			<app-mini-segment
				[id]="segments[stage][0]"
				class="col segment px-0"
				type="next"
				[allStickies]="allStickies"
				[selectedSegment]="selectedSegment"
				[segmentName]="showSegmentNames && portfolioService.getSegmentName(portfolio, segments[stage][0])"
				(click)="onClick(segments[stage][0])"
				[ngClass]="{ hoverable: canSelectSegment }"
			></app-mini-segment>
		</ng-container>
	</div>
	<div class="row canvas-bottom mx-0">
		<ng-container *ngFor="let stage of stages">
			<app-mini-segment
				[id]="segments[stage][1]"
				class="col segment px-0"
				type="next"
				[allStickies]="allStickies"
				[selectedSegment]="selectedSegment"
				[segmentName]="showSegmentNames && portfolioService.getSegmentName(portfolio, segments[stage][1])"
				(click)="onClick(segments[stage][1])"
				[ngClass]="{ hoverable: canSelectSegment }"
			></app-mini-segment>
		</ng-container>
	</div>
</div>
