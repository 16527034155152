<div class="modal-content">
	<div class="modal-header">
		<p *ngIf="!editable">{{ 'insight.singular' | translate }}</p>
		<p *ngIf="editable && insight.isNew()">{{ 'insight.new' | translate }}</p>
		<p *ngIf="editable && !insight.isNew()">{{ 'insight.edit' | translate }}</p>

		<button class="btn btn-link-white ml-auto" *ngIf="!editable" (click)="makeEditable()">{{ 'general.edit' | translate }}</button>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body edit" *ngIf="editable">
		<form>
			<div class="row mb-1">
				<div class="col-12 form-group">
					<div class="form-check">
						<input class="form-check-input" type="radio" name="decision" id="decision-persevere" [value]="InsightDecision.PERSEVERE" [(ngModel)]="insight.decision" />
						<label class="form-check-label" for="decision-persevere">
							<strong>{{ 'insight.persevere.title' | translate }}</strong
							>: {{ 'insight.persevere.explanation' | translate }}
						</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="radio" name="decision" id="decision-pivot" [value]="InsightDecision.PIVOT" [(ngModel)]="insight.decision" />
						<label class="form-check-label" for="decision-pivot">
							<strong>{{ 'insight.pivot.title' | translate }}</strong
							>: {{ 'insight.pivot.explanation' | translate }}
						</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="radio" name="decision" id="decision-stop" [value]="InsightDecision.STOP" [(ngModel)]="insight.decision" />
						<label class="form-check-label" for="decision-stop">
							<strong>{{ 'insight.stop.title' | translate }}</strong
							>: {{ 'insight.stop.explanation' | translate }}
						</label>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 form-group">
					<label>{{ 'insight.explanation' | translate }}</label>
					<quill-editor name="insight" [(ngModel)]="insight.text" [styles]="{ height: '7rem' }" placeholder=""></quill-editor>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-body" *ngIf="!editable">
		<p class="mb-3">
			<span *ngIf="insight.decision === InsightDecision.PERSEVERE" class="badge badge-light mr-1" title="{{ 'insight.persevere.explanation' | translate }}"
				><i class="bi bi-sign-merge-left"></i> {{ 'insight.persevere.title' | translate }}</span
			>
			<span *ngIf="insight.decision === InsightDecision.PIVOT" class="badge badge-primary mr-1" title="{{ 'insight.pivot.explanation' | translate }}"
				><i class="bi bi-sign-intersection-y"></i> {{ 'insight.pivot.title' | translate }}</span
			>
			<span *ngIf="insight.decision === InsightDecision.STOP" class="badge badge-danger mr-1" title="{{ 'insight.stop.explanation' | translate }}"
				><i class="bi bi-sign-dead-end"></i> {{ 'insight.stop.title' | translate }}</span
			>

			<span *ngIf="insight.experiment" (click)="openExperiment()" class="badge light-blue hand"><i class="bi bi-eyedropper"></i>{{ insight.product.getAbbrev() }}-{{ insight.experiment.number }}</span>
		</p>
		<h2 class="modal-body-title editor-data" [innerHtml]="insight.text"></h2>
	</div>
	<div class="modal-footer">
		<button class="btn btn-link-danger mr-auto" *ngIf="!insight.isNew()" (click)="deleteInsight()">
			{{ 'general.remove' | translate }}
		</button>
		<button *ngIf="editable && !insight.isNew()" ngbAutofocus type="button" class="btn btn-primary" (click)="onSave()" [disabled]="!insight.text">
			{{ 'general.save' | translate }}
		</button>
		<button *ngIf="editable && insight.isNew()" ngbAutofocus type="button" class="btn btn-primary" (click)="onSave()" [disabled]="!insight.text">
			{{ 'insight.create' | translate }}
		</button>
		<button *ngIf="!editable" ngbAutofocus type="button" class="btn btn-primary" (click)="activeModal.close()">
			{{ 'general.close' | translate }}
		</button>
	</div>
</div>
