import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccessGuard } from './authenticate/access.guard';
import { AdminGuard } from './authenticate/admin.guard';
import { PortfolioAccessGuard } from './portfolio/portfolio-access.guard';

import { SignupComponent } from './authenticate/signup/signup.component';
import { LoginComponent } from './authenticate/login/login.component';
import { ProductCanvasDetailComponent } from './product/canvas-detail/canvas-detail.component';
import { ProductDetailComponent } from './product/product-detail/product-detail.component';
import { ProfileComponent } from './user/profile/profile.component';
import { HomeComponent } from './layout/home/home.component';
import { PortfolioDetailComponent } from './portfolio/portfolio-detail/portfolio-detail.component';
import { AdminComponent } from './admin/admin/admin.component';
import { ForgottenPasswordComponent } from './authenticate/forgotten-password/forgotten-password.component';
import { SprintDetailComponent } from './sprint/detail/detail.component';
import { ProductSettingsComponent } from './product-settings/product-settings/product-settings.component';
import { FunnelComponent } from './portfolio/funnel/funnel.component';
import { PortfolioBoardComponent } from './portfolio/portfolio-board/portfolio-board.component';
import { PortfolioAccountingComponent } from './portfolio/portfolio-accounting/portfolio-accounting.component';
import { PortfolioProductsComponent } from './portfolio/portfolio-products/portfolio-products.component';
import { BacklogComponent } from './sprint/backlog/backlog.component';
import { ProductAccountingOverviewComponent } from './product/product-accounting-overview/product-accounting-overview.component';
import { ProgressDetailComponent } from './progress/progress-detail/progress-detail.component';
import { ProductCockpitComponent } from './product/product-cockpit/product-cockpit.component';
import { YourWorkComponent } from './sprint/your-work/your-work.component';
import { PortfolioSettingsComponent } from './portfolio-settings/portfolio-settings/portfolio-settings.component';
import { NotfoundComponent } from './layout/notfound/notfound.component';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { AdminPortfoliosComponent } from './admin/admin-portfolios/admin-portfolios.component';
import { ValidateEmailComponent } from './authenticate/validate-email/validate-email.component';
import { SignupThankyouComponent } from './authenticate/signup-thankyou/signup-thankyou.component';
import { ResetPasswordComponent } from './authenticate/reset-password/reset-password.component';
import { ResetPasswordThankyouComponent } from './authenticate/reset-password-thankyou/reset-password-thankyou.component';
import { ContentExperimentDetailComponent } from './content-experiment/content-experiment-detail/content-experiment-detail.component';
import { AdminContentExperimentOverviewComponent } from './admin/admin-content-experiment-overview/admin-content-experiment-overview.component';
import { ContentExperimentToolOverviewComponent } from './content-experiment/content-experiment-tool-overview/content-experiment-tool-overview.component';
import { AdminContentToolOverviewComponent } from './admin/admin-content-tool-overview/admin-content-tool-overview.component';
import { ContentToolDetailComponent } from './content-tool/content-tool-detail/content-tool-detail.component';
import { CreateSubscriptionComponent } from './subscription/create-subscription/create-subscription.component';
import { BillingComponent } from './subscription/billing/billing.component';
import { SubscriptionAccessGuard } from './subscription/subscription-access.guard';
import { PortfolioSettingsGeneralComponent } from './portfolio-settings/portfolio-settings-general/portfolio-settings-general.component';
import { PortfolioSettingsStagenamesComponent } from './portfolio-settings/portfolio-settings-stagenames/portfolio-settings-stagenames.component';
import { PortfolioSettingsCollaboratorsComponent } from './portfolio-settings/portfolio-settings-collaborators/portfolio-settings-collaborators.component';
import { PortfolioSettingsThesisComponent } from './portfolio-settings/portfolio-settings-thesis/portfolio-settings-thesis.component';
import { PortfolioSettingsCanvasDesignComponent } from './portfolio-settings/portfolio-settings-canvas-design/portfolio-settings-canvas-design.component';
import { KeyQuestionsOverviewComponent } from './key-question/key-question-overview/key-question-overview.component';
import { BoardMeetingPreparationComponent } from './decision/board-meeting-preparation/board-meeting-preparation.component';
import { ProductSettingsGeneralComponent } from './product-settings/product-settings-general/product-settings-general.component';
import { ProductSettingsCockpitComponent } from './product-settings/product-settings-cockpit/product-settings-cockpit.component';
import { ProductSettingsPortfolioComponent } from './product-settings/product-settings-portfolio/product-settings-portfolio.component';
import { ProductSettingsCollaboratorsComponent } from './product-settings/product-settings-collaborators/product-settings-collaborators.component';
import { PortfolioLearningsComponent } from './portfolio/portfolio-learnings/portfolio-learnings.component';
import { PortfolioSettingsContentExperimentsComponent } from './portfolio-settings/portfolio-settings-content-experiments/portfolio-settings-content-experiments.component';
import { PortfolioSettingsLensesComponent } from './portfolio-settings/portfolio-settings-lenses/portfolio-settings-lenses.component';
import { PortfolioSettingsCockpitComponent } from './portfolio-settings/portfolio-settings-cockpit/portfolio-settings-cockpit.component';
import { NewTenantComponent } from './authenticate/new-tenant/new-tenant.component';

const routes: Routes = [
	{ path: '', component: HomeComponent, canActivate: [AccessGuard] },

	// loggedout pages
	{ path: 'signup', component: SignupComponent },
	{ path: 'signup/:inviteId', component: SignupComponent },
	{ path: 'signupThankyou', component: SignupThankyouComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'login/sso', component: LoginComponent },
	{ path: 'new-tenant', component: NewTenantComponent },
	
	{ path: 'demo-login', component: LoginComponent },
	{ path: 'forgottenPassword', component: ForgottenPasswordComponent },
	{ path: 'resetPassword', component: ResetPasswordComponent },
	{ path: 'resetPasswordThankyou', component: ResetPasswordThankyouComponent },
	{ path: 'validateEmail', component: ValidateEmailComponent },

	// The following paths are only accessible for Loggedin users
	{ path: 'profile', component: ProfileComponent, canActivate: [AccessGuard] },

	{ path: 'upgrade', component: CreateSubscriptionComponent, canActivate: [AccessGuard] },
	{ path: 'billing', component: BillingComponent, canActivate: [SubscriptionAccessGuard] },

	// only for admin users
	{
		path: 'admin',
		component: AdminComponent,
		canActivate: [AdminGuard],
		children: [
			{ path: '', redirectTo: 'portfolios', pathMatch: 'full' },
			{ path: 'admin-users', component: AdminUsersComponent, canActivate: [AdminGuard] },
			{ path: 'portfolios', component: AdminPortfoliosComponent, canActivate: [AdminGuard] },
			{ path: 'experiments', component: AdminContentExperimentOverviewComponent, canActivate: [AdminGuard] },
			{ path: 'tools', component: AdminContentToolOverviewComponent, canActivate: [AdminGuard] },
		],
	},

	// Redirect old urls
	{ path: 'dashboard', redirectTo: '', pathMatch: 'full' },

	// Portfolio
	{
		path: 'portfolio/:portfolio',
		component: PortfolioDetailComponent,
		canActivate: [AccessGuard],
		// resolve: { portfolio: PortfolioResolver },
		children: [
			{ path: '', redirectTo: 'startups', pathMatch: 'full' },
			{ path: 'startups', component: PortfolioProductsComponent, canActivate: [AccessGuard] },
			{ path: 'funnel', component: FunnelComponent, canActivate: [AccessGuard] },
			{ path: 'board', component: PortfolioBoardComponent, canActivate: [AccessGuard] },
			{ path: 'accounting', component: PortfolioAccountingComponent, canActivate: [AccessGuard] },
			{ path: 'your-work', component: YourWorkComponent, canActivate: [AccessGuard] },
			{ path: 'learnings', component: PortfolioLearningsComponent, canActivate: [PortfolioAccessGuard] },

			// Experiments & tools
			{ path: 'experiment-tool', component: ContentExperimentToolOverviewComponent },
			{ path: 'experiment/:slug', component: ContentExperimentDetailComponent },
			{ path: 'tool/:slug', component: ContentToolDetailComponent },

			{
				path: 'settings',
				component: PortfolioSettingsComponent,
				canActivate: [PortfolioAccessGuard],
				children: [
					{ path: '', redirectTo: 'general', pathMatch: 'full' },
					{ path: 'general', component: PortfolioSettingsGeneralComponent, canActivate: [PortfolioAccessGuard] },
					{ path: 'stage-names', component: PortfolioSettingsStagenamesComponent, canActivate: [PortfolioAccessGuard] },
					{ path: 'access', component: PortfolioSettingsCollaboratorsComponent, canActivate: [PortfolioAccessGuard] },
					{ path: 'theses', component: PortfolioSettingsThesisComponent, canActivate: [PortfolioAccessGuard] },
					{ path: 'canvas-design', component: PortfolioSettingsCanvasDesignComponent, canActivate: [PortfolioAccessGuard] },
					{ path: 'billing', component: BillingComponent, canActivate: [SubscriptionAccessGuard] },
					{ path: 'key-questions', component: KeyQuestionsOverviewComponent, canActivate: [PortfolioAccessGuard] },
					{ path: 'experiments', component: PortfolioSettingsContentExperimentsComponent, canActivate: [PortfolioAccessGuard] },
					{ path: 'lenses', component: PortfolioSettingsLensesComponent, canActivate: [PortfolioAccessGuard] },
					{ path: 'cockpit', component: PortfolioSettingsCockpitComponent, canActivate: [PortfolioAccessGuard] },

					{
						path: 'product-settings/:product',
						component: ProductSettingsComponent,
						canActivate: [PortfolioAccessGuard],
						children: [
							{ path: '', redirectTo: 'general', pathMatch: 'full' },
							{ path: 'general', component: ProductSettingsGeneralComponent },
							{ path: 'cockpit', component: ProductSettingsCockpitComponent },
							{ path: 'portfolio', component: ProductSettingsPortfolioComponent },
							{ path: 'collaborators', component: ProductSettingsCollaboratorsComponent },
						],
					},
					{ path: 'product-settings', component: ProductSettingsComponent, canActivate: [PortfolioAccessGuard] },
				],
			},

			// Startups
			{
				path: 'startup/:id',
				component: ProductDetailComponent,
				canActivate: [AccessGuard],
				children: [
					{ path: '', redirectTo: 'cockpit', pathMatch: 'full' },
					{ path: 'accounting', component: ProductAccountingOverviewComponent, canActivate: [AccessGuard] },
					{ path: 'cockpit', component: ProductCockpitComponent, canActivate: [AccessGuard] },
					{ path: 'canvas/:canvas', component: ProductCanvasDetailComponent, canActivate: [AccessGuard] },
					{
						path: 'canvas',
						pathMatch: 'full',
						component: ProductCanvasDetailComponent,
						canActivate: [AccessGuard],
					},
					{ path: 'backlog', component: BacklogComponent, canActivate: [AccessGuard] },
					{ path: 'sprint', component: SprintDetailComponent, canActivate: [AccessGuard] },
					{ path: 'sprint/:sprint', component: SprintDetailComponent, canActivate: [AccessGuard] },
					{ path: 'progress', component: ProgressDetailComponent, canActivate: [AccessGuard] },
					{ path: 'decision/:decision', component: BoardMeetingPreparationComponent, canActivate: [AccessGuard] },
				],
			},
		],
	},

	// 404 page
	{
		path: '**',
		component: NotfoundComponent,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
