<div class="modal-content">
	<div class="modal-header align-items-center">
		<p class="modal-title" *ngIf="!editable">{{ 'keyQuestion.singular' | translate }}</p>
		<p class="modal-title" *ngIf="editable && !keyQuestion.isNew()">{{ 'keyQuestion.edit' | translate }}</p>
		<p class="modal-title" *ngIf="editable && keyQuestion.isNew()">{{ 'keyQuestion.new' | translate }}</p>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>

		<button class="btn btn-link-white ml-auto" *ngIf="!editable" (click)="makeEditable()">{{ 'general.edit' | translate }}</button>
	</div>
	<div class="modal-body edit" *ngIf="editable">
		<div class="form-group">
			<label class="mb-0" for="selectStage">{{ 'stage.singular' | translate }}</label>
			<ng-select id="selectStage" name="stage" [(ngModel)]="keyQuestion.stage" [items]="stages">
				<ng-template ng-label-tmp let-item="item">
					<p>{{ portfolioService.getStageName(keyQuestion.portfolio, item) }}</p>
				</ng-template>
				<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
					{{ portfolioService.getStageName(keyQuestion.portfolio, item) }}
				</ng-template>
			</ng-select>
		</div>

		<label>
			{{ 'keyQuestion.singular' | translate }}
			<input type="text" class="form-control" [(ngModel)]="keyQuestion.question" name="question" ngbAutoFocus />
		</label>
	</div>
	<div class="modal-body" *ngIf="!editable">
		<h2 class="modal-header-title">{{ keyQuestion.question }}</h2>
		<p>{{ 'stage.singular' | translate }}: {{ portfolioService.getStageName(keyQuestion.portfolio, keyQuestion.stage) }}</p>
	</div>

	<div class="modal-footer">
		<button class="btn btn-link-danger mr-auto" *ngIf="!keyQuestion.isNew()" (click)="deleteKeyQuestion()">{{ 'general.remove' | translate }}</button>

		<button class="btn btn-primary ml-auto" *ngIf="editable && keyQuestion.isNew()" (click)="createKeyQuestion()" [disabled]="!keyQuestion.question || !keyQuestion.stage">
			{{ 'keyQuestion.create' | translate }}
		</button>
		<button class="btn btn-primary ml-auto" *ngIf="editable && !keyQuestion.isNew()" (click)="saveEditable()">{{ 'general.save' | translate }}</button>
		<button class="btn btn-primary ml-auto" *ngIf="!editable" (click)="activeModal.close()">{{ 'general.close' | translate }}</button>
	</div>
</div>
