<div class="container login">
	<div class="row justify-content-center mt-5">
		<form id="Login" name="Login Platform" class="col-4">
			<div class="row mb-3">
				<div class="col-12">
					<h1 view-title>New Tenant</h1>
					<p>Please give concent for GroundControl, so users can use their Microsoft account to login.</p>
					<p class="mt-3">The issuer will be NEXT Amsterdam, the company running GroundControl.</p>

					<ngb-alert class="mt-3" *ngIf="errorMessage" type="danger" [dismissible]="false">{{ errorMessage }}</ngb-alert>
				</div>
			</div>
			<div class="row align-content-center" *ngIf="success == false">
				<div class="col-12">
					<button type="submit" class="btn btn-primary" (click)="onMicrosoft()"><i class="bi bi-windows mr-3"></i>Grant admin consent for NEXT Amsterdam</button>
				</div>
			</div>
			<div class="row align-content-center" *ngIf="success == true">
				<div class="col-12">
					<p>Admin consent successfully given.</p>
				</div>
			</div>
		</form>
	</div>
</div>
