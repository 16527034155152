<div class="row">
	<div *ngIf="!!canvas && canvases.length > 0" class="col-12 col-md product-content">
		<div class="row align-items-center">
			<div class="col-12 col-md-5">
				<div class="sprint-number" ngbDropdown #sprintDrop="ngbDropdown" display="dynamic" placement="bottom-left">
					<span id="headerDropdown" ngbDropdownToggle class="hand">
						<h4>{{ canvas.name }}</h4>
						<i class="bi bi-caret-down-fill"></i>
					</span>
					<div ngbDropdownMenu aria-labelledby="headerDropdown">
						<a
							[routerLink]="['/portfolio', product.portfolio.id, 'startup', product.id, 'canvas', c.id]"
							ngbDropdownItem
							[ngClass]="{ 'active-canvas': canvas.id === c.id }"
							*ngFor="let c of canvases"
							>{{ c.name }}</a
						>
						<hr />
						<div class="hand" (click)="createCanvas()" ngbDropdownItem>{{ 'canvas.new' | translate }}</div>
					</div>
				</div>
			</div>
			<div class="col-10 col-md-5 text-center">
				<p class="header-info canvas-versions" *ngIf="!!activeVersion">
					{{ 'canvasDetail.version' | translate }}:
					<button class="btn btn-link btn-sm" (click)="showPreviousVersion()" [disabled]="isOldest()">
						<i class="bi bi-caret-left"></i>
					</button>
					{{ activeVersion | date }}
					<button class="btn btn-link btn-sm" (click)="showNextVersion()" [disabled]="isNewest()">
						<i class="bi bi-caret-right"></i>
					</button>
				</p>
			</div>
			<div class="col-2 text-right">
				<button (click)="openSettings()" class="btn btn-outline-primary bi bi-gear"></button>
			</div>
		</div>

		<!-- <div class="row"> -->
		<div class="main-pane" *ngIf="!!canvas">
			<app-canvas *ngIf="canvas.type !== 'pre-validation'" [canvas]="canvas" [canvasVersion]="activeVersion" [allStickies]="stickies"></app-canvas>
			<app-pre-validation-canvas *ngIf="canvas.type === 'pre-validation'" [canvas]="canvas" [canvasVersion]="activeVersion" [allStickies]="stickies"> </app-pre-validation-canvas>
		</div>
		<!-- </div> -->
	</div>

	<div *ngIf="canvases && canvases.length <= 0" class="col-12 col-md product-content">
		<div class="row justify-content-center align-items-center no-canvas-container">
			<div class="col-12 col-md-6 text-center">
				<img src="/assets/img/canvas/placeholder.png" class="img-fluid mb-3" />
				<h4>{{ 'canvas.none' | translate }}</h4>
				<p class="mb-3">{{ 'canvas.createFirst' | translate }}</p>
				<button class="btn btn-primary" (click)="createCanvas()">{{ 'canvas.create' | translate }}</button>
			</div>
		</div>
	</div>

	<!-- side bar -->
	<!-- [ngClass]="{ collapsed: this.menuCollapsed }" -->
	<div *ngIf="canvas" class="col-12 col-md product-side-bar right risk-scores">
		<app-product-loop></app-product-loop>

		<h5 class="mt-5">Risky Assumptions</h5>
		<p class="mt-3 mb-1 most-risky" *ngIf="mostRiskyStickies.length">{{ 'sticky.risk.most-risky' | translate }}</p>
		<app-canvas-sticky *ngFor="let sticky of mostRiskyStickies" [sticky]="sticky" [canvas]="sticky.canvas" [canOpen]="true"></app-canvas-sticky>

		<p class="mt-3 mb-1 risky" *ngIf="riskyStickies.length">{{ 'sticky.risk.risky' | translate }}</p>
		<app-canvas-sticky *ngFor="let sticky of riskyStickies" [sticky]="sticky" [canvas]="sticky.canvas" [canOpen]="true"></app-canvas-sticky>

		<p class="mt-3 mb-1 confirm" *ngIf="confirmStickies.length">{{ 'sticky.risk.confirm' | translate }}</p>
		<app-canvas-sticky *ngFor="let sticky of confirmStickies" [sticky]="sticky" [canvas]="sticky.canvas" [canOpen]="true"></app-canvas-sticky>
	</div>
</div>
