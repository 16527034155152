import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { StripeService } from '../stripe.service';
import { environment } from '../../../environments/environment';
import { Cloud } from 'parse';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-card-details-modal',
	templateUrl: './card-details-modal.component.html',
	styleUrls: ['./card-details-modal.component.scss'],
})
export class CardDetailsModalComponent implements AfterViewInit, OnDestroy {
	public isEditing: boolean;
	private stripe;
	public loading = false;
	public cardName: string;

	card: any;
	cardHandler = this.onChange.bind(this);
	error: string;
	@ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;

	constructor(private stripeService: StripeService, public activeModal: NgbActiveModal) {}

	async ngAfterViewInit() {
		this.stripe = await this.stripeService.setPublishableKey(environment.stripe.publishableKey);

		// Create Stripe elements integration
		const elements = this.stripe.elements();
		const style = {
			base: {
				color: '#202020',
				fontFamily: "'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif",
				fontSmoothing: 'antialiased',
				fontSize: '1rem',
				'::placeholder': {
					color: '#8e8e93',
				},
			},
			invalid: {
				color: '#da4d2b',
				iconColor: '#da4d2b',
			},
		};

		this.card = elements.create('card', {
			style: style,
			hidePostalCode: true,
		});
		this.card.mount(this.cardInfo.nativeElement);
		this.card.addEventListener('change', this.cardHandler);
	}

	ngOnDestroy() {
		this.card.removeEventListener('change', this.cardHandler);
		this.card.destroy();
	}

	onChange({ error }) {
		if (error) {
			this.error = error.message;
		} else {
			this.error = null;
		}
	}

	async saveCard() {
		this.loading = true;
		this.error = null;

		const billingDetails = await Cloud.run('getBillingDetails');

		try {
			const paymentMethodResult = await this.stripe.createPaymentMethod({
				type: 'card',
				card: this.card,

				billing_details: {
					address: {
						city: billingDetails.city,
						country: billingDetails.country,
						line1: billingDetails.address1,
						line2: billingDetails.address2,
						postal_code: billingDetails.postalCode,
						state: null,
					},
					email: billingDetails.email,
					name: this.cardName,
					phone: null,
				},
			});

			if (paymentMethodResult.error) {
				throw paymentMethodResult.error;
			}

			const updateResult = await Cloud.run('editCard', {
				stripeCustomerPaymentMethodId: paymentMethodResult.paymentMethod.id,
			});

			if (updateResult.error) {
				throw paymentMethodResult.error;
			}

			// If customers needs to authenticate card payment
			if (updateResult.status === 'requires_action') {
				const confirmResult = await this.stripe.confirmCardPayment(updateResult.client_secret, {
					payment_method: paymentMethodResult.paymentMethod.id,
				});

				if (confirmResult.error) {
					throw confirmResult.error;
				}
			}

			this.loading = false;
			this.activeModal.close(true);
			// this.onUpdate.emit('update');
		} catch (err) {
			this.loading = false;
			console.error(err);
			this.error = err.message;
		}
	}
}
