<div class="container page">
	<div class="row mb-5">
		<div class="col-12">
			<h1>{{ 'learning.plural' | translate }}</h1>
		</div>
	</div>
	<div class="row mb-2">
		<div class="col-12 input-group">
			<input
				type="text"
				class="form-control"
				[(ngModel)]="searchTerms"
				[placeholder]="'general.search' | translate"
				aria-label="search"
				aria-describedby="button-search"
				(keydown.enter)="queryLearnings()"
			/>
			<div class="input-group-append">
				<button class="btn btn-outline-primary" type="button" id="button-search" (click)="queryLearnings()">
					<i class="bi bi-search"></i>
				</button>
			</div>
		</div>
	</div>
	<div class="row mb-5">
		<div class="col-12 d-flex flex-row align-items-center justify-content-left">
			<div class="mr-4" ngbDropdown display="dynamic">
				<p class="header" ngbDropdownToggle id="time-filter" role="button">{{ 'show.' + timeFilter | translate }}</p>
				<div ngbDropdownMenu aria-labelledby="time-filter" class="dropdown-menu">
					<div class="form-group mb-1 pl-3 pr-4 mt-1">
						<div *ngFor="let option of timeFilterOptions" class="form-check pt-1 pb-1">
							<input type="radio" class="form-check-input" [name]="option" [id]="option" [(ngModel)]="timeFilter" [value]="option" (change)="queryLearnings($event)" />
							<label [for]="option" class="form-check-label">{{ 'show.' + option | translate }}</label>
						</div>
					</div>
				</div>
			</div>
			<div class="mr-4" ngbDropdown display="dynamic">
				<p class="header" ngbDropdownToggle id="segment-filter" role="button">{{ 'segment.singular' | translate }}</p>
				<div ngbDropdownMenu aria-labelledby="segment-filter" class="dropdown-menu p-3">
					<label class="mb-1">{{ 'segment.select' | translate }}</label>
					<div class="canvas-container mb-3" *ngIf="canvas">
						<app-canvas-mini
							[type]="canvas.type"
							[showSegmentNames]="true"
							[selectedSegment]="selectedSegment"
							[canSelectSegment]="true"
							[exampleStickies]="[]"
							[canvas]="canvas"
							(selectCanvas)="onSelectSegment($event)"
							[portfolio]="portfolio"
						>
						</app-canvas-mini>
					</div>
					<!-- <p *ngIf="canvas?.type === 'next-canvas'" class="hand link" (click)="toggleCanvasType()">
						{{ 'segment.selectPreValidation' | translate }}
						<i class="bi bi-caret-right"></i>
					</p>
					<p *ngIf="canvas?.type === 'pre-validation'" class="hand link" (click)="toggleCanvasType()">
						{{ 'segment.selectNext' | translate }}
						<i class="bi bi-caret-right"></i>
					</p> -->
				</div>
			</div>
			<div class="mr-4" ngbDropdown display="dynamic">
				<p class="header" ngbDropdownToggle id="question-filter" role="button">{{ 'keyQuestion.singular' | translate }}</p>
				<div ngbDropdownMenu aria-labelledby="question-filter" class="dropdown-menu p-3">
					<div class="form-group mb-1 pl-3 pr-4 mt-1 question-container">
						<div class="form-check pt-1 pb-1">
							<input type="radio" class="form-check-input" name="all questions" id="allQuestions" [(ngModel)]="keyQuestion" [value]="undefined" (change)="queryLearnings($event)" />
							<label for="allQuestions" class="form-check-label">{{ 'keyQuestion.showAll' | translate }}</label>
						</div>
						<div *ngFor="let question of keyQuestionOptions" class="form-check pt-1 pb-1">
							<input type="radio" class="form-check-input" [name]="question" [id]="question.id" [(ngModel)]="keyQuestion" [value]="question" (change)="queryLearnings($event)" />
							<label *ngIf="question.default" [for]="question.id" class="form-check-label">{{ 'stageGateQuestions.' + question.stage + '.' + question.question | translate }}</label>
							<label *ngIf="!question.default" [for]="question.id" class="form-check-label">{{ question.question }}</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngFor="let month of learningsPerMonth" class="row mb-5">
		<p class="col-12 header mb-2">{{ month.date | date: 'LLLL y' }}</p>
		<div *ngFor="let learning of month.learnings" class="col-4 learning-container mb-3">
			<app-learning-card [learning]="learning" [showExperimentTag]="true" [showProductTag]="true"></app-learning-card>
		</div>
	</div>
</div>
