<div class="decision-container">
	<div *ngFor="let decision of decisions" class="d-flex flex-row justify-content-left align-items-center decision-row hand" (click)="openDecision(decision)">
		<div *ngIf="decision.decisionMade === DecisionType.MOVEFWD" class="circle icon forward"></div>
		<div *ngIf="decision.decisionMade === DecisionType.REMAIN" class="circle icon remain"></div>
		<div *ngIf="decision.decisionMade === DecisionType.START" class="circle icon start"></div>
		<div *ngIf="decision.decisionMade === DecisionType.STOP" class="circle icon stop"></div>
		<div *ngIf="decision.decisionMade === DecisionType.MOVEBACK" class="circle icon back"></div>

		<div class="decision-content">
			<p class="decision-title">
				<span class="badge badge-secondary mr-2" *ngIf="decision.status === DecisionStatus.PREPARED">{{ 'general.prepared' | translate }}</span>
				{{ portfolioService.getDecisionLabel(decision.product.portfolio, decision.decisionMade, decision.fromStage, decision.toStage) }}
			</p>
			<div class="d-flex flex-row justify-content-left decision-info">
				<p><i class="bi bi-calendar3"></i>{{ decision.decisionDate | date }}</p>
				<p *ngIf="!!decision.nextMeetingDate"><i class="bi bi-calendar3"></i>{{ decision.nextMeetingDate | date }}</p>
				<p *ngIf="!!decision.moneyGranted"><i class="bi bi-piggy-bank"></i>{{ decision.moneyGranted | currency: decision.product.portfolio.currency:'symbol':'3.0' }}</p>
			</div>
		</div>
	</div>
</div>
