<div class="container">
	<div class="row">
		<div *ngIf="isReady" class="col-12 col-md product-content">
			<div class="row align-items-center">
				<div class="col-12 col-md-2">
					<h4>{{ 'your-work.singular' | translate }}</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-md-2">
					<p class="header">{{ 'task.plural' | translate }}</p>
				</div>
				<div class="col-md-10">
					<div class="row">
						<div class="col-md-4 px-2">
							<div class="task-box h100">
								<p>{{ 'taskStage.toDo' | translate | uppercase }}</p>
								<div
									*ngIf="toDos"
									class="task-container"
									cdkDropList
									id="toDo"
									[cdkDropListData]="[toDos, TaskStatus.TODO]"
									[cdkDropListConnectedTo]="['toDo', 'inProgress', 'done']"
									(cdkDropListDropped)="dropTask($event)"
								>
									<div *ngFor="let task of toDos" class="task" cdkDrag [cdkDragData]="task">
										<app-task-card [task]="task" [badge]="true" (click)="openTask(task)"></app-task-card>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 px-2">
							<div class="task-box h100">
								<p>{{ 'taskStage.inProgress' | translate | uppercase }}</p>
								<div
									*ngIf="inProgress"
									class="task-container"
									cdkDropList
									id="inProgress"
									[cdkDropListData]="[inProgress, TaskStatus.DOING]"
									[cdkDropListConnectedTo]="['toDo', 'inProgress', 'done']"
									(cdkDropListDropped)="dropTask($event)"
								>
									<div *ngFor="let task of inProgress" class="task" cdkDrag [cdkDragData]="task">
										<app-task-card [task]="task" [badge]="true" (click)="openTask(task)"></app-task-card>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 px-2">
							<div class="task-box h100">
								<p>{{ 'taskStage.done' | translate | uppercase }}</p>
								<div
									*ngIf="done"
									class="task-container"
									cdkDropList
									id="done"
									[cdkDropListData]="[done, TaskStatus.DONE]"
									[cdkDropListConnectedTo]="['toDo', 'inProgress', 'done']"
									(cdkDropListDropped)="dropTask($event)"
								>
									<div *ngFor="let task of done" class="task" cdkDrag [cdkDragData]="task">
										<app-task-card [task]="task" [badge]="true" (click)="openTask(task)"></app-task-card>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
