import { Component, OnInit } from '@angular/core';
import { NextTitleService } from 'app/next-title.service';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
	public formAction: string;
	public username: string;
	public token: string;
	public errorMessage: string;

	constructor(
		private titleService: NextTitleService
	) { }

	ngOnInit(): void {
		this.titleService.setTitle('Reset your password');

		const urlParams = {};

		let pair; // Really a match. Index 0 is the full match; 1 & 2 are the key & val.
		const tokenize = /([^&=]+)=?([^&]*)/g;

		// decodeURIComponents escapes everything but will leave +s that should be ' '
		const re_space = function (s) { return decodeURIComponent(s.replace(/\+/g, " ")); };

		// Substring to cut off the leading '?'
		const querystring = window.location.search.substring(1);

		while (pair = tokenize.exec(querystring)) {
			urlParams[re_space(pair[1])] = re_space(pair[2]);
		}

		const id = urlParams['id'];

		this.formAction = environment.parse.serverURL + 'apps/' + id + '/request_password_reset';
		this.username = urlParams['username'];


		this.token = urlParams['token'];
		this.errorMessage = urlParams['error'];

	}
}
