import { Component, Input, OnInit } from '@angular/core';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Canvas } from '../../../../shared/models/canvas';
import { Sticky } from '../../../../shared/models/sticky';

@Component({
	selector: 'app-canvas',
	templateUrl: './canvas.component.html',
	styleUrls: ['./canvas.component.scss'],
})
export class CanvasComponent implements OnInit {
	@Input() canvas: Canvas;
	@Input() allStickies: Sticky[];
	@Input() canvasVersion: Date;
	public stages: string[];
	public segments: object;
	public allIds: string[];

	constructor(public portfolioService: PortfolioService) {}

	ngOnInit(): void {
		this.stages = this.portfolioService.getMainStages(this.canvas.product.portfolio);
		this.segments = this.portfolioService.segmentPerStage;

		this.allIds = [];
		for (let stage of this.stages) {
			this.allIds = this.allIds.concat(this.segments[stage]);
		}
	}
}
