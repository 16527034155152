import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-unsaved-modal',
	templateUrl: './unsaved-modal.component.html',
	styleUrls: ['./unsaved-modal.component.scss'],
})
export class UnsavedModalComponent {
	constructor(public activeModal: NgbActiveModal) {}
}
