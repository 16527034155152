import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { RemoveModalComponent } from 'app/validating-modals/remove-modal/remove-modal.component';
import { ContentTool } from '../../../../shared/models/content-tool';
import { Product } from '../../../../shared/models/product';
import { QuillConfigService } from 'app/quill.service';
import { NextConfigService } from 'app/next-config.service';
import { UnsavedModalComponent } from 'app/validating-modals/unsaved-modal/unsaved-modal.component';
import { PortfolioService } from 'app/portfolio/portfolio.service';

@Component({
	selector: 'app-content-tool-modal',
	templateUrl: './content-tool-modal.component.html',
	styleUrls: ['./content-tool-modal.component.scss'],
})
export class ContentToolModalComponent implements OnInit {
	@Input() contentTools: object;
	@Input() editable?: boolean;
	public stages;

	public leftoverLanguages: string[] = [];
	public contentTool: ContentTool;
	public currentLanguages: string[];

	public getStage() {
		return Product.Stage;
	}

	constructor(
		public activeModal: NgbActiveModal,
		public translate: TranslateService,
		public modalService: NgbModal,
		public quillConfigService: QuillConfigService,
		public configService: NextConfigService,
		private portfolioService: PortfolioService
	) {}

	async ngOnInit() {
		this.stages = this.portfolioService.getDefaultStagesWithKickbox();

		if (this.contentTools[this.translate.currentLang]) {
			this.contentTool = this.contentTools[this.translate.currentLang];
		} else if (this.contentTools[this.translate.defaultLang]) {
			this.contentTool = this.contentTools[this.translate.defaultLang];
		} else if (Object.keys(this.contentTools).length > 0) {
			this.contentTool = this.contentTools[Object.keys(this.contentTools)[0]];
		}

		this.getLeftoverLanguages();
	}

	getLeftoverLanguages() {
		this.leftoverLanguages = [...this.configService.languages];
		this.currentLanguages = Object.keys(this.contentTools);
		this.currentLanguages.sort();
		for (let language of this.currentLanguages) {
			let index = this.leftoverLanguages.findIndex((lan) => language == lan);
			if (index >= 0) {
				this.leftoverLanguages.splice(index, 1);
			}
		}
	}

	async addTranslation(language) {
		let newContTool = new ContentTool();
		newContTool.language = language;
		newContTool.slug = this.contentTool.slug;
		newContTool.stages = this.contentTool.stages;
		this.contentTools[language] = newContTool;
		this.switchTranslation(newContTool);
	}

	async switchTranslation(contTool) {
		if (contTool.language === this.contentTool.language) {
			return;
		}

		if (this.contentTool.dirty() && this.contentTool.language) {
			// current contentTool was changed
			const modal = this.modalService.open(UnsavedModalComponent, {
				size: 'sm',
				centered: true,
			});
			const result = await modal.result;

			if (!result) {
				// wants to cancel and continue working on the changes
				if (contTool.isNew()) {
					// delete newly created language
					delete this.contentTools[contTool.language];
				}
				return;
			}

			// wants to revert changes
			if (this.contentTool.isNew()) {
				// delete new translation from contentTools
				delete this.contentTools[this.contentTool.language];
			} else {
				// revert changes
				this.contentTool.revert();
			}
		} else {
			// current tool was not changed
			if (this.contentTool.isNew()) {
				// delete new translation from contentTools
				delete this.contentTools[this.contentTool.language];
			}
		}

		// switch to translation
		this.contentTool = contTool;
		this.getLeftoverLanguages();
	}

	makeEditable() {
		this.editable = true;
	}

	async deleteContentTool() {
		const modalRef = this.modalService.open(RemoveModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.element = 'tool';

		let removed = await modalRef.result;

		// if close button was clicked
		if (!removed) {
			return;
		}

		// remove all translations from tool
		const langs = Object.keys(this.contentTools);
		for (let lang of langs) {
			this.contentTools[lang].archived = true;
			this.contentTools[lang].save();
		}
		this.activeModal.close();
	}

	async deleteTranslation(language) {
		const modalRef = this.modalService.open(RemoveModalComponent, {
			size: 'sm',
			centered: true,
		});

		modalRef.componentInstance.element = 'translate';

		let removed = await modalRef.result;

		// if removing was cancelled
		if (!removed) {
			return;
		}

		if (!this.contentTools[language].isNew()) {
			this.contentTools[language].archived = true;
			this.contentTools[language].save();
		}

		const langs = Object.keys(this.contentTools);
		if (langs.length == 1 && langs[0] == language) {
			this.contentTool = new ContentTool();
			this.contentTools = {};
		} else {
			delete this.contentTools[language];
			this.contentTool = this.contentTools[Object.keys(this.contentTools)[0]];
		}

		this.getLeftoverLanguages();
	}

	onSave() {
		this.contentTool.save();
		this.editable = false;
	}
}
