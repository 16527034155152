<div class="row settings-content" *ngIf="portfolio">
	<h5 class="col-12">{{ 'general.singular' | translate }}</h5>
	<div class="form-group col-12 col-md-6">
		<label>{{ 'portfolio.name' | translate }} <input type="text" class="form-control" name="name" [(ngModel)]="portfolio.name" /> </label>
	</div>
	<div class="col-12">
		<h5>{{ 'accounting.innovation' | translate }}</h5>
	</div>
	<div class="form-group col-12 col-md-6">
		<label for="experimentLength">{{ 'portfolioModalSettings.general.innovaccounting.text' | translate }}</label>
		<select id="experimentLength" class="form-control" [(ngModel)]="portfolio.experimentLength">
			<option disabled>{{ 'general.choose' | translate }}...</option>
			<option [ngValue]="1">1 {{ 'portfolioModalSettings.general.innovaccounting.week' | translate }}</option>
			<option [ngValue]="2">2 {{ 'portfolioModalSettings.general.innovaccounting.weeks' | translate }}</option>
			<option [ngValue]="3">3 {{ 'portfolioModalSettings.general.innovaccounting.weeks' | translate }}</option>
			<option [ngValue]="4">4 {{ 'portfolioModalSettings.general.innovaccounting.weeks' | translate }}</option>
			<option [ngValue]="5">5 {{ 'portfolioModalSettings.general.innovaccounting.weeks' | translate }}</option>
			<option [ngValue]="6">6 {{ 'portfolioModalSettings.general.innovaccounting.weeks' | translate }}</option>
			<option [ngValue]="7">7 {{ 'portfolioModalSettings.general.innovaccounting.weeks' | translate }}</option>
			<option [ngValue]="8">8 {{ 'portfolioModalSettings.general.innovaccounting.weeks' | translate }}</option>
		</select>
	</div>

	<div class="col-12">
		<h5>{{ 'currency.singular' | translate }}</h5>
	</div>
	<div class="form-group col-12 col-md-6">
		<div class="form-group">
			<label class="mb-0" for="selectCurrency">{{ 'currency.choose' | translate }}</label>
			<ng-select
				id="selectCurrency"
				name="currency"
				[(ngModel)]="portfolio.currency"
				[items]="currencies"
				bindValue="code"
				[searchFn]="searchCurrency"
				clearAllText="clear"
			>
				<ng-template ng-label-tmp let-item="item">
					<p>{{ item.name }}</p>
				</ng-template>
				<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
					{{ item.name }}
				</ng-template>
			</ng-select>
		</div>
	</div>

	<div class="col-12 mt-3">
		<ngb-alert type="warning" [dismissible]="false" *ngIf="errorMessage">
			{{ errorMessage }}
		</ngb-alert>
		<ngb-alert type="success" [dismissible]="false" *ngIf="resultMessage">
			{{ resultMessage }}
		</ngb-alert>
	</div>
	<div class="col-12 col-md-6">
		<button type="button" class="btn btn-primary" (click)="onSave()">{{ 'portfolio.save' | translate }}</button>
	</div>
	<div class="col-12 col-md-6 text-right">
		<button type="button" class="btn btn-outline-secondary mr-auto" (click)="downloadData()">
			{{ 'general.export' | translate }}
		</button>
	</div>
</div>
