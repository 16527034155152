import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-velocity-card',
	templateUrl: './velocity-card.component.html',
	styleUrls: ['./velocity-card.component.scss'],
})
export class VelocityCardComponent {
	@Input() title: string;
	@Input() fraction: number;
	@Input() clickable: boolean;
	@Output() cardClicked?: EventEmitter<any> = new EventEmitter<any>();

	constructor() {}

	openChartModal() {
		this.cardClicked?.emit(true);
	}
}
