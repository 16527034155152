<div class="card mb-2">
	<p *ngIf="fileMeta?.file">
		<i *ngIf="fileType === 'docx'" class="file-icon bi bi-file-earmark-word"></i>
		<i *ngIf="fileType === 'pdf'" class="file-icon bi bi-file-earmark-pdf"></i>
		<i *ngIf="fileType === 'png' || fileType === 'jpeg' || fileType === 'jpg'" class="file-icon bi bi-file-earmark-image"></i>
		<i *ngIf="fileType === 'mp4'" class="file-icon bi bi-file-earmark-play"></i>
		<i
			*ngIf="
				fileType !== 'docx' &&
				fileType !== 'pdf' &&
				fileType !== 'png' &&
				fileType !== 'jpeg' &&
				fileType !== 'jpg' &&
				fileType !== 'mp4'
			"
			class="file-icon bi bi-paperclip"
		></i>
		<a [href]="fileMeta.file.url()" target="_blank">{{ fileMeta.name }}</a>
		<i class="bi bi-trash3" (click)="removeFile()"></i>
	</p>
</div>
