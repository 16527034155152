import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-product-loop',
	templateUrl: './product-loop.component.html',
	styleUrls: ['./product-loop.component.scss']
})
export class ProductLoopComponent implements OnInit {
	public activeRoute: string;
	public steps: string[] = ['progress', 'canvas', 'backlog', 'sprint'];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
	) { }

	ngOnInit(): void {
		this.route.url.subscribe((params) => {
			this.activeRoute = params[0].path;
		});
	}

	public getNextStep() {
		const index = this.steps.indexOf(this.activeRoute);
		const nextIndex = (index + 1) % this.steps.length;
		const nextStep = this.steps[nextIndex] || this.steps[0]

		return '../' + nextStep;

		// this.router.navigate(['../' + nextStep])
	}

}
