import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InsightModalComponent } from 'app/insight/insight-modal/insight-modal.component';
import { LearningModalComponent } from 'app/learning/learning-modal/learning-modal.component';
import { LogService } from 'app/log/log.service';
import { ProductService } from 'app/product/product.service';
import { RemoveModalComponent } from 'app/validating-modals/remove-modal/remove-modal.component';
import * as Parse from 'parse';
import { Experiment } from '../../../../shared/models/experiment';
import { Insight } from '../../../../shared/models/insight';
import { Learning } from '../../../../shared/models/learning';
import { Product } from '../../../../shared/models/product';
import { ExperimentModalAddComponent } from '../experiment-modal-add/experiment-modal-add.component';
import { TaskService } from 'app/task/task.service';
import { TranslateService } from '@ngx-translate/core';
import { slugifyFile } from '../../../../shared/slugify';
import { Task } from '../../../../shared/models/task';
import { Query } from 'parse';
import { PortfolioService } from 'app/portfolio/portfolio.service';

@Component({
	selector: 'app-experiment-modal',
	templateUrl: './experiment-modal.component.html',
	styleUrls: ['./experiment-modal.component.scss'],
})
export class ExperimentModalComponent implements OnInit, OnDestroy {
	@Input() experiment: Experiment;
	@Input() product: Product;

	public experimentResult: string;
	public files: File[];
	public tasks: Task[];
	public showProgress = false;
	public progressUpload = 0;
	public fileErrorMessage: string;
	public resultMessage: string;
	public taskPercentage = 0;

	private experimentSubscription: Parse.LiveQuerySubscription;
	private taskSubscription: Parse.LiveQuerySubscription;

	get ExperimentStatus() {
		return Experiment.Status;
	}
	get TaskStatus() {
		return Task.Status;
	}

	constructor(
		public productService: ProductService,
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
		public logService: LogService,
		public taskService: TaskService,
		public portfolioService: PortfolioService,
		private translate: TranslateService
	) {}

	async ngOnInit() {
		this.experiment.fetchWithInclude(['learnings', 'insights', 'contentExperiment', 'learnings.keyQuestion', 'attachments']);

		// update experiment
		const experimentQuery = new Parse.Query(Experiment);
		experimentQuery.equalTo('objectId', this.experiment.id);
		experimentQuery.include(['learnings', 'insights', 'learnings.keyQuestion']);
		this.experimentSubscription = await experimentQuery.subscribe();
		this.experimentSubscription.on('update', (experiment: Experiment) => {
			this.experiment = experiment;
			this.initExperiment();
		});

		this.initExperiment();

		// fetch tasks
		const taskQuery = new Query(Task);
		taskQuery.equalTo('experiment', this.experiment);
		taskQuery.equalTo('archived', false);
		this.tasks = await taskQuery.find();
		this.taskSubscription = await taskQuery.subscribe();

		this.taskSubscription.on('update', (task: Task) => {
			this.calculatePercentage();
		});
		this.taskSubscription.on('enter', (task: Task) => {
			this.tasks.push(task);
			this.calculatePercentage();
		});

		this.calculatePercentage();
	}

	ngOnDestroy() {
		if (this.experimentSubscription) {
			this.experimentSubscription.unsubscribe();
		}

		if (this.taskSubscription) {
			this.taskSubscription.unsubscribe();
		}
	}

	calculatePercentage() {
		let doneCount = 0;

		for (let task of this.tasks) {
			if (task.status === Task.Status.DONE) {
				doneCount++;
			}
		}

		this.taskPercentage = Math.round((doneCount / this.tasks.length) * 100);
	}

	initExperiment() {
		// Backwards compatibility
		if (!this.experiment.learninggoal && this.experiment.hypothesis) {
			this.experiment.learninggoal = this.experiment.hypothesis;
		}

		if (!this.experiment.type) {
			if (this.experiment.successcriteria) {
				this.experiment.type = 'confirm';
			} else {
				this.experiment.type = 'learn';
			}
		}

		// setup experiment result
		this.experimentResult = this.experiment.getResult();
	}

	async editExperiment() {
		this.activeModal.close();

		const modalRef = this.modalService.open(ExperimentModalAddComponent, {
			size: 'md',
		});
		modalRef.componentInstance.experiment = this.experiment;
		modalRef.componentInstance.canvas = this.experiment.canvas;
		modalRef.componentInstance.product = this.product;
	}

	async submitEdit() {
		// setup experiment result
		this.experiment.setResult(this.experimentResult);

		await this.experiment.save();

		this.resultMessage = 'Experiment successfully saved';
	}

	setStatus(status) {
		if (status === 'started' && !this.experiment.startAt) {
			this.experiment.startAt = new Date();
		} else if (status === 'analyzed') {
			this.experiment.endAt = new Date();
			this.experiment.endSprint = this.product.activeSprint;

			if (this.experiment.type === 'confirm') {
				if (this.experiment.validated === true) {
					this.experiment.sticky.validated = true;
					this.experiment.sticky.save();
				} else if (this.experiment.validated === false) {
					this.experiment.sticky.validated = false;
					this.experiment.sticky.save();
				}
			}
		}

		this.experiment.status = status;

		this.submitEdit();
		this.activeModal.close(true);
	}

	async uploadFiles(files) {
		if (!this.experiment.attachments) {
			this.experiment.attachments = [];
		}
		for (let file of files) {
			this.experiment.addUnique('attachments', file);
		}
		this.submitEdit();
	}

	onSelectSegment(evt) {
		// Set canvas segment on Experiment, evt.segment comes from mini next/pre-validation
		this.experiment.segment = evt.segment;

		this.submitEdit();
	}

	async addLearning() {
		let learning = new Learning();
		learning.product = this.product;
		learning.experiment = this.experiment;
		learning.segment = this.experiment.segment;
		const modalRef = this.modalService.open(LearningModalComponent, {
			size: 'md',
		});

		modalRef.componentInstance.learning = learning;
		modalRef.componentInstance.editable = true;

		let newLearning = await modalRef.result;

		if (newLearning) {
			this.submitEdit();
		}
	}

	async addInsight() {
		let insight = new Insight();
		insight.product = this.product;
		insight.experiment = this.experiment;
		const modalRef = this.modalService.open(InsightModalComponent, {
			size: 'md',
		});

		modalRef.componentInstance.insight = insight;
		modalRef.componentInstance.editable = true;

		let newInsight = await modalRef.result;

		if (newInsight) {
			this.submitEdit();
		}
	}

	getSegmentName() {
		return this.portfolioService.getSegmentName(this.experiment.product.portfolio, this.experiment.segment);
	}

	async deleteExperiment() {
		const modalRef = this.modalService.open(RemoveModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.element = 'experiment';

		let removed = await modalRef.result;

		// if close button was clicked
		if (!removed) {
			return;
		}

		this.experiment.archived = true;
		await this.experiment.save();

		this.activeModal.close();
	}

	async createNewTask() {
		const task = new Task();
		task.status = Task.Status.TODO;
		task.experiment = this.experiment;

		const sprint = this.product.activeSprint || this.product.backlog;

		const result = await this.taskService.openModal(task, sprint, this.product);

		if (result) {
			this.tasks.push(task);
		}
	}

	openTask(task: Task) {
		this.taskService.openModal(task, this.product.activeSprint, this.product);
	}

	changeTaskStatus(event, task: Task) {
		if (event.target.checked) {
			task.status = Task.Status.DONE;
		} else {
			task.status = Task.Status.TODO;
		}

		task.save();

		this.calculatePercentage();
	}

	async removeFile(file) {
		// file is removed, remove filemeta from attachments
		this.experiment.remove('attachments', file);
		this.submitEdit();
	}
}
