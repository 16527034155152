import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../../../shared/models/user';

@Component({
	selector: 'app-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
	@Input() avatarSize: string;
	@Input() user: User;
	@Input() isPreview?: boolean;
	public initials = '';

	constructor() { }

	ngOnInit(): void {
		if (this.user.firstName) {
			this.initials += this.user.firstName[0].toUpperCase();
		}

		if (this.user.lastName) {
			this.initials += this.user.lastName[0].toUpperCase();
		}
	}

}
