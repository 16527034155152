<div class="container login">
	<div class="row justify-content-center mt-5">
		<form class="col-12 col-md-4">
			<div class="row mb-3">
				<div class="col-12">
					<h1 view-title >{{ 'resetPasswordThankyou.title' | translate }}</h1>
					<p class="mb-3">{{ 'resetPasswordThankyou.description' | translate }}</p>
					<p class="text-center"><a class="btn btn-primary" [routerLink]="['/login']">{{ 'login.title' | translate }}</a>
				</div>
			</div>
		</form>
	</div>
</div>
