<svg viewBox="0, 0, 100, 100">
   <circle
      class="progress-circle"
      stroke-width="4"
      [style.strokeDasharray]="circumference"
      [style.strokeDashoffset]="strokeDashOffset"
      fill="transparent"
      [style.stroke]="configService.colorPrimary[0]"
      r="48"
      cx="50"
      cy="50"
   />
</svg>