<div class="modal-content">
	<div class="modal-header align-items-center">
		<p class="modal-title" *ngIf="!editable">{{ 'lens.singular' | translate }}</p>
		<p class="modal-title" *ngIf="editable && !lens.isNew()">{{ 'lens.edit' | translate }}</p>
		<p class="modal-title" *ngIf="editable && lens.isNew()">{{ 'lens.new' | translate }}</p>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>

		<button class="btn btn-link-white ml-auto" *ngIf="!editable" (click)="makeEditable()">{{ 'general.edit' | translate }}</button>
	</div>
	<div class="modal-body edit" *ngIf="editable">
		<div class="form-group">
			<label class="mb-0"
				>{{ 'lens.name' | translate }}
				<input type="text" class="form-control" [(ngModel)]="lens.name" name="name" ngbAutoFocus />
			</label>
		</div>

		<div class="form-group">
			<label for="selectValue" class="mb-0">
				{{ 'lens.chooseUnit' | translate }}
			</label>
			<ng-select id="selectValue" name="value" [(ngModel)]="selectedOption" [items]="lensOptions" (change)="setValue()">
				<ng-template ng-label-tmp let-item="item">
					<p>{{ item }}</p>
				</ng-template>
				<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
					{{ item }}
				</ng-template>
			</ng-select>
			<label class="mb-0 mt-3" *ngIf="selectedOption === ('general.custom' | translate)">
				{{ 'lens.customUnit' | translate }}
				<input type="text" class="form-control" name="value" [(ngModel)]="lens.value" />
			</label>
		</div>

		<div class="form-group">
			<label>{{ 'lens.bucketValues' | translate }}</label>
			<div class="row mb-2 align-items-center">
				<label class="mb-0 col-12 col-md-1">XL</label>
				<div class="col-12 col-md-11">
					<div class="input-group">
						<div class="input-group-prepend">
							<div class="input-group-text"><=</div>
						</div>
						<input type="number" class="form-control" [(ngModel)]="lens.labels[0]" />
					</div>
				</div>
			</div>
			<div class="row mb-2 align-items-center">
				<label class="mb-0 col-12 col-md-1">L</label>
				<div class="col-12 col-md-11">
					<div class="input-group">
						<div class="input-group-prepend">
							<div class="input-group-text"><=</div>
						</div>
						<input type="number" class="form-control" [(ngModel)]="lens.labels[1]" />
					</div>
				</div>
			</div>
			<div class="row mb-2 align-items-center">
				<label class="mb-0 col-12 col-md-1">M</label>
				<div class="col-12 col-md-11">
					<div class="input-group">
						<div class="input-group-prepend">
							<div class="input-group-text"><=</div>
						</div>
						<input type="number" class="form-control" [(ngModel)]="lens.labels[2]" />
					</div>
				</div>
			</div>
			<div class="row mb-2 align-items-center">
				<label class="mb-0 col-12 col-md-1">S</label>
				<div class="col-12 col-md-11">
					<div class="input-group">
						<div class="input-group-prepend">
							<div class="input-group-text"><=</div>
						</div>
						<input type="number" class="form-control" [(ngModel)]="lens.labels[3]" />
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal-body" *ngIf="!editable">
		<h2 class="modal-header-title">{{ lens.name }}</h2>
		<p>
			{{ 'lens.value' | translate }}:
			<span *ngIf="lens.value === LensValues.CURRENCY">{{ lens.portfolio.currency | currencySymbol }}</span>
			<span *ngIf="lens.value !== LensValues.CURRENCY">{{ lens.value }}</span>
		</p>
		<p class="modal-body-header mt-4">{{ 'lens.bucketValues' | translate }}</p>
		<ng-container *ngIf="lens.value === LensValues.CURRENCY">
			<p>XL: {{ lens.labels[0] | currency: lens.portfolio.currency:'symbol':'.0' }}</p>
			<p>L: {{ lens.labels[1] | currency: lens.portfolio.currency:'symbol':'.0' }}</p>
			<p>M: {{ lens.labels[2] | currency: lens.portfolio.currency:'symbol':'.0' }}</p>
			<p>S: {{ lens.labels[3] | currency: lens.portfolio.currency:'symbol':'.0' }}</p>
		</ng-container>
		<ng-container *ngIf="lens.value !== LensValues.CURRENCY">
			<p>XL: {{ lens.labels[0] }} {{ lens.value }}</p>
			<p>L: {{ lens.labels[1] }} {{ lens.value }}</p>
			<p>M: {{ lens.labels[2] }} {{ lens.value }}</p>
			<p>S: {{ lens.labels[3] }} {{ lens.value }}</p>
		</ng-container>
	</div>

	<div class="modal-footer">
		<button class="btn btn-link-danger mr-auto" *ngIf="!lens.isNew()" (click)="deleteLens()">{{ 'general.remove' | translate }}</button>

		<button class="btn btn-primary ml-auto" *ngIf="editable && lens.isNew()" (click)="createLens()" [disabled]="!lens">
			{{ 'lens.create' | translate }}
		</button>
		<button class="btn btn-primary ml-auto" *ngIf="editable && !lens.isNew()" (click)="saveEditable()">
			{{ 'general.save' | translate }}
		</button>
		<button class="btn btn-primary ml-auto" *ngIf="!editable" (click)="activeModal.close()">{{ 'general.close' | translate }}</button>
	</div>
</div>
