<div class="modal-content">
	<div class="modal-header align-items-center">
		<p class="modal-title" *ngIf="!editable">{{ 'task.singular' | translate }}</p>
		<p class="modal-title" *ngIf="editable && task.isNew()">{{ 'task.new' | translate }}</p>
		<p class="modal-title" *ngIf="editable && !task.isNew()">{{ 'task.edit' | translate }}</p>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>

		<button class="btn btn-link-white ml-auto" *ngIf="!editable" (click)="makeEditable()">{{ 'general.edit' | translate }}</button>
	</div>
	<div class="modal-body edit" *ngIf="editable">
		<label
			>{{ 'title.singular' | translate }}
			<input type="text" class="form-control" [(ngModel)]="task.title" name="title" [placeholder]="'task.titlePlaceholder' | translate" ngbAutoFocus />
		</label>

		<div class="quil-wrapper">
			<label class="mb-0">{{ 'description.singular' | translate }}</label>
			<quill-editor name="mostRisky" [(ngModel)]="task.description" [styles]="{ height: '10rem' }"></quill-editor>
		</div>

		<label *ngIf="!!sprint">
			{{ 'experiment.singular' | translate }}
			<ng-select name="experiment" class="custom" [(ngModel)]="task.experiment" [searchable]="false" [items]="sprint.experiments" [placeholder]="'task.chooseExperiment' | translate">
				<ng-template ng-label-tmp let-item="item"> {{ item.number }}: {{ item.learninggoal || item.hypothesis }} </ng-template>
				<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
					<app-experiment-row [experiment]="item"></app-experiment-row>
				</ng-template>
			</ng-select>
		</label>
		<label>
			{{ 'assignee.singular' | translate }}
			<ng-select
				name="assignees"
				class="custom"
				[(ngModel)]="task.assignedUsers"
				[searchable]="false"
				[multiple]="true"
				[hideSelected]="true"
				[items]="assignableMembers"
				[placeholder]="'task.chooseAssignee' | translate"
			>
				<ng-template ng-label-tmp let-item="item" let-clear="clear">
					<span class="ng-value-label"> <app-avatar [user]="item" [avatarSize]="1.5"></app-avatar> {{ item.firstName }} {{ item.lastName }} </span>
					<span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
				</ng-template>
				<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
					<app-avatar [user]="item" [avatarSize]="1.5"></app-avatar>
					{{ item.firstName }} {{ item.lastName }}
				</ng-template>
			</ng-select>
			<p class="text-primary" (click)="assignMe()">{{ 'assignee.toMe' | translate }}</p>
		</label>
	</div>
	<div class="modal-body" *ngIf="!editable">
		<div class="container-fluid">
			<div class="row">
				<h2 class="col modal-header-title">{{ task.title }}</h2>
			</div>
			<div class="row" *ngIf="task.description">
				<div class="col">
					<h4 class="modal-body-header">{{ 'description.singular' | translate }}</h4>
					<p class="editor-data" [innerHtml]="task.description"></p>
				</div>
			</div>
			<div class="row mb-0" *ngIf="task.experiment">
				<div class="col-12">
					<h4 class="modal-body-header">{{ 'experiment.singular' | translate }}</h4>
				</div>
			</div>
			<div class="row align-items-center" *ngIf="task.experiment">
				<div class="col-12">
					<div class="hand" (click)="openExperiment(task.experiment)">
						<app-experiment-row [experiment]="task.experiment"></app-experiment-row>
					</div>
				</div>
			</div>
			<div class="row" *ngIf="task.assignedUsers">
				<div class="col-12">
					<h4 class="modal-body-header" *ngIf="task.assignedUsers.length === 1">{{ 'assignee.singular' | translate }}</h4>
					<h4 class="modal-body-header" *ngIf="task.assignedUsers.length > 1">{{ 'assignee.plural' | translate }}</h4>
				</div>
				<app-avatar-row class="col-12" [users]="task.assignedUsers" direction="left" [avatarSize]="1.5"></app-avatar-row>
			</div>
		</div>
	</div>

	<div class="modal-footer">
		<button class="btn btn-link-danger mr-auto" *ngIf="!task.isNew()" (click)="deleteTask()">{{ 'general.remove' | translate }}</button>

		<button class="btn btn-primary ml-auto" *ngIf="editable && task.isNew()" (click)="createTask()" [disabled]="!task.title">{{ 'task.create' | translate }}</button>
		<button class="btn btn-primary ml-auto" *ngIf="editable && !task.isNew()" (click)="saveEditable()">{{ 'general.save' | translate }}</button>
		<button class="btn btn-primary ml-auto" *ngIf="!editable" (click)="activeModal.close()">{{ 'general.close' | translate }}</button>
	</div>
</div>
