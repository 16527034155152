import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Product } from '../../../../shared/models/product';
import { Sprint } from '../../../../shared/models/sprint';
import { Task } from '../../../../shared/models/task';

@Component({
	selector: 'app-experiment-tasks-drag-modal',
	templateUrl: './experiment-tasks-drag-modal.component.html',
	styleUrls: ['./experiment-tasks-drag-modal.component.scss'],
})
export class ExperimentTasksDragModalComponent implements OnInit {
	@Input() destination: Sprint;
	@Input() source: Sprint;
	@Input() tasks: Task[];
	@Input() product: Product;

	public result: string;
	public shouldAskMovement: boolean;
	public sourceName: string;
	public destinationName: string;

	constructor(public translate: TranslateService, public activeModal: NgbActiveModal) {}

	ngOnInit(): void {
		if (this.source.status === Sprint.Status.BACKLOG) {
			this.sourceName = this.translate.instant('backlog.singular');
			this.shouldAskMovement = true;
		} else {
			this.sourceName = this.translate.instant('sprint.singular') + ' ' + this.source.number;
		}

		if (this.destination.status === Sprint.Status.BACKLOG) {
			this.destinationName = this.translate.instant('backlog.singular');
		} else {
			this.destinationName = this.translate.instant('sprint.singular') + ' ' + this.destination.number;
		}
	}
}
