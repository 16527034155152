<div class="mini-canvas" [ngClass]="stage">
	<app-spinner [show]="busy" class="spinner"></app-spinner>
	<div class="row no-gutters segment-container">
		<div class="col-10">
			<div class="row canvas-left mx-0">
				<app-mini-segment
					id="solution"
					class="col-12 segment px-0"
					[allStickies]="allStickies"
					[segmentName]="showSegmentNames && portfolioService.getSegmentName(canvas.product.portfolio, 'solution', 'pre-validation')"
					[selectedSegment]="selectedSegment"
					(click)="onClick('solution')"
					[ngClass]="{ hoverable: canSelectSegment }"
				></app-mini-segment>
			</div>
			<div class="row canvas-left mx-0">
				<app-mini-segment
					id="revenuemodel"
					class="col-3 segment px-0"
					[allStickies]="allStickies"
					[segmentName]="showSegmentNames && portfolioService.getSegmentName(canvas.product.portfolio, 'revenuemodel', 'pre-validation')"
					[selectedSegment]="selectedSegment"
					(click)="onClick('revenuemodel')"
					[ngClass]="{ hoverable: canSelectSegment }"
				></app-mini-segment>
				<app-mini-segment
					id="customersegment"
					class="col-6 gray segment px-0"
					[allStickies]="allStickies"
					[segmentName]="showSegmentNames && portfolioService.getSegmentName(canvas.product.portfolio, 'customersegment', 'pre-validation')"
					[selectedSegment]="selectedSegment"
					(click)="onClick('customersegment')"
					[ngClass]="{ hoverable: canSelectSegment }"
				></app-mini-segment>
				<app-mini-segment
					id="growthengine"
					class="col-3 segment px-0"
					[allStickies]="allStickies"
					[segmentName]="showSegmentNames && portfolioService.getSegmentName(canvas.product.portfolio, 'growthengine', 'pre-validation')"
					[selectedSegment]="selectedSegment"
					(click)="onClick('growthengine')"
					[ngClass]="{ hoverable: canSelectSegment }"
				></app-mini-segment>
			</div>
			<div class="row canvas-left mx-0">
				<app-mini-segment
					id="metrics"
					class="col-3 segment px-0"
					[allStickies]="allStickies"
					[segmentName]="showSegmentNames && portfolioService.getSegmentName(canvas.product.portfolio, 'metrics', 'pre-validation')"
					[selectedSegment]="selectedSegment"
					(click)="onClick('metrics')"
					[ngClass]="{ hoverable: canSelectSegment }"
				></app-mini-segment>
				<app-mini-segment
					id="valueproposition"
					class="col-6 gray segment px-0"
					[allStickies]="allStickies"
					[segmentName]="showSegmentNames && portfolioService.getSegmentName(canvas.product.portfolio, 'valueproposition', 'pre-validation')"
					[selectedSegment]="selectedSegment"
					(click)="onClick('valueproposition')"
					[ngClass]="{ hoverable: canSelectSegment }"
				></app-mini-segment>
				<app-mini-segment
					id="channels"
					class="col-3 segment px-0"
					[allStickies]="allStickies"
					[segmentName]="showSegmentNames && portfolioService.getSegmentName(canvas.product.portfolio, 'channels', 'pre-validation')"
					[selectedSegment]="selectedSegment"
					(click)="onClick('channels')"
					[ngClass]="{ hoverable: canSelectSegment }"
				></app-mini-segment>
			</div>
			<div class="row canvas-left mx-0">
				<app-mini-segment
					id="problem"
					class="col-12 segment px-0"
					[allStickies]="allStickies"
					[segmentName]="showSegmentNames && portfolioService.getSegmentName(canvas.product.portfolio, 'problem', 'pre-validation')"
					[selectedSegment]="selectedSegment"
					(click)="onClick('problem')"
					[ngClass]="{ hoverable: canSelectSegment }"
				></app-mini-segment>
			</div>
		</div>
		<div class="col-2">
			<div class="row canvas-right mx-0">
				<app-mini-segment
					id="marketsize"
					class="col-12 segment px-0"
					[allStickies]="allStickies"
					[segmentName]="showSegmentNames && portfolioService.getSegmentName(canvas.product.portfolio, 'marketsize', 'pre-validation')"
					[selectedSegment]="selectedSegment"
					(click)="onClick('marketsize')"
					[ngClass]="{ hoverable: canSelectSegment }"
				></app-mini-segment>
			</div>
			<div class="row canvas-right mx-0">
				<app-mini-segment
					id="determination"
					class="col-12 segment px-0"
					[allStickies]="allStickies"
					[segmentName]="showSegmentNames && portfolioService.getSegmentName(canvas.product.portfolio, 'determination', 'pre-validation')"
					[selectedSegment]="selectedSegment"
					(click)="onClick('determination')"
					[ngClass]="{ hoverable: canSelectSegment }"
				></app-mini-segment>
			</div>
		</div>
	</div>
</div>
