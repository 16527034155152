import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExperimentService } from 'app/experiment/experiment.service';
import { Insight } from '../../../../shared/models/insight';
import { InsightModalComponent } from '../insight-modal/insight-modal.component';

@Component({
	selector: 'app-insight-card',
	templateUrl: './insight-card.component.html',
	styleUrls: ['./insight-card.component.scss'],
})
export class InsightCardComponent implements OnInit {
	@Input() insight: Insight;
	@Input() showExperimentTag: boolean;
	public abbrev: string;

	get InsightDecision() {
		return Insight.Decision;
	}

	constructor(
		public modalService: NgbModal,
		private experimentService: ExperimentService
	) {}

	ngOnInit() {
		if (this.showExperimentTag) {
			this.abbrev = this.insight.product.getAbbrev();
		}
	}

	openExperiment() {
		this.experimentService.openModal(this.insight.experiment, this.insight.product);
	}

	openInsight() {
		const modalRef = this.modalService.open(InsightModalComponent, {
			size: 'md',
		});

		modalRef.componentInstance.insight = this.insight;
	}
}
