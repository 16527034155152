import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NextConfigService } from 'app/next-config.service';
import { Sprint } from '../../../../shared/models/sprint';

@Component({
	selector: 'app-chart-happiness-modal',
	templateUrl: './chart-happiness-modal.component.html',
	styleUrls: ['./chart-happiness-modal.component.scss'],
})
export class ChartHappinessModalComponent implements OnInit {
	@Input() sprints: Sprint[];
	public labels: string[];
	public datasets;
	public chartOptions;
	public chartColors: Array<any> = [
		{
			backgroundColor: [],
		},
	];
	public isReady: boolean;
	public sortedSprints: Sprint[];
	get SprintStatus() {
		return Sprint.Status;
	}

	constructor(
		public activeModal: NgbActiveModal,
		private translate: TranslateService,
		private configService: NextConfigService
	) {
		this.labels = [];
		this.datasets = [];
	}

	ngOnInit(): void {
		// setup colors
		this.chartColors[0].borderColor = this.configService.colorHappiness[0];
		this.chartColors[0].backgroundColor = this.configService.colorHappiness[1];

		// setup chart options
		this.chartOptions = {
			responsive: true,
			legend: false,
			scales: {
				yAxes: [
					{
						scaleLabel: {
							display: false,
							labelString: 'test',
						},
						ticks: {
							callback: (value, index, values) => {
								return this.getLabel(value);
							},
							stepSize: 5,
							min: 1,
							max: 5,
						},
					},
				],
			},
		};

		this.sortedSprints = this.sprints.slice(0, 10);
		this.sortedSprints.sort(this.sortSprints);
		let chartData = [];

		for (let sprint of this.sortedSprints) {
			if (sprint.status === Sprint.Status.DONE) {
				if (!!sprint.teamHapiness && sprint.teamHapiness !== 'undefined') {
					chartData.unshift(parseInt(sprint.teamHapiness + '', 10));
				} else {
					chartData.unshift(0);
				}

				const label = this.translate.instant('sprint.singular') + ' ' + sprint.number;
				this.labels.unshift(label);
			}
		}

		this.datasets = [
			{
				data: chartData,
				label: this.translate.instant('team.happiness'),
			},
		];

		this.isReady = true;
	}

	getLabel(number) {
		switch (number) {
			case 1:
				return this.translate.instant('happiness.min');
			case 5:
				return this.translate.instant('happiness.max');
			default:
				return '';
		}
	}

	sortSprints(sprintA, sprintB) {
		if (sprintA.number < sprintB.number) {
			return 1;
		} else if (sprintA.number > sprintB.number) {
			return -1;
		} else {
			return 0;
		}
	}

	calcScore(sprint) {
		return ((sprint.teamHapiness - 1) / 4) * 100 || 0;
	}
}
