// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: true,
	parse: {
		serverURL: 'https://api.togroundcontrol.com/api/',
		appId: 'zl8xx3s3xdezxxxnlqh00qsvvl87kdge6xwr4r0w',
		appKey: 'aikxsib50hzyg0i041npe3v7zgwvrvsexf4b6d0l'
	},
	mixpanelToken: 'da0e84fa8d525eaf839fe7e55aaddf1c',
	gaToken: 'UA-71847040-1',
	appSignalKey: '42bdcb3d-6a4b-4633-8a60-e1d47c7b62f9',
	stripe: {
		sdkUrl: 'https://js.stripe.com/v3/',
		publishableKey: 'pk_live_cQY7Jo3kfCutSWIFN49Brlwp'
	},
	microsoft: {
		clientId: '1c8a0ac0-7a1e-4941-930e-a57cad0309ca',
		redirectUrl: 'https://platform.togroundcontrol.com/login'
	}
};
