<div class="modal-content">
	<div class="modal-header">
		<p class="modal-title">{{ 'sprint.retroModal.title' | translate }} {{ sprint.number }}</p>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 form-group">
					<label>{{ 'sprint.retroModal.demo' | translate }}</label>
						<quill-editor name="sprint-demo" [(ngModel)]="sprint.demo" [styles]="{ height: '5rem' }"></quill-editor>
				</div>

				<div class="col-12 form-group range-container">
					<label>{{ 'sprint.retroModal.happiness.question' | translate }}
						<input type="range" min="1" max="5" class="custom-range" [(ngModel)]="sprint.teamHapiness" />
					</label>
					<p class="small left">{{ 'happiness.min' | translate }}</p>
					<p class="small right">{{ 'happiness.max' | translate }}</p>
				</div>

				<div class="col-12 form-group">
					<label>{{ 'sprint.retroModal.comments' | translate }}</label>
					<quill-editor name="sprint-comments" [(ngModel)]="sprint.commentsTeam" [styles]="{ height: '5rem' }"></quill-editor>
				</div>

				<div class="col-12 form-group range-container">
					<label>{{ 'sprint.retroModal.teamEducation.question' | translate }}
						<input type="range" min="1" max="5" class="custom-range" [(ngModel)]="sprint.teamEducation" />
					</label>
					<p class="small left">{{ 'knowledge.min' | translate }}</p>
					<p class="small right">{{ 'knowledge.max' | translate }}</p>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button class="btn btn-primary ml-auto" (click)="saveSprint()">{{ 'general.save' | translate }}</button>
	</div>
</div>
