<div class="modal-content">
	<div class="modal-header align-items-center">
		<p class="modal-title">{{ 'card.edit' | translate }}</p>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="row mb-3">
			<div class="col-12">
				<label>{{ 'user.cardName' | translate }}
					<input type="text" class="form-control" name="cardName" [(ngModel)]="cardName" required>
				</label>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div id="card-info" class="card-info" #cardInfo></div>
				<ngb-alert *ngIf="error" type="danger" [dismissible]="false" class="mt-1">{{ error }}</ngb-alert>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button class="btn btn-link-secondary" (click)="activeModal.close()" [disabled]="loading">{{ 'general.cancel' | translate }}</button>
		<button class="btn btn-primary ml-auto" (click)="saveCard()" [disabled]="loading">{{ 'card.save' | translate }}</button>
	</div>
</div>