<div class="card d-flex flex-row justify-content-left hand" (click)="openHappinessChart()">
	<div class="circle"><i class="bi bi-emoji-smile"></i></div>
	<div class="progress-container">
		<p class="card-title">{{ 'team.happiness' | translate }}</p>
		<div class="progress">
			<div class="progress-bar" role="progressbar" [style.width.%]="((happiness - 1) / 4) * 100 || 0" aria-valuemin="1" aria-valuemax="5"></div>
		</div>
		<div class="d-flex flex-row justify-content-between">
			<p class="progress-label">{{ 'happiness.min' | translate }}</p>
			<p class="progress-label">{{ 'happiness.max' | translate }}</p>
		</div>
	</div>
</div>
