<div *ngIf="contentExperiment">
	<div class="mb-3">
		<h1>{{ contentExperiment.title }}</h1>
		<p>{{ contentExperiment.excerpt }}</p>
	</div>
	<div class="row mb-4">
		<div class="col-12 col-md-auto">
			<p class="header" *ngIf="contentExperiment.stages.length === 1">{{ 'stage.singular' | translate }}</p>
			<p class="header" *ngIf="contentExperiment.stages.length > 1">{{ 'stage.plural' | translate }}</p>
		</div>
		<div class="col-12 col-md">
			<p class="badge badge-secondary mr-2" *ngFor="let stage of contentExperiment.stages">
				{{ !!portfolio ? portfolioService.getStageName(portfolio, stage) : ('productStages.' + stage | translate) }}
			</p>
		</div>
	</div>
	<div class="row mb-4">
		<div class="col-12 col-md-auto">
			<p class="header" *ngIf="contentExperiment.types?.length === 1">{{ 'type.singular' | translate }}</p>
			<p class="header" *ngIf="contentExperiment.types?.length > 1">{{ 'type.plural' | translate }}</p>
		</div>
		<div class="col-12 col-md">
			<p *ngIf="contentExperiment.types?.includes(ContExpType.CONFIRM)" class="badge badge-secondary confirm mr-2">
				{{ 'type.confirm' | translate }}
			</p>
			<p *ngIf="contentExperiment.types?.includes(ContExpType.LEARN)" class="badge badge-secondary learn mr-2">
				{{ 'type.learn' | translate }}
			</p>
		</div>
	</div>
	<div *ngIf="contentExperiment.helpsAnswer" class="mb-4">
		<h3>{{ 'contentExperiment.helpsAnswer' | translate }}</h3>
		<div class="editor-data" [innerHtml]="contentExperiment.helpsAnswer"></div>
	</div>
	<div *ngIf="contentExperiment.description" class="mb-4">
		<h3>{{ 'contentExperiment.whatIsIt' | translate }}</h3>
		<div class="editor-data" [innerHtml]="contentExperiment.description"></div>
	</div>
	<div *ngIf="contentExperiment.howToAnalyze" class="mb-4">
		<h3>{{ 'contentExperiment.howToAnalyze' | translate }}</h3>
		<div class="editor-data" [innerHtml]="contentExperiment.howToAnalyze"></div>
	</div>
	<div *ngIf="contentExperiment.example" class="mb-4">
		<h3>{{ 'example.singular' | translate }}</h3>
		<div class="editor-data" [innerHtml]="contentExperiment.example"></div>
	</div>
	<div *ngIf="contentExperiment.readMore" class="mb-4">
		<h3>{{ 'contentExperiment.readMore' | translate }}</h3>
		<div class="editor-data" [innerHtml]="contentExperiment.readMore"></div>
	</div>
	<div *ngIf="contentExperiment.standardExperimentPlan" class="mb-4">
		<h3>{{ 'contentExperiment.standardExperimentPlan' | translate }}</h3>
		<div class="editor-data" [innerHtml]="contentExperiment.standardExperimentPlan"></div>
	</div>
	<div *ngIf="contentExperiment.source" class="mt-5 mb-5">
		<p>{{ 'source.singular' | translate }}: {{ contentExperiment.source }}</p>
	</div>
</div>
