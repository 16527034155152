import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Cloud } from 'parse';
import { Portfolio } from '../../../../shared/models/portfolio';
import { CardDetailsModalComponent } from '../card-details-modal/card-details-modal.component';
import { BillingDetailsModalComponent } from '../billing-details-modal/billing-details-modal.component';

@Component({
	selector: 'app-billing',
	templateUrl: './billing.component.html',
	styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
	public products: number;
	public portfolio: Portfolio;
	public canceled: boolean;
	public subscription: object;
	public invoices: object;
	public billingDetails: object = {};
	public billingDetailsShadow: object = {};
	public renewDate: Date;

	public loading = false;

	constructor(
		private portfolioService: PortfolioService,
		private modalService: NgbModal
	) { }

	async ngOnInit() {
		this.portfolio = await this.portfolioService.getActivePortfolio();
		this.getSubscription();
		this.getInvoices();
		this.getBillingDetails();
	}

	async getSubscription() {
		this.subscription = await Cloud.run('getSubscription');
		// const endDate = this.subscription['current_period_end'];
		// endDate.setDate(endDate.getDate() + 1);
		this.renewDate = this.subscription['current_period_end'];;
	}

	async getBillingDetails() {
		this.billingDetails = await Cloud.run('getBillingDetails');
	}

	async cancelSubscription() {
		this.loading = true;

		this.canceled = await Cloud.run('cancelSubscription');
		await this.getSubscription();

		this.loading = false;
	}

	async resumeSubscription() {
		this.loading = true;

		this.canceled = await Cloud.run('resumeCancelledSubscription');
		await this.getSubscription();

		this.loading = false;
	}

	async getInvoices() {
		this.invoices = await Cloud.run('getInvoices');
	}

	async openInvoice(invoiceId) {
		const invoiceUrl = await Cloud.run('getMoneybirdInvoiceURL', {
			invoiceId: invoiceId
		});
		window.open(invoiceUrl, "_blank");
	}

	async editBillingDetails() {
		this.billingDetailsShadow = {
			email: this.billingDetails['email'],
			address1: this.billingDetails['address1'],
			address2: this.billingDetails['address2'],
			postalCode: this.billingDetails['postalCode'],
			city: this.billingDetails['city'],
			country: this.billingDetails['country'],
			taxId: this.billingDetails['taxId']
		};

		const modalRef = this.modalService.open(BillingDetailsModalComponent, {
			size: 'md',
			centered: true,
		});
		modalRef.componentInstance.billingDetailsShadow = this.billingDetailsShadow;

		let result = await modalRef.result;
		if (result) {
			this.billingDetails = result;
		}
	}

	async editCardDetails() {
		const modalRef = this.modalService.open(CardDetailsModalComponent, {
			size: 'md',
			centered: true,
		});
		let result = await modalRef.result;
		if (result) {
		  this.getSubscription();
		}
	}

}
