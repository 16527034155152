import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NextTitleService } from 'app/next-title.service';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { User } from '../../../../shared/models/user';
import { ProductService } from 'app/product/product.service';
import { Portfolio } from '../../../../shared/models/portfolio';
import { Product } from '../../../../shared/models/product';
import { AnalyticsService } from 'app/shared/analytics.service';
import { Cloud, File } from 'parse';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
	public user: User;
	public product: Product;
	public portfolio: Portfolio;
	public isReady = false;

	public email1: string;
	public email2: string;
	public email3: string;

	public step = 0;

	public showProgress = false;
	public progressUpload = 0;

	constructor(
		private analytics: AnalyticsService,
		private productService: ProductService,
		private portfolioService: PortfolioService,
		private router: Router,
		private titleService: NextTitleService
	) {}

	async ngOnInit() {
		// Set page title
		this.titleService.setTitle('');

		const activePortfolio = await this.portfolioService.getActivePortfolio();

		if (activePortfolio) {
			// check if portfolio contains non-demo products
			const products = await this.productService.getProducts();
			for (let product of products) {
				if (!product.isDemo) {
					// portfolio contains product that isn't a demo, onboarding not necessary
					this.router.navigate(['/portfolio', activePortfolio.id]);
					return;
				}
			}

			this.portfolio = activePortfolio;
		}

		this.isReady = true;

		// Enable No portfolio view
		this.user = User.current() as User;

		this.product = new Product();

		this.product.stage = Product.Stage.KICKBOX;
		this.product.portfolio = this.portfolio;
	}

	nextStep() {
		this.step++;
	}

	async startTrial() {
		this.nextStep();

		this.analytics.track('Start trial');

		// send invites
		if (this.email1) {
			this.sendInvite(this.email1);
		}

		if (this.email2) {
			this.sendInvite(this.email2);
		}

		if (this.email3) {
			this.sendInvite(this.email3);
		}

		// create product and demo products
		await this.productService.addProduct(this.product, this.portfolio);

		// redirect to funnel for optimal aha moment
		this.router.navigate(['/portfolio', this.portfolio.id, 'funnel']);
	}

	async sendInvite(email) {
		await Cloud.run('inviteUserToPortfolio', {
			email: email.toLowerCase(),
			portfolioId: this.portfolio.id,
		});

		this.analytics.track('Invited user to portfolio');
	}

	async onLogoChange(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			const parseFile = new File(file.name, file);

			this.showProgress = true;

			await await parseFile.save({
				progress: (progressValue, loaded, total, { type }) => {
					if (type === 'upload' && progressValue !== null) {
						// Update the UI using progressValue
						this.progressUpload = progressValue * 100;
					}
				},
			} as any);

			this.product.logo = parseFile;
		}
	}
}
