import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from '@shared/models/product';
import { Lens } from '@shared/models/lens';
import { Query } from 'parse';
import { Cockpit } from '@shared/models/cockpit';

@Component({
	selector: 'app-cockpit-modal',
	templateUrl: './cockpit-modal.component.html',
	styleUrls: ['./cockpit-modal.component.scss'],
})
export class CockpitModalComponent implements OnInit {
	@Input() product: Product;
	public lenses: Lens[];
	public cockpitFields: Cockpit[];

	get LensValues() {
		return Lens.LensValues;
	}

	constructor(public activeModal: NgbActiveModal) {}

	async ngOnInit() {
		this.lenses = await new Query(Lens)
			.equalTo('archived', false)
			.equalTo('portfolio', this.product.portfolio)
			.ascending('orderIndex')
			.find();
		
		for (let lens of this.lenses) {
			if (!this.product.lensValues[lens.id]) {
				this.product.lensValues[lens.id] = {
					value: '',
					note: ''
				}
			}
		}

		this.cockpitFields = await new Query(Cockpit)
			.equalTo('portfolio', this.product.portfolio)
			.equalTo('archived', false)
			.ascending('orderIndex')
			.find();
	}

	async saveCockpit() {
		await this.product.save();
		this.activeModal.close();
	}
}
