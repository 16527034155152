<div class="card d-flex flex-row justify-content-right knowledge hand" (click)="openKnowledgeChart()">
	<div class="circle"><i class="bi bi-lightbulb"></i></div>
	<div class="progress-container">
		<p class="card-title">{{ 'team.knowledgeability' | translate }}</p>
		<div class="progress">
			<div class="progress-bar" role="progressbar" [style.width.%]="((knowledge - 1) / 4) * 100 || 0" aria-valuemin="1" aria-valuemax="5"></div>
		</div>
		<div class="d-flex flex-row justify-content-between">
			<p class="progress-label">{{ 'knowledge.min' | translate }}</p>
			<p class="progress-label">{{ 'knowledge.max' | translate }}</p>
		</div>
	</div>
</div>
