import * as Parse from 'parse';
import { ParseSubClass, ParseProperty } from '../parse.decorators';
import { Thesis } from './thesis';
import { ProductDecision } from './product-decision';
import { User } from './user';
import dayjs from 'dayjs';

@ParseSubClass('Portfolio')
export class Portfolio extends Parse.Object {
	@ParseProperty() public name: string;
	@ParseProperty() public logo: Parse.File;
	@ParseProperty() public theses: Array<Thesis>;
	@ParseProperty() public decisions: Parse.Relation<ProductDecision>;
	@ParseProperty() public createdBy: User;
	@ParseProperty() public createdAt: Date;
	@ParseProperty() public canvasSegmentNames: Object;
	@ParseProperty() public stageNames: Object;
	@ParseProperty() public experimentLength: number;
	@ParseProperty() public trial: boolean;
	@ParseProperty() public trialEndsAt: Date;
	@ParseProperty() public defaultKeyQuestions: boolean;
	@ParseProperty() public sprintType: Portfolio.SprintType;
	@ParseProperty() public currency: string;
	@ParseProperty() public amountStages: number;
	@ParseProperty() public defaultContentExperiments: boolean;

	constructor() {
		super('Portfolio');
	}

	getRemainingTrialDays() {
		let end = dayjs(this.trialEndsAt);
		let now = dayjs(new Date());
		let days = end.diff(now, 'days') + 1;

		if (days < 0) {
			days = 0;
		}

		return days;
	}
}

export namespace Portfolio {
	export enum SprintType {
		KANBAN = 'kanban',
		SCRUM = 'scrum',
	}
}
