import { Component, Input, OnChanges } from '@angular/core';
import { NextConfigService } from 'app/next-config.service';
import { ProductDecision } from '../../../../shared/models/product-decision';
import { Portfolio } from '../../../../shared/models/portfolio';
import { CurrencyPipe } from '@angular/common';
import { PortfolioService } from 'app/portfolio/portfolio.service';

@Component({
	selector: 'app-chart-stage-average-investment',
	templateUrl: './stage-average-investment.component.html',
})
export class StageAverageInvestmentComponent implements OnChanges {
	@Input() decisions: Array<ProductDecision>;
	@Input() portfolio: Portfolio;

	public stages = {
		datasets: [],
		labels: [],
	};
	public datasets = [];
	public chartColors = [{ backgroundColor: '' }];
	public chartOptions = {
		responsive: true,
		legend: false,
		scales: {
			xAxes: [
				{
					ticks: {
						callback: function (value, index, values) {
							return value; // Questions.labels[value];
						},
					},
				},
			],
			yAxes: [
				{
					scaleLabel: {
						display: false,
						labelString: 'Euro',
					},
					ticks: {
						callback: (value, index, values) => {
							return this.currencyPipe.transform(value, this.portfolio.currency, 'symbol', '1.0-0');
						},
						// precision: 1000,
						maxTicksLimit: 5,
						min: 0,
					},
				},
			],
		},
		tooltips: {
			callbacks: {
				label: (tooltipItem, data) => {
					return this.currencyPipe.transform(Math.round(tooltipItem.yLabel), this.portfolio.currency, 'symbol', '3.0');
				},
			},
		},
	};
	public isReady = false;

	constructor(private configService: NextConfigService, private currencyPipe: CurrencyPipe, private portfolioService: PortfolioService) {
		this.chartColors[0].backgroundColor = this.configService.colorPrimary[0];
	}

	ngOnChanges(changes: any) {
		const portfolioStages = this.portfolioService.getMainStages(this.portfolio);
		const labels = this.portfolioService.getStageNames(this.portfolio, portfolioStages);
		const stages = {};
		const products = {};
		const chartData = [];

		portfolioStages.forEach((stage) => {
			stages[stage] = [];
			chartData.push(0);
		});

		// We first have to calculate how much investment each product received,
		// Not average the investment per decision
		this.decisions.forEach((decision) => {
			if (!decision.toStage) {
				return;
			}

			if (!decision.moneyGranted) {
				return;
			}

			if (decision.toStage === 'kickbox') {
				return;
			}

			if (decision.toStage === 'validated') {
				return;
			}

			if (!products[decision.product.id]) {
				products[decision.product.id] = {};
			}

			if (!products[decision.product.id][decision.toStage]) {
				products[decision.product.id][decision.toStage] = 0;
			}

			products[decision.product.id][decision.toStage] += decision.moneyGranted;
		});

		// Move from total investment per product per stage
		// To an array of total investments per stage
		for (let key in products) {
			const productStages = products[key];

			for (let k in productStages) {
				stages[k].push(productStages[k]);
			}
		}

		// Calculare averages from these arrays
		let i = 0;

		for (let key in stages) {
			if (stages[key].length === 0) {
				i++;
				continue;
			}

			chartData[i++] =
				stages[key].reduce(function (a, b) {
					return a + b;
				}) / stages[key].length;
		}

		this.stages.labels = labels;
		this.datasets = [
			{
				label: 'Average investment per stage',
				data: chartData,
			},
		];

		// Now we can show the graph, doesn't work otherwise
		this.isReady = true;
	}
}
