<div *ngIf="!!product" id="cockpit-info">
	<div class="row">
		<div class="col-12 col-md-2">
			<p class="header">{{ 'cockpit.startupInfo' | translate }}</p>
		</div>
		<div class="col-12 col-md-10">
			<div class="row align-items-top">
				<div class="col-2" *ngIf="product.logo">
					<img class="logo" [src]="product.logo.url()" />
				</div>
				<div class="col-10 col-md pitch">
					<h5>{{ product.name }}</h5>
					<p>{{ product.pitch }}</p>
				</div>
			</div>
			
			<div class="row align-items-top">
				<div class="col-10 col-md-4">
					<h5 class="info-header">{{ 'product.startedAt' | translate }}</h5>
					<p>{{ product.startedAt | date }}</p>
				</div>
				<div class="col-10 col-md-4">
					<h5 class="info-header">{{ 'decision.last' | translate }}</h5>
					<p *ngIf="previousDecision">{{ previousDecision.decisionDate | date }}</p>
					<p *ngIf="!previousDecision">-</p>
				</div>
				<div class="col-10 col-md-4">
					<h5 class="info-header">{{ 'decision.deadline' | translate }}</h5>
					<p *ngIf="previousDecision && previousDecision.nextMeetingDate">{{ previousDecision.nextMeetingDate | date }}</p>
					<p *ngIf="!previousDecision || !previousDecision.nextMeetingDate">-</p>
				</div>
				
			</div>
			<div class="row align-items-top">
				<div class="col-10 col-md-4">
					<h5 class="info-header">{{ 'stage.current' | translate }}</h5>
					<p>{{ portfolioService.getStageName(product.portfolio, product.stage) }}</p>
				</div>
				<div class="col-10 col-md-4">
					<h5 class="info-header">{{ 'progress.singular' | translate }}</h5>
					<p class="pt-2 pr-5">
						<ngb-progressbar [type]="progressbarType" [value]="progress?.getAverageScore()" height=".25rem" title="{{ 'guide.progress.question' | translate }}"></ngb-progressbar>
					</p>
				</div>

				<div class="col-10 col-md-4">
					<h5 class="info-header">{{ 'thesis.singular' | translate }}</h5>
					<p *ngIf="product.thesis">{{ product.thesis.title }}</p>
					<p *ngIf="!product.thesis">-</p>
				</div>
			</div>
			<div class="row" *ngIf="!!product.description">
				<div class="col">
					<h5 class="info-header">{{ 'description.singular' | translate }}</h5>
					<p class="editor-data" [innerHtml]="product.description"></p>
				</div>
			</div>
			
			<div class="row mb-0" *ngIf="cockpitFields">
				<div class="col-12 col-md-6 mb-5" *ngFor="let field of cockpitFields">
					<h5 class="info-header">{{ field.name }}</h5>
					<p class="editor-data" [innerHtml]="product.cockpitValues[field.id] || '-'"></p>

				</div>
			</div>
			<div class="row mb-0" *ngIf="lenses">
				<div class="col-12 col-md mb-5" *ngFor="let lens of lenses">
					<h5 class="info-header">{{ lens.name }}</h5>
					<div class="d-flex justify-content-between">
						<p *ngIf="lens.value === LensValues.CURRENCY">
							{{ product.lensValues[lens.id]?.value || 0 | currency: product.portfolio.currency:'symbol':'.0' }}
						</p>
						<p *ngIf="lens.value !== LensValues.CURRENCY">{{ product.lensValues[lens.id]?.value || 0 }} {{ lens.value }}</p>
						<p *ngIf="product.lensValues[lens.id]?.note"><i class="bi bi-info-circle text-primary hand" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"></i></p>
					</div>
					<div #collapse="ngbCollapse" [ngbCollapse]="true" class="mt-3">
						<div class="card">
							<div class="card-body" [innerHtml]="product.lensValues[lens.id]?.note"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-12 col-md-2">
			<p class="header">{{ 'product.teamMembers' | translate }}</p>
		</div>
		<div class="col-12 col-md-10">
			<div class="row">
				<div class="col-6 col-md-3 mb-3" *ngFor="let user of product.teamMembers">
					<div class="card">
						<div class="card-body text-center">
							<div class="avatar-container">
								<app-avatar [user]="user" [avatarSize]="3"></app-avatar>
							</div>
							<p class="header user-name">{{ user.getName() }}</p>
							<a class="btn btn-outline-primary" [href]="'mailto:' + user.username">{{ 'cockpit.contact' | translate }}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
