import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../../../shared/models/product';
import { Query } from 'parse';
import { Lens } from '@shared/models/lens';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { ProductDecision } from '@shared/models/product-decision';
import { Progress } from '@shared/models/progress';
import { Cockpit } from '@shared/models/cockpit';

@Component({
	selector: 'app-product-info',
	templateUrl: './product-info.component.html',
	styleUrls: ['./product-info.component.scss'],
})
export class ProductInfoComponent implements OnInit {
	@Input() product: Product;
	public lenses: Lens[];
	public cockpitFields: Cockpit[];
	public previousDecision: ProductDecision;
	public progress: Progress;
	public progressbarType = 'secondary';

	public color = 'transparent';

	get LensValues() {
		return Lens.LensValues;
	}

	constructor(public portfolioService: PortfolioService) {}

	async ngOnInit() {

		// fietch Lenses
		this.lenses = await new Query(Lens)
			.equalTo('portfolio', this.product.portfolio)
			.equalTo('archived', false)
			.ascending('orderIndex')
			.find();

		// fetch Cockpit fields
		this.cockpitFields = await new Query(Cockpit)
			.equalTo('portfolio', this.product.portfolio)
			.equalTo('archived', false)
			.ascending('orderIndex')
		 	.find();

		// fetch previous decision
		this.previousDecision = await new Query(ProductDecision)
			.equalTo('product', this.product)
			.equalTo('status', ProductDecision.Status.DONE)
			.equalTo('archived', false)
			.descending('decisionDate')
			.first();
		
		// set progress bar
		if (this.product.activeProgress && this.product.activeProgress.stage == this.product.stage) {
			this.progress = this.product.activeProgress;

			if (this.progress.getAverageScore() >= 80) {
				this.progressbarType = 'success';
			}
		}
	}
}
