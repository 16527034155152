import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Sprint } from '../../../../shared/models/sprint';
import { Portfolio } from '../../../../shared/models/portfolio';

@Component({
	selector: 'app-sprint-modal-start',
	templateUrl: './sprint-modal-start.component.html',
	styleUrls: ['./sprint-modal-start.component.scss'],
})
export class SprintModalStartComponent implements OnInit {
	@Input() sprint: Sprint;
	public portfolio: Portfolio;
	public duration: number;
	get SprintStatus() {
		return Sprint.Status;
	}

	constructor(
		public activeModal: NgbActiveModal,
		public calendar: NgbCalendar,
		public portfolioService: PortfolioService
	) {}

	async ngOnInit() {
		const portfolio = await this.portfolioService.getActivePortfolio();

		if (this.sprint.status === Sprint.Status.PREPARING) {
			this.duration = portfolio.experimentLength || 2;
		} else {
			this.duration = 0;
		}

		this.calcDates();
	}

	calcDates() {
		if (!this.sprint.startedAt) {
			this.sprint.startedAt = new Date();
		}

		if (this.sprint.status === Sprint.Status.PREPARING) {
			const endDate = new Date();
			endDate.setDate(endDate.getDate() + this.duration*7);

			this.sprint.endedAt = endDate;
		}
	}

	startSprint() {
		this.activeModal.close(true);
	}
}
