import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Query, LiveQuerySubscription } from 'parse';
import { Learning } from '../../../../shared/models/learning';
import { Product } from '../../../../shared/models/product';

@Component({
	selector: 'app-learning-modal-overview',
	templateUrl: './learning-modal-overview.component.html',
	styleUrls: ['./learning-modal-overview.component.scss'],
})
export class LearningModalOverviewComponent implements OnInit, OnDestroy {
	@Input() product: Product;
	@Input() type: string;
	public learnings: Learning[];
	public learningsPerMonth: any[];
	public searchTerms: string;
	private learningSubscription: LiveQuerySubscription;

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {
		this.queryLearnings();
	}

	ngOnDestroy() {
		if (this.learningSubscription) {
			this.learningSubscription.unsubscribe();
		}
	}

	async queryLearnings() {
		const learningQuery = new Query(Learning);
		learningQuery.equalTo('product', this.product);
		learningQuery.equalTo('archived', false);
		learningQuery.include(['experiment', 'keyQuestion']);

		if (!!this.searchTerms) {
			learningQuery.fullText('text', this.searchTerms);
		}

		learningQuery.descending('createdAt');
		this.learnings = await learningQuery.find();
		this.sortLearningsPerMonth();

		// make sure learnings update on edit/delete/enter
		this.learningSubscription = await learningQuery.subscribe();
		this.learningSubscription.on('create', (learning: Learning) => {
			this.learnings.unshift(learning);
			this.sortLearningsPerMonth();
		});

		this.learningSubscription.on('update', (learning: Learning) => {
			const index = this.learnings.findIndex((element) => element.id === learning.id);
			this.learnings[index] = learning;
			this.sortLearningsPerMonth();
		});

		this.learningSubscription.on('leave', (learning: Learning) => {
			const index = this.learnings.findIndex((element) => element.id === learning.id);
			this.learnings.splice(index, 1);
			this.sortLearningsPerMonth();
		});
	}

	sortLearningsPerMonth() {
		this.learningsPerMonth = [];
		let previousMonth;
		let monthIndex = -1;

		this.learnings.forEach((learning) => {
			if (previousMonth === 'undefined' || learning.createdAt.getMonth() !== previousMonth) {
				previousMonth = learning.createdAt.getMonth();
				const newMonthObject = {
					date: learning.createdAt,
					learnings: [learning],
				};
				this.learningsPerMonth.push(newMonthObject);

				monthIndex++;
			} else {
				this.learningsPerMonth[monthIndex]['learnings'].push(learning);
			}
		});
	}
}
