import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExperimentService } from 'app/experiment/experiment.service';
import { Log } from '../../../../../shared/models/log';

@Component({
	selector: 'app-log-add-experiment',
	templateUrl: './log-add-experiment.component.html',
	styleUrls: ['./log-add-experiment.component.scss'],
})
export class LogAddExperimentComponent implements OnInit {
	@Input() log: Log;
	public translate: string;

	constructor(public modalService: NgbModal, private experimentService: ExperimentService) {}

	ngOnInit(): void {
		this.translate = 'activity.actions.' + this.log.activity.replace(' ', '_');
	}

	open() {
		this.experimentService.openModal(this.log.experiment, this.log.product);
	}
}
