import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NextConfigService } from 'app/next-config.service';
import { Cloud } from 'parse';

@Component({
  selector: 'app-billing-details-modal',
  templateUrl: './billing-details-modal.component.html',
  styleUrls: ['./billing-details-modal.component.scss']
})
export class BillingDetailsModalComponent implements OnInit {
  @Input() billingDetailsShadow: object;
  public euCountries: string[];

  constructor(
	  public activeModal: NgbActiveModal,
	  public configService: NextConfigService
	) { }

  ngOnInit(): void {
	  this.euCountries = this.configService.euCountries
  }

  async saveDetails() {
    this.billingDetailsShadow = await Cloud.run('editBillingDetails', {
      details: this.billingDetailsShadow
    });
    this.activeModal.close(this.billingDetailsShadow);
  }

}
