<div class="container page">
	<div class="row">
		<div class="col-0 col-md-2"></div>
		<div class="col-12 col-md-8">
			<app-content-experiment [contentExperiment]="contentExperiment"></app-content-experiment>
			<ng-container *ngIf="relatedContentExperiments.length > 0">
				<h3 class="mb-3">{{ 'experiment.related' | translate }}</h3>
				<div class="row">
					<div class="col-12 col-md-4 mb-3" *ngFor="let experiment of relatedContentExperiments">
						<app-content-experiment-card [contentExperiment]="experiment" (cardClicked)="visitContentExperiment($event)"></app-content-experiment-card>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
