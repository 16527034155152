<div class="card h-100 hand" (click)="openInsight()">
	<div class="card-body">
		<p>
			<span *ngIf="insight.decision === InsightDecision.PERSEVERE" class="badge badge-light mb-1 mr-1"><i class="bi bi-sign-merge-left"></i> {{ 'insight.persevere.title' | translate }}</span>
			<span *ngIf="insight.decision === InsightDecision.PIVOT" class="badge badge-primary mb-1 mr-1"><i class="bi bi-sign-intersection-y"></i> {{ 'insight.pivot.title' | translate }}</span>
			<span *ngIf="insight.decision === InsightDecision.STOP" class="badge badge-danger mb-1 mr-1"><i class="bi bi-sign-dead-end"></i> {{ 'insight.stop.title' | translate }}</span>
			<span *ngIf="insight.experiment && showExperimentTag" class="badge light-blue hand mb-1"><i class="bi bi-eyedropper"></i>{{ abbrev }}-{{ insight.experiment.number }}</span>
		</p>
		<p class="card-text editor-data" [innerHtml]="insight.text"></p>
		<div class="card-meta d-flex flex-row justify-content-between align-items-center">
			<p class="small">{{ insight.createdAt | date: 'mediumDate' }}</p>
		</div>
	</div>
</div>
