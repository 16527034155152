<div [id]="id" [title]="id" class="segment" [ngClass]="{ selected: selectedSegment === id }">
	<p *ngIf="!!segmentName">{{ segmentName }}</p>
	<ng-container *ngFor="let sticky of allStickies">
		<div
			*ngIf="sticky.segment === id && !sticky.archived"
			class="mini-sticky"
			[ngClass]="{ risky: sticky.risky, validated: sticky.validated, invalidated: sticky.validated === false }"
		></div>
	</ng-container>
</div>
