<div class="modal-content">
	<div class="modal-header">
		<p *ngIf="!editable">{{ 'learning.singular' | translate }}</p>
		<p *ngIf="editable && learning.isNew()">{{ 'learning.new' | translate }}</p>
		<p *ngIf="editable && !learning.isNew()">{{ 'learning.edit' | translate }}</p>

		<button class="btn btn-link-white ml-auto" *ngIf="!editable" (click)="makeEditable()">{{ 'general.edit' | translate }}</button>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close(false)">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body edit" *ngIf="editable">
		<form class="row">
			<div class="col-12 col-md-10 mini-canvas mb-5">
				<label>{{ 'learningModal.segment' | translate }}</label>
				<app-canvas-mini
					[type]="learning.experiment.canvas.type"
					[showSegmentNames]="true"
					[selectedSegment]="learning.segment"
					[canSelectSegment]="true"
					[exampleStickies]="exampleStickies"
					[canvas]="learning.experiment.canvas"
					(selectCanvas)="onSelectSegment($event)"
					[portfolio]="learning.product.portfolio"
				>
				</app-canvas-mini>
			</div>
			<div class="col-12 form-group">
				<label>{{ 'keyQuestion.choose' | translate }}</label>
				<ng-select id="selectKeyQuestion" name="keyQuestion" [(ngModel)]="learning.keyQuestion" [items]="questions">
					<ng-template ng-label-tmp let-item="item">
						<p *ngIf="!item.default">{{ item.question }}</p>
						<p *ngIf="item.default">{{ 'stageGateQuestions.' + item.stage + '.' + item.question | translate }}</p>
					</ng-template>
					<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
						<ng-container *ngIf="item.default">{{ 'stageGateQuestions.' + item.stage + '.' + item.question | translate }}</ng-container>
						<ng-container *ngIf="!item.default">{{ item.question }}</ng-container>
					</ng-template>
				</ng-select>
			</div>
			<div class="col-12 form-group">
				<label>{{ 'learningModal.question' | translate }}</label>
				<quill-editor name="learning" [(ngModel)]="learning.text" [styles]="{ height: '7rem' }" placeholder=""></quill-editor>
			</div>
			<div class="col-12 form-group">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" name="createAssumtion" [value]="true" id="createAssumtion" [(ngModel)]="createAssumption" />
					<label class="form-check-label" for="createAssumtion">{{ 'learningModal.createAssumption' | translate }}</label>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-body" *ngIf="!editable">
		<p class="mb-3">
			<span *ngIf="learning.segment" class="badge badge-light mr-1">{{ portfolioService.getSegmentName(learning.experiment.canvas, learning.segment) }}</span>
			<span *ngIf="learning.experiment" (click)="openExperiment()" class="badge light-blue hand"
				><i class="bi bi-eyedropper"></i>{{ learning.product.getAbbrev() }}-{{ learning.experiment.number }}</span
			>
		</p>
		<ng-container *ngIf="learning.keyQuestion">
			<p class="modal-body-header">{{ 'keyQuestion.contributes' | translate }}</p>
			<p *ngIf="learning.keyQuestion.default" class="mb-3">
				{{ 'stageGateQuestions.' + learning.keyQuestion.stage + '.' + learning.keyQuestion.question | translate }}
			</p>
			<p *ngIf="!learning.keyQuestion.default" class="mb-3">{{ learning.keyQuestion.question }}</p>
		</ng-container>

		<h2 class="mb-4 modal-body-title editor-data" [innerHtml]="learning.text"></h2>
	</div>
	<div class="modal-footer">
		<button class="btn btn-link-danger mr-auto" *ngIf="!learning.isNew()" (click)="deleteLearning()">
			{{ 'general.remove' | translate }}
		</button>
		<button *ngIf="editable && !learning.isNew()" ngbAutofocus type="button" class="btn btn-primary" (click)="onSave()" [disabled]="!learning.text">
			{{ 'general.save' | translate }}
		</button>
		<button *ngIf="editable && learning.isNew()" ngbAutofocus type="button" class="btn btn-primary" (click)="onSave()" [disabled]="!learning.text">
			{{ 'learning.create' | translate }}
		</button>
		<button *ngIf="!editable" ngbAutofocus type="button" class="btn btn-primary" (click)="activeModal.close()">
			{{ 'general.close' | translate }}
		</button>
	</div>
</div>
