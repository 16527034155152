import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as Parse from 'parse';
import { Observable } from 'rxjs';


@Injectable()
export class AdminGuard implements CanActivate {
	constructor(private router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (!Parse.User.current()) {
			this.router.navigate(['login']);
			return false;
		}

		// Has the current the user Admin role?
		return new Promise<boolean>((resolve, reject) => {
			const query = new Parse.Query(Parse.Role);
			query.equalTo('users', Parse.User.current());
			query.equalTo('name', 'admins');
			query.first().then((role) => {
				if (!role) {
					this.router.navigate(['']);

					reject(false);
					return;
				}

				resolve(true);
			});
		});
	}
}
