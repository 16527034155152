<div class="modal-content">
	<div class="modal-header">
		<p>{{ 'progress.update' | translate }}</p>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="container-fluid">
			<ng-container *ngFor="let object of learningsPerQuestion">
				<div class="row align-items-center mb-2">
					<p class="col-12 col-md-9 header mb-0" *ngIf="object.question.default">{{ 'stageGateQuestions.' + product.stage + '.' + object.question.question | translate }}</p>
					<p class="col-12 col-md-9 header mb-0" *ngIf="!object.question.default">{{ object.question.question }}</p>
					<div class="col-12 col-md-3 range-container">
						<input type="range" min="1" max="5" class="custom-range" [name]="'question-' + object.question.id" [(ngModel)]="newProgress.questions[object.question.id]" (change)="onChange($event)"/>
						<p class="left">{{ 'progress.notConfident' | translate }}</p>
						<p class="right">{{ 'progress.confident' | translate }}</p>
					</div>
				</div>
				<div class="row">
					<div *ngFor="let learning of object.learnings" class="col-4 learning-container">
						<app-learning-card [learning]="learning" [showExperimentTag]="true"></app-learning-card>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="updateProgress()" [disabled]="!progressHasChanged" >{{ 'progress.update' | translate }}</button>
	</div>
</div>
