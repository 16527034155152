import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Cockpit } from '@shared/models/cockpit';

@Component({
  selector: 'app-cockpit-field-modal',
  templateUrl: './cockpit-field-modal.component.html',
  styleUrls: ['./cockpit-field-modal.component.scss']
})
export class CockpitFieldModalComponent implements OnInit {
  @Input() cockpit: Cockpit;
  @Input() editable: Boolean;

  constructor(public activeModal: NgbActiveModal, private translate: TranslateService) { }

  ngOnInit(): void {
  }

  createCockpit() {
    if (!this.cockpit.name || !this.cockpit.isNew()) {
      return;
    }

    this.saveEditable();
    this.activeModal.close(this.cockpit);
  }

  async deleteCockpit() {
    this.cockpit.archived = true;
    await this.cockpit.save();
    this.activeModal.close('deleted');
  }

  saveEditable() {
    this.cockpit.save();
    this.editable = false;
  }

  makeEditable() {
    this.editable = true;
  }

}
