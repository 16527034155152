import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-team-knowledge-card',
	templateUrl: './team-knowledge-card.component.html',
	styleUrls: ['./team-knowledge-card.component.scss'],
})
export class TeamKnowledgeCardComponent {
	@Input() knowledge: number;
	@Input() data: Object[];
	@Input() showPortfolioData: boolean;
	@Output() openChart: EventEmitter<any> = new EventEmitter<any>();

	constructor() {}

	openKnowledgeChart() {
		this.openChart.emit(true);
	}
}
