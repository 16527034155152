import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { Canvas } from '../../../../shared/models/canvas';
import { Sticky } from '../../../../shared/models/sticky';
import { StickyService } from '../sticky.service';

@Component({
	selector: 'app-canvas-sticky',
	templateUrl: './sticky.component.html',
	styleUrls: ['./sticky.component.scss'],
})
export class CanvasStickyComponent implements OnInit {
	@Input() sticky: Sticky;
	@Input() canvas: Canvas;
	@Input() canvasVersion: Date;
	@Input() canOpen: boolean;

	constructor(private stickyService: StickyService) {}

	ngOnInit() {
		dayjs.extend(isSameOrAfter);
	}

	isRisky() {
		// return (this.sticky.risky && dayjs(this.canvasVersion).isSameOrAfter(dayjs(this.sticky.riskyAt), 'day'));
		return this.sticky.getRiskyness() === 'most-risky' || this.sticky.risky;
	}

	isShown() {
		return (
			dayjs(this.canvasVersion).isSameOrAfter(dayjs(this.sticky.createdAt), 'day') &&
			(this.sticky.archived == false ||
				this.sticky.archived == undefined ||
				dayjs(this.canvasVersion).isBefore(dayjs(this.sticky.archivedAt), 'day'))
		);
	}

	isValidated() {
		return this.sticky.validated && dayjs(this.canvasVersion).isSameOrAfter(dayjs(this.sticky.validatedAt), 'day');
	}

	isInvalidated() {
		return (
			this.sticky.validated == false &&
			dayjs(this.canvasVersion).isSameOrAfter(dayjs(this.sticky.validatedAt), 'day')
		);
	}

	enableEditMode() {
		this.stickyService.openModal(this.sticky, this.canvas);
	}
}
