import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Cloud } from 'parse';
import { KeyQuestion } from '../../../../shared/models/key-question';
import { Portfolio } from '../../../../shared/models/portfolio';
import { KeyQuestionModalComponent } from '../key-question-modal/key-question-modal.component';

@Component({
	selector: 'app-key-question-overview',
	templateUrl: './key-question-overview.component.html',
	styleUrls: ['./key-question-overview.component.scss'],
})
export class KeyQuestionsOverviewComponent implements OnInit {
	public portfolio: Portfolio;
	public keyQuestions: KeyQuestion[];
	public filteredQuestions: object;
	public stages: string[];

	constructor(private modalService: NgbModal, public portfolioService: PortfolioService) {}

	ngOnInit(): void {
		this.getKeyQuestions();
	}

	async getKeyQuestions() {
		this.portfolio = await this.portfolioService.getActivePortfolio();
		const keyQuestionQuery = new Parse.Query(KeyQuestion);
		keyQuestionQuery.equalTo('portfolio', this.portfolio);
		keyQuestionQuery.equalTo('archived', false);
		keyQuestionQuery.ascending('orderIndex');
		this.keyQuestions = await keyQuestionQuery.find();
		this.filterQuestions();
	}

	async addKeyQuestion() {
		let keyQuestion = new KeyQuestion();
		keyQuestion.portfolio = this.portfolio;
		const modalRef = this.modalService.open(KeyQuestionModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.keyQuestion = keyQuestion;
		modalRef.componentInstance.editable = true;

		const result = await modalRef.result;
		if (result) {
			this.keyQuestions.push(result);
			this.filterQuestions();
		}
	}

	async savePortfolio() {
		await this.portfolio.save();
	}

	async openKeyQuestion(keyQuestion) {
		const modalRef = this.modalService.open(KeyQuestionModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.keyQuestion = keyQuestion;
		let result = await modalRef.result;
		if (result == 'deleted') {
			// remove key question from keyQuestions
			this.keyQuestions.splice(
				this.keyQuestions.findIndex((q) => q.id == keyQuestion.id),
				1
			);
			this.filterQuestions();
		}
	}

	filterQuestions() {
		this.filteredQuestions = {};
		this.stages = this.portfolioService.getMainAndKickboxStages(this.portfolio);
		for (let stage of this.stages) {
			this.filteredQuestions[stage] = [];
		}

		for (let keyQuestion of this.keyQuestions) {
			if (this.stages.indexOf(keyQuestion.stage) > -1) {
				this.filteredQuestions[keyQuestion.stage].push(keyQuestion);
			}
		}
	}

	dropKeyQuestion(event: CdkDragDrop<any[]>) {
		// When moving between stages
		if (event.previousContainer.data !== event.container.data) {
			event.item.data.stage = event.container.data;

			this.filterQuestions();
		}

		let keyQuestion = event.item.data;
		let stage = event.container.data as any;

		Cloud.run('reOrderKeyQuestion', {
			keyQuestionId: keyQuestion.id,
			stage: stage,
			newIndex: event.currentIndex,
		});

		// update visuals
		moveItemInArray(this.filteredQuestions[stage], this.filteredQuestions[stage].indexOf(keyQuestion), event.currentIndex);
	}
}
