import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanvasService } from 'app/canvas/canvas.service';
import { ExperimentModalAddComponent } from 'app/experiment/experiment-modal-add/experiment-modal-add.component';
import { ExperimentService } from 'app/experiment/experiment.service';
import { LogService } from 'app/log/log.service';
import { RemoveModalComponent } from 'app/validating-modals/remove-modal/remove-modal.component';
import { UnsavedModalComponent } from 'app/validating-modals/unsaved-modal/unsaved-modal.component';
import { Canvas } from '../../../../shared/models/canvas';
import { Experiment } from '../../../../shared/models/experiment';
import { Sticky } from '../../../../shared/models/sticky';
import { StickyRiskModalComponent } from '../sticky-risk-modal/sticky-risk-modal.component';
import { Query } from 'parse';
import { NextConfigService } from 'app/next-config.service';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { dir } from 'console';
import { Sprint } from '@shared/models/sprint';

@Component({
	selector: 'app-sticky-modal',
	templateUrl: './sticky-modal.component.html',
	styleUrls: ['./sticky-modal.component.scss'],
})
export class StickyModalComponent implements OnInit {
	@Input() sticky: Sticky;
	@Output() create: EventEmitter<any> = new EventEmitter();

	public canvas: Canvas;
	public exampleCanvas: Canvas;
	public exampleStickies: Sticky[];
	public canvases: Canvas[];
	public experiments: Experiment[];
	public canvasWasNotGiven = false;
	public editable = false;

	constructor(
		public activeModal: NgbActiveModal,
		private canvasService: CanvasService,
		public experimentService: ExperimentService,
		public modalService: NgbModal,
		public logService: LogService,
		public router: Router,
		public configService: NextConfigService,
		public portfolioService: PortfolioService
	) {}

	async ngOnInit() {
		this.exampleCanvas = new Canvas();
		this.exampleCanvas.type = this.canvas.type;
		this.exampleStickies = [];

		if (this.sticky.isNew()) {
			this.editable = true;

			this.sticky.confidenceScore = 5;
			this.sticky.impactScore = 5;
		} else {
			this.getLinkedExperiments();
		}
	}

	createSticky() {
		this.activeModal.close(true);
	}

	async deleteSticky() {
		const modalRef = this.modalService.open(RemoveModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.element = 'sticky';

		let removed = await modalRef.result;
		if (removed) {
			this.canvasService.deleteAssumption(this.sticky);
			this.activeModal.close();
		}
	}

	makeEditable() {
		this.editable = true;
	}

	saveEditable(event) {
		this.sticky.save();
		this.logService.logStickyActivity('edited sticky', this.sticky);

		this.editable = false;
	}

	getSegmentName() {
		return this.portfolioService.getSegmentName(this.canvas.product.portfolio, this.sticky.segment, this.canvas.type);
	}

	onSelectSegment(evt) {
		// Set canvas segment on Experiment, evt.segment comes from mini next/pre-validation
		this.sticky.segment = evt.segment;
	}

	onClearCanvas() {
		this.canvas = undefined;
		this.sticky.segment = undefined;
	}

	isComplete() {
		return !!this.sticky.segment && !!this.sticky.text;
	}

	openRiskModal() {
		this.modalService.open(StickyRiskModalComponent, {
			size: 'sm',
		});
	}

	async discard() {
		if (!this.editable || !this.sticky.dirty()) {
			this.activeModal.close();
			return;
		}

		const modal = this.modalService.open(UnsavedModalComponent, {
			size: 'sm',
			centered: true,
		});
		const result = await modal.result;

		if (result) {
			this.sticky.revert();
			this.activeModal.close();
		}
	}

	async newExperiment() {
		let experiment = new Experiment();
		experiment.product = this.sticky.product;
		experiment.sticky = this.sticky;
		experiment.segment = this.sticky.segment;
		experiment.canvas = this.sticky.canvas;
		experiment.type = 'confirm';

		const modalRef = this.modalService.open(ExperimentModalAddComponent, {
			size: 'md',
			centered: true,
		});
		modalRef.componentInstance.experiment = experiment;
		modalRef.componentInstance.product = experiment.product;
		modalRef.componentInstance.canvas = experiment.canvas;
		modalRef.componentInstance.startStep = 1;

		// add resulting experiment to backlog of product
		const result = (await modalRef.result) as Experiment;
		if (result) {

			// Fetch current sprint
			const query = new Query(Sprint);
			query.equalTo('product', result.product);
			query.containedIn('status', [Sprint.Status.RUNNING, Sprint.Status.PREPARING]);
			query.ascending('number');
			let sprint = await query.first();

			if (!sprint) {
				sprint = result.product.backlog;
			}

			sprint.add('experiments', result);
			await sprint.save();
		}

		this.activeModal.close();
		this.router.navigate(['portfolio', experiment.product.portfolio.id, 'startup', experiment.product.id, 'backlog']);
	}

	async getLinkedExperiments() {
		let query = new Query(Experiment);
		query.equalTo('sticky', this.sticky);
		query.equalTo('archived', false);
		this.experiments = await query.find();
	}

	openExperiment(experiment) {
		this.experimentService.openModal(experiment, this.sticky.product);
	}
}
