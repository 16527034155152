import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentExperiment } from '../../../../shared/models/content-experiment';
import { Query } from 'parse';
import { TranslateService } from '@ngx-translate/core';
import { ContentExperimentService } from '../content-experiment.service';
import { NextTitleService } from 'app/next-title.service';
import { Portfolio } from '@shared/models/portfolio';
import { PortfolioService } from 'app/portfolio/portfolio.service';

@Component({
	selector: 'app-content-experiment-detail',
	templateUrl: './content-experiment-detail.component.html',
	styleUrls: ['./content-experiment-detail.component.scss'],
})
export class ContentExperimentDetailComponent implements OnInit, OnDestroy {
	public portfolio: Portfolio;
	public contentExperiment: ContentExperiment;
	private routeSubscription;
	public relatedContentExperiments: ContentExperiment[];

	constructor(
		public route: ActivatedRoute,
		public translate: TranslateService,
		public router: Router,
		private titleService: NextTitleService,
		private contExpService: ContentExperimentService,
		private portfolioService: PortfolioService
	) {}

	async ngOnInit() {
		this.routeSubscription = this.route.params.subscribe(async (params) => {
			this.portfolio = await this.portfolioService.getActivePortfolio();
			await this.getContentExperiment(params['slug']);
			this.getRelatedExperiments();
		});
		this.relatedContentExperiments = [];

		this.translate.onLangChange.subscribe(async () => {
			await this.getContentExperiment(this.contentExperiment.slug);
			this.getRelatedExperiments();
		});

		this.portfolio = await this.portfolioService.getActivePortfolio();
	}

	ngOnDestroy() {
		this.routeSubscription.unsubscribe();
	}

	async getContentExperiment(slug) {
		let lang = this.translate.currentLang;
		let contentExperimentQuery = new Query(ContentExperiment);
		contentExperimentQuery.equalTo('slug', slug);
		contentExperimentQuery.equalTo('language', lang);
		contentExperimentQuery.containedIn('archived', [false, undefined, null]);
		this.contentExperiment = await contentExperimentQuery.first();

		if (!this.contentExperiment) {
			contentExperimentQuery.equalTo('language', this.translate.defaultLang);
			this.contentExperiment = await contentExperimentQuery.first();

			if (!this.contentExperiment) {
				this.router.navigate(['/portfolio', this.portfolio.id, 'experiment-tool']);
			}
		}
		this.titleService.setTitle(this.contentExperiment.title);
	}

	async getRelatedExperiments() {
		this.relatedContentExperiments = await this.contExpService.getRelatedContentExperiments(this.contentExperiment, this.portfolio);
	}

	visitContentExperiment(experiment) {
		this.router.navigate(['/portfolio', this.portfolio.id, 'experiment', experiment.slug]);
	}
}
