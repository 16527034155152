<div class="modal-content">
	<div class="modal-header">
		<p class="modal-title">{{ 'team.happiness' | translate }}</p>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="row chart-row">
			<div class="col-md-12">
				<canvas baseChart [datasets]="datasets" [labels]="labels" [options]="chartOptions" [colors]="chartColors" chartType="line" *ngIf="isReady"> </canvas>
			</div>
		</div>

		<div class="row">
			<div class="col-md-2">
				<p class="header">{{ 'sprint.plural' | translate }}</p>
			</div>
			<div class="col-md-10" *ngIf="isReady">
				<div *ngFor="let sprint of sortedSprints" class="d-flex flex-row justify-content-between align-items-center sprint-row">
					<p>{{ 'sprint.singular' | translate }} {{ sprint.number }}</p>
					<ng-container *ngIf="sprint.status === SprintStatus.RUNNING">
						<p class="current-sprint">{{ 'sprint.current' | translate }}</p>
					</ng-container>
					<div class="progress" *ngIf="sprint.status !== SprintStatus.RUNNING">
						<div class="progress-bar" role="progressbar" [style.width.%]="calcScore(sprint)" aria-valuemin="1" aria-valuemax="5"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
