import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root',
})
export class NextTitleService extends Title {
	setTitle(title: string) {
		super.setTitle(title + ' - GroundControl');
	}
}
