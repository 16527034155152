import { GndCtrlUser } from '../gndctrl-object';
import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Canvas } from './canvas';
import { Experiment } from './experiment';
import { Insight } from './insight';
import { Learning } from './learning';
import { Portfolio } from './portfolio';
import { ProductDecision } from './product-decision';
import { Progress } from './progress';
import { Sprint } from './sprint';
import { Thesis } from './thesis';
import { User } from './user';

@ParseSubClass('Product')
export class Product extends Parse.Object {
	@ParseProperty() public name: string;
	@ParseProperty() public logo: Parse.File;
	@ParseProperty() public pitch: string;
	@ParseProperty() public url: string;
	@ParseProperty() public ideaStatement: string;
	@ParseProperty() public problemStatement: string;
	@ParseProperty() public description: string;
	@ParseProperty() public isDemo: boolean;

	@ParseProperty() public createdBy: GndCtrlUser;
	@ParseProperty() public updatedBy: GndCtrlUser;

	@ParseProperty() public canvases: Array<Canvas>;

	@ParseProperty() public stage: Product.Stage;
	@ParseProperty() public thesis: Thesis;

	@ParseProperty() public startedAt: Date;
	@ParseProperty() public endedAt: Date;
	@ParseProperty() public startStage: string;

	@ParseProperty() public experiments: Parse.Relation<Experiment>;
	@ParseProperty() public activeSprint: Sprint;
	@ParseProperty() public portfolio: Portfolio;

	@ParseProperty() public learnings: Parse.Relation<Learning>;
	@ParseProperty() public insights: Parse.Relation<Insight>;
	@ParseProperty() public decisions: Parse.Relation<ProductDecision>;

	@ParseProperty() public archived: boolean;
	@ParseProperty() public stopped: boolean;
	@ParseProperty() public status: string;

	@ParseProperty() public innovationAccounting: any;
	@ParseProperty() public backlog: Sprint;

	@ParseProperty() public teamMembers: User[];

	@ParseProperty() public onboarding: any;
	@ParseProperty() public activeProgress: Progress;

	@ParseProperty() public lensValues: Object;
	@ParseProperty() public cockpitValues: Object;

	constructor() {
		super('Product');
	}

	getAbbrev() {
		let name = this.name;

		// filter non alphanumeric characters
		name = name.replace(/[^0-9a-z]/gi, '');

		// first 3 characters
		name = name.slice(0, 3);

		// uppercase
		name = name.toUpperCase();

		return name;
	}

	isStarted() {
		return this.stage !== Product.Stage.KICKBOX;
	}

	isStopped() {
		return !!this.stopped;
	}

	isValidated() {
		return this.stage === Product.Stage.VALIDATED;
	}

	isActive() {
		return this.isStarted() && !this.isStopped() && !this.isValidated();
	}
}

export namespace Product {
	export enum Stage {
		KICKBOX = 'kickbox',
		PROBLEM = 'problem',
		SOLUTION = 'solution',
		REVENUE = 'revenue',
		SCALE = 'scale',
		VALIDATED = 'validated',
		STAGE5 = 'stage5',
		STAGE6 = 'stage6',
	}
}
