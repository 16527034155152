import { Injectable } from "@angular/core";

@Injectable({
	providedIn: 'root',
})
export class QuillConfigService {
	public editorOptions = {
		toolbar: [
			['bold', 'italic', 'underline', 'strike'], // toggled buttons
			// [{ header: 1 }, { header: 2 }], // custom button values
			[{ list: 'ordered' }, { list: 'bullet' }],
			// [{ 'color': [] }/*, { 'background': [] }*/],          // dropdown with defaults from theme
			// [{ 'align': [] }],
			['link', 'image']
		],
		magicUrl: true
	}

	constructor() {}
}