import { Injectable } from '@angular/core';
import dayjs from 'dayjs'
import { Query } from 'parse';
import { Sprint } from '../../../shared/models/sprint';
import { Product } from '../../../shared/models/product';

@Injectable()
export class SprintService {
	constructor() {}

	getSprint(id: string, includes?: Array<string>): Promise<Sprint> {
		const query = new Query(Sprint);
		query.include('experiment');
		query.include(includes);

		return query.get(id);
	}

	getSprints(product: Product): Promise<Sprint[]> {
		const query = new Query(Sprint);
		query.equalTo('product', product);
		query.descending('number');

		return query.find() as Promise<Sprint[]>;
	}

	getRemainingDays(sprint: Sprint) {
		let end = dayjs(sprint.endedAt);
		let now = dayjs(new Date());

		return end.diff(now, 'days') + 1;
	}
}
