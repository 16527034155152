import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Portfolio } from '@shared/models/portfolio';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { ContentExperiment } from '../../../../shared/models/content-experiment';

@Component({
	selector: 'app-content-experiment',
	templateUrl: './content-experiment.component.html',
	styleUrls: ['./content-experiment.component.scss'],
})
export class ContentExperimentComponent implements OnInit, OnDestroy {
	@Input() contentExperiment: ContentExperiment;
	@Input() portfolio: Portfolio;
	private routeSubscription;

	get ContExpType() {
		return ContentExperiment.Type;
	}

	constructor(public portfolioService: PortfolioService, private route: ActivatedRoute) {}

	async ngOnInit() {
		this.routeSubscription = this.route.params.subscribe(async (params) => {
			if (Object.keys(params).length > 0) {
				// check if used in admin or in portfolio
				this.portfolio = await this.portfolioService.getActivePortfolio();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.routeSubscription) {
			this.routeSubscription.unsubscribe();
		}
	}
}
