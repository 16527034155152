import { Component, OnDestroy, OnInit } from '@angular/core';
import { CanvasService } from 'app/canvas/canvas.service';
import { LogService } from 'app/log/log.service';
import * as Parse from 'parse';
import { Query, Role } from 'parse';
import { Product } from '@shared/models/product';
import { User } from '@shared/models/user';
import { Invite } from '@shared/models/invite';
import { slugifyFile } from '@shared/slugify';
import { ProductService } from '../../product/product.service';
import { Portfolio } from '@shared/models/portfolio';
import { TranslateService } from '@ngx-translate/core';
import { NextConfigService } from 'app/next-config.service';
import { NextTitleService } from 'app/next-title.service';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-product-settings',
	templateUrl: './product-settings.component.html',
	styleUrls: ['./product-settings.component.scss'],
})
export class ProductSettingsComponent implements OnInit, OnDestroy {
	public portfolio: Portfolio;
	public product: Product;
	public products: Product[];
	public routeSubscription;

	public resultMessage: string;

	constructor(
		public canvasService: CanvasService,
		public productService: ProductService,
		public logService: LogService,
		private titleService: NextTitleService,
		public translate: TranslateService,
		private portfolioService: PortfolioService,
		public route: ActivatedRoute,
		public router: Router
	) {}

	async ngOnInit() {
		this.portfolio = await this.portfolioService.getActivePortfolio();

		this.routeSubscription = this.route.url.subscribe(async (url) => {
			let productId;
			if (url.length > 1) {
				productId = url[1].path;
			}

			this.products = await this.productService.getProducts();

			if (!!productId) {
				const productquery = new Query(Product);
				productquery.equalTo('archived', false);
				this.product = await productquery.get(productId);

				this.titleService.setTitle('Settings  - ' + this.product.name);
			} else {
				this.router.navigate(['portfolio', this.portfolio.id, 'settings', 'product-settings', this.products[0].id]);
			}
		});
	}

	ngOnDestroy() {
		this.routeSubscription?.unsubscribe();
	}

	async switchProduct() {
		this.router.navigate(['portfolio', this.portfolio.id, 'settings', 'product-settings', this.product.id]);
	}
}
