<div class="container login">
	<div class="row justify-content-center mt-5">
		<form class="col-12 col-md-4">
			<div class="row mb-3">
				<h1 view-title class="col-12">{{ 'signupThankyou.title' | translate }}</h1>
				<p class="col-12">{{ 'signupThankyou.description' | translate }}</p>
			</div>
		</form>
	</div>
</div>
