import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';

@ParseSubClass('Thesis')
export class Thesis extends Parse.Object {
	@ParseProperty() public title: string;
	@ParseProperty() public description: string;
	@ParseProperty() public createdBy: Parse.User;
	@ParseProperty() public color: string;

	constructor() {
		super('Thesis');
	}
}
