<div *ngIf="!product.isStarted() && !product.isStopped()" class="row align-items-center pre-launch-container p-3">
	<div class="col-auto">
		<img src="/assets/img/product/pre-launch.svg" class="img-fluid" />
	</div>
	<div class="col">
		<h4>{{ 'product.notStarted.title' | translate: { name: product.name } }}</h4>
		<p>{{ 'product.notStarted.explanation' | translate: { name: product.name } }}</p>
	</div>
	<div class="col-auto text-right">
		<a class="btn btn-primary" (click)="newDecision()">{{ 'product.start' | translate: { name: product.name } }}</a>
	</div>
</div>
<div *ngIf="product.isStopped()" class="row align-items-center stopped-container p-3">
	<div class="col-auto">
		<img src="/assets/img/product/pre-launch.svg" class="img-fluid" />
	</div>
	<div class="col">
		<h4>{{ 'product.invalidated.title' | translate: { name: product.name } }}</h4>
		<p>{{ 'product.invalidated.explanation' | translate: { name: product.name } }}</p>
	</div>
</div>
<div *ngIf="product.isValidated()" class="row align-items-center validated-container p-3">
	<div class="col-auto">
		<img src="/assets/img/product/pre-launch.svg" class="img-fluid" />
	</div>
	<div class="col">
		<h4>{{ 'product.validated.title' | translate: { name: product.name } }}</h4>
		<p>{{ 'product.validated.explanation' | translate: { name: product.name } }}</p>
	</div>
</div>
