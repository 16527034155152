<div class="card drag-cursor">
	<div class="card-body">
		<p>{{ task.title }}</p>
		<div class="row align-items-end">
			<div class="col-12 col-md-6">
				<a class="identifier" *ngIf="badge" [routerLink]="['../startup', task.product.id, 'sprint']">{{ task.product.name }}</a>
				<div class="color-bar mt-1" *ngIf="task.experiment" [ngClass]="{ confirm: task.experiment && task.experiment.type === 'confirm', learn: task.experiment && task.experiment.type === 'learn' }"></div>
			</div>
			<div class="col-12 col-md-6">
				<i *ngIf="task.description" class="bi bi-chat-left-text description-icon" [style]="task.assignedUsers.length > 0? 'right: ' + (task.assignedUsers.length * 0.75 + 1.75) + 'rem;' : 'right: 1rem;'"></i>
				<app-avatar-row [users]="task.assignedUsers" direction="right" [avatarSize]="1"></app-avatar-row>
			</div>
		</div>
	</div>
</div>
