import { Component, Input, OnInit } from '@angular/core';
import { TaskService } from 'app/task/task.service';
import { Log } from '../../../../../shared/models/log';

@Component({
	selector: 'app-log-task',
	templateUrl: './log-task.component.html',
	styleUrls: ['./log-task.component.scss'],
})
export class LogTaskComponent implements OnInit {
	@Input() log: Log;
	public translate: string;

	constructor(private taskService: TaskService) {}

	ngOnInit(): void {
		this.translate = 'activity.actions.' + this.log.activity.replace(' ', '_');
	}

	open() {
		this.taskService.openModal(this.log.task, this.log.sprint, this.log.product);
	}
}
