<div class="modal-header">
	<p>{{ 'portfolio.new' | translate }}</p>

	<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<form>
		<div class="form-group">
			<label
				>{{ 'portfolioModalAdd.label' | translate }}
				<input type="text" class="form-control" name="name" [(ngModel)]="portfolio.name" />
			</label>
		</div>
		<div class="form-group">
			<div class="custom-control custom-switch">
				<input type="checkbox" class="custom-control-input" name="addDemoStartups" id="switchDemo" [(ngModel)]="addDemoStartups">
				<label class="custom-control-label" for="switchDemo">{{ 'product.addDemo' | translate }}</label>
			</div>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button ngbAutofocus type="button" class="btn btn-primary" (click)="onSave()">{{ 'portfolio.add' | translate }}</button>
</div>
