import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as Parse from 'parse';
import { Observable } from 'rxjs';


@Injectable()
export class PortfolioAccessGuard implements CanActivate {
	constructor(private router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (!Parse.User.current()) {
			this.router.navigate(['login']);
			return false;
		}

		// Is current user included in portfolio role?
		let portfolioId;
		if (route.parent.params.portfolio) {
			portfolioId = route.parent.params.portfolio;
		} else {
			portfolioId = route.parent.parent.params.portfolio;
		}
		return new Promise<boolean>((resolve, reject) => {
			const query = new Parse.Query(Parse.Role);
			query.equalTo('users', Parse.User.current());
			query.equalTo('name', 'portfolio-' + portfolioId);
			query.first().then((role) => {
				if (!role) {
					this.router.navigate(['portfolio', portfolioId]);

					reject(false);
					return;
				}

				resolve(true);
			});
		});
	}
}
