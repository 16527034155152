<div class="settings-content">
	<h5>{{ 'cockpitField.plural' | translate }}</h5>
	<hr />
	<div
		id="cockpit"
		class="cockpit-container"
		cdkDropList
		(cdkDropListDropped)="dropItem($event)"
	>
		<div *ngFor="let cockpit of cockpits" class="question-row" cdkDrag [cdkDragData]="cockpit">
			<div class="my-2" (click)="openModal(cockpit)">
				<p>
					<i class="bi bi-grip-vertical drag-cursor text-muted mr-2"></i>
					{{ cockpit.name }}</p>
			</div>
			<div *cdkDragPlaceholder matchSize="true" class="placeholderblock"></div>
		</div>
	</div>
	<button class="btn btn-primary mt-2" (click)="addCockpit()">{{ 'cockpitField.add' | translate }}</button>
</div>
