import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NextConfigService } from 'app/next-config.service';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { ProductService } from 'app/product/product.service';
import { TaskService } from 'app/task/task.service';
import { LiveQuerySubscription, Query } from 'parse';
import { SprintService } from '../sprint.service';
import { Product } from '../../../../shared/models/product';
import { Experiment } from '../../../../shared/models/experiment';
import { Task } from '../../../../shared/models/task';
import { User } from '../../../../shared/models/user';
import { NextTitleService } from 'app/next-title.service';

@Component({
	selector: 'app-your-work',
	templateUrl: './your-work.component.html',
	styleUrls: ['./your-work.component.scss'],
})
export class YourWorkComponent implements OnInit, OnDestroy {
	public product: Product;
	public experiments: Experiment[];
	public tasks: Task[];
	public toDos: Task[];
	public inProgress: Task[];
	public done: Task[];
	public isReady = false;

	get TaskStatus() {
		return Task.Status;
	}

	private taskSubscription: LiveQuerySubscription;

	constructor(
		public route: ActivatedRoute,
		public productService: ProductService,
		public sprintService: SprintService,
		public taskService: TaskService,
		public configService: NextConfigService,
		public router: Router,
		public modalService: NgbModal,
		private portfolioService: PortfolioService,
		private titleService: NextTitleService
	) {}

	async ngOnInit() {
		this.experiments = [];
		const portfolio = await this.portfolioService.getActivePortfolio();

		this.titleService.setTitle('Your Work - ' + portfolio.name);

		let productQuery = new Query(Product);
		productQuery.equalTo('portfolio', portfolio);
		productQuery.equalTo('archived', false);

		const taskQuery = new Query(Task);
		taskQuery.equalTo('archived', false);
		taskQuery.equalTo('assignedUsers', User.current());
		taskQuery.include(['experiment', 'product', 'assignedUsers']);
		taskQuery.matchesKeyInQuery('sprint', 'activeSprint', productQuery);

		this.isReady = true;
		const tasks = await taskQuery.find();

		this.filterTasks(tasks);

		// Setup live query for Tasks
		this.taskSubscription = await taskQuery.subscribe();
		this.taskSubscription.on('update', () => {
			this.filterTasks(tasks);
		});
		this.taskSubscription.on('enter', () => {
			this.filterTasks(tasks);
		});
		this.taskSubscription.on('leave', () => {
			this.filterTasks(tasks);
		});
	}

	ngOnDestroy() {
		if (this.taskSubscription) {
			this.taskSubscription.unsubscribe();
		}
	}

	filterTasks(tasks) {
		this.toDos = [];
		this.inProgress = [];
		this.done = [];

		tasks.forEach((task) => {
			if (task.status === Task.Status.DOING) {
				this.inProgress.push(task);
			} else if (task.status === Task.Status.DONE) {
				this.done.push(task);
			} else {
				this.toDos.push(task);
			}
		});
	}

	async dropTask(event: CdkDragDrop<string[]>) {
		if (event.previousContainer !== event.container) {
			// changed status
			let task = event.item.data;
			task.status = event.container.data[1];
			task.save();

			// update visuals
			let newContainer = <any[]>(<unknown>event.container.data[0]);
			let previousContainer = <any[]>(<unknown>event.previousContainer.data[0]);
			transferArrayItem(previousContainer, newContainer, event.previousIndex, 0);
		}
		// else: reordered
	}

	openTask(task) {
		this.taskService.openModal(task, task.sprint, task.product);
	}
}
