import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Portfolio } from './portfolio';
import { User } from './user';

@ParseSubClass('KeyQuestion')
export class KeyQuestion extends Parse.Object {
	@ParseProperty() public question: string;
	@ParseProperty() public stage: string;
	@ParseProperty() public createdBy: User;
	@ParseProperty() public updatedBy: User;
	@ParseProperty() public portfolio: Portfolio;
	@ParseProperty() public archived: boolean;
	@ParseProperty() public default: boolean;
	@ParseProperty() public orderIndex: number;

	constructor() {
		super('KeyQuestion');
	}
}
