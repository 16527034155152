import { Component, Input, OnInit } from '@angular/core';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Product } from '../../../../shared/models/product';

@Component({
	selector: 'app-stages-card',
	templateUrl: './stages-card.component.html',
	styleUrls: ['./stages-card.component.scss'],
})
export class StagesCardComponent implements OnInit {
	@Input() product: Product;
	@Input() timeSpentStages: Object;
	@Input() moneyReceivedStages: Object;
	@Input() moneySpentStages: Object;
	public stages: string[];

	constructor(public portfolioService: PortfolioService) {}

	ngOnInit(): void {
		this.stages = this.portfolioService.getMainStages(this.product.portfolio);
	}
}
