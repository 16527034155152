import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductDecision } from '../../../../shared/models/product-decision';
import { Progress } from '../../../../shared/models/progress';
import { Sprint } from '../../../../shared/models/sprint';
import { Cloud, Query } from 'parse';
import { Learning } from '../../../../shared/models/learning';
import { ChartHappinessModalComponent } from '../../accounting/chart-happiness-modal/chart-happiness-modal.component';
import { ChartKnowledgeModalComponent } from '../../accounting/chart-knowledge-modal/chart-knowledge-modal.component';
import { ProductService } from '../../product/product.service';
import { DecisionModalComponent } from '../decision-modal/decision-modal.component';
import dayjs from 'dayjs';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Sticky } from '@shared/models/sticky';
import { StickyService } from 'app/sticky/sticky.service';
import { Experiment } from '@shared/models/experiment';

@Component({
	selector: 'app-prepared-decision-info',
	templateUrl: './prepared-decision-info.component.html',
	styleUrls: ['./prepared-decision-info.component.scss'],
})
export class PreparedDecisionInfoComponent implements OnInit {
	@Input() decision: ProductDecision;
	public sprints: Sprint[];
	public latestHappiness: number;
	public latestEducation: number;
	public currentQuarter: any;
	public learningsPerQuestion: any[];
	public newProgress: Progress;
	public duration = 0;
	public stagesSummaryIA: Object;
	public riskyStickies: Sticky[];
	public experiments: Experiment[];

	private previousDecision: ProductDecision;

	get DecisionType() {
		return ProductDecision.DecisionType;
	}

	get DecisionStatus() {
		return ProductDecision.Status;
	}

	constructor(
		private modalService: NgbModal,
		private productService: ProductService,
		public portfolioService: PortfolioService,
		private stickyService: StickyService
	) { }

	async ngOnInit() {
		// fetch previous decision
		this.previousDecision = await new Query(ProductDecision)
			.equalTo('product', this.decision.product)
			.equalTo('status', ProductDecision.Status.DONE)
			.equalTo('archived', false)
			.descending('decisionDate')
			.first();

		// await this.getLatestSprintInfo();
		await this.getInnovationAccounting();
		await this.queryLearnings();
		await this.getInnovationStagesAndSummary();
		await this.fetchStickies();
		await this.getExperiments();

		const a = dayjs(this.decision.nextMeetingDate);
		const b = dayjs(this.decision.decisionDate);
		this.duration = Math.round(a.diff(b, 'months', true));
	}

	// async getLatestSprintInfo() {
	// 	this.sprints = await this.productService.getStartedSprints(this.decision.product);

	// 	// check if current sprint is finished, otherwise get previous sprint
	// 	if (this.sprints[0]?.status === Sprint.Status.DONE) {
	// 		this.latestHappiness = parseInt(this.sprints[0].teamHapiness);
	// 		this.latestEducation = parseInt(this.sprints[0].teamEducation);
	// 	} else {
	// 		if (this.sprints.length > 1) {
	// 			this.latestHappiness = parseInt(this.sprints[1].teamHapiness);
	// 			this.latestEducation = parseInt(this.sprints[1].teamEducation);
	// 			return;
	// 		}

	// 		this.latestHappiness = 0;
	// 		this.latestEducation = 0;
	// 	}
	// }

	async getInnovationAccounting() {
		const productIA = await Cloud.run('getInnovationAccountingProduct', {
			productId: this.decision.product.id,
		});

		const keys = Object.keys(productIA);
		this.currentQuarter = productIA[keys[keys.length - 1]];
	}

	async queryLearnings() {
		const stages = this.portfolioService.getMainStages(this.decision.product.portfolio);
		const questions = await this.portfolioService.getKeyQuestions(this.decision.product.portfolio);

		questions.sort((a, b) => {
			const aStage = this.portfolioService.segmentToStage(a.stage);
			const bStage = this.portfolioService.segmentToStage(b.stage);

			if (stages.indexOf(aStage) > stages.indexOf(bStage)) {
				return -1;
			} else if (stages.indexOf(aStage) < stages.indexOf(bStage)) {
				return 1;
			}

			return 0;
		});

		const learningQuery = new Query(Learning);
		learningQuery.equalTo('product', this.decision.product);
		learningQuery.equalTo('archived', false);
		learningQuery.greaterThan('createdAt', this.previousDecision.decisionDate);
		learningQuery.include(['experiment', 'keyQuestion']);
		learningQuery.descending('createdAt');
		const learnings = await learningQuery.find();

		this.learningsPerQuestion = [];
		for (let question of questions) {
			const filteredLearnings = learnings.filter((l) => l.keyQuestion?.id == question.id);

			if (!filteredLearnings.length) {
				continue;
			}

			const newQuestionObject = {
				question: question,
				learnings: filteredLearnings,
			};
			this.learningsPerQuestion.push(newQuestionObject);
		}
	}

	openHappinessModal() {
		const modalRef = this.modalService.open(ChartHappinessModalComponent, {
			size: 'md',
		});
		modalRef.componentInstance.sprints = this.sprints as Sprint[];
	}

	openKnowledgeModal() {
		const modalRef = this.modalService.open(ChartKnowledgeModalComponent, {
			size: 'md',
		});
		modalRef.componentInstance.sprints = this.sprints as Sprint[];
	}

	editDecision() {
		const modalRef = this.modalService.open(DecisionModalComponent, {
			size: 'md',
		});
		modalRef.componentInstance.decision = this.decision;
		modalRef.componentInstance.editable = true;
	}

	finalizeDecision() {
		this.decision.status = ProductDecision.Status.DONE;
		this.editDecision();
	}

	removeFile(file) {
		this.decision.remove('attachments', file);
		this.decision.save();
	}

	async getInnovationStagesAndSummary() {
		this.stagesSummaryIA = await Cloud.run('getInnovationAccountingStages', {
			productId: this.decision.product.id,
		});
	}

	async fetchStickies() {
		const stickyQuery = new Query(Sticky);
		stickyQuery.equalTo('product', this.decision.product);
		stickyQuery.greaterThan('confidenceScore', 5);
		stickyQuery.greaterThan('impactScore', 5);
		stickyQuery.equalTo('archived', false);
		stickyQuery.equalTo('validated', undefined);
		// stickyQuery.ascending('orderIndex');

		const stickies = await stickyQuery.find();
		const riskiestStickies = this.filterRiskyness(stickies, this.decision.product);

		this.riskyStickies = riskiestStickies;
	}

	// Different because it includes future stages
	filterRiskyness(stickies, product) {
		let mostRiskyStickies = [];
		let stages = this.portfolioService.getMainStages(product.portfolio);

		// Order by stage, early stages on top of later stages
		stickies.sort((a, b) => {
			const aStage = this.portfolioService.segmentToStage(a.segment);
			const bStage = this.portfolioService.segmentToStage(b.segment);

			if (stages.indexOf(aStage) > stages.indexOf(bStage)) {
				return -1;
			} else if (stages.indexOf(aStage) < stages.indexOf(bStage)) {
				return 1;
			}

			return 0;
		});

		for (let sticky of stickies) {
			if (sticky.getRiskyness() == 'most-risky') {
				mostRiskyStickies.push(sticky);
			}
		}

		return mostRiskyStickies;
	}

	async getExperiments() {
		const experimentQuery = new Query(Experiment);
		experimentQuery.equalTo('product', this.decision.product);
		experimentQuery.equalTo('status', 'analyzed');
		experimentQuery.greaterThan('startAt', this.previousDecision?.createdAt);
		experimentQuery.descending('endAt');
		experimentQuery.equalTo('archived', false);
		this.experiments = await experimentQuery.find();
	}
}
