<div class="modal-header">
	<p>{{ 'product.new' | translate }}</p>

	<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<form class="row">
		<div class="form-group col-md-6">
			<label
				>{{ 'product.title' | translate }}
				<input type="text" class="form-control" name="name" [(ngModel)]="product.name" ngbAutoFocus />
			</label>
		</div>
		<div class="form-group col-md-12">
			<label>{{ 'product.pitch' | translate }} <input type="text" class="form-control" name="pitch" [(ngModel)]="product.pitch" /> </label>
		</div>

		<div class="form-group col-12">
			<label class="mb-0" for="input-description">{{ 'description.singular' | translate }}</label>
			<quill-editor id="input-description" name="description" [(ngModel)]="product.description" [styles]="{ height: '10rem' }" [placeholder]=""></quill-editor>
		</div>

		<div class="form-group col-9">
			<label for="fileInput" class="btn attachment"
				><i class="bi bi-camera"></i> {{ 'product.uploadLogo' | translate }}
				<input type="file" class="form-control-file" id="fileInput" accept="image/*" (change)="onLogoChange($event)" #logoInput />
			</label>
			<p>
				<ngb-progressbar type="primary" *ngIf="showProgress" [value]="progressUpload" height=".25rem"></ngb-progressbar>
			</p>
		</div>
		<div class="form-group col-3">
			<img *ngIf="product.logo" [src]="product.logo?.url()" class="img-fluid logo px-0" />
		</div>
		<div class="form-group col-12">
			<label class="mb-0" for="input-problem-statement">{{ 'statement.problem' | translate }}</label>
			<quill-editor
				id="input-problem-statement"
				name="problemStatement"
				[(ngModel)]="product.problemStatement"
				[styles]="{ height: '10rem' }"
				[placeholder]="'statement.problemPlaceholder' | translate"
			></quill-editor>
		</div>

		<div class="form-group col-12">
			<label class="mb-0" for="input-idea-statement">{{ 'statement.idea' | translate }}</label>
			<quill-editor
				id="input-idea-statement"
				name="ideaStatement"
				[(ngModel)]="product.ideaStatement"
				[styles]="{ height: '10rem' }"
				[placeholder]="'statement.ideaPlaceholder' | translate"
			></quill-editor>
		</div>
	</form>
</div>
<!-- Show license notice when a product is started -->
<div class="modal-body">
	<ngb-alert class="wizard" [dismissible]="false"><i class="bi bi-credit-card mr-3"></i> {{ 'general.notLicenseNotice' | translate }}</ngb-alert>
</div>
<div class="modal-footer">
	<button ngbAutofocus type="button" class="btn btn-primary" (click)="onSave()">{{ 'product.add' | translate }}</button>
</div>
