import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import * as Parse from 'parse';
import { Query, Role } from 'parse';
import { User } from '../../../../shared/models/user';
import { AnalyticsService } from '../../shared/analytics.service';
import { Portfolio } from '../../../../shared/models/portfolio';
import { NextConfigService } from 'app/next-config.service';
import { NextTitleService } from 'app/next-title.service';
import { AfterViewInit } from '@angular/core';

declare let Headway: any;

//declare gives Angular app access to ga function
declare let gtag: Function;

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
	public portfolios: Array<Portfolio>;
	public activePortfolio: Portfolio;
	public user: User;
	public authenticated = false;
	public isCollapsed = false;
	public userInPortfolio = false;
	public language;

	private subscription;
	private portfolioSubscription;

	constructor(
		private router: Router,
		private analytics: AnalyticsService,
		public translate: TranslateService,
		private portfolioService: PortfolioService,
		public configService: NextConfigService,
		private titleService: NextTitleService
	) {}

	async ngOnInit() {
		this.subscription = this.router.events.subscribe(async (evt) => {
			if (evt instanceof NavigationEnd) {
				try {
					const authenticated = !!User.current();

					// if authentication changes, update header with portfolio
					if (authenticated) {
						this.user = User.current() as User;

						if (authenticated !== this.authenticated) {
							this.setupPortfolio();
						}

						// Do not check email verified on the following pages:
						if (evt.url === '/validateEmail' || evt.url === '/signupThankyou') {
							return;
						}

						// If email is not verified, check
						// if (!this.user.emailVerified) {
						// 	await this.user.fetch();

						// 	// Still not verified? redirect
						// 	if (!this.user.emailVerified && !this.user.email.endsWith('@next.amsterdam')) {
						// 		this.router.navigate(['validateEmail']);
						// 	}
						// }

						// Track pageview with Google Analytics
						this.analytics.trackPageView(evt.urlAfterRedirects);
					}

					this.authenticated = authenticated;
				} catch (err) {
					if (err.code == 209) {
						this.onLogout();
					}

					console.log(err);
				}
			}
		});

		this.portfolioSubscription = this.portfolioService.portfolioSwitched.subscribe((portfolio) => {
			this.setupPortfolio();
		});
	}

	ngAfterViewInit() {
		// const script = document.createElement('script');
		// script.async = true;
		// script.src = 'https://cdn.headwayapp.co/widget.js';
		// document.head.appendChild(script);

		// // Headway release notes
		// // @see https://docs.headwayapp.co/widget for more configuration options.
		// var HW_config = {
		// 	selector: '.header-changelog', // CSS selector where to inject the badge
		// 	account: 'xMljgy',
		// };

		// script.onload = function () {
		// 	Headway.init(HW_config);
		// };
	}

	ngOnDestroy() {
		this.subscription?.unsubscribe();
		this.portfolioSubscription?.unsubscribe();
	}

	async setupPortfolio() {
		this.activePortfolio = await this.portfolioService.getActivePortfolio();
		this.portfolios = await this.portfolioService.getPortfolios();

		if (!this.activePortfolio) {
			this.userInPortfolio = false;
			return;
		}

		// check if user is in Portfolio role
		const roleQuery = new Query(Role);
		roleQuery.equalTo('users', User.current());
		roleQuery.equalTo('name', 'portfolio-' + this.activePortfolio.id);

		roleQuery.first().then((role) => {
			if (!!role) {
				this.userInPortfolio = true;
			} else {
				this.userInPortfolio = false;
			}
		});
	}

	switchPortfolio(portfolio: Portfolio) {
		this.activePortfolio = portfolio;
		this.portfolioService.setActivePortfolio(portfolio);
		this.router.navigate(['portfolio', portfolio.id]);
	}

	async onLogout() {
		User.logOut();
		this.analytics.logout();

		this.portfolioService.removeActivePortfolio();
		await Parse.dumpLocalDatastore();
		localStorage.clear();

		this.titleService.setTitle('');

		this.router.navigate(['/login']);
	}

	setLanguage(language: string) {
		// evt.preventDefault()
		localStorage.setItem('language', language);
		this.translate.use(language);
	}

	toggleMenu() {
		this.isCollapsed = !this.isCollapsed;
	}
}
