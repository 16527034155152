import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Minute } from '../../../../shared/models/minute';

@Component({
	selector: 'app-minute-modal',
	templateUrl: './minute-modal.component.html',
	styleUrls: ['./minute-modal.component.scss'],
})
export class MinuteModalComponent implements OnInit {
	@Input() minute: Minute;
	@Output() save: EventEmitter<any> = new EventEmitter();

	public shadowMinute: Minute;
	public editable = false;

	public sectionToolbar = {
		toolbar: [
			['bold', 'italic', 'underline', 'strike'], // toggled buttons
			[{ header: 1 }, { header: 2 }], // custom button values
			[{ list: 'ordered' }, { list: 'bullet' }],
		],
	};

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit() {
		if (this.minute.isNew()) {
			this.editable = true;
		}

		if (!this.minute.bodyHtml && this.minute.body) {
			this.minute.bodyHtml = this.minute.getBody();
			this.minute.body = '';
		}

		this.shadowMinute = this.minute.clone();
	}

	makeEditable() {
		this.editable = true;
	}

	onSave() {
		const dirtyKeys = this.shadowMinute.dirtyKeys();
		dirtyKeys.forEach((key) => {
			this.minute.set(key, this.shadowMinute.get(key));
		});

		this.save.emit({ minute: this.minute });

		if (this.minute.isNew()) {
			this.activeModal.close();
		} else {
			this.editable = false;
		}
	}
}
