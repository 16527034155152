import { Injectable } from '@angular/core';

import * as Parse from 'parse';
import { environment } from '../environments/environment';

@Injectable()
export class ParseService {
	static defineAttributes(object, attributes) {
		if (object instanceof Parse.Object) {
			if (!(attributes instanceof Array)) {
				attributes = Array.prototype.slice.call(arguments, 1);
			}
			attributes.forEach(function (attribute) {
				Object.defineProperty(object, attribute, {
					get: function () {
						return this.get(attribute);
					},
					set: function (value) {
						this.set(attribute, value);
					},
					configurable: true,
					enumerable: true,
				});
			});
		} else if (typeof object === 'function') {
			return ParseService.defineAttributes(object.prototype, attributes);
		} else {
			if (object instanceof Array) {
				attributes = object;
			} else {
				attributes = Array.prototype.slice.call(arguments, 0);
			}
			return function defineAttributesDecorator(target) {
				ParseService.defineAttributes(target, attributes);
			};
		}
	}

	static initialize() {
		Parse.initialize(environment.parse.appId, environment.parse.appKey);
		(Parse as any).serverURL = environment.parse.serverURL;

		// Use localstorage as potential cache
		Parse.enableLocalDatastore();

		// Encrypt user data in localstorage
		// Parse.enableEncryptedUser();
		// (Parse as any).secret = '9RxMosWBswaQJJLkexZWYZPNPPqsyCTNozPGCELl';

		// Keep one instance of every object in memory and keep them in sync
		(Parse.Object as any).enableSingleInstance();
	}

	static registerSubClass(name, object, attributes) {
		Parse.Object.registerSubclass(name, object);
		ParseService.defineAttributes(object, attributes);
	}
}
