import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { GndCtrlObject } from '../gndctrl-object';
import { User } from './user';

@ParseSubClass('FileMeta')
export class FileMeta extends GndCtrlObject {
	@ParseProperty() public name: string;
	@ParseProperty() public file: Parse.File;
	@ParseProperty() public createdAt: Date;
	@ParseProperty() public createdBy: User;
	@ParseProperty() public type: string;
	@ParseProperty() public archived: boolean;

	constructor() {
		super('FileMeta');
	}
}
