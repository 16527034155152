<div class="modal-content">
	<div class="modal-header">
		<p *ngIf="!editable">{{ 'experiment.singular' | translate }}</p>
		<p *ngIf="editable && contentExperiment.isNew()">{{ 'experiment.new' | translate }}</p>
		<p *ngIf="editable && !contentExperiment.isNew()">{{ 'experiment.edit' | translate }}</p>

		<button class="btn btn-link-white ml-auto" *ngIf="!editable" (click)="makeEditable()">{{ 'general.edit' | translate }}</button>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body edit" *ngIf="editable">
		<!-- Start with translatable content -->
		<div class="flag-container right" *ngIf="translatable">
			<div class="btn-group" role="group" *ngFor="let language of currentLanguages">
				<button
					class="ml-2 btn"
					[ngClass]="{ 'btn-primary': language === contentExperiment.language, 'btn-light': language !== contentExperiment.language }"
					(click)="switchTranslation(contentExperiments[language])"
				>
					<span *ngIf="language === 'en'" class="flag-icon flag-icon-gb mr-1"></span>
					<span *ngIf="language !== 'en'" [ngClass]="'mr-1 flag-icon flag-icon-' + language"></span>
					{{ configService.fullLanguages[language] }}
					<i *ngIf="language !== contentExperiment.language" class="bi bi-pencil ml-2"></i>
				</button>
				<button class="btn btn-danger" (click)="deleteTranslation(language)">
					<i class="bi bi-x"></i>
				</button>
			</div>

			<div ngbDropdown class="ml-3 hand d-inline-block dropdown" placement="bottom-right" *ngIf="leftoverLanguages.length > 0">
				<button ngbDropdownToggle id="dropdownLanguages" class="btn btn-outline-primary" [title]="'translate.add' | translate">
					<i class="bi bi-plus"></i>
				</button>
				<div ngbDropdownMenu aria-labelledby="dropdownLanguages">
					<button ngbDropdownItem *ngFor="let leftoverLanguage of leftoverLanguages" (click)="addTranslation(leftoverLanguage)">
						{{ configService.fullLanguages[leftoverLanguage] }}
					</button>
				</div>
			</div>
		</div>
		<p class="text-danger text-right" *ngIf="!contentExperiment.language">{{ 'translate.selectLanguage' | translate }}</p>
		<div class="form-group">
			<label
				>{{ 'title.singular' | translate }}
				<input type="text" class="form-control" [(ngModel)]="contentExperiment.title" [disabled]="!contentExperiment.language" />
			</label>
		</div>
		<div class="form-group">
			<label
				>{{ 'contentExperiment.excerpt' | translate }}
				<textarea class="form-control" [(ngModel)]="contentExperiment.excerpt" rows="4" [disabled]="!contentExperiment.language"></textarea>
			</label>
		</div>
		<div class="form-group">
			<label for="selectStage">{{ 'stage.singular' | translate }}</label>
			<ng-select id="selectStage" name="stage" [(ngModel)]="contentExperiment.stages" [items]="stages" [multiple]="true" [disabled]="!contentExperiment.language">
				<ng-template ng-label-tmp let-item="item">
					<p>{{ !!contentExperiment.portfolio ? portfolioService.getStageName(contentExperiment.portfolio, item) : ('productStages.' + item | translate) }}</p>
				</ng-template>
				<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
					{{ !!contentExperiment.portfolio ? portfolioService.getStageName(contentExperiment.portfolio, item) : ('productStages.' + item | translate) }}
				</ng-template>
			</ng-select>
		</div>
		<div class="form-group">
			<label for="selectType">{{ 'type.singular' | translate }}</label>
			<ng-select id="selectType" name="type" [(ngModel)]="contentExperiment.types" [items]="types" [multiple]="true" [disabled]="!contentExperiment.language">
				<ng-template ng-label-tmp let-item="item">
					<p>{{ 'type.' + item | translate }}</p>
				</ng-template>
				<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
					{{ 'type.' + item | translate }}
				</ng-template>
			</ng-select>
		</div>
		<div class="form-group">
			<label for="helpsAnswer">{{ 'contentExperiment.helpsAnswer' | translate }}</label>
			<quill-editor
				id="helpsAnswer"
				name="helps answer"
				[modules]="quillConfigService.editorOptions"
				[(ngModel)]="contentExperiment.helpsAnswer"
				[styles]="{ height: '10rem' }"
				placeholder=""
				[disabled]="!contentExperiment.language"
			></quill-editor>
		</div>
		<div class="form-group">
			<label for="description">{{ 'description.singular' | translate }}</label>
			<quill-editor
				id="description"
				name="description"
				[modules]="quillConfigService.editorOptions"
				[(ngModel)]="contentExperiment.description"
				[styles]="{ height: '10rem' }"
				placeholder=""
				[disabled]="!contentExperiment.language"
			></quill-editor>
		</div>
		<div class="form-group">
			<label for="howToAnalyze">{{ 'contentExperiment.howToAnalyze' | translate }}</label>
			<quill-editor
				id="howToAnalyze"
				name="how to analyze"
				[modules]="quillConfigService.editorOptions"
				[(ngModel)]="contentExperiment.howToAnalyze"
				[styles]="{ height: '10rem' }"
				placeholder=""
				[disabled]="!contentExperiment.language"
			></quill-editor>
		</div>
		<div class="form-group">
			<label for="example">{{ 'example.singular' | translate }}</label>
			<quill-editor
				id="example"
				name="example"
				[modules]="quillConfigService.editorOptions"
				[(ngModel)]="contentExperiment.example"
				[styles]="{ height: '10rem' }"
				placeholder=""
				[disabled]="!contentExperiment.language"
			></quill-editor>
		</div>
		<div class="form-group">
			<label for="readMore">{{ 'contentExperiment.readMore' | translate }}</label>
			<quill-editor
				id="readMore"
				name="read more"
				[modules]="quillConfigService.editorOptions"
				[(ngModel)]="contentExperiment.readMore"
				[styles]="{ height: '10rem' }"
				placeholder=""
				[disabled]="!contentExperiment.language"
			></quill-editor>
		</div>
		<div class="form-group">
			<label for="source">{{ 'source.singular' | translate }}</label>
			<input id="source" type="text" class="form-control" [(ngModel)]="contentExperiment.source" [disabled]="!contentExperiment.language" />
		</div>
		<div class="form-group">
			<label for="standardExperimentPlan">{{ 'contentExperiment.standardExperimentPlan' | translate }}</label>
			<quill-editor
				id="standardExperimentPlan"
				name="standard experiment plan"
				[modules]="quillConfigService.editorOptions"
				[(ngModel)]="contentExperiment.standardExperimentPlan"
				[styles]="{ height: '10rem' }"
				placeholder=""
				[disabled]="!contentExperiment.language"
			></quill-editor>
		</div>
	</div>
	<div class="modal-body" *ngIf="!editable">
		<div class="flag-container right" *ngIf="translatable">
			<span
				*ngFor="let language of currentLanguages"
				class="mr-2 btn"
				[ngClass]="{ 'btn-primary': language === contentExperiment.language, 'btn-light': language !== contentExperiment.language }"
				(click)="switchTranslation(contentExperiments[language])"
			>
				<span *ngIf="language === 'en'" class="flag-icon flag-icon-gb mr-1"></span>
				<span *ngIf="language !== 'en'" [ngClass]="'mr-1 flag-icon flag-icon-' + language"></span>
				{{ configService.fullLanguages[language] }}
			</span>
		</div>
		<app-content-experiment [contentExperiment]="contentExperiment" [portfolio]="portfolio"></app-content-experiment>
	</div>
	<div class="modal-footer">
		<button class="btn btn-link-danger mr-auto" *ngIf="!contentExperiment.isNew()" (click)="deleteContentExperiment()">
			{{ 'contentExperiment.remove' | translate }}
		</button>
		<button *ngIf="!editable" type="button" class="btn btn-primary" (click)="activeModal.close()">
			{{ 'general.close' | translate }}
		</button>

		<button
			*ngIf="editable && translatable && !contentExperiment.isNew()"
			ngbAutofocus
			type="button"
			class="btn btn-primary ml-auto"
			(click)="onSave()"
			[disabled]="!contentExperiment.title || !contentExperiment.language || !(contentExperiment.stages?.length > 0)"
		>
			{{ 'translate.save' | translate }}
		</button>
		<button
			*ngIf="editable && translatable && contentExperiment.isNew()"
			ngbAutofocus
			type="button"
			class="btn btn-primary ml-auto"
			(click)="onSave()"
			[disabled]="!contentExperiment.title || !contentExperiment.language || !(contentExperiment.stages?.length > 0)"
		>
			{{ 'translate.create' | translate }}
		</button>
		<button
			*ngIf="editable && !translatable && !contentExperiment.isNew()"
			ngbAutofocus
			type="button"
			class="btn btn-primary ml-auto"
			(click)="onSave()"
			[disabled]="!contentExperiment.title || !contentExperiment.language || !(contentExperiment.stages?.length > 0)"
		>
			{{ 'contentExperiment.save' | translate }}
		</button>
		<button
			*ngIf="editable && !translatable && contentExperiment.isNew()"
			ngbAutofocus
			type="button"
			class="btn btn-primary ml-auto"
			(click)="onSave()"
			[disabled]="!contentExperiment.title || !contentExperiment.language || !(contentExperiment.stages?.length > 0)"
		>
			{{ 'contentExperiment.create' | translate }}
		</button>
	</div>
</div>
