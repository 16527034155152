<div>
	<canvas
		baseChart
		[datasets]="datasets"
		[labels]="stages.labels"
		[colors]="chartColors"
		[options]="chartOptions"
		chartType="bar"
		*ngIf="isReady"
	></canvas>
</div>
