import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ContentExperiment } from '@shared/models/content-experiment';
import { Portfolio } from '@shared/models/portfolio';
import { Product } from '@shared/models/product';
import { NextConfigService } from 'app/next-config.service';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { UnsavedModalComponent } from 'app/validating-modals/unsaved-modal/unsaved-modal.component';
import { ContentExperimentModalComponent } from '../content-experiment-modal/content-experiment-modal.component';

@Component({
	selector: 'app-content-experiment-overview',
	templateUrl: './content-experiment-overview.component.html',
	styleUrls: ['./content-experiment-overview.component.scss'],
})
export class ContentExperimentOverviewComponent implements OnInit, OnChanges {
	@Input() contentExperiments: ContentExperiment[];
	@Input() portfolio?: Portfolio;
	@Input() translatable?: boolean;
	public stageExperiments: object;
	public contExpsPerSlug: object;
	public stages: string[];

	get StageNames() {
		return Product.Stage;
	}

	constructor(public configService: NextConfigService, private modalService: NgbModal, public translate: TranslateService, public portfolioService: PortfolioService) {}

	async ngOnInit() {
		this.translate.onLangChange.subscribe(async () => {
			this.populateContentExperiments();
		});
		this.populateContentExperiments();
	}

	async ngOnChanges(changes: any) {
		this.populateContentExperiments();
	}

	// XexpPerSlug structure:
	// XexpPerSlug = {
	// 	slug: {
	// 		lang1: ContentExperiment,
	// 		lang2: ContentExperiment,
	// 	},
	// 	slug2: {
	// 		lang2: ContentExperiment,
	// 		lang1: ContentExperiment
	// 	},
	// 	slug3: {
	// 		lang1: ContentExperiment
	// 	}
	// }
	populateContentExperiments() {
		this.stages = [];
		if (this.portfolio) {
			// portfolio: used in portfolio, get stages from portfolio
			this.stages = this.portfolioService.getMainAndKickboxStages(this.portfolio);
		} else {
			// no portfolio: used in admin, get default stages
			this.stages = this.portfolioService.getDefaultStagesWithKickbox();
		}

		this.stageExperiments = [];
		this.contExpsPerSlug = {};

		for (let stage of this.stages) {
			this.stageExperiments[stage] = [];
		}

		this.contentExperiments.forEach((contentExperiment) => {
			if (!this.contExpsPerSlug[contentExperiment.slug]) {
				this.contExpsPerSlug[contentExperiment.slug] = {};
			}
			this.contExpsPerSlug[contentExperiment.slug][contentExperiment.language] = contentExperiment;
		});

		const slugs = Object.keys(this.contExpsPerSlug);
		for (let slug of slugs) {
			const langs = Object.keys(this.contExpsPerSlug[slug]);
			langs.sort();
			let translation;
			if (langs.includes(this.translate.currentLang)) {
				translation = this.contExpsPerSlug[slug][this.translate.currentLang];
			} else if (langs.includes(this.translate.defaultLang)) {
				translation = this.contExpsPerSlug[slug][this.translate.defaultLang];
			} else if (langs.length >= 0) {
				translation = this.contExpsPerSlug[slug][langs[0]];
			}

			for (let stage of this.stages) {
				if (translation.stages.includes(stage)) {
					this.stageExperiments[stage].push(translation);
				}
			}
		}
	}

	openContentExperiment(contentExperiment) {
		const modalRef = this.modalService.open(ContentExperimentModalComponent, {
			size: 'md',
			beforeDismiss: async () => {
				let langs = Object.keys(modalRef.componentInstance.contentExperiments);
				let result;

				for (let lang of langs) {
					if (modalRef.componentInstance.contentExperiments[lang].dirty()) {
						const modal = this.modalService.open(UnsavedModalComponent, {
							size: 'sm',
							centered: true,
						});
						result = await modal.result;

						if (result) {
							if (modalRef.componentInstance.contentExperiments[lang].isNew()) {
								delete modalRef.componentInstance.contentExperiments[lang];
							} else {
								modalRef.componentInstance.contentExperiments[lang].revert();
							}
						}
					}
				}
				return result;
			},
		});
		modalRef.componentInstance.contentExperiments = this.contExpsPerSlug[contentExperiment.slug];
		modalRef.componentInstance.translatable = this.translatable;
		modalRef.componentInstance.portfolio = this.portfolio;
	}
}
