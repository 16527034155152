<div class="row" *ngIf="!!product && !!decision">
	<div class="col-12 col-md-10 product-content">
		<div class="row">
			<div class="col-12 col-md-5">
				<h4>{{ 'decision.pitch.singular' | translate }}</h4>
			</div>
			<div class="col-12 col-md-7 d-flex">
				<button class="ml-auto mr-3 btn btn-link" (click)="editDecision()">{{ 'general.edit' | translate }}</button>
				<button class="mr-3 btn btn-light" (click)="downloadPitch()">{{ 'decision.downloadPitch' | translate }}</button>
				<button *ngIf="decision.status === DecisionStatus.PREPARED" class="btn btn-primary" (click)="finalizeDecision()">
					{{ 'decision.finalize' | translate }}
				</button>
			</div>
		</div>
		<app-prepared-decision-info [decision]="decision"></app-prepared-decision-info>
		<div class="row">
			<div class="col-12 col-md-12 d-flex">
				<button class="ml-auto mr-3 btn btn-link" (click)="editDecision(false)">{{ 'general.edit' | translate }}</button>
				<button class="mr-3 btn btn-light" (click)="downloadPitch()">{{ 'decision.downloadPitch' | translate }}</button>
				<button *ngIf="decision.status === DecisionStatus.PREPARED" class="btn btn-primary" (click)="finalizeDecision()">
					{{ 'decision.finalize' | translate }}
				</button>
			</div>
		</div>
	</div>
</div>
