<div class="container login">
	<div class="row justify-content-center mt-5">
		<div  class="col-4">
			<div class="row mb-3">
				<div class="col-12">
					<h1>{{ 'resetPassword.title' | translate }}</h1>
  					<p>{{ 'resetPassword.description' | translate:{'username': username} }}</p>

					<ngb-alert class="mt-3" *ngIf="errorMessage" type="danger" [dismissible]="false">{{ errorMessage }}</ngb-alert>
				</div>
			</div>
			<form class="row" id='form' ngNoForm [action]="formAction" method='POST'>
				<div class="col-12 form-group">
					<label for="new_password">{{ 'user.password' | translate }}</label>
					<input name="new_password" id="new_password" class="form-control" type="password" />
				</div>
				<div class="col-12 form-group">
					<input type="submit" class="btn btn-primary" value="Change Password" />
				</div>

				<input name='utf-8' type='hidden' value='✓' />
				<input name="username" id="username" type="hidden" [value]="username" />
				<input name="token" id="token" type="hidden" [value]="token" />
			</form>
		</div>
	</div>
</div>
