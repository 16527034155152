<div class="settings-content" *ngIf="portfolio">
	<div class="row">
		<div class="col-12 col-md">
			<h5>{{ 'experiment.plural' | translate }}</h5>
		</div>
		<div class="col-12 col-md-auto" *ngIf="!portfolio.defaultContentExperiments">
			<button class="btn btn-primary" (click)="createContentExperiment()">{{ 'experiment.new' | translate }}</button>
		</div>
	</div>

	<hr />
	<div class="custom-control custom-switch mb-5">
		<input type="checkbox" class="custom-control-input" id="switchDefaultContentExperiments" [(ngModel)]="portfolio.defaultContentExperiments" (change)="changeContentExperimentSetting()" />
		<label class="custom-control-label" for="switchDefaultContentExperiments">{{ 'contentExperiment.useDefault' | translate }}</label>
	</div>

	<ng-container *ngIf="contentExperiments?.length > 0 && !portfolio.defaultContentExperiments">
		<app-content-experiment-overview [contentExperiments]="contentExperiments" [portfolio]="portfolio"></app-content-experiment-overview>
	</ng-container>
</div>
