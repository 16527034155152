<div class="container-fluid page funnelWrapper funnel mt-3 pb-0">
	<div class="row justify-content-center mb-3" *ngIf="portfolio && portfolio.theses && portfolio.theses.length > 0">
		<div class="col text-center" *ngFor="let thesis of portfolio.theses; let i = index">
			<p><span class="color-bar" [style]="'background-color: ' + thesis.color"></span> {{ thesis.title }}</p>
		</div>
	</div>

	<div class="row">
		<div class="col-12 mb-3" ngbDropdown display="dynamic">
			<p class="lens-dropdown-btn">
				{{ 'lens.singular' | translate }}: <span ngbDropdownToggle id="lens-filter" class="header" role="button">{{ selectedLens.name | translate }}</span>
			</p>
			<div ngbDropdownMenu aria-labelledby="lens-filter" class="dropdown-menu">
				<div class="form-group mb-1 pl-3 pr-4 mt-1">
					<div class="form-check pt-1 pb-1">
						<input type="radio" class="form-check-input" name="default lens" id="default" [(ngModel)]="selectedLens" [value]="defaultLens" (change)="updateFunnel($event)" />
						<label for="default" class="form-check-label">{{ defaultLens.name | translate }}</label>
					</div>
					<div class="form-check pt-1 pb-1">
						<input type="radio" class="form-check-input" name="default lens" id="stopped" [(ngModel)]="selectedLens" [value]="stoppedLens" (change)="updateFunnel($event)" />
						<label for="stopped" class="form-check-label">{{ stoppedLens.name | translate }}</label>
					</div>
					<div *ngFor="let lens of lenses; index as lensIndex" class="form-check pt-1 pb-1">
						<input type="radio" class="form-check-input" [name]="lens.name" [id]="'lens-' + lensIndex" [(ngModel)]="selectedLens" [value]="lens" (change)="updateFunnel($event)" />
						<label [for]="'lens-' + lensIndex" class="form-check-label">{{ lens.name }}</label>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Confidence in Key questions lens -->
	<ng-container *ngIf="selectedLens.name === 'lens.default.confidence' || selectedLens.name === 'lens.default.stopped'">
		<div class="row">
			<div class="col-12 col-md stages" *ngFor="let stage of stages" [ngClass]="stage">
				<p>{{ portfolioService.getStageName(portfolio, stage) }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md funnel-column" *ngFor="let stage of stages" [ngClass]="stage">
				<div class="inner scrollbar" [id]="stage">
					<ng-container *ngFor="let product of populatedProducts">
						<app-funnel-card class="col-12 mb-3" *ngIf="product.stage === stage" [product]="product" [portfolio]="portfolio"></app-funnel-card>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-container>

	<!-- Other lenses -->
	<div class="scrollable pb-5" *ngIf="selectedLens.name !== 'lens.default.confidence' && selectedLens.name !== 'lens.default.stopped'">
		<div class="row" [ngStyle]="{ width: stages.length * 300 + 150 + 'px' }">
			<div class="col stages bucket-descriptors"><p></p></div>
			<div class="col stages" *ngFor="let stage of stages" [ngClass]="stage">
				<p>{{ portfolioService.getStageName(portfolio, stage) }}</p>
			</div>
		</div>
		<div class="row" [ngStyle]="{ width: stages.length * 300 + 150 + 'px' }" *ngFor="let bucket of buckets; index as bucketIndex">
			<div class="col funnel-column bucket-descriptors header">
				<div class="inner scrollbar text-right" [ngStyle]="{ height: bucketHeight + 'vh' }">
					<p *ngIf="selectedLens.value === 'Currency'">{{ bucket.value | currency: portfolio.currency:'symbol':'1.0-0' }}</p>
					<p *ngIf="selectedLens.value !== 'Currency'">{{ bucket.value }}</p>
				</div>
			</div>
			<div class="col funnel-column" *ngFor="let stage of stages" [ngClass]="stage">
				<div class="inner scrollbar" [ngStyle]="{ height: bucketHeight + 'vh' }" [id]="stage">
					<ng-container *ngFor="let product of bucket.products">
						<app-funnel-card *ngIf="product.stage === stage" [product]="product" [portfolio]="portfolio" [lens]="selectedLens"></app-funnel-card>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
