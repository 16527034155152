<div *ngIf="portfolio" class="settings-content">
	<h5>{{ 'lens.plural' | translate }}</h5>
	<hr />
	<div
		[id]="lens"
		class="lens-container"
		cdkDropList
		(cdkDropListDropped)="dropItem($event)"
	>
		<div  *ngFor="let lens of lenses" class=" question-row" cdkDrag [cdkDragData]="lens">
			<div class="my-2" (click)="openModal(lens)">
				<p>
					<i class="bi bi-grip-vertical drag-cursor text-muted mr-2"></i>
					{{ lens.name }}</p>
			</div>
			<div *cdkDragPlaceholder matchSize="true" class="placeholderblock"></div>
		</div>
	</div>
	<button class="btn btn-primary mt-2" (click)="addLens()">{{ 'lens.add' | translate }}</button>
</div>
