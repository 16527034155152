<div class="container signup">
	<div class="row align-items-center justify-content-around wrapper">
		<form id="Signup" name="Signup Platform" class="col-12 col-md-4">
			

			<h1 view-title>{{ 'signup.title' | translate }}</h1>

			<p *ngIf="invite">{{ 'signup.invitedBy' | translate:{'createdBy': invite.createdBy } }}</p>
			<p *ngIf="!invite">{{ 'signup.trial' | translate }}</p>

			<ngb-alert *ngIf="invalidInvite" class="mt-3" type="danger">
				<p>{{ 'invite.invalidMessage' | translate }}</p>
				<button class="btn btn-primary mt-2" [routerLink]="['/signup']">{{ 'signup.toStandard' | translate }}</button>
			</ngb-alert>
			<ngb-alert *ngIf="errorMessage" type="danger" [dismissible]="false" class="mt-3">{{ errorMessage }}</ngb-alert>
			
			<div *ngIf="signupType == ''">
				<div class="row mt-5">
					<div class="col-12 col-md-6 form-group">
						<label>{{ 'user.firstName' | translate }}
							<input type="text" class="form-control" placeholder="" name="firstName" [(ngModel)]="user.firstName" [disabled]="invalidInvite" required autocomplete />
						</label>
					</div>
					<div class="col-12 col-md-6 form-group">
						<label>{{ 'user.lastName' | translate }}
							<input type="text" class="form-control" placeholder="" name="lastName" [(ngModel)]="user.lastName" [disabled]="invalidInvite" required autocomplete />
						</label>
					</div>
				</div>
			
				<div class="row">
					<div class="col-12 form-group">
						<label>{{ 'user.company' | translate }}
							<input type="text" class="form-control" placeholder="" name="company" [(ngModel)]="user.company" [disabled]="invalidInvite" required />
						</label>
					</div>
				</div>
				<div class="row">
					<div class="col-12 form-group">
						<label>{{ 'user.email' | translate }}
							<input type="email" class="form-control" placeholder="" name="email" [(ngModel)]="user.email" [disabled]="invalidInvite" #email="ngModel" required autocomplete />
						</label>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-md-6 form-group">
						<label>{{ 'user.password' | translate }}
							<input type="password" class="form-control" name="password" [(ngModel)]="user.password" [disabled]="invalidInvite" required autocomplete />
						</label>
					</div>
					<div class="col-12 col-md-6 form-group">
						<label>{{ 'user.password-repeat' | translate }} <input type="password" class="form-control" name="password-repeat" [(ngModel)]="passwordRepeat" [disabled]="invalidInvite" required autocomplete />
						</label>
					</div>
				</div>
				<div class="row">
					<div class="col-12 form-group">
						<div class="form-check">
							<input type="checkbox" class="form-check-input" name="accept" id="accept" [(ngModel)]="user.acceptedTerms" [disabled]="invalidInvite" required />
							<label class="form-check-label" for="accept" [innerHtml]="'user.accept' | translate"></label>
						</div>
					</div>
				</div>
				<div class="row align-items-center">
					<div class="col-4 form-group">
						<button type="submit" class="btn btn-primary" (click)="onSignup()" [disabled]="invalidInvite">{{ 'signup.button' | translate }}</button>
					</div>
					<div class="col-8 form-group text-right">
						<a routerLink="/login" class="already-account">{{ 'signup.alreadyAccount' | translate }}</a>
					</div>
				</div>
			</div>
			<div *ngIf="signupType == 'sso'" class="row mt-5">
				<div class="col-12 form-group text-center">
					<button type="submit" class="btn btn-primary" (click)="onMicrosoft()"><i class="bi bi-windows mr-3"></i>{{ 'login.microsoft' | translate }}</button>
				</div>
			</div>
		</form>
		<div class="col-12 col-md-6 text-center companies">
			<h1 class="text-center">{{ 'signup.goodCompany.heading' | translate }}</h1>
			<p [innerHtml]="'signup.goodCompany.description' | translate"></p>
			<p class="text-center my-5"><img src="https://togroundcontrol.com/files/2019/08/dhl-logo-512x72.png" class="company"></p>
			<p class="text-center mb-5"><img src="https://togroundcontrol.com/files/2020/02/TMobile-logo-512x87.png" class="company"></p>
			<p class="text-center"><img src="https://togroundcontrol.com/files/2020/02/Rabobank-logo-512x209.png" class="company"></p>
		</div>
	</div>
</div>
