import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NextConfigService } from 'app/next-config.service';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { ThesisModalComponent } from 'app/thesis/thesis-modal/thesis-modal.component';
import { Portfolio } from '../../../../shared/models/portfolio';
import { Thesis } from '../../../../shared/models/thesis';

@Component({
  selector: 'app-portfolio-settings-thesis',
  templateUrl: './portfolio-settings-thesis.component.html',
  styleUrls: ['./portfolio-settings-thesis.component.scss']
})
export class PortfolioSettingsThesisComponent implements OnInit {
	public portfolio: Portfolio;

	constructor(
		public modalService: NgbModal,
		public configService: NextConfigService,
		private portfolioService: PortfolioService
	) { }

	async ngOnInit() {
		this.portfolio = await this.portfolioService.getActivePortfolio();

	}

  	openModal(thesis, editable) {
		const modalRef = this.modalService.open(ThesisModalComponent, {
			size: 'sm',
			centered: true,
			backdrop: 'static'
		});
		modalRef.componentInstance.thesis = thesis;
		modalRef.componentInstance.editable = editable;
		modalRef.componentInstance.portfolio = this.portfolio;
	}

	addThesis(){
		const newThesis = new Thesis();
		newThesis.color = this.configService.colors[this.portfolio.theses.length % this.configService.colors.length];
		this.openModal(newThesis, true);
	}

}
