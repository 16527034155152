import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Lens } from '@shared/models/lens';
import { RemoveModalComponent } from 'app/validating-modals/remove-modal/remove-modal.component';

@Component({
	selector: 'app-lens-modal',
	templateUrl: './lens-modal.component.html',
	styleUrls: ['./lens-modal.component.scss'],
})
export class LensModalComponent implements OnInit {
	@Input() lens: Lens;
	@Input() editable: Boolean;
	public lensOptions: string[];
	public selectedOption: string;

	get LensValues() {
		return Lens.LensValues;
	}

	constructor(public activeModal: NgbActiveModal, private translate: TranslateService, private modalService: NgbModal) {}

	ngOnInit(): void {
		if (this.lens.isNew()) {
			this.lens.labels = [];
		}

		this.lensOptions = this.getStandardOptions();
		this.lensOptions.push(this.translate.instant('general.custom'));

		// init selectedOption
		if (this.lens.value) {
			if (this.valueInStandardOptions(this.lens.value)) {
				this.selectedOption = this.lens.value;
			} else {
				this.selectedOption = this.translate.instant('general.custom');
			}
		}
	}

	getStandardOptions() {
		let standardOptions = [];
		for (let standardOption in Lens.LensValues) {
			standardOptions.push(Lens.LensValues[standardOption]);
		}
		return standardOptions;
	}

	valueInStandardOptions(option) {
		const standardOptions = this.getStandardOptions();
		return standardOptions.indexOf(option) > -1;
	}

	setValue() {
		if (this.lensOptions.indexOf(this.selectedOption) > -1) {
			// option is a standard option
			this.lens.value = this.selectedOption;
		}
	}

	makeEditable() {
		this.editable = true;
	}

	async deleteLens() {
		this.lens.archived = true;
		await this.lens.save();
		this.activeModal.close('deleted');
	}

	createLens() {
		if (!this.lens.name || !this.lens.value || !(this.lens.labels.length > 0) || !this.lens.isNew()) {
			return;
		}

		this.saveEditable();
		this.activeModal.close(this.lens);
	}

	saveEditable() {
		this.lens.save();
		this.editable = false;
	}
}
