import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExperimentModalAddComponent } from './experiment-modal-add/experiment-modal-add.component';
import { ExperimentModalComponent } from './experiment-modal/experiment-modal.component';
import { Query } from 'parse';
import { Task } from '../../../shared/models/task';

@Injectable({
	providedIn: 'root',
})
export class ExperimentService {
	constructor(private modalService: NgbModal) {}

	openModal(experiment, product) {
		const modalRef = this.modalService.open(ExperimentModalComponent, {
			size: 'md',
			centered: true,
		});
		modalRef.componentInstance.experiment = experiment;
		modalRef.componentInstance.product = product;

		return modalRef.result;
	}

	openAddModal(experiment, product) {
		const modalRef = this.modalService.open(ExperimentModalAddComponent, {
			size: 'md',
			centered: true,
		});
		modalRef.componentInstance.experiment = experiment;
		modalRef.componentInstance.product = product;

		return modalRef.result;
	}

	async tasksLeftTodo(experiment) {
		let taskQuery = new Query(Task);
		taskQuery.equalTo('experiment', experiment);
		taskQuery.containedIn('status', [Task.Status.DOING, Task.Status.TODO, undefined, null]);
		taskQuery.containedIn('archived', [false, undefined, null]);

		const task = await taskQuery.first();
		return !!task;
	}
}
