export function slugify(title) {
	return title
		.toString()                     	// Cast to string
		.toLowerCase()                  	// Convert the string to lowercase letters
		.normalize('NFD')       			// The normalize() method returns the Unicode Normalization Form of a given string.
		.trim()                         	// Remove whitespace from both sides of a string
		.replace(/\s+/g, '-')          	// Replace spaces with -
		.replace(/[^\w\-]+/g, '')       	// Remove all non-word chars
		.replace(/\-\-+/g, '-');
};

export function slugifyFile(fileName) {
	const fileNameParts = fileName.split('.');

	const extension = fileNameParts.pop();
	const fileNameWithoutExtension = fileNameParts.join('.');

	const cleanFilename = slugify(fileNameWithoutExtension);

	return cleanFilename + '.' + extension;
}
