import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { KeyQuestion } from '../../../../shared/models/key-question';

@Component({
	selector: 'app-key-question-modal',
	templateUrl: './key-question-modal.component.html',
	styleUrls: ['./key-question-modal.component.scss'],
})
export class KeyQuestionModalComponent implements OnInit {
	@Input() keyQuestion: KeyQuestion;
	@Input() editable: boolean;
	public stages: string[];

	constructor(public activeModal: NgbActiveModal, public portfolioService: PortfolioService) {}

	ngOnInit(): void {
		this.stages = this.portfolioService.getMainAndKickboxStages(this.keyQuestion.portfolio);
	}

	makeEditable() {
		this.editable = true;
	}

	async deleteKeyQuestion() {
		this.keyQuestion.archived = true;
		await this.keyQuestion.save();
		this.activeModal.close('deleted');
	}

	async createKeyQuestion() {
		await this.saveEditable();
		this.activeModal.close(this.keyQuestion);
	}

	async saveEditable() {
		await this.keyQuestion.save();
		this.editable = false;
	}
}
