<div>
	<canvas
		baseChart
		[datasets]="datasets"
		[labels]="labels"
		[options]="chartOptions"
		[colors]="chartColors"
		chartType="line"
		*ngIf="isReady"
	></canvas>
</div>
