import { Component, Input } from '@angular/core';
import { Task } from '../../../../shared/models/task';

@Component({
	selector: 'app-task-card',
	templateUrl: './task-card.component.html',
	styleUrls: ['./task-card.component.scss'],
})
export class TaskCardComponent {
	@Input() task: Task;
	@Input() badge?: boolean = false;

	constructor() {}
}
