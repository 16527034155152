import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KeyQuestion } from '@shared/models/key-question';
import { Product } from '@shared/models/product';
import { AnalyticsService } from 'app/shared/analytics.service';
import * as Parse from 'parse';
import { Portfolio } from '../../../shared/models/portfolio';

@Injectable({
	providedIn: 'root',
})
export class PortfolioService {
	public portfolioSwitched: EventEmitter<Portfolio> = new EventEmitter();

	public stages = [Product.Stage.PROBLEM, Product.Stage.SOLUTION, Product.Stage.REVENUE, Product.Stage.SCALE, Product.Stage.STAGE5, Product.Stage.STAGE6];

	public segmentPerStage = {
		problem: ['customersegment', 'problem'],
		solution: ['valueproposition', 'solution'],
		revenue: ['metrics', 'revenuemodel'],
		scale: ['channels', 'growthengine'],
		stage5: ['segment9', 'segment10'],
		stage6: ['segment11', 'segment12'],
	};

	private segmentStage = {
		customersegment: 'problem',
		problem: 'problem',
		valueproposition: 'solution',
		solution: 'solution',
		metrics: 'revenue',
		revenuemodel: 'revenue',
		channels: 'scale',
		growthengine: 'scale',
		segment9: 'stage5',
		segment10: 'stage5',
		segment11: 'stage6',
		segment12: 'stage6',
	};

	private activePortfolio: Portfolio;
	private portfolios: Array<Portfolio>;

	constructor(private analytics: AnalyticsService, private translate: TranslateService) {}

	async getPortfolios() {
		if (!this.portfolios) {
			const query = new Parse.Query(Portfolio);
			query.include('theses');
			query.ascending('name');

			this.portfolios = await query.find();
		}

		return this.portfolios;
	}

	setActivePortfolio(portfolio: Portfolio) {
		this.activePortfolio = portfolio;

		localStorage.setItem('activePortfolio', portfolio.id);

		this.portfolioSwitched.emit(portfolio);

		this.analytics.registerSuperProperties({
			Portfolio: portfolio.name,
		});
	}

	removeActivePortfolio() {
		this.activePortfolio = null;
		localStorage.removeItem('activePortfolio');
	}

	async getActivePortfolio() {
		if (this.activePortfolio) {
			return this.activePortfolio;
		}

		await this.getPortfolios();

		const id = localStorage.getItem('activePortfolio');
		let activePortfolio;

		if (!this.portfolios.length) {
			return null;
		}

		if (id) {
			this.portfolios.forEach((portfolio) => {
				if (id != portfolio.id) {
					return;
				}

				this.setActivePortfolio(portfolio);

				activePortfolio = portfolio;
			});
		}

		if (!activePortfolio) {
			activePortfolio = this.portfolios[0];

			this.setActivePortfolio(activePortfolio);
		}

		return activePortfolio;
	}

	resetPortfolios() {
		this.portfolios = null;
	}

	getMainStages(portfolio: Portfolio) {
		return this.stages.slice(0, portfolio.amountStages);
	}

	getMainAndKickboxStages(portfolio: Portfolio) {
		let stages = this.getMainStages(portfolio);
		stages.unshift(Product.Stage.KICKBOX);
		return stages;
	}

	getAllStages(portfolio: Portfolio) {
		let stages = this.getMainStages(portfolio);
		stages.unshift(Product.Stage.KICKBOX);
		stages.push(Product.Stage.VALIDATED);
		return stages;
	}

	getDefaultStagesWithKickbox() {
		let stages = this.stages.slice(0, 4);
		stages.unshift(Product.Stage.KICKBOX);
		return stages;
	}

	getStageName(portfolio: Portfolio, stage) {
		if (portfolio && portfolio.stageNames && portfolio.stageNames[stage]) {
			return portfolio.stageNames[stage];
		}
		return this.translate.instant('productStages.' + stage);
	}

	getStageNames(portfolio: Portfolio, stages: String[]) {
		let stageNames = [];

		for (let stage of stages) {
			stageNames.push(this.getStageName(portfolio, stage));
		}

		return stageNames;
	}

	segmentToStage(segment) {
		return this.segmentStage[segment];
	}

	getSegmentName(portfolio: Portfolio, segment, type?) {
		if (!type) {
			type = 'next-canvas';
		}

		// return personalized segment name if set
		if (portfolio && portfolio.canvasSegmentNames && portfolio.canvasSegmentNames[type] && portfolio.canvasSegmentNames[type][segment]) {
			return portfolio.canvasSegmentNames[type][segment];
		}

		if (type === 'pre-validation') {
			return this.translate.instant('canvas.prevalidationCanvas.' + segment);
		}

		return this.translate.instant('canvas.nextCanvas.' + segment);
	}

	getDecisionLabel(portfolio, decision, fromStage?, toStage?) {
		if (decision === undefined) {
			return '';
		}

		let fromStageName = this.getStageName(portfolio, fromStage);
		let toStageName = this.getStageName(portfolio, toStage);

		if (toStage === Product.Stage.VALIDATED) {
			return this.translate.instant('decisionLabels.validated');
		}

		return this.translate.instant('decisionLabels.' + decision, {
			previousStage: fromStageName,
			nextStage: toStageName,
		});
	}

	async getKeyQuestions(portfolio: Portfolio, stage?: string) {
		const query = new Parse.Query(KeyQuestion);

		if (portfolio.defaultKeyQuestions) {
			query.equalTo('default', true);
		} else {
			query.equalTo('portfolio', portfolio);
		}

		if (stage) {
			query.equalTo('stage', stage);
		}

		query.equalTo('archived', false);
		query.ascending('orderIndex');

		return await query.find();
	}
}
