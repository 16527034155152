import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-learning-cost-card',
	templateUrl: './learning-cost-card.component.html',
	styleUrls: ['./learning-cost-card.component.scss'],
})
export class LearningCostCardComponent {
	@Input() title: string;
	@Input() cost: number;
	@Input() currency: string;

	constructor() {}
}
