<div class="modal-content">
	<div class="modal-header">
		<p *ngIf="!editable">{{ 'tool.singular' | translate }}</p>
		<p *ngIf="editable && contentTool.isNew()">{{ 'tool.new' | translate }}</p>
		<p *ngIf="editable && !contentTool.isNew()">{{ 'tool.edit' | translate }}</p>

		<button class="btn btn-link-white ml-auto" *ngIf="!editable" (click)="makeEditable()">{{ 'general.edit' | translate }}</button>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body edit" *ngIf="editable">
		<!-- Start with translatable content -->
		<div class="flag-container right">
			<div class="btn-group" role="group" *ngFor="let language of currentLanguages">
				<button
					class="ml-2 btn"
					[ngClass]="{ 'btn-primary': language === contentTool.language, 'btn-light': language !== contentTool.language }"
					(click)="switchTranslation(contentTools[language])"
				>
					<span *ngIf="language === 'en'" class="flag-icon flag-icon-gb mr-1"></span>
					<span *ngIf="language !== 'en'" [ngClass]="'mr-1 flag-icon flag-icon-' + language"></span>
					{{ configService.fullLanguages[language] }}
					<i *ngIf="language !== contentTool.language" class="bi bi-pencil ml-2"></i>
				</button>
				<button class="btn btn-danger" (click)="deleteTranslation(language)">
					<i class="bi bi-x"></i>
				</button>
			</div>

			<div ngbDropdown class="ml-3 hand d-inline-block dropdown" placement="bottom-right" *ngIf="leftoverLanguages.length > 0">
				<button ngbDropdownToggle id="dropdownLanguages" class="btn btn-outline-primary" [title]="'translate.add' | translate">
					<i class="bi bi-plus"></i>
				</button>
				<div ngbDropdownMenu aria-labelledby="dropdownLanguages">
					<button ngbDropdownItem *ngFor="let leftoverLanguage of leftoverLanguages" (click)="addTranslation(leftoverLanguage)">
						{{ configService.fullLanguages[leftoverLanguage] }}
					</button>
				</div>
			</div>
		</div>
		<p class="text-danger text-right" *ngIf="!contentTool.language">{{ 'translate.selectLanguage' | translate }}</p>
		<div class="form-group">
			<label
				>{{ 'title.singular' | translate }}
				<input type="text" class="form-control" [(ngModel)]="contentTool.title" [disabled]="!contentTool.language" />
			</label>
		</div>
		<div class="form-group">
			<label
				>{{ 'contentExperiment.excerpt' | translate }}
				<textarea class="form-control" [(ngModel)]="contentTool.excerpt" rows="4" [disabled]="!contentTool.language"></textarea>
			</label>
		</div>
		<div class="form-group">
			<label for="selectStage">{{ 'stage.singular' | translate }}</label>
			<ng-select
				id="selectStage"
				name="stage"
				[(ngModel)]="contentTool.stages"
				[items]="stages"
				[multiple]="true"
				[disabled]="!contentTool.language"
			>
				<ng-template ng-label-tmp let-item="item">
					<p>{{ 'productStages.' + item | translate }}</p>
				</ng-template>
				<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
					{{ 'productStages.' + item | translate }}
				</ng-template>
			</ng-select>
		</div>
		<div class="form-group">
			<label for="description">{{ 'description.singular' | translate }}</label>
			<quill-editor
				id="description"
				name="description"
				[modules]="quillConfigService.editorOptions"
				[(ngModel)]="contentTool.description"
				[styles]="{ height: '10rem' }"
				placeholder=""
				[disabled]="!contentTool.language"
			></quill-editor>
		</div>
		<div class="form-group">
			<label for="example">{{ 'example.singular' | translate }}</label>
			<quill-editor
				id="example"
				name="example"
				[modules]="quillConfigService.editorOptions"
				[(ngModel)]="contentTool.example"
				[styles]="{ height: '10rem' }"
				placeholder=""
				[disabled]="!contentTool.language"
			></quill-editor>
		</div>
		<div class="form-group">
			<label for="readMore">{{ 'contentExperiment.readMore' | translate }}</label>
			<quill-editor
				id="readMore"
				name="read more"
				[modules]="quillConfigService.editorOptions"
				[(ngModel)]="contentTool.readMore"
				[styles]="{ height: '10rem' }"
				placeholder=""
				[disabled]="!contentTool.language"
			></quill-editor>
		</div>
		<div class="form-group">
			<label for="source">{{ 'source.singular' | translate }}</label>
			<input id="source" type="text" class="form-control" [(ngModel)]="contentTool.source" [disabled]="!contentTool.language" />
		</div>
		<div class="text-right">
			<button
				*ngIf="editable && !contentTool.isNew()"
				ngbAutofocus
				type="button"
				class="btn btn-primary"
				(click)="onSave()"
				[disabled]="!contentTool.title || !contentTool.language"
			>
				{{ 'translate.save' | translate }}
			</button>
			<button
				*ngIf="editable && contentTool.isNew()"
				ngbAutofocus
				type="button"
				class="btn btn-primary"
				(click)="onSave()"
				[disabled]="!contentTool.title || !contentTool.language"
			>
				{{ 'translate.create' | translate }}
			</button>
		</div>
	</div>
	<div class="modal-body" *ngIf="!editable">
		<div class="flag-container right">
			<span
				*ngFor="let language of currentLanguages"
				class="mr-2 btn"
				[ngClass]="{ 'btn-primary': language === contentTool.language, 'btn-light': language !== contentTool.language }"
				(click)="switchTranslation(contentTools[language])"
			>
				<span *ngIf="language === 'en'" class="flag-icon flag-icon-gb mr-1"></span>
				<span *ngIf="language !== 'en'" [ngClass]="'mr-1 flag-icon flag-icon-' + language"></span>
				{{ configService.fullLanguages[language] }}
			</span>
		</div>
		<app-content-tool [contentTool]="contentTool"></app-content-tool>
	</div>
	<div class="modal-footer">
		<button class="btn btn-link-danger mr-auto" *ngIf="!contentTool.isNew()" (click)="deleteContentTool()">
			{{ 'tool.remove' | translate }}
		</button>
		<button *ngIf="!editable" type="button" class="btn btn-primary" (click)="activeModal.close()">
			{{ 'general.close' | translate }}
		</button>
	</div>
</div>
