import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as Parse from 'parse';
import { Observable } from 'rxjs';


@Injectable()
export class SubscriptionAccessGuard implements CanActivate {
	constructor(private router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (!Parse.User.current()) {
			this.router.navigate(['login']);
			return false;
		}

		// Does user have a subscription?
		return new Promise<boolean>((resolve, reject) => {
			Parse.Cloud.run('getSubscription').then(() => {
			resolve(true);
			}).catch(err => {
				this.router.navigate(['upgrade']);
				reject(false);
			});
		});
	}
}
