import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Portfolio } from '@shared/models/portfolio';
import * as Parse from 'parse';
import { Canvas } from '../../../../shared/models/canvas';
import { Sticky } from '../../../../shared/models/sticky';

@Component({
	selector: 'app-canvas-mini',
	templateUrl: './mini.component.html',
	styleUrls: ['./mini.component.scss'],
})
export class CanvasMiniComponent implements OnInit, OnDestroy {
	@Input() canvasVersion: Date;
	@Input() type: string;
	@Input() stage: string;
	@Input() canvas: Canvas;
	@Input() exampleStickies: Sticky[]; // If you do not want to show any stickies, give an empty array as input
	@Input() selectedSegment: string;
	@Input() canSelectSegment: boolean; // set to true if you want to be able to select segments
	@Input() showSegmentNames: boolean; // set to true if you want segments to show names
	@Input() portfolio?: Portfolio; // set if next canvas, adjusts amount of stages
	@Output() selectCanvas: EventEmitter<any> = new EventEmitter();

	public stickySubscription: Parse.LiveQuerySubscription;
	public stickies: Sticky[];

	constructor() {}

	async ngOnInit() {
		// check if new mini canvas is just used for showing where sticky/experiment is placed at (example canvas)
		if (this.exampleStickies) {
			this.stickies = this.exampleStickies;
		} else {
			const stickyQuery = new Parse.Query(Sticky);
			stickyQuery.equalTo('canvas', this.canvas);

			this.stickySubscription = await stickyQuery.subscribe();
			this.stickySubscription.on('create', (sticky: Sticky) => {
				this.stickies.push(sticky);
			});
			this.stickySubscription.on('update', (sticky: Sticky) => {
				this.stickies[this.stickies.findIndex((s) => s.id == sticky.id)] = sticky;
			});

			this.stickies = await stickyQuery.find();
		}
	}

	ngOnDestroy() {
		if (this.stickySubscription) {
			this.stickySubscription.unsubscribe();
		}
	}

	onClick(evt) {
		if (this.canSelectSegment) {
			this.selectCanvas.emit(evt);
			this.selectedSegment = evt.segment;
		}
	}
}
