<div class="card" (click)="onSelect()">
	<div class="card-body">
		<div class="row align-items-center">
			<div class="col-1" *ngIf="product.logo">
				<img class="logo" [src]="product.logo?.url()" />
			</div>
			<div class="col-11 col-md-4">
				<h5 class="name">{{ product.name }}</h5>
				<p class="pitch">{{ product.pitch }}</p>
			</div>
			<ng-container *ngIf="product.isActive()">
				<div class="col-10 col-md-3" (click)="onSelectAccounting()">
					<h5 class="info-header">{{ 'innovationAccounting.experimentVelocity' | translate }}</h5>
					<p *ngIf="experimentVelocity">
						{{ experimentVelocity | percent }}
						<i *ngIf="experimentDif > 0" class="bi bi-arrow-up text-success"></i>
						<i *ngIf="experimentDif === 0" class="bi bi-arrow-right text-secondary"></i>
						<i *ngIf="experimentDif < 0" class="bi bi-arrow-down text-danger"></i>
						<span *ngIf="experimentDif" [ngClass]="{ 'text-success': experimentDif > 0, 'text-secondary': experimentDif === 0, 'text-danger': experimentDif < 0 }">{{
							getAbs(experimentDif) | percent
						}}</span>
					</p>
					<p *ngIf="!experimentVelocity">-</p>
				</div>
				<div class="col-10 col-md-2" (click)="onSelectAccounting()">
					<h5 class="info-header">{{ 'innovationAccounting.learningRatio' | translate }}</h5>
					<p *ngIf="learningRatio">
						{{ learningRatio | percent }}
						<i *ngIf="learningDif > 0" class="bi bi-arrow-up text-success"></i>
						<i *ngIf="learningDif === 0" class="bi bi-arrow-right text-secondary"></i>
						<i *ngIf="learningDif < 0" class="bi bi-arrow-down text-danger"></i>
						<span *ngIf="learningDif" [ngClass]="{ 'text-success': learningDif > 0, 'text-secondary': learningDif === 0, 'text-danger': learningDif < 0 }">{{
							getAbs(learningDif) | percent
						}}</span>
					</p>
					<p *ngIf="!learningRatio">-</p>
				</div>
				<div class="col-10 col-md-2" (click)="onSelectAccounting()">
					<h5 class="info-header">{{ 'innovationAccounting.teamHappiness' | translate }}</h5>
					<div *ngIf="teamHappiness" class="d-flex justify-content-left align-items-center">
						<div class="progress">
							<div class="progress-bar" role="progressbar" [style.width.%]="teamHappiness * 100" aria-valuemin="1" aria-valuemax="5"></div>
						</div>
						<p>
							<i *ngIf="happinessDif > 0" class="bi bi-arrow-up text-success"></i>
							<i *ngIf="happinessDif === 0" class="bi bi-arrow-right text-secondary"></i>
							<i *ngIf="happinessDif < 0" class="bi bi-arrow-down text-danger"></i>
						</p>
					</div>
					<p *ngIf="!teamHappiness">-</p>
				</div>
			</ng-container>
			<ng-container *ngIf="!product.isStarted() && !product.isStopped()">
				<div class="col col-md-3">
					<h5 class="info-header">Added at</h5>
					<p>{{ product.createdAt | date }}</p>
				</div>
				<div class="col col-md-2">
					<h5 class="info-header">Added by</h5>
					<p>{{ product.createdBy?.getName() }}</p>
				</div>
			</ng-container>
			<ng-container *ngIf="product.isValidated()">
				<div class="col col-md-3">
					<h5 class="info-header">Validated at</h5>
					<p>{{ product.endedAt | date }}</p>
				</div>
				<div class="col col-md-2">
					<h5 class="info-header">After</h5>
					<p>{{ duration }}</p>
				</div>
				<div class="col col-md-2">
					<h5 class="info-header">Total investment</h5>
					<p>{{ totalMoneyReceived | currency: product.portfolio.currency:'symbol':'1.0-0' }}</p>
				</div>
			</ng-container>
			<ng-container *ngIf="product.isStopped()">
				<div class="col col-md-3">
					<h5 class="info-header">Invalidated at</h5>
					<p>{{ product.endedAt | date }}</p>
				</div>
				<div class="col col-md-2">
					<h5 class="info-header">After</h5>
					<p>{{ duration }}</p>
				</div>
				<div class="col col-md-2">
					<h5 class="info-header">Total investment</h5>
					<p>{{ totalMoneyReceived | currency: product.portfolio.currency:'symbol':'1.0-0' }}</p>
				</div>
			</ng-container>
		</div>
		<div *ngIf="product.isDemo">
			<p class="demo-badge">{{ 'product.demo' | translate | uppercase }}</p>
		</div>
	</div>
</div>
