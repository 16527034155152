import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Canvas } from '../../../../shared/models/canvas';
import { Product } from '../../../../shared/models/product';

@Component({
	selector: 'app-canvas-modal-add',
	templateUrl: './canvas-modal-add.component.html',
	styleUrls: ['./canvas-modal-add.component.scss'],
})
export class CanvasModalAddComponent {
	@Input() product: Product;
	@Input() canvas: Canvas;
	@Output() save: EventEmitter<any> = new EventEmitter();

	constructor(public activeModal: NgbActiveModal) {}

	onClick(type) {
		this.canvas.type = type;
	}

	onSave() {
		this.save.emit({ canvas: this.canvas });
		this.activeModal.close();
	}
}
