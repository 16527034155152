<div class="row">
	<div class="col-12 col-md">
		<h3>{{ 'portfolio.plural' | translate }}</h3>
	</div>
	<div class="col-12 col-md-auto">
		<button type="button" class="btn btn-primary" (click)="addPortfolio()">{{ 'portfolio.add' | translate }}</button>
	</div>
	<div class="col-12" *ngIf="waitMessage">
		<div class="alert alert-success mb-0 mt-3">{{ 'product.addingDemo' | translate }}</div>
	</div>
</div>
<hr />
<div class="row mb-5">
	<div class="col-12 col-md-12">
		<div class="input-group">
			<input class="form-control" type="text" [(ngModel)]="searchKey" aria-label="search" aria-describedby="search-button" />
			<div class="input-group-append">
				<button type="button" class="btn btn-primary" (click)="search()"><i class="bi bi-search"></i></button>
			</div>
		</div>
	</div>
</div>
<div class="row">
	<div class="col-12 mb-3" *ngFor="let portfolio of portfolios">
		<app-portfolio-card [portfolio]="portfolio" (selectPortfolio)="openPortfolio($event)"></app-portfolio-card>
	</div>
</div>
