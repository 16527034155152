import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../../shared/models/user';

@Component({
	selector: 'app-avatar-row',
	templateUrl: './avatar-row.component.html',
	styleUrls: ['./avatar-row.component.scss'],
})
export class AvatarRowComponent implements OnInit {
	@Input() users: User[];
	@Input() direction?: string;
	@Input() avatarSize?: number;

	public directionalUsers: User[];
	public zIndex = 0;

	constructor() {}

	ngOnInit(): void {
		if(!this.users) {
			return;
		}

		if (this.direction === 'left') {
			this.directionalUsers = this.users;
			this.zIndex = 1;
		} else {
			this.direction = 'right';

			// Show users from left to right
			// We have to do it this way, since reverse, reverses the original array as well
			this.directionalUsers = [...this.users].reverse();
		}

		if (!this.avatarSize) {
			this.avatarSize = 1;
		}
	}
}
