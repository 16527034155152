import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NextTitleService } from 'app/next-title.service';
import * as Parse from 'parse';
import { User } from '../../../../shared/models/user';
import { slugifyFile } from '../../../../shared/slugify';


@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
	public user: User;
	public errorMessage: string;
	public resultMessage: string;
	public showProgress = false;
	public progressUpload = 0;
	public isPreview: boolean;

	constructor(
		private titleService: NextTitleService,
		private translate: TranslateService) {

	}

	ngOnInit() {
		this.titleService.setTitle('Profile');

		this.user = User.current() as User;
	}

	async onAvatarChange(event) {
		if (event.target.files.length > 0) {
			this.showProgress = true;
			this.errorMessage = '';

			const file = event.target.files[0];
			const parseFile = new Parse.File(slugifyFile(file.name), file);
			try {
				const logo = await parseFile.save({
					progress: (progressValue, loaded, total, { type }) => {
						if (type === 'upload' && progressValue !== null) {
							// Update the UI using progressValue
							this.progressUpload = progressValue * 100;
						}
					},
				} as any);
				this.user.set('avatar_original', logo);
				this.isPreview = true;
			} catch(error) {
				let message = this.translate.instant('parseError.' + error.message);
				if (message == 'parseError.' + error.message) {
					// show default message
					message = this.translate.instant('parseError.fileNotUploaded');
				}

				this.errorMessage = message;
				this.showProgress = false;
			}
		}
	}

	onSave() {
		this.user.save().then(
			() => {
				this.resultMessage = 'yes';
				this.errorMessage = '';
				this.showProgress = false;
				this.isPreview = false;
			},
			(error) => {
				// Show the error message somewhere and let the user try again.
				let message = this.translate.instant('parseError.profileNotSaved');
				this.errorMessage = message;
				this.resultMessage = '';
			}
		);
	}
}
