import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class NextConfigService {
	public colors = ['#459989', '#347796', '#CB4343', '#F1A139', '#FBE97D', '#396175'];
	public colorHappiness = ['rgb(243, 161, 22)', 'rgba(243, 161, 22, 0.3)'];
	public colorKnowledgeability = ['rgb(76, 217, 100)', 'rgba(76, 217, 100, 0.3)'];
	public colorPrimary = ['rgb(19, 119, 149)', 'rgba(19, 119, 149, 0.3)'];
	public colorGrey = '#8e8e93';
	public colorAssumptions = ['#fbe97d', '#f1af39', '#6BBCB2', '#7c7db8', '#347796'];

	public standardTasks = {
		customerInterview: ['makeTopicMap', 'interview', 'processNotes', 'analyzeInterviews'],
	};
	public experimentMethods = ['customerInterview'];

	public stageGateQuestions = {
		kickbox: ['identifyProblem', 'identifyMarket', 'ideaAllignment'],
		problem: ['validatedProblem', 'bigEnough', 'earlyAdopter'],
		solution: ['validatedJTBD', 'commitedCustomer', 'solutionExperiment'],
		revenue: ['validatedValue', 'willingToPay'],
		scale: ['validatedChannels', 'broadenedSegment', 'validatedGrowthEngine'],
	};

	public languages = ['en', 'nl'];

	public fullLanguages = {
		en: 'English',
		nl: 'Nederlands',
	};

	public euCountries = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'EL', 'ES', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'SE'];

	public vat = {
		reverseCharge: {
			rate: 0,
		},
		noTax: {
			rate: 0,
		},
		NL: {
			rate: 21,
		},
		DE: {
			rate: 19,
		},
		AT: {
			rate: 20,
		},
		BE: {
			rate: 21,
		},
		BU: {
			rate: 20,
		},
		CY: {
			rate: 19,
		},
		CZ: {
			rate: 21,
		},
		DK: {
			rate: 25,
		},
		EE: {
			rate: 20,
		},
		EL: {
			rate: 24,
		},
		ES: {
			rate: 21,
		},
		FI: {
			rate: 24,
		},
		FR: {
			rate: 20,
		},
		HR: {
			rate: 25,
		},
		HU: {
			rate: 27,
		},
		IE: {
			rate: 23,
		},
		IT: {
			rate: 22,
		},
		LT: {
			rate: 21,
		},
		LU: {
			rate: 17,
		},
		LV: {
			rate: 21,
		},
		MT: {
			rate: 18,
		},
		PL: {
			rate: 23,
		},
		PT: {
			rate: 23,
		},
		RO: {
			rate: 19,
		},
		SE: {
			rate: 25,
		},
		SI: {
			rate: 25,
		},
		SK: {
			rate: 20,
		},
	};

	constructor() {}
}
