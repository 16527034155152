import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Canvas } from './canvas';
import { Experiment } from './experiment';
import { Product } from './product';
import { User } from './user';

@ParseSubClass('Sticky')
export class Sticky extends Parse.Object {
	@ParseProperty() public name: string;
	@ParseProperty() public canvas: Canvas;
	@ParseProperty() public product: Product;
	@ParseProperty() public experiments: Array<Experiment>;
	@ParseProperty() public segment: string;
	@ParseProperty() public text: string;
	@ParseProperty() public description: string;
	@ParseProperty() public validated: boolean;
	@ParseProperty() public validatedAt: Date;
	@ParseProperty() public risky: boolean;
	@ParseProperty() public riskyAt: Date;
	@ParseProperty() public createdBy: User;
	@ParseProperty() public createdAt: Date;
	@ParseProperty() public archived: boolean;
	@ParseProperty() public archivedAt: Date;
	@ParseProperty() public orderIndex: number;
	@ParseProperty() public color: string;

	@ParseProperty() public impactScore: number;
	@ParseProperty() public confidenceScore: number;

	constructor() {
		super('Sticky');
	}

	getRiskynessScore() {
		let stageScore = 1;

		// switch(this.segment) {
		// 	case 'problem':
		// 	case 'customersegment':
		// 		stageScore = 2.5;
		// 	default:
		// 		stageScore = 1;
		// }

		return this.impactScore * this.confidenceScore * stageScore;
	}

	getRiskyness() {
		/**
		 *         10
		 *         |
		 * Confirm |  Risky    | Most Risk
		 * -------------------------- 10
		 * Ignore  |   Seductive distractons
		 *         |
		 */

		// Validated has no risk
		if (this.validated === true) {
			return 'validated';
		}

		// Invalidated has no risk
		if (this.validated === false) {
			return 'invalidated';
		}

		// Backwards compatible boolean
		if (this.risky === true) {
			return 'most-risky';
		}

		// Low impact assumptions, you can ignore
		if (this.impactScore < 5) {
			return 'ignore';
		}

		// If you know a lot about an assumption, whu has it not been (in)validated yet?
		if (this.confidenceScore < 5) {
			return 'confirm';
		}

		// If an assumption has > 5 impact on the Business Model
		// Knowing very little is most risky
		if (this.confidenceScore > 8 && this.impactScore > 7) {
			return 'most-risky';
		}
		if(this.confidenceScore > 7 && this.impactScore > 8) {
			return 'most-risky';
		}

		// If an assumption has > 5 impact on the Business Model
		// Knowing little is risky
		if (this.confidenceScore > 5) {
			return 'risky';
		}

		return 'unknown';
	}
}
