import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from 'app/log/log.service';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Cloud, Query, Role } from 'parse';
import { Invite } from '../../../../shared/models/invite';
import { Portfolio } from '../../../../shared/models/portfolio';
import { User } from '../../../../shared/models/user';

@Component({
	selector: 'app-portfolio-settings-collaborators',
	templateUrl: './portfolio-settings-collaborators.component.html',
	styleUrls: ['./portfolio-settings-collaborators.component.scss']
})
export class PortfolioSettingsCollaboratorsComponent implements OnInit {
	public portfolio: Portfolio;
	public errorMessage: string;
	public resultMessage: string;
	public users: Array<User>;
	public userInPortfolio: boolean;
	public pendingInvites: Invite[];
	public email: string;
	public signupLink: string;
	public inviteResultMessage: string;

	constructor(
		private translate: TranslateService,
		private logService: LogService,
		private portfolioService: PortfolioService
	) { }

	async ngOnInit() {
		this.portfolio = await this.portfolioService.getActivePortfolio();

		this.signupLink = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/signup/';
		this.getUsers();
		this.getPendingInvites();
	}

	async getUsers() {
		const queryRoles = new Query(Role);
		queryRoles.equalTo('name', 'portfolio-' + this.portfolio.id);
		const role = await queryRoles.first();

		if (!!role) {
			this.users = await Cloud.run('getPortfolioUsers', {
				portfolioId: this.portfolio.id
			});
			this.userInPortfolio = true;
		}
	}

	async getPendingInvites() {
		let queryInvites = new Query(Invite);
		queryInvites.equalTo('portfolio', this.portfolio);
		queryInvites.equalTo('pending', true);
		this.pendingInvites = await queryInvites.find();
	}

	async inviteUser() {
		try {
			const result = await Cloud.run('inviteUserToPortfolio', {
				email: this.email.toLowerCase(),
				portfolioId: this.portfolio.id,
			});

			if (result instanceof Invite) {
				// user did not exist already, created invite class + link
				await this.getPendingInvites();

				const link = this.signupLink + result.id;
				this.inviteResultMessage = this.translate.instant('invite.linkDescription', {
					link: link,
					class: this.translate.instant('portfolio.singular')
				});
			} else {
				await this.getUsers();

				this.inviteResultMessage = 'User added to portfolio';
			}

			this.resultMessage = '';

			this.logService.logActivity(
				'invited user to portfolio',
				'Portfolio',
				this.portfolio.id,
				undefined,
				this.portfolio,
				{
					email: this.email.toLowerCase(),
				}
			);
			this.errorMessage = '';
			this.email = '';
		} catch (err) {
			this.errorMessage = err.message;
		}
	}

	removeAccess(user: User) {
		Cloud.run('removeAccessUserToPortfolio', {
			userId: user.id,
			portfolioId: this.portfolio.id,
		}).then(
			async (result) => {
				this.logService.logActivity(
					'removed user from portfolio',
					'Portfolio',
					this.portfolio.id,
					undefined,
					this.portfolio,
					{
						email: user.username,
					}
				);
				
				await this.getUsers();

				this.resultMessage = result;
				this.errorMessage = '';
				this.inviteResultMessage = '';
			},
			(err) => {
				this.errorMessage = err.message;
				this.resultMessage = '';
			}
		);
	}

}
