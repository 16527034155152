import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Experiment } from '../../../shared/models/experiment';
import { Product } from '../../../shared/models/product';
import { Sprint } from '../../../shared/models/sprint';
import { Task } from '../../../shared/models/task';
import { User } from '../../../shared/models/user';
import { TaskModalAddComponent } from './task-modal-add/task-modal-add.component';
import { Query } from 'parse';

@Injectable()
export class TaskService {
	constructor(
		private modalService: NgbModal
	) {}

	async saveTaskToSprint(sprint: Sprint, product: Product, task: Task) {
		task.product = product;
		task.sprint = sprint;
		await task.save();

		sprint.addUnique('tasks', task);

		// save manually, so we only have to save the sprint once.
		// await sprint.save();
	}

	async getTasks(sprint: Sprint, status?: string) {
		let taskQuery = new Query(Task);
		taskQuery.equalTo('sprint', sprint);
		taskQuery.equalTo('archived', false);
		taskQuery.ascending('orderIndex');

		if (status) {
			taskQuery.equalTo('status', status);
		}
		return taskQuery.find();
	}

	async getTasksPerUser(sprint: Sprint, user: User, status?: string) {
		let taskQuery = new Query(Task);
		taskQuery.equalTo('sprint', sprint);
		taskQuery.equalTo('archived', false);
		taskQuery.equalTo('assignedUsers', user);
		taskQuery.ascending('orderIndex');

		if (status) {
			taskQuery.equalTo('status', status);
		}
		return taskQuery.find();
	}

	async getTasksPerExperiment(experiment: Experiment) {
		let taskQuery = new Query(Task);
		taskQuery.equalTo('experiment', experiment);
		taskQuery.equalTo('archived', false);
		return await taskQuery.find();
	}

	async removeTask(task: Task) {
		task.archived = true;
		return await task.save();
	}

	openModal(task, sprint, product) {
		const modalRef = this.modalService.open(TaskModalAddComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.task = task;
		modalRef.componentInstance.sprint = sprint;
		modalRef.componentInstance.product = product;
		modalRef.componentInstance.abbrev = product.getAbbrev();

		return modalRef.result;
	}
}
