<div class="canvas">
	<div class="row">
		<div class="col-9">
			<div class="row">
				<app-canvas-segment [canvas]="canvas" [allStickies]="allStickies" id="solution" [allIds]="allIds" location="top" type="pre-validation" name="Idea" class="col segment"> </app-canvas-segment>
			</div>
			<div class="row">
				<app-canvas-segment [canvas]="canvas" [allStickies]="allStickies" id="revenuemodel" [allIds]="allIds" location="top" type="pre-validation" name="Hurdles" class="col segment"> </app-canvas-segment>
				<app-canvas-segment [canvas]="canvas" [allStickies]="allStickies" id="customersegment" [allIds]="allIds" location="top" type="pre-validation" name="Who are they?" class="col segment"> </app-canvas-segment>
				<app-canvas-segment [canvas]="canvas" [allStickies]="allStickies" id="growthengine" [allIds]="allIds" location="top" type="pre-validation" name="Competitors" class="col segment"> </app-canvas-segment>
			</div>
			<div class="row">
				<app-canvas-segment [canvas]="canvas" [allStickies]="allStickies" id="metrics" [allIds]="allIds" location="bottom" type="pre-validation" name="Triggers" class="col segment"> </app-canvas-segment>
				<app-canvas-segment [canvas]="canvas" [allStickies]="allStickies" id="valueproposition" [allIds]="allIds" location="bottom" type="pre-validation" name="Job to be done" class="col segment"> </app-canvas-segment>
				<app-canvas-segment [canvas]="canvas" [allStickies]="allStickies" id="channels" [allIds]="allIds" location="bottom" type="pre-validation" name="Current Solutions" class="col segment"> </app-canvas-segment>
			</div>
			<div class="row">
				<app-canvas-segment [canvas]="canvas" [allStickies]="allStickies" id="problem" [allIds]="allIds" location="bottom" type="pre-validation" name="Problem" class="col segment"> </app-canvas-segment>
			</div>
		</div>
		<div class="col-3">
			<div class="row">
				<app-canvas-segment [canvas]="canvas" [allStickies]="allStickies" id="marketsize" [allIds]="allIds" location="top" type="pre-validation" name="Market Size" class="col segment"></app-canvas-segment>
			</div>
			<div class="row">
				<app-canvas-segment [canvas]="canvas" [allStickies]="allStickies" id="determination" [allIds]="allIds" location="bottom" type="pre-validation" name="Determination" class="col segment"></app-canvas-segment>
			</div>
		</div>
	</div>
	<!-- <div class="circle"></div> -->
</div>
