import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskService } from 'app/task/task.service';
import { Query } from 'parse';
import { Experiment } from '../../../../shared/models/experiment';
import { Product } from '../../../../shared/models/product';
import { Sprint } from '../../../../shared/models/sprint';
import { Task } from '../../../../shared/models/task';
import { SprintModalRetrospectComponent } from '../sprint-modal-retrospect/sprint-modal-retrospect.component';

@Component({
	selector: 'app-sprint-modal-complete',
	templateUrl: './sprint-modal-complete.component.html',
	styleUrls: ['./sprint-modal-complete.component.scss'],
})
export class SprintModalCompleteComponent implements OnInit {
	@Input() sprint: Sprint;
	@Input() product: Product;
	public sprints: Sprint[];
	public tasksDone: Task[];
	public tasksOpen: Task[];
	public experimentsDone: Experiment[];
	public experimentsOpen: Experiment[];
	public destinationSprint: Sprint;
	public backlog: Sprint;
	public newSprint: Sprint;

	constructor(
		public router: Router,
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
		public taskService: TaskService
	) {
		this.tasksDone = [];
		this.tasksOpen = [];
		this.experimentsDone = [];
		this.experimentsOpen = [];
	}

	ngOnInit(): void {
		this.backlog = this.product.backlog;

		this.fetchTasks();
		this.fetchExperiments();

		// Create new sprint
		this.newSprint = new Sprint();
		this.newSprint.product = this.product;

		// Select new sprint
		this.destinationSprint = this.newSprint;
	}

	fetchExperiments() {
		for (let experiment of this.sprint.experiments) {
			if (experiment.status === Experiment.Status.DONE) {
				this.experimentsDone.push(experiment);
			} else {
				this.experimentsOpen.push(experiment);
			}
		}
	}

	async fetchTasks() {
		const tasks = await this.taskService.getTasks(this.sprint);

		for (let task of tasks) {
			if (task.status === Task.Status.DONE) {
				this.tasksDone.push(task);
			} else {
				this.tasksOpen.push(task);
			}
		}
	}

	async fetchSprints() {
		// get all sprints of product
		const query = new Query(Sprint);
		query.include('experiments');
		query.include('tasks');
		query.equalTo('product', this.product);
		query.equalTo('status', Sprint.Status.PREPARING);
		query.ascending('createdAt');

		this.sprints = (await query.find()) as Sprint[];
	}

	async completeSprint() {
		// Save new sprint, so there is always a new sprint
		await this.newSprint.save();

		// moving tasks to sprint
		for (let task of this.tasksOpen) {
			// add task to sprint
			// this.sprint.remove('tasks', task);
			this.taskService.saveTaskToSprint(this.destinationSprint, this.product, task);
		}

		await this.destinationSprint.save();

		// copy experiments to sprint
		for (let experiment of this.experimentsOpen) {
			this.destinationSprint.addUnique('experiments', experiment);
		}

		this.destinationSprint.save();

		// Update sprint status
		this.sprint.status = Sprint.Status.DONE;
		this.sprint.endedAt = new Date();
		this.sprint.save();

		this.product.activeSprint = null;
		this.product.save();

		// Close complete modal
		this.activeModal.close();

		// Start retrospect
		this.sprint.teamEducation = '3';
		this.sprint.teamHapiness = '3';
		const modalRef = this.modalService.open(SprintModalRetrospectComponent, {
			size: 'md',
		});
		modalRef.componentInstance.sprint = this.sprint;
		modalRef.componentInstance.product = this.product;

		await modalRef.result;

		// After retrospect, move to Backlog
		this.router.navigate(['/portfolio', this.product.portfolio.id, 'startup', this.product.id, 'backlog']);
	}
}
