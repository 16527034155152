import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NextConfigService } from 'app/next-config.service';
import { Cloud } from 'parse';
import { Portfolio } from '../../../../shared/models/portfolio';

@Component({
  selector: 'app-portfolio-chart-knowledge-modal',
  templateUrl: './portfolio-chart-knowledge-modal.component.html',
  styleUrls: ['./portfolio-chart-knowledge-modal.component.scss']
})
export class PortfolioChartKnowledgeModalComponent implements OnInit {
  	@Input() accounting: Object[];
	@Input() portfolio: Portfolio;
	public labels: string[];
	public datasets;
	public chartOptions;
	public chartColors: Array<any> = [
		{
			backgroundColor: [],
		},
	];

	// innovation accounting per product
	public quarter: number;
	public year: number;
	public iAPs: any[] = [];

	public isReady: boolean;

	constructor(
		public activeModal: NgbActiveModal,
		private translate: TranslateService,
		private configService: NextConfigService
	) {
		this.labels = [];
		this.datasets = [];
	}

	ngOnInit(): void {
		// setup colors
		this.chartColors[0].borderColor = this.configService.colorKnowledgeability[0];
		this.chartColors[0].backgroundColor = this.configService.colorKnowledgeability[1];

		// setup chart options
		this.chartOptions = {
			responsive: true,
			legend: false,
			scales: {
				yAxes: [
					{
						scaleLabel: {
							display: false,
							labelString: 'test',
						},
						ticks: {
							callback: (value, index, values) => {
								return this.getLabel(value);
							},
							stepSize: 5,
							min: 1,
							max: 5,
						},
					},
				],
			},
		};

		let chartData = [];

		let keys = Object.keys(this.accounting);
		for (let key of keys) {
			const quarter = this.accounting[key];
			if (quarter.totalRecordedKnowledgibility === 0) {
				chartData.push(1);
			} else {
				const averageKnowledgeability = Math.round(quarter.totalTeamKnowledgibility / quarter.totalRecordedKnowledgibility);
				chartData.push(averageKnowledgeability);
			}

			const quarterNumber = quarter.quarter + 1
			const label = 'Q' + quarterNumber + ' ' + quarter.year;
			this.labels.push(label);
		}

		this.datasets = [
			{
				data: chartData,
				label: this.translate.instant('team.knowledgeability'),
			},
		];

		this.quarter = this.accounting[keys[keys.length - 1]].quarter;
		this.year = this.accounting[keys[keys.length - 1]].year;
		this.getInnovationAccountingPerStartup();
		this.isReady = true;
	}

	getLabel(number) {
		switch (number) {
			case 1:
				return this.translate.instant('knowledge.min');
			case 5:
				return this.translate.instant('knowledge.max');
			default:
				return '';
		}
	}

	getInnovationAccountingPerStartup() {
		Cloud.run('getInnovationAccountingQuarter', {
			portfolioId: this.portfolio.id,
			year: this.year,
			quarter: this.quarter,
		})
		.then((result) => {
			this.iAPs = result;
		})
		.catch((error) => {
			console.error(error);
		});
	}

	dataClicked(event) {
		if (event.active.length > 0) {
			const chart = event.active[0]._chart;
			const activePoints = chart.getElementAtEvent(event.event);
			if ( activePoints.length > 0) {
				// get the internal index of slice in pie chart
				const clickedElementIndex = activePoints[0]._index;
				const label = chart.data.labels[clickedElementIndex];
				// get value by index
				this.quarter = Number(label.slice(1, 2)) - 1;
				this.year = Number(label.slice(3, 7));
				this.getInnovationAccountingPerStartup();
			}
		}
	}
}
