<div class="container page">
	<div *ngIf="subscription && billingDetails && invoices" class="row justify-content-center mt-5">
		<form class="col-6">
			<div class="card mb-3">
				<div class="card-body px-3">
					<h1>{{ 'billing.title' | translate }} for {{ portfolio.name }}</h1>
					<h2 class="mt-3">{{ 'plan.your' | translate }}</h2>
					<p><strong>{{ 'plan.innovationLab.title' | translate }}</strong></p>

					<p *ngIf="!subscription.cancel_at_period_end" class="mt-3">{{ 'subscription.willRenew' | translate:{date: renewDate | date} }}</p>

					<div class="row align-items-baseline">
						<div class="col-12 col-md-4"><h2 class="mt-5">{{ 'billing.details' | translate }}</h2></div>
						<div class="col-12 col-md-8"><p class="edit" (click)="editBillingDetails()">{{ 'general.edit' | translate }}</p></div>
					</div>
					<div class="row">
						<div class="col-12 col-md-4"><p class="header">{{ 'user.billingEmail' | translate }}</p></div>
						<div class="col-12 col-md-8"><p>{{ billingDetails.email }}</p></div>
					</div>
					<div class="row">
						<div class="col-12 col-md-4"><p class="header">{{ 'user.address' | translate }}</p></div>
						<div class="col-12 col-md-8"><p>{{ billingDetails.address1 }}, {{ billingDetails.address2 }}</p></div>
					</div>
					<div class="row">
						<div class="col-12 col-md-4"><p class="header">{{ 'user.postalCode' | translate }}</p></div>
						<div class="col-12 col-md-8"><p>{{ billingDetails.postalCode }}</p></div>
					</div>
					<div class="row">
						<div class="col-12 col-md-4"><p class="header">{{ 'user.city' | translate }}</p></div>
						<div class="col-12 col-md-8"><p>{{ billingDetails.city }}</p></div>
					</div>
					<div class="row">
						<div class="col-12 col-md-4"><p class="header">{{ 'user.country' | translate }}</p></div>
						<div class="col-12 col-md-8"><p>{{ billingDetails.country }}</p></div>
					</div>
					<div class="row" *ngIf="billingDetails.taxId">
						<div class="col-12 col-md-4"><p class="header">{{ 'user.vatId' | translate }}</p></div>
						<div class="col-12 col-md-8"><p>{{ billingDetails.taxId }}</p></div>
					</div>

					<div class="row align-items-baseline">
						<div class="col-12 col-md-4"><h2 class="mt-5">{{ 'card.info' | translate }}</h2></div>
						<div class="col-12 col-md-8"><p class="edit" (click)="editCardDetails()">{{ 'general.edit' | translate }}</p></div>
					</div>
					<div class="row">
						<div class="col-12 col-md-4"><p class="header">{{ 'user.cardName' | translate }}</p></div>
						<div class="col-12 col-md-8"><p>{{ subscription['billing_details']['name'] }}</p></div>
					</div>
					<div class="row">
						<div class="col-12 col-md-4"><p class="header">{{ 'card.number' | translate }}</p></div>
						<div class="col-12 col-md-8"><p><span class="text-secondary">XXXX XXXX XXXX</span> {{ subscription['card']['last4'] }}</p></div>
					</div>
					<div class="row">
						<div class="col-12 col-md-4"><p class="header">{{ 'card.expirationDate' | translate }}</p></div>
						<div class="col-12 col-md-8"><p>{{ subscription['card']['exp_month']}}/{{ subscription['card']['exp_year'] }}</p></div>
					</div>

					<ng-container *ngIf="subscription['upcoming_invoice']">
						<h2 class="mt-5">{{ 'invoice.upcoming' | translate }}</h2>
						<p>{{ 'invoice.upcomingDescription' | translate:{date: subscription.upcoming_invoice.next_payment_attempt | date, amount: subscription.upcoming_invoice.total | currency:'EUR' } }}</p>
					</ng-container>

					<h2 class="mt-5">{{ 'invoice.plural' | translate }}</h2>
					<p *ngIf="invoices.length === 0" class="text-secondary">{{ 'invoice.noHistory' | translate }}</p>
					<div *ngFor="let invoice of invoices" class="row align-items-center">
						<div class="col-12 col-md-4">
							<p>{{ invoice.amount | currency:invoice.currency }}</p>
						</div>
						<div class="col-12 col-md-4">
							<p>{{ invoice.invoiceDate | date }}</p>
						</div>
						<div class="col-12 col-md-4 text-right">
							<button class="btn btn-primary" (click)="openInvoice(invoice.id)">{{ 'invoice.download' | translate }} <i class="bi bi-cloud-download ml-2"></i></button>
						</div>
					</div>

					<h2 class="mt-5">{{ 'subscription.cancel' | translate }}</h2>
					<button *ngIf="!subscription.cancel_at_period_end" type="button" class="btn btn-danger" (click)="cancelSubscription()" [disabled]="loading">{{ 'subscription.cancel' | translate  }}</button>

					<p *ngIf="subscription.cancel_at_period_end">{{ 'subscription.canceled' | translate:{ date: subscription.current_period_end | date} }}</p>
					<button *ngIf="subscription.cancel_at_period_end" type="button" class="btn btn-primary bt-3" (click)="resumeSubscription()" [disabled]="loading">{{ 'subscription.resume' | translate  }}</button>
				</div>
			</div>
		</form>
	</div>
</div>
