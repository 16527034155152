<div class="card">
	<p class="card-title">{{ 'stage.plural' | translate }}</p>
	<div class="row phases" *ngIf="timeSpentStages && moneyReceivedStages">
		<div class="col-12 col-md stage-block" *ngFor="let stage of stages; let stageIndex = index" [ngClass]="{ active: product.stage === stage }">
			<div class="header-block">
				<p>{{ portfolioService.getStageName(product.portfolio, stage) }}</p>
			</div>
			<p>
				<i class="bi bi-calendar3" *ngIf="stageIndex === 0" [title]="'stage.timeSpent' | translate"></i>
				<span *ngIf="timeSpentStages[stage] > 2"> {{ timeSpentStages[stage] | number: '1.0-0' }} {{ 'accounting.monthsSpent' | translate }} </span>
				<span *ngIf="timeSpentStages[stage] <= 2"> {{ timeSpentStages[stage] | number: '1.0-1' }} {{ 'accounting.monthSpent' | translate }} </span>
				<span *ngIf="!timeSpentStages[stage]">&nbsp;</span>
			</p>
			<p>
				<i class="bi bi-piggy-bank" *ngIf="stageIndex === 0" [title]="'stage.moneyReceived' | translate"></i>
				<ng-container *ngIf="moneyReceivedStages[stage] && timeSpentStages[stage]">
					{{ moneyReceivedStages[stage] | currency: product.portfolio.currency:'symbol':'1.0-0' }}
				</ng-container>
				<ng-container *ngIf="!moneyReceivedStages[stage] && timeSpentStages[stage]"> - </ng-container>
				<ng-container *ngIf="!moneyReceivedStages[stage] && !timeSpentStages[stage]">&nbsp;</ng-container>
			</p>
			<p>
				<i class="bi bi-cash-stack" *ngIf="stageIndex === 0" [title]="'stage.moneySpent' | translate"></i>
				<ng-container *ngIf="moneySpentStages[stage] && timeSpentStages[stage]">
					{{ moneySpentStages[stage] | currency: product.portfolio.currency:'symbol':'1.0-0' }}
				</ng-container>
				<ng-container *ngIf="!moneySpentStages[stage] && timeSpentStages[stage]"> - </ng-container>
				<ng-container *ngIf="!moneySpentStages[stage] && !timeSpentStages[stage]">&nbsp;</ng-container>
			</p>
		</div>
	</div>
</div>
