import { Portfolio } from './portfolio';
import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Experiment } from './experiment';
import { Product } from './product';
import { Sprint } from './sprint';
import { Sticky } from './sticky';
import { Task } from './task';

@ParseSubClass('Log')
export class Log extends Parse.Object {
	@ParseProperty() public activity: String;
	@ParseProperty() public targetClass: String;
	@ParseProperty() public targetId: String;
	@ParseProperty() public options: any;
	@ParseProperty() public product: Product;
	@ParseProperty() public portfolio: Portfolio;
	@ParseProperty() public createdBy: Parse.User;

	@ParseProperty() public experiment: Experiment;
	@ParseProperty() public sprint: Sprint;
	@ParseProperty() public sticky: Sticky;
	@ParseProperty() public task: Task;

	constructor() {
		super('Log');
	}
}
