import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Product } from './product';
import { User } from './user';

@ParseSubClass('Minute')
export class Minute extends Parse.Object {
	// @ParseProperty() public subject: string;
	@ParseProperty() public body: string;
	@ParseProperty() public bodyHtml: string;
	@ParseProperty() public actionables: string;
	@ParseProperty() public product: Product;
	@ParseProperty() public createdBy: User;

	constructor() {
		super('Minute');
	}

	getActionables() {
		return this.nl2br(this.actionables);
	}

	getBody() {
		return this.bodyHtml || this.nl2br(this.body);
	}

	private nl2br(str) {
		if (typeof str === 'undefined' || str === null) {
			return '';
		}
		// const textParsed = value.replace(/(?:\r\n|\r|\n)/g, '<br />');
		return (str + '').replace(/(?:\r\n|\r|\n)/g, '$1<br>$2');
	}
}
