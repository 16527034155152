import { Component, OnInit } from '@angular/core';
import { NextTitleService } from 'app/next-title.service';
import { User } from '../../../../shared/models/user';

declare let gtag: Function;

@Component({
	selector: 'app-signup-thankyou',
	templateUrl: './signup-thankyou.component.html',
	styleUrls: ['./signup-thankyou.component.scss']
})
export class SignupThankyouComponent implements OnInit {

	constructor(
		private titleService: NextTitleService
	) { }

	ngOnInit(): void {
		this.titleService.setTitle('Thank you for signing up');

		const user = User.current() as User;
		let pagePath = '/signupThankYou';

		// When a user was invited, use different page for Analytics
		if(!!user.invite) {
			pagePath = '/signupWithInvite';
		}

		// track manually because /platform/ is filtered out
		// and this is an important page
		gtag('event', 'page_view', {
			page_location: location.hostname,
			page_path: pagePath
		});
	}

}
