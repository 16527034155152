import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Portfolio } from './portfolio';
import { User } from './user';

@ParseSubClass('Cockpit')
export class Cockpit extends Parse.Object {
	@ParseProperty() public portfolio: Portfolio;
	@ParseProperty() public name: string;
	@ParseProperty() public createdBy: User;
	@ParseProperty() public updatedBy: User;
	@ParseProperty() public archived: boolean;
	@ParseProperty() public explanation: string;
	@ParseProperty() public orderIndex: number;

	constructor() {
		super('Cockpit');
	}
}
