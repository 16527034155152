import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-team-happiness-card',
	templateUrl: './team-happiness-card.component.html',
	styleUrls: ['./team-happiness-card.component.scss'],
})
export class TeamHappinessCardComponent {
	@Input() happiness: number;
	@Input() data: Object[];
	@Input() showPortfolioData: boolean;
	@Output() openChart: EventEmitter<any> = new EventEmitter<any>();

	constructor() {}

	openHappinessChart() {
		this.openChart.emit(true);
	}
}
