import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NextConfigService } from 'app/next-config.service';

@Component({
   selector: 'app-velocity-chart',
   templateUrl: './velocity-chart.component.html',
   styleUrls: ['./velocity-chart.component.scss']
})
export class VelocityChartComponent implements OnInit {
	@Input() labels: string[];
	@Input() datasets: Object[];
	@Input() isClickable: boolean;
	@Output() clickedPoint?: EventEmitter<string> = new EventEmitter<string>();

	public chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		legend: false,
		scales: {
			yAxes: [
				{
					scaleLabel: {
						display: false,
						labelString: '',
					},
					ticks: {
						callback: (value, index, values) => {
							return this.getLabel(value);
						},
						min: 0,
						maxTicksLimit: 5
					},
				},
			],
		},
	};
   public chartColors: Array<any> = [{
		backgroundColor: [],
	}];
   public isReady: boolean = false;

	constructor(
		private configService: NextConfigService
	) { }

   ngOnInit(): void {
      // setup colors
		this.chartColors[0].borderColor = this.configService.colorPrimary[0];
		this.chartColors[0].backgroundColor = this.configService.colorPrimary[1];

		this.isReady = true;
   }


	getLabel(value: number) {
		return '' + value + '%';
	}

	dataClicked(event) {
		if (this.isClickable){
			if (event.active.length > 0) {
				const chart = event.active[0]._chart;
				const activePoints = chart.getElementAtEvent(event.event);
				if ( activePoints.length > 0) {
					// get the internal index of slice in pie chart
					const clickedElementIndex = activePoints[0]._index;
					const label = chart.data.labels[clickedElementIndex];
					// get value by index
					this.clickedPoint.emit(label);
				}
			}
		}
	}
}
