import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NextTitleService } from 'app/next-title.service';

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit, OnDestroy {
	public activeTab: string;
	private routeSubscription;

	constructor(private titleService: NextTitleService, public router: Router) {}

	ngOnInit() {
		this.titleService.setTitle('Admin');

		this.activeTab = this.router.url.replace('/admin/', '');
		this.routeSubscription = this.router.events.subscribe(async (evt) => {
			if (evt['urlAfterRedirects']) {
				this.activeTab = evt['urlAfterRedirects'].replace('/admin/', '');
			}
		});
	}

	ngOnDestroy() {
		if (this.routeSubscription) {
			this.routeSubscription.unsubscribe();
		}
	}
}
