import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from 'app/log/log.service';
import { AnalyticsService } from 'app/shared/analytics.service';
import * as Parse from 'parse';
import { Canvas } from '../../../shared/models/canvas';
import { Experiment } from '../../../shared/models/experiment';
import { Product } from '../../../shared/models/product';
import { Sticky } from '../../../shared/models/sticky';
import { CanvasModalAddComponent } from './canvas-modal-add/canvas-modal-add.component';

@Injectable()
export class CanvasService {
	private cache: Object;
	public canvasUpdated: EventEmitter<any> = new EventEmitter();

	constructor(private analyticsService: AnalyticsService, private logService: LogService, private translate: TranslateService, private router: Router, private modalService: NgbModal) {
		this.cache = {};
	}

	getCanvas(id: string, includes?: Array<string>): Promise<Canvas> {
		if (this.cache[id]) {
			return new Promise((resolve, reject) => {
				resolve(this.cache[id]);
			});
		}

		const query = new Parse.Query(Canvas);

		if (includes) {
			query.include(includes);
		}
		query
			.include('activeVersion.customersegment')
			.include('activeVersion.valueproposition')
			.include('activeVersion.metrics')
			.include('activeVersion.channels')
			.include('activeVersion.problem')
			.include('activeVersion.solution')
			.include('activeVersion.revenuemodel')
			.include('activeVersion.growthengine');

		return query.get(id).then((model) => {
			this.cache[model.id] = model;

			return model;
		});
	}

	createCanvas(product: Product) {
		const canvas = new Canvas();
		canvas.product = product;

		const modalRef = this.modalService.open(CanvasModalAddComponent, {
			size: 'md',
		});
		modalRef.componentInstance.product = product;
		modalRef.componentInstance.canvas = canvas;
		const subscription = modalRef.componentInstance.save.subscribe(($e) => {
			this.addCanvas(canvas, product);

			subscription.unsubscribe();
		});
	}

	async addCanvas(canvas: Canvas, product: Product) {
		canvas.product = product;

		await canvas.save();
		// await canvas.activeVersion.fetch();

		// this.analyticsService.track('Created canvas');
		this.analyticsService.peopleIncrement('Canvases');

		this.router.navigate(['/portfolio', product.portfolio.id, 'startup', product.id, 'canvas', canvas.id]);

		// Add canvas to product
		// Need to save, otherwise one can't remove a canvas immediately after creating, even though this happens in the backend as well
		product.addUnique('canvases', canvas);
		await product.save();

		return canvas;
	}

	addAssumption(sticky: Sticky) {
		return sticky.save().then(() => {
			// this.analyticsService.track('Added sticky', {
			// 	segment: sticky.segment,
			// });
			this.analyticsService.peopleIncrement('Stickies');

			// version.addUnique(sticky.segment, sticky);
			// return version.save();
		});
	}

	addExperiment(experiment: Experiment, canvas: Canvas) {
		experiment.validated = null;
		experiment.canvas = canvas;

		return experiment.save().then((model) => {
			// this.analyticsService.track('Added experiment');
			this.analyticsService.peopleIncrement('Experiments');

			return model;
		});
	}

	async markRiskyAssumption(sticky: Sticky) {
		sticky.risky = !sticky.risky;
		sticky.riskyAt = new Date();

		// this.analyticsService.track('Marked sticky risky', {
		// 	segment: sticky.segment,
		// });

		await sticky.save();

		if (sticky.risky) {
			this.logService.logStickyActivity('marked sticky as risky', sticky);
		} else {
			this.logService.logStickyActivity('unmarked sticky as risky', sticky);
		}
	}

	async deleteAssumption(sticky: Sticky) {
		sticky.archived = true;
		sticky.archivedAt = new Date();

		await sticky.save();

		// Don't destroy sticky, since it is still visible in older canvas versions
	}

	public setActiveCanvas(canvas: Canvas) {
		localStorage.setItem('activeCanvas-' + canvas.product.id, canvas.id);
	}

	public removeActiveCanvas(product: Product) {
		localStorage.removeItem('activeCanvas-' + product.id);
	}

	public async getActiveCanvas(product: Product) {
		let canvases = product.canvases;

		if (!canvases.length) {
			return null;
		}

		const id = localStorage.getItem('activeCanvas-' + product.id);
		let activeCanvas;
		if (id) {
			for (let canvas of canvases) {
				if (canvas.id === id) {
					activeCanvas = canvas;
					this.setActiveCanvas(canvas);
				}
			}
		}

		if (!activeCanvas) {
			activeCanvas = canvases[0];
			this.setActiveCanvas(activeCanvas);
		}

		return activeCanvas;
	}

	public async deleteCanvas(canvas: Canvas) {
		canvas.archived = true;
		await canvas.save();

		const product = await canvas.product.fetch();
		const canvases = [...product.canvases].reverse();
		if (canvases?.length > 0) {
			this.setActiveCanvas(canvases[0]);
			this.router.navigate(['/portfolio', canvas.product.portfolio.id, 'startup', canvas.product.id, 'canvas', canvases[0].id]);
		} else {
			this.removeActiveCanvas(canvas.product);
			this.router.navigate(['/portfolio', canvas.product.portfolio.id, 'startup', canvas.product.id, 'canvas']);
		}
	}
}
