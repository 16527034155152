<div>
	<div class="row">
		<div class="col-12 col-md">
			<h3>{{ 'tool.overview' | translate }}</h3>
		</div>
		<div class="col-12 col-md-auto">
			<button class="btn btn-primary" (click)="createContentTool()">{{ 'tool.new' | translate }}</button>
		</div>
	</div>
	<hr />

	<ng-container *ngFor="let stage of stages">
		<h4 class="mb-3">{{ 'productStages.' + stage | translate }}</h4>
		<div class="row mb-5" *ngIf="!!stageTools && stageTools[stage].length > 0">
			<div class="col-12 col-md-6 mb-3" *ngFor="let contentTool of stageTools[stage]">
				<app-content-tool-card [contentTool]="contentTool" (cardClicked)="openContentTool($event)"></app-content-tool-card>
			</div>
		</div>
	</ng-container>
</div>
