import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Learning } from '../../../../shared/models/learning';
import { RemoveModalComponent } from 'app/validating-modals/remove-modal/remove-modal.component';
import { LogService } from 'app/log/log.service';
import { ProductService } from 'app/product/product.service';
import { Sticky } from '../../../../shared/models/sticky';
import { CanvasService } from 'app/canvas/canvas.service';
import { StickyService } from 'app/sticky/sticky.service';
import { ExperimentService } from 'app/experiment/experiment.service';
import { KeyQuestion } from '../../../../shared/models/key-question';
import { PortfolioService } from 'app/portfolio/portfolio.service';

@Component({
	selector: 'app-learning-modal',
	templateUrl: './learning-modal.component.html',
	styleUrls: ['./learning-modal.component.scss'],
})
export class LearningModalComponent implements OnInit {
	@Input() learning: Learning;
	@Input() editable: boolean;

	public createAssumption = false;
	public exampleStickies: Sticky[] = [];
	public questions: KeyQuestion[];

	constructor(
		public activeModal: NgbActiveModal,
		private canvasService: CanvasService,
		private experimentService: ExperimentService,
		private modalService: NgbModal,
		private logService: LogService,
		private productService: ProductService,
		private stickyService: StickyService,
		public portfolioService: PortfolioService
	) {}

	async ngOnInit() {
		this.questions = await this.portfolioService.getKeyQuestions(this.learning.product.portfolio, this.learning.product.stage);
	}

	onSave() {
		const isNew = this.learning.isNew();

		this.learning.save().then(() => {
			if (isNew) {
				this.logService.logActivity('added learning', 'Learning', this.learning.id, this.learning.product, this.learning.product.portfolio, {
					experiment: this.learning.experiment.toPointer(),
				});

				this.productService.productUpdated.emit(this.learning.product);
			} else {
				this.logService.logActivity('edited learning', 'Learning', this.learning.id, this.learning.product, this.learning.product.portfolio, {
					experiment: this.learning.experiment.toPointer(),
				});
			}
		});

		if (this.createAssumption) {
			this.addAssumption();
		}

		this.activeModal.close(true);
	}

	makeEditable() {
		this.editable = true;
	}

	async onSelectSegment(event) {
		this.learning.segment = event.segment;

		// convert segment to stage
		const stage = this.portfolioService.segmentToStage(event.segment);

		// update key questions
		this.questions = await this.portfolioService.getKeyQuestions(this.learning.product.portfolio, stage);
	}

	async addAssumption() {
		const sticky = new Sticky();
		sticky.text = this.learning.text.replace(/<.*?>/g, ''); // replace tags;
		sticky.segment = this.learning.segment;
		sticky.canvas = this.learning.experiment.canvas;
		sticky.product = this.learning.product;

		// Open Modal
		const result = await this.stickyService.openModal(sticky, this.learning.experiment.canvas);

		if (result) {
			this.canvasService.addAssumption(sticky);
			this.canvasService.canvasUpdated.emit();
		}
	}

	openExperiment() {
		this.experimentService.openModal(this.learning.experiment, this.learning.product);
	}

	async deleteLearning() {
		const modalRef = this.modalService.open(RemoveModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.element = 'learning';

		let removed = await modalRef.result;
		if (removed) {
			this.learning.archived = true;
			this.learning.save();
			this.activeModal.close(true);
		}
	}
}
