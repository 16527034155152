<div class="card h-100 hand" (click)="openLearning()">
	<div class="card-body">
		<p>
			<span *ngIf="learning.segment" class="badge badge-light mb-1 mr-1">{{ portfolioService.getSegmentName(learning.product.portfolio, learning.segment) }}</span>
			<span *ngIf="learning.experiment && showExperimentTag" class="badge light-blue hand mb-1"><i class="bi bi-eyedropper"></i>{{ abbrev }}-{{ learning.experiment.number }}</span>
		</p>
		<p class="card-text editor-data" [innerHtml]="learning.text"></p>
		<div class="card-meta d-flex flex-row justify-content-between align-items-center">
			<p class="small">{{ learning.createdAt | date: 'mediumDate' }}</p>
			<a class="identifier mb-1 mr-1" *ngIf="learning.product && showProductTag" [routerLink]="['../startup', learning.product.id, 'progress']">{{ learning.product.name }}</a>
		</div>
	</div>
</div>
