import { Component, OnInit } from '@angular/core';
import { NextTitleService } from 'app/next-title.service';
import { Cloud, Query, Role } from 'parse';
import { User } from '../../../../shared/models/user';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {
	public email: string;
	public errorMessage: string;
	public adminUsers: User[];

	constructor(
		private titleService: NextTitleService
	) { }

	ngOnInit() : void {
		this.titleService.setTitle('Admin - Users');

		this.getAdminUsers();
	}

	async getAdminUsers() {
		const roleQuery = new Query(Role);
		roleQuery.equalTo('name', 'admins');
		roleQuery.include('users');
		const adminRole = await roleQuery.first();

		const userQuery = adminRole.getUsers().query();
		userQuery.ascending('firstName');
		this.adminUsers = (await userQuery.find()) as Array<User>;
	}

	makeAdmin() {
		Cloud.run('addUserToAdmin', {
			email: this.email,
		}).then(
			(result) => {
				this.getAdminUsers();
				this.errorMessage = '';
				this.email = '';
			},
			(err) => {
				this.errorMessage = err.message;
			}
		);
	}

	removeUserAdmin(user) {
		Cloud.run('removeAccessUserAdmin', {
			userId: user.id
		}).then(
			(result) => {
				this.getAdminUsers();
				this.errorMessage = '';
			},
			(err) => {
				this.errorMessage = err.message;
			}
		)
	}
}
