import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Invite } from '@shared/models/invite';
import { Product } from '@shared/models/product';
import { User } from '@shared/models/user';
import { LogService } from 'app/log/log.service';
import { Query, Cloud } from 'parse';

@Component({
	selector: 'app-product-settings-collaborators',
	templateUrl: './product-settings-collaborators.component.html',
	styleUrls: ['./product-settings-collaborators.component.scss'],
})
export class ProductSettingsCollaboratorsComponent implements OnInit, OnDestroy {
	public signupLink: string;
	public product: Product;
	public pendingInvites: Invite[];
	public email: string;
	public inviteUsers: User[];
	public inviteResultMessage: Object;
	public errorMessage = '';
	public portfolioUsers: Array<User>;
	public productUsers: Array<User>;
	private routeSubscription;

	constructor(private translate: TranslateService, private logService: LogService, private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.signupLink = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/signup/';
		this.routeSubscription = this.route.parent.url.subscribe(async (url) => {
			const productId = url[1].path;
			const productquery = new Query(Product);
			this.product = await productquery.get(productId);

			this.getUsers();
			this.getPendingUsers();
		});
	}

	ngOnDestroy() {
		this.routeSubscription?.unsubscribe();
	}

	async getUsers() {
		this.portfolioUsers = await Cloud.run('getPortfolioUsers', {
			portfolioId: this.product.portfolio.id,
		});

		this.productUsers = await Cloud.run('getProductUsers', {
			productId: this.product.id,
			portfolioId: this.product.portfolio.id,
		});
	}

	async getPendingUsers() {
		let queryInvites = new Query(Invite);
		queryInvites.equalTo('product', this.product);
		queryInvites.equalTo('pending', true);
		this.pendingInvites = await queryInvites.find();
	}

	async onAddUser(evt) {
		this.email = evt.username;
		await this.inviteUser();

		this.inviteUsers = [];
	}

	inviteUser() {
		return Cloud.run('inviteUserToProduct', {
			email: this.email.toLowerCase(),
			productId: this.product.id,
			portfolioId: this.product.portfolio.id,
		}).then(
			(result) => {
				if (result instanceof Invite) {
					// user did not exist already, created invite class + link
					const link = this.signupLink + result.id;
					this.inviteResultMessage = this.translate.instant('invite.linkDescription', {
						link: link,
						class: this.translate.instant('product.singular'),
					});

					this.getPendingUsers();
				} else {
					// user existed and is added to startup
					this.inviteResultMessage = '';
					this.logService.logActivity('added user', 'User', this.email, this.product, this.product.portfolio, {
						email: this.email.toLowerCase(),
					});
				}

				this.errorMessage = '';
				this.email = '';
				this.getUsers();
			},
			(err) => {
				this.errorMessage = err.message;
			}
		);
	}

	removeAccess(user) {
		Cloud.run('removeAccessUserToProduct', {
			user: user.id,
			productId: this.product.id,
			portfolioId: this.product.portfolio.id,
		}).then(
			(result) => {
				// log
				this.logService.logActivity('remove user', 'User', user.id, this.product, this.product.portfolio, {
					// email: this.email.toLowerCase()
				});

				this.errorMessage = '';
				this.getUsers();
			},
			(err) => {
				this.errorMessage = err.message;
			}
		);
	}
}
