import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Portfolio } from '@shared/models/portfolio';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { ContentTool } from '../../../../shared/models/content-tool';

@Component({
	selector: 'app-content-tool',
	templateUrl: './content-tool.component.html',
	styleUrls: ['./content-tool.component.scss'],
})
export class ContentToolComponent implements OnInit, OnDestroy {
	@Input() contentTool: ContentTool;
	public portfolio: Portfolio;
	public routeSubscription;

	constructor(public portfolioService: PortfolioService, private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.routeSubscription = this.route.params.subscribe(async (params) => {
			if (Object.keys(params).length > 0) {
				// check if used in admin or in portfolio
				this.portfolio = await this.portfolioService.getActivePortfolio();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.routeSubscription) {
			this.routeSubscription.unsubscribe();
		}
	}
}
