<div class="element-group col-12 settings-content" *ngIf="product">
	<h5>{{ 'general.singular' | translate }}</h5>
	<hr />
	<div class="row">
		<div class="form-group col-md-6">
			<label
				>{{ 'product.title' | translate }}
				<input type="text" class="form-control" name="name" [(ngModel)]="product.name" />
			</label>
		</div>
	</div>
	<div class="row">
		<div class="form-group col-md-6">
			<label
				>{{ 'product.pitch' | translate }}
				<input type="text" class="form-control" name="pitch" [(ngModel)]="product.pitch" />
			</label>
		</div>
	</div>
	<div class="row">
		<div class="form-group col-md-6">
			<label for="input-product-description" class="mb-0">{{ 'description.singular' | translate }}</label>
			<quill-editor id="input-product-description" name="product description" [(ngModel)]="product.description" [styles]="{ height: '10rem' }" placeholder=""></quill-editor>
		</div>
	</div>

	<div class="form-group mt-5">
		<h5>{{ 'productModalSettings.general.logo' | translate }}</h5>
		<hr />
		<img *ngIf="product.logo" [src]="product.logo?.url()" class="img-fluid logo px-0" />
	</div>
	<div class="form-group">
		<label for="fileInput" class="btn attachment"
			><i class="bi bi-camera"></i>{{ 'product.uploadLogo' | translate }}
			<input type="file" class="form-control-file" id="fileInput" accept="image/*" (change)="onLogoChange($event)" #logoInput />
		</label>
		<p><ngb-progressbar type="primary" *ngIf="showProgress" [value]="progressUpload" height=".5rem"></ngb-progressbar></p>
	</div>

	<h5 class="mt-5 text-danger">{{ 'productModalSettings.general.archive' | translate }}</h5>
	<hr />
	<div class="btn-group btn-group-toggle" ngbRadioGroup name="archived" [(ngModel)]="product.archived">
		<label ngbButtonLabel [ngClass]="{ 'btn-primary': !product.archived, 'btn-outline-primary': product.archived }">
			<input ngbButton type="radio" [value]="false" /> {{ 'general.active' | translate }}
		</label>
		<label ngbButtonLabel [ngClass]="{ 'btn-danger': product.archived, 'btn-outline-danger': !product.archived }">
			<input ngbButton type="radio" [value]="true" /> {{ 'general.archived' | translate }}
		</label>
	</div>

	<div class="row mt-5">
		<div class="col-12">
			<ngb-alert type="warning" [dismissible]="false" *ngIf="errorMessage">
				{{ errorMessage }}
			</ngb-alert>
			<ngb-alert type="success" [dismissible]="false" *ngIf="resultMessage">
				{{ resultMessage }}
			</ngb-alert>
		</div>

		<div class="col-12 col-md-6">
			<button type="button" class="btn btn-primary" (click)="onSave()">{{ 'general.save' | translate }}</button>
		</div>
		<div class="col-12 col-md-6 text-right">
			<button type="button" class="btn btn-outline-secondary mr-auto" (click)="downloadData()">
				{{ 'general.export' | translate }}
			</button>
		</div>
	</div>
</div>
