import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Sticky } from '@shared/models/sticky';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { UnsavedModalComponent } from 'app/validating-modals/unsaved-modal/unsaved-modal.component';
import { StickyModalComponent } from './sticky-modal/sticky-modal.component';

@Injectable({
	providedIn: 'root',
})
export class StickyService {
	constructor(private modalService: NgbModal, private portfolioService: PortfolioService) {}

	openModal(sticky, canvas) {
		const modalRef = this.modalService.open(StickyModalComponent, {
			size: 'sm',
			centered: true,
			beforeDismiss: async () => {
				if (!sticky.dirty()) {
					return true;
				}

				const modal = this.modalService.open(UnsavedModalComponent, {
					size: 'sm',
					centered: true,
				});
				const result = await modal.result;

				if (result) {
					sticky.revert();
				}

				return result;
			},
		});
		modalRef.componentInstance.sticky = sticky;
		modalRef.componentInstance.canvas = canvas;

		return modalRef.result;
	}

	filterRiskyness(stickies: Sticky[], product) {
		let mostRiskyStickies = [];
		let riskyStickies = [];
		let confirmStickies = [];
		let stages = this.portfolioService.getMainStages(product.portfolio);

		// Order by stage, early stages on top of later stages
		stickies.sort((a, b) => {
			const aStage = this.portfolioService.segmentToStage(a.segment);
			const bStage = this.portfolioService.segmentToStage(b.segment);

			if (stages.indexOf(aStage) > stages.indexOf(bStage)) {
				return 1;
			} else if (stages.indexOf(aStage) < stages.indexOf(bStage)) {
				return -1;
			}

			return 0;
		});

		// Divide the stickies in risk levels
		for (let sticky of stickies) {
			const stickyStage = this.portfolioService.segmentToStage(sticky.segment);

			console.log(sticky.text, sticky.canvas.name, sticky.canvas.archived)

			// Remove archived canvases
			if (sticky.canvas.archived == true) {
				continue;
			}

			// Remove hidden stages (5 and/or 6)
			if (stages.indexOf(stickyStage) == -1) {
				continue;
			}

			// Remove future stages
			if (stages.indexOf(stickyStage) > stages.indexOf(product.stage)) {
				continue;
			}

			if (sticky.getRiskyness() == 'most-risky') {
				mostRiskyStickies.push(sticky);
			}

			if (sticky.getRiskyness() == 'risky') {
				riskyStickies.push(sticky);
			}

			if (sticky.getRiskyness() == 'confirm') {
				confirmStickies.push(sticky);
			}
		}

		return {
			'most-risky': mostRiskyStickies,
			risky: riskyStickies,
			confirm: confirmStickies,
		};
	}
}
