import { Component, OnInit } from '@angular/core';
import { NextTitleService } from 'app/next-title.service';

@Component({
	selector: 'app-reset-password-thankyou',
	templateUrl: './reset-password-thankyou.component.html',
	styleUrls: ['./reset-password-thankyou.component.scss']
})
export class ResetPasswordThankyouComponent implements OnInit {

	constructor(
		private titleService: NextTitleService
	) { }

	ngOnInit(): void {
		this.titleService.setTitle('Password reset successful');
	}

}
