<div>
	<h5>Innovation Steps <!--<i class="fal text-secondary ml-1 fa-question-circle"></i>--></h5>
	<ol class="mx-0 pl-3">
		<li *ngFor="let step of steps"><a [routerLink]="['../' + step]" routerLinkActive="font-weight-bold text-primary">{{ 'guide.' + step + '.question' | translate }}</a></li>
	</ol>

	<ngb-alert type="primary" [dismissible]="false">
			<h5>To do</h5>
			<p class="mb-0" [innerHtml]="'guide.' + activeRoute + '.explanation' | translate"></p>
		</ngb-alert>
		<ngb-alert type="info" [dismissible]="false">
			<h5>Next steps</h5>
			<p class="mb-2" [innerHtml]="'guide.' + activeRoute + '.nextStep' | translate"></p>
			<p class="mb-0 text-inherit"><a [routerLink]="getNextStep()">Go to next step.</a></p>
		</ngb-alert>
</div>