<div>
	<h3>{{ 'admin.users' | translate }}</h3>
	<hr>
	<div class="row">
		<div class="col">
			<ngb-alert *ngIf="errorMessage" type="danger" [dismissible]="false">{{ errorMessage }}</ngb-alert>
			<p *ngIf="adminUsers && !adminUsers.length">-</p>
			<div class="row">
				<div class="col-6 col-md-3" *ngFor="let user of adminUsers">
					<app-user-card [user]="user" (remove)="removeUserAdmin($event)" [removeMessage]="'admin.remove' | translate:{userName: user.firstName}"></app-user-card>
				</div>
			</div>

			<div class="input-group">
				<input type="text" class="form-control" [placeholder]="'email.example' | translate" name="email" [(ngModel)]="email" />
				<div class="input-group-addon">
					<input type="button" class="btn btn-primary" [value]="'admin.make' | translate" (click)="makeAdmin()" />
				</div>
			</div>
		</div>
	</div>
</div>
