<div class="row" *ngIf="!!product">
	<div class="col-12 col-md product-content">
		<ng-container *ngFor="let sprint of sprints; let i = index">
			<!-- sprint header -->

			<div
				*ngIf="sprint.status !== SprintStatus.BACKLOG"
				[attr.aria-expanded]="!collapseTracking[sprint.id]"
				[attr.aria-controls]="'collapse-' + sprint.id"
				class="header header-dropdown d-flex justify-content-between flex-row align-items-center"
			>
				<!-- <span class="hand" (click)="collapseTracking[sprint.id] = !collapseTracking[sprint.id]"> -->
				<h4 *ngIf="!isActiveSprint(sprint)">{{ 'sprint.singular' | translate }} {{ sprint.number }}</h4>
				<h4 *ngIf="isActiveSprint(sprint)" class="current-sprint">
					{{ 'sprint.current' | translate }} <span>{{ sprint.number }}</span>
				</h4>

				<!-- <i *ngIf="!collapseTracking[sprint.id]" class="bi bi-caret-down-fill"></i> -->
				<!-- <i *ngIf="collapseTracking[sprint.id]" class="bi bi-caret-right"></i> -->
				<!-- </span> -->

				<span>
					<button *ngIf="sprint.status !== SprintStatus.PREPARING" class="btn btn-link mr-1" (click)="editSprint(sprint)" [disabled]="!product.isActive()">
						{{ 'general.edit-details' | translate }}
					</button>
					<button *ngIf="!isActiveSprint(sprint) && i === 0" class="btn btn-primary" (click)="startSprint(sprint, true)" [disabled]="!product.isActive()">
						{{ 'sprint.start' | translate }}
					</button>
					<button *ngIf="isActiveSprint(sprint)" class="btn btn-primary" (click)="completeSprint(sprint)" [disabled]="!product.isActive()">
						{{ 'sprint.complete' | translate }}
					</button>
				</span>
			</div>
			<!-- backlog header -->
			<div
				*ngIf="sprint.status === SprintStatus.BACKLOG"
				class="header-dropdown backlog-dropdown d-flex justify-content-between flex-row"
			>
				<h4>Future Experiments</h4>
				<button *ngIf="sprints.length <= 1" class="btn" [ngClass]="{ 'btn-outline-primary': sprints.length > 1, 'btn-primary': sprints.length <= 1 }" (click)="createNewSprint()">
					{{ 'sprint.createNew' | translate }}
				</button>
			</div>

			<div class="sprint-container" [id]="'collapse-' + sprint.id" [ngbCollapse]="collapseTracking[sprint.id]">
				<div class="row">
					<div class="col-12 col-lg-2">
						<p class="header">
							{{ 'experiment.plural' | translate }}
						</p>
						<p *ngIf="product.portfolio.sprintType === PortfolioSprintType.KANBAN && sprint.status !== SprintStatus.BACKLOG" class="header">Running</p>
						<p *ngIf="product.portfolio.sprintType === PortfolioSprintType.KANBAN && sprint.status === SprintStatus.BACKLOG" class="header">Prepared</p>
					</div>
					<div class="col-12 col-lg-10 row-container">
						<div
							class="experiments"
							cdkDropList
							[id]="'experimentsSprint-' + sprint.id"
							[cdkDropListData]="sprint"
							[cdkDropListConnectedTo]="allExperimentDropLists"
							(cdkDropListDropped)="dropExperiment($event)"
						>
							<div *ngFor="let experiment of sprint.experiments; let i = index" class="backlog-row drag-cursor" cdkDrag [cdkDragData]="experiment" (click)="openExperiment(experiment)">
								<app-experiment-row [experiment]="experiment"></app-experiment-row>
								<div *cdkDragPlaceholder matchSize="true" class="placeholderblock"></div>
							</div>
							<p class="new">
								<button class="btn btn-link" (click)="createNewExperiment(sprint)" [disabled]="!product.isActive()"><i class="bi bi-plus"></i>{{ 'experiment.createNew' | translate }}</button>
							</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-2">
						<p class="header">{{ 'task.plural' | translate }}</p>
					</div>
					<div class="col-lg-10 row-container">
						<div class="tasks" cdkDropList [id]="'tasksSprint-' + sprint.id" [cdkDropListData]="sprint" [cdkDropListConnectedTo]="allTaskDropLists" (cdkDropListDropped)="dropTask($event)">
							<div *ngFor="let task of sprint.tasks; let i = index" class="backlog-row drag-cursor" cdkDrag [cdkDragData]="task" (click)="openTask(task, sprint)">
								<app-task-row [task]="task" [product]="product"></app-task-row>
								<div *cdkDragPlaceholder matchSize="true" class="placeholderblock"></div>
							</div>
							<p class="new">
								<button class="btn btn-link" (click)="createNewTask(sprint)" [disabled]="!product.isActive()"><i class="bi bi-plus"></i>{{ 'task.createNew' | translate }}</button>
							</p>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="col-12 col-md product-side-bar right risk-scores">
		<app-product-loop></app-product-loop>
		
		<p class="mt-3 mb-1 most-risky" *ngIf="mostRiskyStickies.length">{{ 'sticky.risk.most-risky' | translate }}</p>
		<app-canvas-sticky *ngFor="let sticky of mostRiskyStickies" [sticky]="sticky" [canvas]="sticky.canvas" [canOpen]="true"></app-canvas-sticky>

		<p class="mt-3 mb-1 risky" *ngIf="riskyStickies.length">{{ 'sticky.risk.risky' | translate }}</p>
		<app-canvas-sticky *ngFor="let sticky of riskyStickies" [sticky]="sticky" [canvas]="sticky.canvas" [canOpen]="true"></app-canvas-sticky>

		<p class="mt-3 mb-1 confirm" *ngIf="confirmStickies.length">{{ 'sticky.risk.confirm' | translate }}</p>
		<app-canvas-sticky *ngFor="let sticky of confirmStickies" [sticky]="sticky" [canvas]="sticky.canvas" [canOpen]="true"></app-canvas-sticky>
	</div>
</div>
