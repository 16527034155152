import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Experiment } from './experiment';
import { Insight } from './insight';
import { Learning } from './learning';
import { Product } from './product';
import { Minute } from './minute';
import { Task } from './task';

@ParseSubClass('Sprint')
export class Sprint extends Parse.Object {
	@ParseProperty() public product: Product;
	@ParseProperty() public status: Sprint.Status;
	@ParseProperty() public stage: string;

	@ParseProperty() public startedAt: Date;
	@ParseProperty() public endedAt: Date;
	@ParseProperty() public createdBy: Parse.User;
	@ParseProperty() public number: number;

	@ParseProperty() public demo: string;

	@ParseProperty() public commentsTeam: string;

	@ParseProperty() public shortImpediments: string;
	@ParseProperty() public longImpediments: string;

	@ParseProperty() public teamHapiness: string;
	@ParseProperty() public teamEducation: string;

	@ParseProperty() public mostRisky: string;
	@ParseProperty() public experiments: Experiment[];

	@ParseProperty() public tasks: Task[];

	@ParseProperty() public minutes: Minute[];

	constructor() {
		super('Sprint');
	}
}

export namespace Sprint {
	export enum Status {
		BACKLOG = 'backlog', // used for backlog tasks and experiments
		PREPARING = 'prepared',
		RUNNING = 'running',
		DONE = 'done',
	}
}
