import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CanvasService } from 'app/canvas/canvas.service';
import { LogService } from 'app/log/log.service';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import JSZip from 'jszip';
import dayjs from 'dayjs';
import { Parser } from '@json2csv/plainjs';
import * as Parse from 'parse';
import { Query } from 'parse';
import { Experiment } from '../../../shared/models/experiment';
import { Insight } from '../../../shared/models/insight';
import { Learning } from '../../../shared/models/learning';
import { Portfolio } from '../../../shared/models/portfolio';
import { Product } from '../../../shared/models/product';
import { ProductDecision } from '../../../shared/models/product-decision';
import { Sprint } from '../../../shared/models/sprint';
import { Sticky } from '../../../shared/models/sticky';
import { ProductModalAddComponent } from './product-modal-add/product-modal-add.component';
import { Progress } from '@shared/models/progress';
import { Lens } from '@shared/models/lens';
import { Cockpit } from '@shared/models/cockpit';

@Injectable()
export class ProductService {
	public productUpdated: EventEmitter<any>;

	private productCache: Object;
	private activeProduct: Promise<Product>;
	private productSubscription: Parse.LiveQuerySubscription;

	public stages = [Product.Stage.KICKBOX, Product.Stage.PROBLEM, Product.Stage.SOLUTION, Product.Stage.REVENUE, Product.Stage.SCALE, Product.Stage.VALIDATED];
	public canvasStages = [Product.Stage.PROBLEM, Product.Stage.SOLUTION, Product.Stage.REVENUE, Product.Stage.SCALE];
	public stageLabels = {
		kickbox: 'Pre-Validation',
		problem: 'Problem',
		solution: 'Solution',
		revenue: 'Revenue',
		scale: 'Scale',
		validated: 'Validated',
	};

	constructor(public translate: TranslateService, public portfolioService: PortfolioService, public canvasService: CanvasService, private logService: LogService, private modalService: NgbModal) {
		this.productCache = {};
		this.productUpdated = new EventEmitter();
	}

	async createProduct(portfolio: Portfolio) {
		const product = new Product();

		product.portfolio = portfolio;

		const modalRef = this.modalService.open(ProductModalAddComponent, { size: 'md' });
		modalRef.componentInstance.product = product;

		const result = await modalRef.result;

		if (!result) {
			return;
		}

		await this.addProduct(product, portfolio);

		return product;
	}

	async addProduct(product: Product, portfolio: Portfolio) {
		await product.save();

		// this.analytics.track('Added product');
		this.logService.logActivity('added startup', 'Product', product.id, product, portfolio);
	}

	async setActiveProduct(product) {
		this.activeProduct = product.fetchWithInclude(['activeSprint', 'activeProgress', 'canvases', 'experiments', 'portfolio', 'portfolio.theses', 'teamMembers', 'thesis']);

		return this.activeProduct;
	}

	getActiveProduct() {
		return this.activeProduct;
	}

	getProduct(id: string, includes?: Array<string>): Promise<Product> {
		if (this.productCache[id]) {
			return new Promise((resolve, reject) => {
				resolve(this.productCache[id]);
			});
		}

		const query = new Parse.Query(Product);

		if (includes) {
			query.include(includes);
		}

		return query.get(id).then((model) => {
			this.productCache[model.id] = model;

			return model;
		});
	}

	async getProducts(includes?: Array<string>): Promise<Product[]> {
		const query = new Parse.Query(Product);

		const portfolio = await this.portfolioService.getActivePortfolio();

		query.equalTo('portfolio', portfolio);
		query.include(['activeProgress', 'canvases', 'experiments', 'portfolio', 'portfolio.theses', 'teamMembers', 'createdBy']);
		query.equalTo('archived', false);
		query.ascending('name');

		return query.find();
	}

	getStartedSprints(product: Product, includes?: Array<string>): Promise<Sprint[]> {
		const query = new Query(Sprint);
		query.equalTo('product', product);
		query.include(['experiments', 'minutes', 'minutes.createdBy', 'tasks', 'experiments.product.portfolio.enabledContent']);

		if (includes) {
			query.include(includes);
		}

		// should not be 'prepared' or 'backlog'
		query.containedIn('status', [Sprint.Status.DONE, Sprint.Status.RUNNING]);
		query.descending('number');

		return query.find() as Promise<Sprint[]>;
	}

	getExperiments(product: Product, includes?: Array<string>): Promise<Experiment[]> {
		const query = product.experiments.query();

		if (includes) {
			query.include(includes);
		}

		query.notEqualTo('archived', true);
		query.ascending('orderIndex');

		return query.find() as Promise<Experiment[]>;
	}

	getTimeSpentInStages(product, decisions) {
		let timeSpent = {};

		if (!decisions) {
			return timeSpent;
		}

		// Clone array
		const sortedDecisions = decisions.slice(0) as ProductDecision[];

		// add decision to show current time in current stage
		const decision = new ProductDecision();
		decision.decisionDate = new Date();
		decision.decisionMade = ProductDecision.DecisionType.MOVEFWD;
		decision.toStage = product.stage;

		sortedDecisions.push(decision);

		// Sort decisions
		sortedDecisions.sort(this.decisionSort);

		if (
			sortedDecisions.length > 0 &&
			(sortedDecisions[0].decisionMade != ProductDecision.DecisionType.START || (sortedDecisions[0].decisionMade === ProductDecision.DecisionType.START && !sortedDecisions[0].decisionDate)) &&
			product.startAt
		) {
			const startDecision = new ProductDecision();
			(startDecision.product = product), (startDecision.decisionDate = product.startedAt);
			startDecision.decisionMade = 'start';
			startDecision.toStage = product.startStage || 'kickbox';
			sortedDecisions.push(startDecision);
		}

		let prevDecision;
		for (let decision of sortedDecisions) {
			if (!prevDecision) {
				prevDecision = decision;
			} else if (decision.decisionMade === ProductDecision.DecisionType.MOVEFWD) {
				if (!timeSpent[prevDecision.toStage]) {
					timeSpent[prevDecision.toStage] = 0;
				}

				const a = dayjs(decision.decisionDate);
				const b = dayjs(prevDecision.decisionDate);
				const diff = a.diff(b, 'months', true);
				timeSpent[prevDecision.toStage] += diff;
				prevDecision = decision;
			}
		}

		return timeSpent;
	}

	getMoneySpentInStages(product, decisions) {
		let moneySpent = {};

		if (!decisions) {
			return moneySpent;
		}

		const sortedDecisions = decisions.slice(0) as ProductDecision[];
		sortedDecisions.sort(this.decisionSort);

		for (let decision of sortedDecisions) {
			if (!!decision.moneyGranted) {
				if (!moneySpent[decision.toStage]) {
					moneySpent[decision.toStage] = decision.moneyGranted;
				} else {
					moneySpent[decision.toStage] += decision.moneyGranted;
				}
			}
		}

		return moneySpent;
	}

	productSortName(a, b): number {
		if (a.name < b.name) {
			return -1;
		} else if (a.name > b.name) {
			return 1;
		} else {
			return 0;
		}
	}

	productSortNameDescending(a, b): number {
		if (a.name > b.name) {
			return -1;
		} else if (a.name < b.name) {
			return 1;
		} else {
			return 0;
		}
	}

	decisionSort(a, b): number {
		if (a.decisionDate > b.decisionDate) {
			return 1;
		} else if (a.decisionDate < b.decisionDate) {
			return -1;
		} else {
			return 0;
		}
	}

	async createShadowProgress(product) {
		if (!product.activeProgress) {
			const questions = await this.portfolioService.getKeyQuestions(product.portfolio, product.stage);

			const progress = new Progress();
			progress.product = product;
			progress.stage = product.stage;
			progress.questions = {};

			// set correct default values for Progress to 1
			for (let question of questions) {
				progress.questions[question.id] = 1;
			}

			await progress.save();

			product.activeProgress = progress;
			await product.save();
		}


		const progress = product.activeProgress.clone();
		// clone the questions separately, otherwise points to the same object
		let newQuestions = {};
		for (let key in progress.questions) {
			newQuestions[key] = progress.questions[key];
		}
		progress.questions = newQuestions;
		return progress;
	}

	async updateProgress(product, newProgress) {
		// save new progress once a day
		const isSameDay = dayjs(product.activeProgress.createdAt).isSame(dayjs(), 'day');

		// or when stage changes
		const isSameStage = product.activeProgress.stage === product.stage;

		if (!isSameDay || !isSameStage) {
			newProgress.stage = product.stage;

			await newProgress.save();

			product.activeProgress = newProgress;
			await product.save();
		}
		// else save current progress
		else {
			product.activeProgress.questions = {};
			product.activeProgress.questions = newProgress.questions;
			await product.activeProgress.save();
		}
	}

	async getLearningsPerKeyQuestion(product) {
		const learningQuery = new Query(Learning);
		learningQuery.equalTo('product', product);
		learningQuery.equalTo('archived', false);
		learningQuery.include(['experiment', 'keyQuestion']);
		learningQuery.descending('createdAt');
		const learnings = await learningQuery.find();
		const questions = await this.portfolioService.getKeyQuestions(product.portfolio, product.stage);

		const learningsPerQuestion = [];
		for (let question of questions) {
			const filteredLearnings = learnings.filter((l) => l.keyQuestion?.id == question.id);
			const newQuestionObject = {
				question: question,
				learnings: filteredLearnings,
			};
			learningsPerQuestion.push(newQuestionObject);
		}

		return learningsPerQuestion;
	}

	public async exportData(exportProducts: Product[]) {
		const products = [];
		const canvases = [];
		const experiments = [];
		const sprints = [];
		const learnings = [];
		const insights = [];
		const decisions = [];

		for (let product of exportProducts) {

			// Add row with product details
			products.push({
				id: product.id,
				name: product.name,
				logo: product.logo ? product.logo.url() : '',
				pitch: product.pitch,
				description: product.description,
				stage: product.stage,
				archived: !!product.archived,
				lenses: product.lensValues,
				cockpit: product.cockpitValues
			});

			// Add canvas data
			product.canvases.forEach(async (canvas) => {
				let stickyQuery = new Parse.Query(Sticky);
				stickyQuery.equalTo('canvas', canvas);
				stickyQuery.notEqualTo('archived', true);
				let stickies = await stickyQuery.find();

				stickies.forEach((assumption: Sticky) => {
					canvases.push({
						id: assumption.id,
						canvasId: canvas.id,
						product: product.id,
						canvasName: canvas.name || 'Untitled',
						canvasType: canvas.type || 'next-canvas',
						segment: this.portfolioService.getSegmentName(product.portfolio, assumption.segment, canvas.type),
						assumption: assumption.text || '',
						description: assumption.description || '',
						validated: !!assumption.validated,
						risk: assumption.getRiskyness(),
					});
				});
			});

			if (!!product.learnings) {
				let learningsQuery = product.learnings.query() as Parse.Query<Learning>;
				const learningsFound = await learningsQuery.find();

				learningsFound.forEach((learning) => {
					learnings.push({
						id: learning.id,
						product: product.id,
						text: learning.text,
					});
				});
			}

			if (!!product.insights) {
				let insightsQuery = product.insights.query() as Parse.Query<Insight>;
				const insightsFound = await insightsQuery.find();

				insightsFound.forEach((insight) => {
					insights.push({
						id: insight.id,
						product: product.id,
						text: insight.text,
						decision: insight.decision,
					});
				});
			}

			const exps = await this.getExperiments(product, ['canvas'])
				
			exps.forEach((experiment) => {
				experiments.push({
					id: experiment.id,
					product: product.id,
					number: experiment.number,
					type: experiment.type,
					status: experiment.status,
					startAt: experiment.startAt,
					endAt: experiment.endAt,
					learninggoal: experiment.learninggoal,
					riskyassumption: experiment.riskyassumption,
					description: experiment.description,
					failmetric: experiment.metric,
					nextsteps: experiment.nextsteps,
					result: experiment.getResult(),
				});
			});
				
			const models = await this.getStartedSprints(product, ['learningsMade', 'insights']);

			models.forEach((sprint) => {
				sprints.push({
					id: sprint.id,
					product: product.id,
					number: sprint.number,
					status: sprint.status,
					startAt: sprint.startedAt,
					endAt: sprint.endedAt,
					mostrisky: sprint.mostRisky,
					demo: sprint.demo,
					teamHapiness: sprint.teamHapiness,
					teamEducation: sprint.teamEducation,
				});
			});

			const decisionsQuery = new Query(ProductDecision);
			decisionsQuery.include(['minutes', 'attachments']);
			decisionsQuery.equalTo('product', product);
			decisionsQuery.equalTo('archived', false);
			decisionsQuery.descending('decisionDate');
			const productDecisions = await decisionsQuery.find();

			for (const decision of productDecisions) {
				decisions.push({
					id: decision.id,
					product: product.id,
					decision: decision.decisionMade,
					fromStage: decision.fromStage,
					toStage: decision.toStage,
					date: decision.decisionDate,
					investment: decision.moneyGranted,
					nextMeeting: decision.nextMeetingDate,
					goal: decision.goal
				});
			}
		}

		const lenses = [];
		const cockpitFields = [];

		const lensesData = await new Query(Lens)
			.equalTo('portfolio', exportProducts[0].portfolio)
			.equalTo('archived', false)
			.find();
		
		for (let lens of lensesData) {
			lenses.push({
				id: lens.id,
				name: lens.name,
				value: lens.value,
				labels: lens.labels
			})
		}
		
		const cockpitFieldsData = await new Query(Cockpit)
			.equalTo('portfolio', exportProducts[0].portfolio)
			.equalTo('archived', false)
			.find();
		
		for (let cockpit of cockpitFieldsData) {
			cockpitFields.push({
				id: cockpit.id,
				name: cockpit.name,
				explanantion: cockpit.explanation
			})
		}

		const productCsv = this.js2csv(products);
		const canvasCsv = this.js2csv(canvases);
		const experimentCsv = this.js2csv(experiments);
		const sprintsCsv = this.js2csv(sprints);
		const learningsCsv = this.js2csv(learnings);
		const insightsCsv = this.js2csv(insights);
		const decisionsCsv = this.js2csv(decisions);

		const lensCsv = this.js2csv(lenses);
		const cockpitCsv = this.js2csv(cockpitFields);

		const jszip = new JSZip();
		jszip.file('products.csv', productCsv);
		jszip.file('assumptions.csv', canvasCsv);
		jszip.file('experiments.csv', experimentCsv);
		jszip.file('sprints.csv', sprintsCsv);
		jszip.file('learnings.csv', learningsCsv);
		jszip.file('insights.csv', insightsCsv);
		jszip.file('decisions.csv', decisionsCsv);

		jszip.file('lenses.csv', lensCsv);
		jszip.file('cockpit.csv', cockpitCsv);

		jszip.generateAsync({ type: 'blob' }).then(function (content) {
			const blob = new Blob([content], {
				type: 'application/zip',
			});
			const url = window.URL.createObjectURL(blob);
			window.open(url);
		});
	}

	private js2csv(data) {
		if (!data.length) {
			return '';
		}

		try {
			const parser = new Parser();
			const csv = parser.parse(data);
			
			return csv;
		} catch (err) {
			console.error(err);
		}
	}
}
