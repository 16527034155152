import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../../../shared/models/user';

@Component({
	selector: 'app-user-card',
	templateUrl: './user-card.component.html',
	styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent {
	@Input() user: User;
	@Input() removeMessage: string;
	@Output() remove: EventEmitter<User> = new EventEmitter<User>();

	constructor() {}

	crossClicked() {
		this.remove.emit(this.user);
	}
}
