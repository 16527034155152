import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../../../../shared/models/user';
import { AnalyticsService } from '../../shared/analytics.service';
import { Invite } from '../../../../shared/models/invite';
import { Cloud } from 'parse';
import { TranslateService } from '@ngx-translate/core';
import { NextTitleService } from 'app/next-title.service';
import { Portfolio } from '@shared/models/portfolio';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { MicrosoftService } from 'app/sso/microsoft.service';

declare let gtag: Function;

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
	public user: User = new User();
	public passwordRepeat: string;
	public invalidInvite: boolean;
	public errorMessage: string;
	public signupType = '';

	public invite: Invite;

	constructor(
		private router: Router,
		private analytics: AnalyticsService,
		private route: ActivatedRoute,
		private titleService: NextTitleService,
		private translate: TranslateService,
		private portfolioService: PortfolioService,
		private microsoftService: MicrosoftService
	) {}

	ngOnInit() {
		if (User.current()) {
			this.router.navigate(['/']);
			return;
		}

		this.titleService.setTitle('Signup');

		// track manually because /platform/ is filtered out
		// and this is an important page
		gtag('event', 'page_view', {
			page_location: location.hostname,
			page_path: '/signup',
		});

		this.route.params.subscribe(async (params) => {
			if (!params['inviteId']) {
				return;
			}

			try {
				this.invite = await Cloud.run('getInviteForSignup', {
					inviteId: params['inviteId'],
				});

				this.user.email = this.invite.email;
				this.user.invite = this.invite;

				if (this.user.email.match('@aes.com')) {
					this.signupType = 'sso';
				}

			} catch (error) {
				this.errorMessage = error.message;
			}
		});
	}

	async onSignup() {
		this.errorMessage = '';

		if (!this.user.company) {
			this.errorMessage = this.translate.instant('signup.companyMissing');
			return;
		}
		if (!this.user.email) {
			return;
		}

		if (!this.user.acceptedTerms) {
			this.errorMessage = this.translate.instant('signup.acceptMissing');
			return;
		}

		if (this.user.password !== this.passwordRepeat) {
			this.errorMessage = this.translate.instant('signup.passwordNotMatch');
			return;
		}

		this.user.email = this.user.email.toLowerCase();

		this.user.set('username', this.user.get('email'));
		try {
			await this.user.signUp(null);

			this.analytics.signup(this.user);

			this.router.navigate(['/signupThankyou']);
		} catch (error) {
			// Show the error message somewhere and let the user try again.
			this.errorMessage = error.message;
		}
	}

	async onMicrosoft() {
		try {
			this.microsoftService.loginWithMicrosoft();
		} catch (error) {
			this.errorMessage = error;
		}
	}
}
