<div>
	<div class="row">
		<div class="col-12 col-md">
			<h3>{{ 'experiment.overview' | translate }}</h3>
		</div>
		<div class="col-12 col-md-auto">
			<button class="btn btn-primary" (click)="createContentExperiment()">{{ 'experiment.new' | translate }}</button>
		</div>
	</div>
	<hr />

	<ng-container *ngIf="contentExperiments.length > 0">
		<app-content-experiment-overview [contentExperiments]="contentExperiments" [translatable]="true"></app-content-experiment-overview>
	</ng-container>
</div>
