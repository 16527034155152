import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'app/product/product.service';
import { Portfolio } from '../../../../shared/models/portfolio';
import { PortfolioService } from '../portfolio.service';
import { Product } from '../../../../shared/models/product';
import { Cloud } from 'parse';
import { AnalyticsService } from 'app/shared/analytics.service';
import { NextTitleService } from 'app/next-title.service';

@Component({
	selector: 'app-portfolio-products',
	templateUrl: './portfolio-products.component.html',
	styleUrls: ['./portfolio-products.component.scss'],
})
export class PortfolioProductsComponent implements OnInit, OnDestroy {
	public products: Product[];
	public portfolio: Portfolio;

	public productsNew: Product[] = [];
	public productsActive: Product[] = [];
	public productsStopped: Product[] = [];
	public productsValidated: Product[] = [];
	public productsArchived: Product[] = [];

	public viewActive = true;
	public productsIA;
	public currentQuarter: string;
	public previousQuarter: string;

	private subscription;

	constructor(
		private route: ActivatedRoute,
		private productService: ProductService,
		public portfolioService: PortfolioService,
		public router: Router,
		private analytics: AnalyticsService,
		private titleService: NextTitleService
	) {}

	async ngOnInit() {
		// this.analytics.track('Opened portfolio startups');

		this.subscription = this.route.url.subscribe(async () => {
			this.portfolio = await this.portfolioService.getActivePortfolio();
			this.products = await this.productService.getProducts();
			await this.getInnovationAcccountingProducts();

			this.titleService.setTitle('Startups - ' + this.portfolio.name);

			this.populateProducts();
		});
	}

	ngOnDestroy() {
		this.subscription?.unsubscribe();
	}

	populateProducts() {
		this.productsNew = [];
		this.productsActive = [];
		this.productsStopped = [];
		this.productsValidated = [];

		this.products.forEach((product) => {
			if (product.isStopped()) {
				this.productsStopped.push(product);
				return;
			}

			if (product.stage === Product.Stage.KICKBOX) {
				this.productsNew.push(product);
				return;
			}

			if (product.isValidated()) {
				this.productsValidated.push(product);
				return;
			}

			this.productsActive.push(product);
		});
	}

	async getInnovationAcccountingProducts() {
		this.productsIA = await Cloud.run('getInnovationAccountingAllProducts', {
			portfolioId: this.portfolio.id,
		});
		if (this.productsIA && this.productsIA['currentFourQuarters']) {
			if (this.productsIA['currentFourQuarters'].length >= 2) {
				this.currentQuarter = this.productsIA['currentFourQuarters'][0];
				this.previousQuarter = this.productsIA['currentFourQuarters'][1];
			} else if ((this.productsIA['currentFourQuarters'].length = 1)) {
				this.currentQuarter = this.productsIA['currentFourQuarters'][0];
			}
		}
	}

	onSelect($event) {
		this.router.navigate(['/portfolio', $event.portfolio.id, 'startup', $event.id]);
	}

	onSelectAccounting($event) {
		this.router.navigate(['/portfolio', $event.portfolio.id, 'startup', $event.id, 'accounting']);
	}

	async createProduct() {
		const product = await this.productService.createProduct(this.portfolio);

		this.router.navigate(['/portfolio', this.portfolio.id, 'startup', product.id]);
	}
}
