import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class NextTranslateService {
	getPreferredLanguage(translate) {
		let useLang = 'en';
		let storedLanguage = localStorage.getItem('language');

		if (storedLanguage) {
			useLang = storedLanguage;
		} else {
			const browserLang = translate.getBrowserLang();
			useLang = browserLang.match(/en|nl/) ? browserLang : 'en';
		}

		return useLang;
	}
}
