import { Component, Input } from '@angular/core';
import { Canvas } from '../../../../shared/models/canvas';
import { Sticky } from '../../../../shared/models/sticky';

@Component({
	selector: 'app-pre-validation-canvas',
	templateUrl: './pre-validation.component.html',
	styleUrls: ['./pre-validation.component.scss'],
})
export class PreValidationComponent {
	@Input() canvas: Canvas;
	@Input() allStickies: Sticky[];
	@Input() canvasVersion: Date;

	public allIds = [
		'customersegment',
		'valueproposition',
		'metrics',
		'channels',
		'problem',
		'solution',
		'revenuemodel',
		'growthengine',
		'marketsize',
		'determination',
	];

	constructor() {}
}
