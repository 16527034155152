import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Product } from './product';
import { Minute } from './minute';
import { User } from './user';
import { FileMeta } from './file-meta';

@ParseSubClass('Decision')
export class ProductDecision extends Parse.Object {
	@ParseProperty() public product: Product;
	@ParseProperty() public createdBy: User;

	@ParseProperty() public fromStage: string;
	@ParseProperty() public toStage: string;

	@ParseProperty() public decisionDate: Date;
	@ParseProperty() public decisionMade: string;

	@ParseProperty() public moneyRequested: number;
	@ParseProperty() public moneyGranted: number;

	@ParseProperty() public nextMeetingDate: Date;

	@ParseProperty() public goal: string;
	// @ParseProperty() public minutes: Minute[];
	@ParseProperty() public archived: boolean;
	@ParseProperty() public status: string;
	@ParseProperty() public attachments: FileMeta[];
	@ParseProperty() public description: string;

	constructor() {
		super('Decision');
	}
}

export namespace ProductDecision {
	export enum DecisionType {
		MOVEFWD = 'moveFwd',
		MOVEBACK = 'moveBack',
		REMAIN = 'remain',
		START = 'start',
		STOP = 'stop',
	}

	export enum Status {
		PREPARED = 'prepared',
		DONE = 'done',
	}
}
