<div class="portfolio-detail" *ngIf="!trialExpired">
	<router-outlet></router-outlet>
</div>

<div *ngIf="trialExpired" class="container mt-5">
	<div class="row justify-content-center align-items-center no-sprint-container">
		<div class="col-12 col-md-6 text-center">
			<img src="/assets/img/sprint/no-sprint.svg" class="img-fluid mb-3" />
			<h4>{{ 'upgrade.trialExpired.title' | translate }}</h4>
			<p class="mb-3">{{ 'upgrade.trialExpired.explanation' | translate }}</p>
			<a class="btn btn-primary" [routerLink]="['/upgrade']">{{ 'upgrade.bar.button' | translate }}</a>
		</div>
	</div>
</div>

<app-trial-bar *ngIf="!!portfolio && !trialExpired" [portfolio]="portfolio"></app-trial-bar>
