import * as Parse from 'parse';
import { md5 } from '../md5';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Invite } from './invite';

Parse.User.allowCustomUserClass(true);

@ParseSubClass('_User')
export class User extends Parse.User {
	@ParseProperty() public email: string;
	@ParseProperty() public username: string;
	@ParseProperty() public password: string;
	@ParseProperty() public firstName: string;
	@ParseProperty() public lastName: string;
	@ParseProperty() public company: string;
	@ParseProperty() public avatar: Parse.File;
	@ParseProperty() public avatar_original: Parse.File;
	@ParseProperty() public invite: Invite;
	@ParseProperty() public emailVerified: boolean;
	@ParseProperty() public acceptedTerms: boolean;

	getAvatar() {
		return this.avatar
			? this.avatar.url()
			: 'https://www.gravatar.com/avatar/' + md5(this.username || '') + '?s=200&default=mp';
	}

	getPreviewAvatar() {
		return this.avatar_original?.url() 
	}

	getName() {
		let name = [];

		if (this.firstName) {
			name.push(this.firstName);
		}

		if (this.lastName) {
			name.push(this.lastName);
		}

		return name.join(' ');
	}
}
