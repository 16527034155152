import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DecisionService } from 'app/decision/decision.service';
import { ProductService } from 'app/product/product.service';
import { Cloud } from 'parse';
import { Portfolio } from '../../../../shared/models/portfolio';
import { PortfolioService } from '../portfolio.service';
import { Product } from '../../../../shared/models/product';
import { ProductDecision } from '../../../../shared/models/product-decision';
import { TranslateService } from '@ngx-translate/core';
import { PortfolioChartHappinessModalComponent } from 'app/accounting/portfolio-chart-happiness-modal/portfolio-chart-happiness-modal.component';
import { PortfolioChartKnowledgeModalComponent } from 'app/accounting/portfolio-chart-knowledge-modal/portfolio-chart-knowledge-modal.component';
import { PortfolioChartVelocityModalComponent } from 'app/accounting/portfolio-chart-velocity-modal/portfolio-chart-velocity-modal.component';
import { AnalyticsService } from 'app/shared/analytics.service';
import { NextTitleService } from 'app/next-title.service';

@Component({
	selector: 'app-portfolio-accounting',
	templateUrl: './portfolio-accounting.component.html',
	styleUrls: ['./portfolio-accounting.component.scss'],
})
export class PortfolioAccountingComponent implements OnInit {
	public products: Array<Product>;
	public decisions: Array<ProductDecision>;
	public portfolio: Portfolio;
	public quarters: Array<any> = [];
	public iA = {};
	public currentQuarter;
	public experimentVelocityData: Object[];
	public learningVelocityData: Object[];
	public labels: string[];
	public costData: {};

	constructor(
		private productService: ProductService,
		private portfolioService: PortfolioService,
		private decisionService: DecisionService,
		private modalService: NgbModal,
		private translate: TranslateService,
		private analytics: AnalyticsService,
		private titleService: NextTitleService
	) {}

	async ngOnInit() {
		// this.analytics.track('Opened portfolio accounting');

		this.portfolio = await this.portfolioService.getActivePortfolio();
		this.iA = await Cloud.run('getInnovationAccountingPortfolio', {
			portfolioId: this.portfolio.id,
		});
		this.quarters = Object.keys(this.iA).sort().reverse();

		this.products = await this.productService.getProducts();
		this.decisions = await this.decisionService.getDecisions();
		this.getVelocityGraphData();

		this.costData = await Cloud.run('getCostsPerStagesPortfolio', {
			portfolioId: this.portfolio.id,
		});

		this.titleService.setTitle('Innovation Accounting - ' + this.portfolio.name);
	}

	getVelocityGraphData() {
		const keys = Object.keys(this.iA);
		this.currentQuarter = this.iA[keys[keys.length - 1]];

		let experimentData = [];
		let learningData = [];
		this.labels = [];
		keys.forEach((key) => {
			const quarter = this.iA[key];
			let averageExperimentVelocity = Math.round((quarter.totalExperimentVelocity / quarter.totalWeeks) * 100);
			experimentData.push(averageExperimentVelocity);
			let averageLearningVelocity = Math.round((quarter.totalLearningVelocity / quarter.totalExperimentVelocity) * 100) || 0;
			learningData.push(averageLearningVelocity);

			const quarterLabel = quarter.quarter + 1;
			const label = 'Q' + quarterLabel + ' ' + quarter.year;
			this.labels.push(label);
		});

		this.experimentVelocityData = [
			{
				data: experimentData,
				label: this.translate.instant('innovationAccounting.experimentVelocity'),
			},
		];

		this.learningVelocityData = [
			{
				data: learningData,
				label: this.translate.instant('innovationAccounting.learningRatio'),
			},
		];
	}

	openHappinessModal() {
		const modalRef = this.modalService.open(PortfolioChartHappinessModalComponent, {
			size: 'md',
		});
		modalRef.componentInstance.accounting = this.iA;
		modalRef.componentInstance.portfolio = this.portfolio;
	}

	openKnowledgeModal() {
		const modalRef = this.modalService.open(PortfolioChartKnowledgeModalComponent, {
			size: 'md',
		});
		modalRef.componentInstance.accounting = this.iA;
		modalRef.componentInstance.portfolio = this.portfolio;
	}

	openExperimentVelocityModal() {
		const modalRef = this.modalService.open(PortfolioChartVelocityModalComponent, {
			size: 'md',
		});
		modalRef.componentInstance.datasets = this.experimentVelocityData;
		modalRef.componentInstance.labels = this.labels;
		modalRef.componentInstance.portfolio = this.portfolio;
		modalRef.componentInstance.title = this.translate.instant('innovationAccounting.experimentVelocity');
		modalRef.componentInstance.isExperimentVelocity = true;
	}

	openLearningVelocityModal() {
		const modalRef = this.modalService.open(PortfolioChartVelocityModalComponent, {
			size: 'md',
		});
		modalRef.componentInstance.datasets = this.learningVelocityData;
		modalRef.componentInstance.labels = this.labels;
		modalRef.componentInstance.portfolio = this.portfolio;
		modalRef.componentInstance.title = this.translate.instant('innovationAccounting.learningRatio');
	}
}
