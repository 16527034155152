import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { Portfolio } from '@shared/models/portfolio';
import { NextConfigService } from 'app/next-config.service';
import { PortfolioService } from 'app/portfolio/portfolio.service';

@Component({
	selector: 'app-stage-average-cost',
	templateUrl: './stage-average-cost.component.html',
})
export class StageAverageCostComponent implements OnChanges {
	@Input() costData: {};
	@Input() portfolio: Portfolio;

	/* Shows the average cost per stage, computed from experiments cost */

	public stages = {
		datasets: [],
		labels: [],
	};
	public datasets = [];
	public chartColors = [{ backgroundColor: '' }];
	public chartOptions = {
		responsive: true,
		legend: false,
		scales: {
			xAxes: [
				{
					ticks: {
						callback: function (value, index, values) {
							return value; // Questions.labels[value];
						},
					},
				},
			],
			yAxes: [
				{
					scaleLabel: {
						display: false,
						labelString: '',
					},
					ticks: {
						// precision: 1000,
						callback: (value, index, values) => {
							return this.getLabel(value);
						},
						maxTicksLimit: 5,
						min: 0,
					},
				},
			],
		},
		tooltips: {
			callbacks: {
				label: (tooltipItem, data) => {
					return this.currencyPipe.transform(Math.round(tooltipItem.yLabel), this.portfolio.currency, 'symbol', '1.0-0');
				},
			},
		},
	};
	public isReady = false;

	constructor(private configService: NextConfigService, private currencyPipe: CurrencyPipe, private portfolioService: PortfolioService) {
		this.chartColors[0].backgroundColor = this.configService.colorPrimary[0];
	}

	ngOnChanges(changes: any) {
		const portfolioStages = this.portfolioService.getMainStages(this.portfolio);
		const labels = this.portfolioService.getStageNames(this.portfolio, portfolioStages);
		const chartData = [];

		portfolioStages.forEach((stage) => {
			if (!!this.costData && !!this.costData[stage] && this.costData[stage].products?.length > 0) {
				const averageCost = this.costData[stage].cost / this.costData[stage].products.length;
				chartData.push(averageCost);
			}

			chartData.push[0];
		});

		this.stages.labels = labels;
		this.datasets = [
			{
				label: 'Average cost per stage',
				data: chartData,
			},
		];

		// Now we can show the graph, doesn't work otherwise
		this.isReady = true;
	}

	getLabel(value: number) {
		return this.currencyPipe.transform(value, this.portfolio.currency, 'symbol', '1.0-0');
	}
}
