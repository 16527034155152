<div class="modal-content">
	<div class="modal-header">
		<p class="modal-title">{{ 'sprint.complete' | translate }} {{ sprint.number }}</p>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="container-fluid">
			<div class="row">
				<div class="col">
					<p>{{ 'sprint.completeModal.overview' | translate }}</p>
					<p [innerHtml]="'sprint.completeModal.tasksDone' | translate: { amount: tasksDone.length }"></p>
					<p [innerHtml]="'sprint.completeModal.experimentsDone' | translate: { amount: experimentsDone.length }"></p>
				</div>
			</div>
			<div class="row" *ngIf="tasksOpen.length || experimentsOpen.length">
				<div class="col">
					<p>{{ 'sprint.completeModal.still' | translate }}</p>
					<p [innerHtml]="'sprint.completeModal.tasksOpen' | translate: { amount: tasksOpen.length }"></p>
					<p [innerHtml]="'sprint.completeModal.experimentsOpen' | translate: { amount: experimentsOpen.length }"></p>
				</div>
			</div>
			<!-- <div class="row" *ngIf="tasksOpen.length || (experimentsOpen.length && !!sprints)">
				<div class="col">
					<label
						>{{ 'sprint.completeModal.selectMove' | translate }}
						<select class="custom-select" name="destination" [(ngModel)]="destinationSprint">
							<option [ngValue]="backlog">{{ 'backlog.singular' | translate }}</option>
							<option [ngValue]="newSprint">{{ 'sprint.new' | translate }}</option>
						</select>
					</label>
				</div>
			</div> -->
		</div>
	</div>
	<div class="modal-footer">
		<button class="btn btn-primary ml-auto" (click)="completeSprint()">{{ 'sprint.complete' | translate }}</button>
	</div>
</div>
