import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Canvas } from '../../../../shared/models/canvas';
import { Sticky } from '../../../../shared/models/sticky';

@Component({
	selector: 'app-mini-pre-validation',
	templateUrl: './mini-pre-validation.component.html',
	styleUrls: ['./mini-pre-validation.component.scss'],
})
export class MiniPreValidationComponent {
	@Input() stage: string;
	@Input() canvas: Canvas;
	@Input() allStickies: Sticky[];
	@Input() canSelectSegment: boolean;
	@Input() selectedSegment: string;
	@Input() showSegmentNames: boolean;
	@Output() selectCanvas: EventEmitter<any> = new EventEmitter();

	public busy = false;

	constructor(public portfolioService: PortfolioService) {}

	onClick(segment: string) {
		this.selectCanvas.emit({ segment: segment });
	}
}
