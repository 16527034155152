<div class="settings-content">
	<h5>{{ 'portfolioModalSettings.users.title' | translate }}</h5>
	<hr />
	<div class="row" *ngIf="errorMessage">
		<div class="col-12">
			<ngb-alert type="danger" [dismissible]="false"> {{ errorMessage }} </ngb-alert>
		</div>
	</div>
	<div class="row" *ngIf="resultMessage">
		<div class="col-12">
			<ngb-alert type="success" [dismissible]="false"> {{ resultMessage }} </ngb-alert>
		</div>
	</div>
	<p class="mb-2 header">{{ 'portfolioModalSettings.users.completePortfolioAccess' | translate }}:</p>
	<p *ngIf="users && !users.length">-</p>

			<table class="table table-hover">
				<tbody>
					<tr *ngFor="let user of users">
						<th scope="row" style="width: 75%">{{ user.getName() }}</th>
						<td class="col-auto text-center">
							<i class="bi bi-x remove" [title]="'portfolio.removeUser' | translate: { userName: user.firstName, portfolioName: portfolio.name }" (click)="removeAccess(user)"></i>
						</td>
					</tr>
				</tbody>
			</table>
			<!-- <app-user-card [user]="user" (remove)="removeAccess($event)" [removeMessage]=""></app-user-card> -->
		
	<div class="form-group">
		<h5 class="mt-5">{{ 'invite.user' | translate }}</h5>
		<hr />
		<div class="input-group form-group">
			<input type="email" class="form-control" [placeholder]="'user.email' | translate" name="email" [(ngModel)]="email" />
			<div class="input-group-addon">
				<input type="button" class="btn btn-primary" [value]="'invite.verb' | translate" (click)="inviteUser()" />
			</div>
		</div>
		<ngb-alert *ngIf="inviteResultMessage" type="success" [innerHtml]="inviteResultMessage"></ngb-alert>
		<div *ngIf="pendingInvites" class="row">
			<div class="col-12">
				<p class="mb-2 header">{{ 'invite.pending' | translate }}</p>

				<table class="table table-hover">
					<tbody>
						<tr *ngFor="let invite of pendingInvites">
							<th scope="row" style="width: 75%">{{ invite.email }}</th>
							<td class="col-auto text-center">
								<button *ngIf="!invite.copied" class="btn btn-primary" [cdkCopyToClipboard]="signupLink + invite.id" (click)="invite.copied = true">
									{{ 'copy.invite' | translate }}
								</button>
								<button *ngIf="invite.copied" class="btn btn-primary" disabled>
									<i class="bi bi-check"></i>
									{{ 'copy.success' | translate }}
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
