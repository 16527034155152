import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as mixpanel from 'mixpanel-browser';
import * as Parse from 'parse';

declare let gtag: Function;

@Injectable()
export class AnalyticsService {
	constructor() {
		if(!environment.mixpanelToken) {
			return;
		}

		mixpanel.init(environment.mixpanelToken);

		gtag('config', environment.gaToken, {
			'send_page_view': false
		});
	}

	identify() {
		if (Parse.User.current()) {
			mixpanel.identify(Parse.User.current().id);
			mixpanel.people.set({
				$last_seen: new Date(),
			});
		}
	}

	track(event: string, attrs?: any) {
		mixpanel.track(event, attrs);

		// push GTM data layer with a custom event

		gtag('event', event, {
			event_category: 'Platform'
		});
	}

	trackPageView(url) {
		gtag('event', 'page_view', {
			page_location: location.hostname,
			page_path: '/platform' + url
		});
	}

	registerSuperProperties(attrs: any) {
		mixpanel.register(attrs);

		gtag('set', attrs);
	}

	signup(user: Parse.User) {
		// use identify because of the new Mixpanel ID merge
		mixpanel.identify(user.id);

		gtag('event', 'Signup', {
			event_category: 'Platform'
		});

		// this.track('Signup', {
		// 	invite: !!user.get('invite')
		// });
		// this.setBasicPeopleAttr(user);
	}

	login(user: Parse.User) {
		mixpanel.identify(user.id);

		gtag('event', 'Login', {
			event_category: 'Platform'
		});

		// this.track('Login');
		// this.setBasicPeopleAttr(user);
	}

	logout() {
		// this.track('Logout');
		mixpanel.reset();
	}

	private setBasicPeopleAttr(user: Parse.User) {
		mixpanel.people.set({
			$email: user.getEmail(),
			$created: user.createdAt,
			$first_name: user.get('firstName'),
			$last_name: user.get('lastName'),
			$last_login: new Date(),

			Company: user.get('company'),
			invite: !!user.get('invite')
		});

		this.registerSuperProperties({
			Company: user.get('company'),
		});
	}

	peopleSet(attrs: any) {
		mixpanel.people.set(attrs);
	}

	peopleIncrement(property: string) {
		mixpanel.people.increment(property);
	}
}
