import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { LiveQuerySubscription, Query } from 'parse';
import { Product } from '../../../../shared/models/product';
import { ProductService } from '../product.service';
import { Log } from '../../../../shared/models/log';
import { AnalyticsService } from 'app/shared/analytics.service';
import { NextTitleService } from 'app/next-title.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CockpitModalComponent } from 'app/cockpit/cockpit-modal/cockpit-modal.component';

@Component({
	selector: 'app-product-cockpit',
	templateUrl: './product-cockpit.component.html',
	styleUrls: ['./product-cockpit.component.scss'],
})
export class ProductCockpitComponent implements OnInit, OnDestroy {
	public product: Product;
	public activityLog: any[];
	private logSubscription: LiveQuerySubscription;
	private routerSubscription;

	constructor(public route: ActivatedRoute, public productService: ProductService, private analytics: AnalyticsService, private titleService: NextTitleService, private modalService: NgbModal) {}

	async ngOnInit() {
		dayjs.extend(relativeTime);

		window.scroll(0, 0);
		// this.analytics.track('Opened cockpit');

		this.routerSubscription = this.route.url.subscribe(async (url) => {
			this.product = await this.productService.getActiveProduct();

			this.titleService.setTitle('Cockpit - ' + this.product.name);

			const query = new Query(Log);
			query.equalTo('product', this.product);
			query.containedIn('activity', ['added experiment', 'analyzed experiment', 'validated sticky', 'invalidated sticky', 'started sprint', 'completed sprint', 'completed task']);
			query.include(['createdBy', 'experiment', 'sprint', 'sticky', 'task']);
			query.descending('createdAt');
			query.limit(30);
			const logs = await query.find();

			this.logSubscription = await query.subscribe();
			this.logSubscription.on('enter', (log: Log) => {
				logs.unshift(log);

				this.buildActivityLog(logs);
			});

			this.buildActivityLog(logs);
		});
	}

	buildActivityLog(logs) {
		let previousDate = null;
		this.activityLog = [];

		for (let log of logs) {
			const logDate = dayjs(log.createdAt).fromNow();

			if (!previousDate || previousDate !== logDate) {
				this.activityLog.push({
					date: logDate,
					logs: [],
				});
			}

			this.activityLog[this.activityLog.length - 1]['logs'].push(log);

			previousDate = logDate;
		}
	}

	ngOnDestroy() {
		this.logSubscription?.unsubscribe();
		this.routerSubscription?.unsubscribe();
	}

	editCockpit() {
		const modalRef = this.modalService.open(CockpitModalComponent, {
			size: 'md',
		});
		modalRef.componentInstance.product = this.product;
	}
}
