import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NextConfigService } from 'app/next-config.service';
import { Product } from '../../../../shared/models/product';
import { Portfolio } from '../../../../shared/models/portfolio';
import { TranslateService } from '@ngx-translate/core';
import { PortfolioService } from 'app/portfolio/portfolio.service';

@Component({
	selector: 'app-chart-stage-product',
	templateUrl: './stage-product.component.html',
})
export class StageProductComponent implements OnInit, OnChanges {
	@Input() products: Array<Product>;
	@Input() portfolio: Portfolio;
	private portfolioStages: string[];

	public stages = {
		data: [],
		labels: [],
	};
	public chartColors: Array<any> = [{ backgroundColor: '' }];
	public chartOptions = {
		responsive: true,
		legend: false,
		scales: {
			xAxes: [
				{
					ticks: {
						callback: function (value, index, values) {
							return value; // Questions.labels[value];
						},
					},
				},
			],
			yAxes: [
				{
					scaleLabel: {
						display: false,
						labelString: 'Euro',
					},
					ticks: {
						precision: 1,
						maxTicksLimit: 5,
						min: 0,
					},
				},
			],
		},
		tooltips: {
			callbacks: {
				label: (tooltipItem, data) => {
					if (tooltipItem.yLabel === 1) {
						return tooltipItem.yLabel + ' ' + this.translate.instant('product.singular');
					}

					return tooltipItem.yLabel + ' ' + this.translate.instant('product.plural');
				},
			},
		},
	};
	public isReady = false;

	constructor(private configService: NextConfigService, private translate: TranslateService, private portfolioService: PortfolioService) {}

	ngOnInit() {
		this.chartColors[0].backgroundColor = this.configService.colorPrimary[0];
		this.portfolioStages = this.portfolioService.getMainStages(this.portfolio);
	}

	ngOnChanges(changes: any) {
		let charData = [];

		if (changes.portfolio) {
			this.portfolio = changes.portfolio.currentValue;

			this.portfolioStages = this.portfolioService.getMainStages(this.portfolio);
			this.stages.labels = this.portfolioService.getStageNames(this.portfolio, this.portfolioStages);
		}

		if (changes.products) {
			this.products = changes.products.currentValue;

			this.portfolioStages.forEach((stage) => {
				charData.push(0);
			});

			this.products.forEach((product) => {
				if (product.archived || product.stopped) {
					return;
				}

				const index = this.portfolioStages.indexOf(product.stage);
				charData[index]++;
			});
		}

		this.stages.data = [
			{
				label: this.translate.instant('charts.stageProduct.info'),
				data: charData,
			},
		];

		this.isReady = true;
	}
}
