import { Component, Input } from '@angular/core';
import { Product } from '../../../../shared/models/product';
import { ProductDecision } from '../../../../shared/models/product-decision';
import { DecisionService } from 'app/decision/decision.service';
import { Router } from '@angular/router';
import { PortfolioService } from '../portfolio.service';

@Component({
	selector: 'app-portfolio-board-row',
	templateUrl: './portfolio-board-row.component.html',
	styleUrls: ['./portfolio-board-row.component.scss'],
})
export class PortfolioBoardRowComponent {
	@Input() product: Product;
	@Input() decision: ProductDecision;

	constructor(public portfolioService: PortfolioService, public decisionService: DecisionService, private router: Router) {}

	openDecision() {
		this.router.navigate(['portfolio', this.product.portfolio.id, 'startup', this.product.id, 'decision', this.decision.id]);
	}
}
