<div class="col-12 form-group element-group settings-content" *ngIf="product">
	<h5>{{ 'productModalSettings.general.portfolio' | translate }}</h5>
	<hr />
	<p *ngIf="product.portfolio">{{ 'productModalSettings.general.partPortfolio' | translate }} {{ product.portfolio.name }}</p>
	<p *ngIf="!product.portfolio">{{ 'productModalSettings.general.noPortfolio' | translate }}</p>
	<div class="row" *ngIf="portfoliosReady && !!portfolios">
		<div class="col-md-6">
			<label *ngIf="!product.portfolio">{{ 'productModalSettings.general.addPortfolio' | translate }}</label>
			<label *ngIf="product.portfolio">{{ 'productModalSettings.general.changePortfolio' | translate }}</label>
			<ng-select name="portfolio" [(ngModel)]="product.portfolio" [items]="portfolios">
				<ng-template ng-label-tmp let-item="item">
					{{ item.name }}
				</ng-template>
				<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
					{{ item.name }}
				</ng-template>
			</ng-select>
		</div>
	</div>
	<div class="row">
		<div class="col-md-6">
			<label class="mt-3">{{ 'productModalSettings.general.changeThesis' | translate }}</label>
			<ng-select
				name="thesis"
				*ngIf="!!product && product.portfolio.theses.length !== 0"
				[items]="product.portfolio.theses"
				[(ngModel)]="product.thesis"
			>
				<ng-template ng-label-tmp let-item="item">
					{{ item.title }}
				</ng-template>
				<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
					{{ item.title }}
				</ng-template>
			</ng-select>
			<p *ngIf="!!product && product.portfolio.theses.length === 0">{{ 'productModalSettings.general.hintThesis' | translate }}</p>
		</div>
	</div>
	<div class="row mt-5">
		<div class="col-12">
			<ngb-alert type="warning" [dismissible]="false" *ngIf="errorMessage">
				{{ errorMessage }}
			</ngb-alert>
			<ngb-alert type="success" [dismissible]="false" *ngIf="resultMessage">
				{{ resultMessage }}
			</ngb-alert>
		</div>

		<div class="col-12 col-md-6">
			<button type="button" class="btn btn-primary" (click)="onSave()">{{ 'general.save' | translate }}</button>
		</div>
	</div>
</div>
