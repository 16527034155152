import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Cloud } from 'parse';
import { environment } from '../../../environments/environment';
import { Portfolio } from '../../../../shared/models/portfolio';
import { Account } from '../../../../shared/models/account';
import { User } from '../../../../shared/models/user';
import { StripeService } from '../stripe.service';
import { NextConfigService } from 'app/next-config.service';

@Component({
	selector: 'app-create-subscription',
	templateUrl: './create-subscription.component.html',
	styleUrls: ['./create-subscription.component.scss'],
})
export class CreateSubscriptionComponent implements OnInit, OnDestroy, AfterViewInit {
	public portfolio: Portfolio;
	public user: User;
	public account: Account;
	public errorMessage: string;
	public upgrade = {
		teams: 0,
		cardName: '',
	};

	@ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;

	private stripe;
	public loading = false;

	card: any;
	cardHandler = this.onChange.bind(this);
	error: string;

	public euCountries: string[];
	public euVAT = false;
	public euReverse = false;
	public vatRate = 0;
	public totalPrice;
	public startupPackages = 0;

	constructor(
		private portfolioService: PortfolioService,
		private stripeService: StripeService,
		private router: Router,
		public configService: NextConfigService
	) {}

	async ngOnInit() {
		this.euCountries = this.configService.euCountries;

		this.user = User.current();

		// const query = new Query(Account);
		// this.account = await query.first();

		if (!this.account) {
			this.account = new Account();
		}

		this.upgrade.cardName = this.user.getName();

		this.portfolio = await this.portfolioService.getActivePortfolio();
		this.upgrade.teams = await Cloud.run('billableProductsCount', {
			portfolioId: this.portfolio.id,
		});

		this.totalPrice = await Cloud.run('previewSubscription', {
			portfolioId: this.portfolio.id,
		});
	}

	async ngAfterViewInit() {
		this.stripe = await this.stripeService.setPublishableKey(environment.stripe.publishableKey);

		// Create Stripe elements integration
		const elements = this.stripe.elements();
		const style = {
			base: {
				color: '#202020',
				fontFamily: "'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif",
				fontSmoothing: 'antialiased',
				fontSize: '1rem',
				'::placeholder': {
					color: '#8e8e93',
				},
			},
			invalid: {
				color: '#da4d2b',
				iconColor: '#da4d2b',
			},
		};

		this.card = elements.create('card', {
			style: style,
			hidePostalCode: true,
		});
		this.card.mount(this.cardInfo.nativeElement);
		this.card.addEventListener('change', this.cardHandler);
	}

	ngOnDestroy() {
		this.card.removeEventListener('change', this.cardHandler);
		this.card.destroy();
	}

	onChange({ error }) {
		if (error) {
			this.error = error.message;
		} else {
			this.error = null;
		}
	}

	checkVAT() {
		if (this.account.billingCountry === 'NL') {
			this.euVAT = true;
			this.euReverse = false;
		} else if (this.euCountries.indexOf(this.account.billingCountry) > -1) {
			if (this.account.billingTaxId) {
				this.euVAT = false;
				this.euReverse = true;
			} else {
				this.euVAT = true;
				this.euReverse = false;
			}
		} else {
			this.euVAT = false;
			this.euReverse = false;
		}

		if (this.euVAT) {
			this.vatRate = this.configService.vat[this.account.billingCountry].rate;
		}
	}

	async onSubmit(form: NgForm) {
		this.loading = true;
		this.error = null;

		try {
			const paymentMethodResult = await this.stripe.createPaymentMethod({
				type: 'card',
				card: this.card,

				billing_details: {
					address: {
						city: this.account.billingCity,
						country: this.account.billingCountry,
						line1: this.account.billingAddress1,
						line2: this.account.billingAddress2,
						postal_code: this.account.billingPostalCode,
						state: null,
					},
					email: this.user.email,
					name: this.upgrade.cardName,
					phone: null,
				},
			});

			if (paymentMethodResult.error) {
				throw paymentMethodResult.error;
			}

			const subscriptionResult = await Cloud.run('createSubscription', {
				stripeCustomerPaymentMethodId: paymentMethodResult.paymentMethod.id,
				quantity: this.upgrade.teams,
				plan: 'lab',
				country: this.account.billingCountry,
				taxId: this.account.billingTaxId,
				address: {
					city: this.account.billingCity,
					country: this.account.billingCountry,
					line1: this.account.billingAddress1,
					line2: this.account.billingAddress2,
					postal_code: this.account.billingPostalCode,
					state: null,
				},
				companyName: this.user.company,
				cardName: this.upgrade.cardName,
				billingEmail: this.user.email,

				portfolioId: this.portfolio.id,
			});

			if (subscriptionResult.error) {
				throw paymentMethodResult.error;
			}

			// If customers needs to authenticate card payment
			if (subscriptionResult.status === 'requires_action') {
				const confirmResult = await this.stripe.confirmCardPayment(subscriptionResult.client_secret, {
					payment_method: paymentMethodResult.paymentMethod.id,
				});

				if (confirmResult.error) {
					throw confirmResult.error;
				}

				// What to do now? :) Navigate and handle result in backend?
			}

			this.loading = false;

			this.router.navigate(['/billing']);
		} catch (err) {
			this.loading = false;

			console.error(err);

			this.error = err.message;
		}
	}
}
