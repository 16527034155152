import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Minute } from '../../../../shared/models/minute';
import { MinuteModalComponent } from '../minute-modal/minute-modal.component';

@Component({
	selector: 'app-minute',
	templateUrl: './minute.component.html',
	styleUrls: ['./minute.component.scss'],
})
export class MinuteComponent {
	@Input() minute: Minute;
	@Output() save: EventEmitter<any> = new EventEmitter();

	constructor(private modalService: NgbModal) {}

	openMinute() {
		const modalRef = this.modalService.open(MinuteModalComponent, {
			size: 'lg',
		});

		modalRef.componentInstance.minute = this.minute;
		const subscription = modalRef.componentInstance.save.subscribe(($e) => {
			this.save.emit({ minute: this.minute });

			subscription.unsubscribe();
		});
	}
}
