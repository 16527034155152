import { Component, OnDestroy, OnInit } from '@angular/core';
import { Query } from 'parse';
import { ContentExperiment } from '../../../../shared/models/content-experiment';
import { NextConfigService } from 'app/next-config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentExperimentModalComponent } from 'app/content-experiment/content-experiment-modal/content-experiment-modal.component';
import { UnsavedModalComponent } from 'app/validating-modals/unsaved-modal/unsaved-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { NextTitleService } from 'app/next-title.service';

@Component({
	selector: 'app-admin-content-experiment-overview',
	templateUrl: './admin-content-experiment-overview.component.html',
	styleUrls: ['./admin-content-experiment-overview.component.scss'],
})
export class AdminContentExperimentOverviewComponent implements OnInit, OnDestroy {
	public contentExperiments: ContentExperiment[] = [];
	private contentExperimentSubscription;

	constructor(public configService: NextConfigService, private modalService: NgbModal, private titleService: NextTitleService, public translate: TranslateService) {}

	async ngOnInit() {
		this.titleService.setTitle('Admin - Content Experiments');

		let contentExperimentQuery = new Query(ContentExperiment);
		contentExperimentQuery.equalTo('archived', false);
		contentExperimentQuery.equalTo('default', true);
		contentExperimentQuery.ascending('title');
		this.contentExperiments = await contentExperimentQuery.find();

		this.contentExperimentSubscription = await contentExperimentQuery.subscribe();
		this.contentExperimentSubscription.on('create', (contentExperiment: ContentExperiment) => {
			this.contentExperiments.push(contentExperiment);
		});
		this.contentExperimentSubscription.on('update', (contentExperiment: ContentExperiment) => {
			const index = this.contentExperiments.findIndex((experiment) => experiment.id === contentExperiment.id);
			this.contentExperiments[index] = contentExperiment;
		});
		this.contentExperimentSubscription.on('leave', (contentExperiment: ContentExperiment) => {
			const index = this.contentExperiments.findIndex((experiment) => experiment.id === contentExperiment.id);
			this.contentExperiments.splice(index, 1);
		});
	}

	ngOnDestroy() {
		if (this.contentExperimentSubscription) {
			this.contentExperimentSubscription.unsubscribe();
		}
	}

	createContentExperiment() {
		const contentExperiment = new ContentExperiment();
		contentExperiment.language = this.translate.currentLang;
		contentExperiment.default = true;
		const modalRef = this.modalService.open(ContentExperimentModalComponent, {
			size: 'md',
			beforeDismiss: async () => {
				let langs = Object.keys(modalRef.componentInstance.contentExperiments);
				let result;

				for (let lang of langs) {
					if (modalRef.componentInstance.contentExperiments[lang].dirty()) {
						const modal = this.modalService.open(UnsavedModalComponent, {
							size: 'sm',
							centered: true,
						});
						result = await modal.result;

						if (result) {
							if (modalRef.componentInstance.contentExperiments[lang].isNew()) {
								delete modalRef.componentInstance.contentExperiments[lang];
							} else {
								modalRef.componentInstance.contentExperiments[lang].revert();
							}
						}
					}
				}
				return result;
			},
		});

		let newContentExperiments = {};
		newContentExperiments[contentExperiment.language] = contentExperiment;
		modalRef.componentInstance.contentExperiments = newContentExperiments;
		modalRef.componentInstance.editable = true;
		modalRef.componentInstance.translatable = true;
	}
}
