import { Component, ComponentFactoryResolver, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NextTitleService } from 'app/next-title.service';
import { User } from '../../../../shared/models/user';
import { AnalyticsService } from '../../shared/analytics.service';
import { MicrosoftService } from '../../sso/microsoft.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
	private routeSubscription;
	public user = {
		email: '',
		password: '',
	};
	public errorMessage: string;
	public loginType = '';

	constructor(
		private router: Router,
		private analytics: AnalyticsService,
		private titleService: NextTitleService,
		private route: ActivatedRoute,
		private microsoftService: MicrosoftService
	) { }

	async ngOnInit() {
		if (User.current()) {
			this.router.navigate(['']);
			return;
		}

		this.titleService.setTitle('Login');
		this.routeSubscription = this.route.url.subscribe(async (url) => {
			console.log(url)
			if (url[0].path === 'demo-login') {
				this.user.email = 'demo@togroundcontrol.com';
				this.user.password = 'demo';
			}

			if (url[1] && url[1].path === 'sso') {
				// await this.microsoftService.redirectWithMicrosoft();
				this.loginType = 'sso';
			}
		});
	}

	ngOnDestroy() {
		this.routeSubscription?.unsubscribe();
	}

	onCheckSSO() {
		if (this.user.email == '') {
			return;
		}

		if (this.user.email.match('@aes.com')) {
			this.loginType = 'sso';
		} else {
			this.loginType = 'password';
		}
	}

	async onMicrosoft() {
		try {
			this.microsoftService.loginWithMicrosoft();
		} catch (error) {
			this.errorMessage = error;
		}
		
	}

	onLogin() {
		User.logIn(this.user.email.toLowerCase(), this.user.password).then(
			(user: User) => {
				this.analytics.login(user);

				// Do stuff after successful login.
				this.router.navigate(['/']);
			},
			(error) => {
				if (error.code === 205) {
					this.router.navigate(['/validateEmail']);
					return;
				}

				// The login failed. Check error to see why.
				this.errorMessage = error.message;
			}
		);
	}
}
