import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContentTool } from '../../../../shared/models/content-tool';
import { LiveQuerySubscription, Query } from 'parse';
import { NextConfigService } from 'app/next-config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentToolModalComponent } from 'app/content-tool/content-tool-modal/content-tool-modal.component';
import { UnsavedModalComponent } from 'app/validating-modals/unsaved-modal/unsaved-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { NextTitleService } from 'app/next-title.service';
import { PortfolioService } from 'app/portfolio/portfolio.service';

@Component({
	selector: 'app-admin-content-tool-overview',
	templateUrl: './admin-content-tool-overview.component.html',
	styleUrls: ['./admin-content-tool-overview.component.scss'],
})
export class AdminContentToolOverviewComponent implements OnInit, OnDestroy {
	public contentTools: ContentTool[] = [];
	public stageTools: object;
	public contToolsPerSlug: object;
	public stages: string[];
	private contentToolSubscription: LiveQuerySubscription;

	constructor(
		public configService: NextConfigService,
		private modalService: NgbModal,
		private titleService: NextTitleService,
		private translate: TranslateService,
		private portfolioService: PortfolioService
	) {}

	async ngOnInit() {
		this.titleService.setTitle('Admin - Content Tools');
		this.stages = this.portfolioService.getDefaultStagesWithKickbox();

		let contentToolQuery = new Query(ContentTool);
		contentToolQuery.equalTo('archived', false);
		contentToolQuery.ascending('title');
		this.contentTools = await contentToolQuery.find();

		this.contentToolSubscription = await contentToolQuery.subscribe();
		this.contentToolSubscription.on('create', (contentTool: ContentTool) => {
			this.contentTools.push(contentTool);
			this.populateContentTools();
		});
		this.contentToolSubscription.on('update', (contentTool: ContentTool) => {
			const index = this.contentTools.findIndex((tool) => tool.id === contentTool.id);
			this.contentTools[index] = contentTool;
			this.populateContentTools();
		});
		this.contentToolSubscription.on('leave', (contentTool: ContentTool) => {
			const index = this.contentTools.findIndex((tool) => tool.id === contentTool.id);
			this.contentTools.splice(index, 1);
			this.populateContentTools();
		});

		this.translate.onLangChange.subscribe(async () => {
			this.populateContentTools();
		});
		this.populateContentTools();
	}

	ngOnDestroy() {
		if (this.contentToolSubscription) {
			this.contentToolSubscription.unsubscribe();
		}
	}

	// XToolsPerSlug structure:
	// XToolsPerSlug = {
	// 	slug: {
	// 		lang1: ContentTool,
	// 		lang2: ContentTool,
	// 	},
	// 	slug2: {
	// 		lang2: ContentTool,
	// 		lang1: ContentTool
	// 	},
	// 	slug3: {
	// 		lang1: ContentTool
	// 	}
	// }
	populateContentTools() {
		this.stageTools = {};
		this.contToolsPerSlug = {};
		this.contentTools.forEach((contentTool) => {
			if (!this.contToolsPerSlug[contentTool.slug]) {
				this.contToolsPerSlug[contentTool.slug] = {};
			}
			this.contToolsPerSlug[contentTool.slug][contentTool.language] = contentTool;
		});

		this.stages.forEach((stage) => {
			this.stageTools[stage] = [];
		});

		const slugs = Object.keys(this.contToolsPerSlug);
		for (let slug of slugs) {
			const langs = Object.keys(this.contToolsPerSlug[slug]);
			langs.sort();
			let translation;
			if (langs.includes(this.translate.currentLang)) {
				translation = this.contToolsPerSlug[slug][this.translate.currentLang];
			} else if (langs.includes(this.translate.defaultLang)) {
				translation = this.contToolsPerSlug[slug][this.translate.defaultLang];
			} else if (langs.length >= 0) {
				translation = this.contToolsPerSlug[slug][langs[0]];
			}

			for (let stage of this.stages) {
				if (translation.stages.includes(stage)) {
					this.stageTools[stage].push(translation);
				}
			}
		}
	}

	openContentTool(contentTool) {
		const modalRef = this.modalService.open(ContentToolModalComponent, {
			size: 'md',
			beforeDismiss: async () => {
				let langs = Object.keys(modalRef.componentInstance.contentTools);
				let result;

				for (let lang of langs) {
					if (modalRef.componentInstance.contentTools[lang].dirty()) {
						const modal = this.modalService.open(UnsavedModalComponent, {
							size: 'sm',
							centered: true,
						});
						result = await modal.result;

						if (result) {
							if (modalRef.componentInstance.contentTools[lang].isNew()) {
								delete modalRef.componentInstance.contentTools[lang];
							} else {
								modalRef.componentInstance.contentTools[lang].revert();
							}
						}
					}
				}
				return result;
			},
		});
		modalRef.componentInstance.contentTools = this.contToolsPerSlug[contentTool.slug];
	}

	createContentTool() {
		const contentTool = new ContentTool();
		contentTool.language = this.translate.currentLang;
		const modalRef = this.modalService.open(ContentToolModalComponent, {
			size: 'md',
			beforeDismiss: async () => {
				let langs = Object.keys(modalRef.componentInstance.contentTools);
				let result;

				for (let lang of langs) {
					if (modalRef.componentInstance.contentTools[lang].dirty()) {
						const modal = this.modalService.open(UnsavedModalComponent, {
							size: 'sm',
							centered: true,
						});
						result = await modal.result;

						if (result) {
							if (modalRef.componentInstance.contentTools[lang].isNew()) {
								delete modalRef.componentInstance.contentTools[lang];
							} else {
								modalRef.componentInstance.contentTools[lang].revert();
							}
						}
					}
				}
				return result;
			},
		});

		let newContentTools = {};
		newContentTools[contentTool.language] = contentTool;
		modalRef.componentInstance.contentTools = newContentTools;
		modalRef.componentInstance.editable = true;
	}
}
