import { Component, Input, OnChanges } from '@angular/core';
import { NextConfigService } from 'app/next-config.service';
import { ProductService } from 'app/product/product.service';
import { Product } from '../../../../shared/models/product';
import { Portfolio } from '../../../../shared/models/portfolio';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-chart-thesis-product',
	templateUrl: './thesis-product.component.html',
})
export class ThesisProductComponent implements OnChanges {
	@Input() products: Array<Product>;
	@Input() portfolio: Portfolio;

	public stages: any;
	public chartColors: Array<any> = [
		{
			backgroundColor: [],
		},
	];
	public chartOptions: any = {
		legend: {
			position: 'left',
		},
		responsive: true,
	};
	public isReady = false;

	constructor(public productService: ProductService, private configService: NextConfigService, private translate: TranslateService) {
		this.chartColors[0].backgroundColor.push(configService.colorGrey);
	}

	ngOnChanges(changes: any) {
		if (!this.portfolio.theses) {
			return;
		}

		this.stages = {
			data: [0],
			labels: [this.translate.instant('thesis.noneAdded')],
		};

		for (let index in this.portfolio.theses) {
			this.stages.data.push(0);
			const thesis = this.portfolio.theses[index];
			this.stages.labels.push(thesis.title);
			if (thesis.color) {
				this.chartColors[0].backgroundColor.push(thesis.color);
			} else {
				const color = this.configService.colors[Number(index) % this.configService.colors.length];
				this.chartColors[0].backgroundColor.push(color);
			}
		}

		this.products.forEach((product) => {
			if (product.archived) {
				return;
			}

			const index = this.indexOf(product.thesis);

			this.stages.data[index]++;
		});

		this.isReady = true;
	}

	indexOf(thesis) {
		let index = 0;

		if (!thesis) {
			return index;
		}

		for (let t of this.portfolio.theses) {
			index++;

			if (t.id === thesis.id) {
				return index;
			}
		}
	}
}
