import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Lens } from '@shared/models/lens';
import { Portfolio } from '@shared/models/portfolio';
import { LensModalComponent } from 'app/lens/lens-modal/lens-modal.component';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { Cloud, LiveQuerySubscription, Query } from 'parse';

@Component({
	selector: 'app-portfolio-settings-lenses',
	templateUrl: './portfolio-settings-lenses.component.html',
	styleUrls: ['./portfolio-settings-lenses.component.scss'],
})
export class PortfolioSettingsLensesComponent implements OnInit {
	public lenses: Lens[];
	public portfolio: Portfolio;

	constructor(private portfolioService: PortfolioService, private modalService: NgbModal) {}

	async ngOnInit() {
		this.portfolio = await this.portfolioService.getActivePortfolio();
		const query = new Query(Lens);
		query.equalTo('portfolio', this.portfolio);
		query.equalTo('archived', false);
		query.ascending('orderIndex');
		this.lenses = await query.find();
	}

	async openModal(lens: Lens) {
		const modalRef = this.modalService.open(LensModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.lens = lens;

		const result = await modalRef.result;
		if (result == 'deleted') {
			// remove lens from lenses
			this.lenses.splice(
				this.lenses.findIndex((l) => l.id == lens.id),
				1
			);
		}
	}

	async addLens() {
		let lens = new Lens();
		lens.portfolio = this.portfolio;
		const modalRef = this.modalService.open(LensModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.lens = lens;
		modalRef.componentInstance.editable = true;

		const result = await modalRef.result;
		if (result) {
			this.lenses.push(result);
		}
	}

	dropItem(event: CdkDragDrop<any[]>) {
		let object = event.item.data;
		
		Cloud.run('reOrderLens', {
			objectId: object.id,
			newIndex: event.currentIndex,
		});

		// update visuals
		moveItemInArray(this.lenses, this.lenses.indexOf(object), event.currentIndex);
	}
}
