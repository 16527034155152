import { Component, Input } from '@angular/core';
import { ProductDecision } from '../../../../shared/models/product-decision';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DecisionModalComponent } from '../decision-modal/decision-modal.component';
import { Router } from '@angular/router';
import { PortfolioService } from 'app/portfolio/portfolio.service';

@Component({
	selector: 'app-product-decisions',
	templateUrl: './product-decisions.component.html',
	styleUrls: ['./product-decisions.component.scss'],
})
export class ProductDecisionsComponent {
	@Input() decisions: ProductDecision[];

	get DecisionType() {
		return ProductDecision.DecisionType;
	}

	get DecisionStatus() {
		return ProductDecision.Status;
	}

	constructor(public portfolioService: PortfolioService, private modalService: NgbModal, private router: Router) {}

	openDecision(decision) {
		if (decision.status === ProductDecision.Status.PREPARED) {
			this.router.navigate(['portfolio', decision.product.portfolio.id, 'startup', decision.product.id, 'decision', decision.id]);
		} else {
			const modalRef = this.modalService.open(DecisionModalComponent, {
				size: 'md',
			});
			modalRef.componentInstance.decision = decision;
		}
	}
}
