import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
	providedIn: 'root'
})
export class CustomNgbDateAdapter extends NgbDateAdapter<Date> {

	fromModel(value: Date | null): NgbDateStruct | null {
		if (value) {

			return {
				day: value.getDate(),
				month: value.getMonth() + 1,
				year: value.getFullYear()
			};
		}
		return null;
	}

	toModel(date: NgbDateStruct | null): Date | null {
		return date ? new Date(date.year, date.month - 1, date.day) : null;
	}
}
