import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InsightModalOverviewComponent } from 'app/insight/insight-modal-overview/insight-modal-overview.component';
import { LearningModalOverviewComponent } from 'app/learning/learning-modal-overview/learning-modal-overview.component';
import { NextConfigService } from 'app/next-config.service';
import { ProductService } from 'app/product/product.service';
import { Query, LiveQuerySubscription } from 'parse';
import { Progress } from '../../../../shared/models/progress';
import { Product } from '../../../../shared/models/product';
import { Learning } from '../../../../shared/models/learning';
import { Insight } from '../../../../shared/models/insight';
import { Experiment } from '../../../../shared/models/experiment';
import { AnalyticsService } from 'app/shared/analytics.service';
import { NextTitleService } from 'app/next-title.service';
import { KeyQuestion } from '../../../../shared/models/key-question';
import { ProgressKeyquestionsModalOverviewComponent } from '../progress-keyquestions-modal-overview/progress-keyquestions-modal-overview.component';
import { DecisionModalComponent } from 'app/decision/decision-modal/decision-modal.component';
import { ProductDecision } from '../../../../shared/models/product-decision';
import { Router } from '@angular/router';
import { PortfolioService } from 'app/portfolio/portfolio.service';

@Component({
	selector: 'app-progress-detail',
	templateUrl: './progress-detail.component.html',
	styleUrls: ['./progress-detail.component.scss'],
})
export class ProgressDetailComponent implements OnInit, OnDestroy {
	public product: Product;
	public recentLearnings: Learning[];
	public recentInsights: Insight[];
	public stageGateProgress: any;
	public experiments: Experiment[];
	private learningSubscription: LiveQuerySubscription;
	private insightSubscription: LiveQuerySubscription;
	private experimentSubscription: LiveQuerySubscription;
	public stages: string[];
	public previousDecision: ProductDecision;
	public learningsPerQuestion: any[];
	public progressbarType = 'secondary';

	constructor(
		private analytics: AnalyticsService,
		private modalService: NgbModal,
		public nextConfigService: NextConfigService,
		private productService: ProductService,
		private router: Router,
		private titleService: NextTitleService,
		public portfolioService: PortfolioService
	) {}

	async ngOnInit() {
		// this.analytics.track('Opened progress');

		this.product = await this.productService.getActiveProduct();

		this.titleService.setTitle('Progress - ' + this.product.name);

		// fetch previous decision
		this.previousDecision = await new Query(ProductDecision)
			.equalTo('product', this.product)
			.equalTo('status', ProductDecision.Status.DONE)
			.equalTo('archived', false)
			.descending('decisionDate')
			.first();

		await this.getExperiments();
		this.getRecentLearnings();
		this.getRecentInsights();

		let newProgressNeeded = false;

		if (!this.product.activeProgress || this.product.activeProgress.stage != this.product.stage) {
			newProgressNeeded = true;
		}

		if (newProgressNeeded) {
			const progress = new Progress();
			progress.product = this.product;
			progress.stage = this.product.stage;
			progress.questions = {};

			// set correct default values for Progress to 1
			await progress.save();

			this.product.activeProgress = progress;
			this.product.save();
		}

		if (this.product.activeProgress.getAverageScore() >= 80) {
			this.progressbarType = 'success';
		}
	}

	ngOnDestroy() {
		if (this.learningSubscription) {
			this.learningSubscription.unsubscribe();
		}

		if (this.insightSubscription) {
			this.insightSubscription.unsubscribe();
		}

		if (this.experimentSubscription) {
			this.experimentSubscription.unsubscribe();
		}
	}

	async getExperiments() {
		const experimentQuery = new Query(Experiment);
		experimentQuery.equalTo('product', this.product);
		experimentQuery.equalTo('status', 'analyzed');
		experimentQuery.greaterThan('startAt', this.previousDecision?.createdAt);
		experimentQuery.descending('endAt');
		experimentQuery.equalTo('archived', false);
		this.experiments = await experimentQuery.find();

		this.experimentSubscription = await experimentQuery.subscribe();

		this.experimentSubscription.on('create', (experiment: Experiment) => {
			this.experiments.unshift(experiment);
		});

		this.experimentSubscription.on('update', (experiment: Experiment) => {
			const index = this.experiments.findIndex((element) => element.id === experiment.id);
			if (experiment.archived) {
				this.experiments.splice(index, 1);
			} else {
				this.experiments[index] = experiment;
			}
		});
	}

	async getRecentLearnings() {
		this.learningsPerQuestion = await this.productService.getLearningsPerKeyQuestion(this.product);
	}

	async getRecentInsights() {
		const insightQuery = new Query(Insight);
		insightQuery.include('experiment');
		insightQuery.equalTo('archived', false);
		insightQuery.equalTo('product', this.product);
		insightQuery.limit(3);
		insightQuery.descending('createdAt');
		this.recentInsights = await insightQuery.find();

		// subscribe learnings and insights so progress is updated if learnings & insights are removed/updated/created.
		this.insightSubscription = await insightQuery.subscribe();

		this.insightSubscription.on('create', (insight: Insight) => {
			this.recentInsights.unshift(insight);
			this.recentInsights.pop();
		});

		this.insightSubscription.on('update', (insight: Insight) => {
			const index = this.recentInsights.findIndex((element) => element.id === insight.id);
			this.recentInsights[index] = insight;
		});

		this.insightSubscription.on('leave', async () => {
			this.recentInsights = await insightQuery.find();
		});
	}

	showLearnings() {
		const modalRef = this.modalService.open(LearningModalOverviewComponent, {
			size: 'lg',
		});

		modalRef.componentInstance.product = this.product;
	}

	showInsights() {
		const modalRef = this.modalService.open(InsightModalOverviewComponent, {
			size: 'lg',
		});

		modalRef.componentInstance.product = this.product;
	}

	async openProgress() {
		const modalRef = this.modalService.open(ProgressKeyquestionsModalOverviewComponent, {
			size: 'lg',
		});

		modalRef.componentInstance.product = this.product;
	}

	async generatePitch() {
		let decision = new ProductDecision();
		decision.product = this.product;
		decision.status = ProductDecision.Status.PREPARED;

		const modalRef = this.modalService.open(DecisionModalComponent, {
			size: 'md',
		});
		modalRef.componentInstance.decision = decision;

		const result = await modalRef.result;

		if (result) {
			this.router.navigate(['portfolio', this.product.portfolio.id, 'startup', this.product.id, 'decision', decision.id]);
		}
	}
}
