import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Product } from './product';

@ParseSubClass('Progress')
export class Progress extends Parse.Object {
	@ParseProperty() public product: Product;
	@ParseProperty() public createdBy: Parse.User;
	@ParseProperty() public stage: string;
	@ParseProperty() public questions: Object;

	constructor() {
		super('Progress');
	}

	getAverageScore() {
		if (!this.questions) {
			return 0;
		}

		let total = 0;
		let count = 0;

		for (let key in this.questions) {
			total += this.questions[key];
			count++;
		}

		const avg = total / count;
		const progress = avg / 5;

		return progress * 100;
	}
}
