<div class="row" *ngIf="product">
	<div class="col-12 col-md product-content">
		<div class="d-flex justify-content-between">
			<h4>{{ 'progress.since' | translate }}</h4>
			<div *ngIf="experiments?.length > 0">
				<button class="btn btn-outline-primary mr-3" (click)="openProgress()" [disabled]="!product.isActive()">{{ 'progress.update' | translate }}</button>
				<button class="btn btn-outline-primary" (click)="generatePitch()" [disabled]="!product.isActive()">{{ 'decision.prepare' | translate }}</button>
			</div>
		</div>

		<div class="row" id="key-questions">
			<div class="col-12 col-md-2">
				<p class="header">{{ 'decision.last' | translate }}</p>
			</div>
			<div class="col-12 col-md-10">
				<p *ngIf="previousDecision">{{ previousDecision.decisionDate | date }}</p>
				<p *ngIf="!previousDecision">-</p>
			</div>
		</div>

		<div class="row" id="key-questions">
			<div class="col-12 col-md-2">
				<p class="header">{{ 'decision.deadline' | translate }}</p>
			</div>
			<div class="col-12 col-md-10">
				<p *ngIf="previousDecision && previousDecision.nextMeetingDate">{{ previousDecision.nextMeetingDate | date }}</p>
				<p *ngIf="!previousDecision || !previousDecision.nextMeetingDate">-</p>
			</div>
		</div>

		<div class="row" id="key-questions">
			<div class="col-12 col-md-2">
				<p class="header">{{ 'stage.current' | translate }}</p>
			</div>
			<div class="col-12 col-md-10">
				{{ portfolioService.getStageName(product.portfolio, product.stage) }}
			</div>
		</div>

		<div class="row" *ngIf="experiments?.length === 0">
			<div class="col-12" [innerHtml]="'progress.noProgress' | translate: { name: product.name }"></div>
		</div>
		<ng-container *ngIf="experiments?.length > 0">
			<!-- decisions -->
			<!-- <div class="row">
				<div class="col-lg-2 header-col">
					<p class="header">{{ 'insight.conclusionsDecisions' | translate }}</p>
				</div>
				<div class="col-lg-10">
					<div class="row">
						<div *ngFor="let insight of recentInsights" class="card-container col-12 col-md-4 h100">
							<app-insight-card [insight]="insight" [showExperimentTag]="true"></app-insight-card>
						</div>
						<p class="col-12 link" (click)="showInsights()">{{ 'insight.seeAll' | translate }}</p>
					</div>
				</div>
			</div> -->

			
			
			<!-- key questions -->
			<div class="row" id="key-questions">
				<div class="col-12 col-md-2">
					<p class="header">{{ 'keyQuestion.plural' | translate }}</p>
				</div>
				<div class="col-12 col-md-10">
					<ng-container *ngFor="let object of learningsPerQuestion">
						<div class="row align-items-center mb-2">
							<p class="col-12 col-md-9 header mb-0" *ngIf="object.question.default">{{ 'stageGateQuestions.' + product.stage + '.' + object.question.question | translate }}</p>
							<p class="col-12 col-md-9 header mb-0" *ngIf="!object.question.default">{{ object.question.question }}</p>
							<div class="col-12 col-md-3 range-container">
								<div class="progress">
									<div
										class="progress-bar"
										role="progressbar"
										[style.width.%]="((product.activeProgress.questions[object.question.id] - 1) / 4) * 100 || 0"
										aria-valuemin="1"
										aria-valuemax="5"
									></div>
								</div>
								<p class="left">{{ 'progress.notConfident' | translate }}</p>
								<p class="right">{{ 'progress.confident' | translate }}</p>
							</div>
						</div>
						<div class="row">
							<div *ngFor="let learning of object.learnings" class="col-4 learning-container">
								<app-learning-card [learning]="learning" [showExperimentTag]="true"></app-learning-card>
							</div>
						</div>
					</ng-container>
				</div>
			</div>

			<!-- experiments-->
			<div class="row">
				<div class="col-12 col-md-2">
					<p class="header">{{ 'experiment.plural' | translate }}</p>
				</div>
				<div class="col-12 col-md-10">
					<div class="row">
						<div *ngFor="let experiment of experiments" class="col-4 mb-4">
							<app-experiment-card [experiment]="experiment" [product]="product"></app-experiment-card>
						</div>
					</div>
				</div>
			</div>
			
			<!-- <div class="row">
				<div class="col-lg-2 header-col">
					<p class="header">{{ 'learning.recent' | translate }}</p>
				</div>
				<div class="col-12 col-lg-10">
					<div class="row">
						<div *ngFor="let learning of recentLearnings" class="card-container col-12 col-md-4 h100">
							<app-learning-card [learning]="learning" [showExperimentTag]="true"></app-learning-card>
						</div>
						<p class="col-12 link" (click)="showLearnings()">{{ 'learning.seeAll' | translate }}</p>
					</div>
				</div>
			</div> -->
			<div class="row">
				<div class="col-lg-2 header-col">
					<p class="header">{{ 'insight.conclusionsDecisions' | translate }}</p>
				</div>
				<div class="col-lg-10">
					<div class="row">
						<div *ngFor="let insight of recentInsights" class="card-container col-12 col-md-4 h100">
							<app-insight-card [insight]="insight" [showExperimentTag]="true"></app-insight-card>
						</div>
						<p class="col-12 link" (click)="showInsights()">{{ 'insight.seeAll' | translate }}</p>
					</div>
				</div>
			</div>
			<!--
			<div class="row">
				<div class="col-12 col-lg-2 header-col">
					<p class="header">{{ 'experiment.plural' | translate }}</p>
				</div>
				<div class="col-12 col-lg-10 mb-5 experiments-overview">
					<div class="row" *ngIf="product">
						<div class="col" *ngFor="let stage of stages">
							<div class="wrapper" [ngClass]="{ active: product.stage === stage }">
								<p>{{ portfolioService.getStageName(product.portfolio, stage) }}</p>
							</div>
						</div>
					</div>
					<div class="row" *ngFor="let experiment of experiments">
						<div class="col" *ngFor="let stage of stages">
							<app-experiment-card *ngIf="portfolioService.segmentToStage(experiment.segment) === stage" [experiment]="experiment" [product]="product"></app-experiment-card>
						</div>
					</div>
				</div>
			</div> -->
		</ng-container>
	</div>
	<div *ngIf="product" class="col-12 col-md product-side-bar right">
		<app-product-loop></app-product-loop>
	</div>
</div>
