<div class="card mb-2">
	<div class="d-flex align-items-center justify-content-center card-img-top" (click)="openFile()">
		<i *ngIf="fileType === 'docx'" class="file-icon bi bi-file-earmark-word"></i>
		<i *ngIf="fileType === 'pdf'" class="file-icon bi bi-file-earmark-pdf"></i>
		<i *ngIf="fileType === 'png' || fileType === 'jpeg' || fileType === 'jpg'" class="file-icon bi bi-file-earmark-image"></i>
		<i *ngIf="fileType === 'mp4'" class="file-icon bi bi-file-earmark-play"></i>
		<i
			*ngIf="
				fileType !== 'docx' &&
				fileType !== 'pdf' &&
				fileType !== 'png' &&
				fileType !== 'jpeg' &&
				fileType !== 'jpg' &&
				fileType !== 'mp4'
			"
			class="file-icon bi bi-paperclip"
		></i>
	</div>
	<div class="card-body">
		<a [href]="fileMeta.file.url()" target="_blank" class="header">{{ fileMeta.name }}</a>
		<p class="text-secondary">
			{{ fileMeta.createdAt | date }}
			<i class="bi bi-trash3 ml-auto" (click)="removeFile()"></i>
		</p>
	</div>
</div>
