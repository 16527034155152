import { Injectable } from '@angular/core';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import * as Parse from 'parse';
import { ProductDecision } from '../../../shared/models/product-decision';

@Injectable({
	providedIn: 'root',
})
export class DecisionService {

	constructor(
		private portfolioService: PortfolioService
	) { }

	async getDecisions(): Promise<ProductDecision[]> {
		const portfolio = await this.portfolioService.getActivePortfolio();
		const decisionQuery = portfolio.decisions.query() as Parse.Query<ProductDecision>;
		decisionQuery.equalTo('archived', false);
		decisionQuery.equalTo('status', ProductDecision.Status.DONE);
		decisionQuery.include(['product', 'product.portfolio', 'attachments']);
		decisionQuery.descending('decisionDate');

		return decisionQuery.find();
	}

	decisionSortDateDescending(a, b): number {
		if (a.decisionDate < b.decisionDate) {
			return 1;
		} else if (a.decisionDate > b.decisionDate) {
			return -1;
		} else {
			return 0;
		}
	}
}
