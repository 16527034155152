import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import dayjs from 'dayjs';
import { Product } from '../../../../shared/models/product';
import { ProductService } from '../product.service';

import relativeTime from 'dayjs/plugin/relativeTime';
import { Cloud } from 'parse';
dayjs.extend(relativeTime);

@Component({
	selector: 'app-product-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
})
export class ProductCardComponent implements OnInit {
	@Input() product: Product;
	@Input() productIA;
	@Input() currentQuarter;
	@Input() previousQuarter;
	@Output() selectProduct: EventEmitter<any> = new EventEmitter();
	@Output() selectAccounting: EventEmitter<any> = new EventEmitter();

	public experimentVelocity;
	public experimentDif;
	public learningRatio;
	public learningDif;
	public teamHappiness;
	public happinessDif;

	public duration: string;
	public totalMoneyReceived: string;

	constructor(public productService: ProductService) {}

	async ngOnInit() {
		if (this.productIA && this.productIA[this.currentQuarter]) {
			if (this.productIA[this.currentQuarter].weeks) {
				if (this.productIA[this.currentQuarter].experimentVelocity) {
					this.experimentVelocity = this.calcExperimentVelocity(this.currentQuarter);

					if (this.productIA[this.previousQuarter] && this.productIA[this.previousQuarter].weeks && this.productIA[this.previousQuarter].experimentVelocity) {
						this.experimentDif = this.experimentVelocity - this.calcExperimentVelocity(this.previousQuarter);
					}

					if (this.productIA[this.currentQuarter].learningVelocity) {
						this.learningRatio = this.calcLearningRatio(this.currentQuarter);

						if (this.productIA[this.previousQuarter] && this.productIA[this.previousQuarter].weeks && this.productIA[this.previousQuarter].learningVelocity) {
							this.learningDif = this.learningRatio - this.calcLearningRatio(this.previousQuarter);
						}
					}
				}
			}

			if (this.productIA[this.currentQuarter].teamHapiness) {
				this.teamHappiness = this.calcHappiness(this.currentQuarter);

				if (this.productIA[this.previousQuarter] && this.productIA[this.previousQuarter].teamHapiness) {
					this.happinessDif = this.teamHappiness - this.calcHappiness(this.previousQuarter);
				}
			}
		}

		let startedAt = dayjs(this.product.startedAt);
		let endedAt = dayjs(this.product.endedAt);

		this.duration = endedAt.from(startedAt, true); // a year

		if (this.product.isValidated() || this.product.isStopped()) {
			let iaStages = await Cloud.run('getInnovationAccountingStages', {
				productId: this.product.id,
			});

			this.totalMoneyReceived = iaStages.totalMoneyReceived;
		}
	}

	onSelect() {
		this.selectProduct.emit(this.product);
	}

	onSelectAccounting() {
		this.selectAccounting.emit(this.product);
	}

	calcExperimentVelocity(quarter) {
		return this.productIA[quarter].experimentVelocity / this.productIA[quarter].weeks || 0;
	}

	calcLearningRatio(quarter) {
		return this.productIA[quarter].learningVelocity / this.productIA[quarter].experimentVelocity || 0;
	}

	calcHappiness(quarter) {
		return (this.productIA[quarter].teamHapiness - 1) / 4 || 0;
	}

	getAbs(value) {
		return Math.abs(value);
	}
}
