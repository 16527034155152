import { Component, Output, EventEmitter, Input } from '@angular/core';
import { slugifyFile } from '../../../../shared/slugify';
import { FileMeta } from '../../../../shared/models/file-meta';
import { Product } from '../../../../shared/models/product';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-attachment-button-add',
	templateUrl: './attachment-button-add.component.html',
	styleUrls: ['./attachment-button-add.component.scss'],
})
export class AttachmentButtonAddComponent {
	@Input() product: Product;
	@Output() fileUpload: EventEmitter<any> = new EventEmitter<any>();
	public showProgress: boolean = false;
	public progressUpload: number = 0;
	public fileErrorMessage: string;

	constructor(private translate: TranslateService) {}

	async uploadFiles(event) {
		if (event.target.files.length > 0) {
			let fileMetas = [];
			for (let file of event.target.files) {
				const parseFile = new Parse.File(slugifyFile(file.name), file);
				this.showProgress = true;
				this.progressUpload = 0;

				try {
					await parseFile.save({
						progress: (progressValue, loaded, total, { type }) => {
							if (type === 'upload' && progressValue !== null) {
								// Update the UI using progressValue
								this.progressUpload = progressValue * 100;
							}
						},
					} as any);

					const fileMeta = new FileMeta();
					fileMeta.file = parseFile;
					fileMeta.set('product', this.product); // for the ACL!
					await fileMeta.save();
					fileMetas.push(fileMeta);
				} catch (error) {
					let message = this.translate.instant('parseError.' + error.message);
					if (message == 'parseError.' + error.message) {
						// show default message
						message = this.translate.instant('parseError.fileNotUploaded');
					}
					this.fileErrorMessage = message;
				}

				this.showProgress = false;
			}
			this.fileUpload.emit(fileMetas);
		}
	}
}
