<div class="modal-content">
	<div class="modal-header align-items-center">
		<p class="modal-title" *ngIf="!editable">{{ 'cockpitField.singular' | translate }}</p>
		<p class="modal-title" *ngIf="editable && !cockpit.isNew()">{{ 'cockpitField.edit' | translate }}</p>
		<p class="modal-title" *ngIf="editable && cockpit.isNew()">{{ 'cockpitField.new' | translate }}</p>

		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
			<span aria-hidden="true">&times;</span>
		</button>

		<button class="btn btn-link-white ml-auto" *ngIf="!editable" (click)="makeEditable()">{{ 'general.edit' | translate }}</button>
	</div>
	<div class="modal-body edit" *ngIf="editable">
		<div class="form-group">
			<label class="mb-0"
				>{{ 'cockpitField.name' | translate }}
				<input type="text" class="form-control" [(ngModel)]="cockpit.name" name="name" ngbAutoFocus />
			</label>
		</div>

		<div class="form-group">
			<label class="mb-0"
				>{{ 'cockpitField.explanation' | translate }}
				<input type="text" class="form-control" [(ngModel)]="cockpit.explanation" name="explanation" />
			</label>
		</div>
		

	</div>

	<div class="modal-body" *ngIf="!editable">
		<div class="row">
			<div class="col-12">
				<h2 class="modal-header-title">{{ cockpit.name }}</h2>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<h4 class="modal-body-header">{{ 'cockpitField.explanation' | translate }}</h4>
				<p>{{ cockpit.explanation }}</p>
			</div>
		</div>
	</div>

	<div class="modal-footer">
		<button class="btn btn-link-danger mr-auto" *ngIf="!cockpit.isNew()" (click)="deleteCockpit()">{{ 'general.remove' | translate }}</button>

		<button class="btn btn-primary ml-auto" *ngIf="editable && cockpit.isNew()" (click)="createCockpit()" [disabled]="!cockpit">
			{{ 'cockpitField.create' | translate }}
		</button>
		<button class="btn btn-primary ml-auto" *ngIf="editable && !cockpit.isNew()" (click)="saveEditable()">
			{{ 'general.save' | translate }}
		</button>
		<button class="btn btn-primary ml-auto" *ngIf="!editable" (click)="activeModal.close()">{{ 'general.close' | translate }}</button>
	</div>
</div>
