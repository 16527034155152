import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Log } from '../../../../../shared/models/log';

@Component({
	selector: 'app-log-sprint',
	templateUrl: './log-sprint.component.html',
	styleUrls: ['./log-sprint.component.scss'],
})
export class LogSprintComponent implements OnInit {
	@Input() log: Log;
	public translate: string;

	constructor(public router: Router) {}

	ngOnInit(): void {
		this.translate = 'activity.actions.' + this.log.activity.replace(' ', '_');
	}

	open() {
		this.router.navigate([
			'/portfolio',
			this.log.product.portfolio.id,
			'startup',
			this.log.product.id,
			'sprint',
			this.log.sprint.id,
		]);
	}
}
