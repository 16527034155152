import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RemoveModalComponent } from 'app/validating-modals/remove-modal/remove-modal.component';
import { FileMeta } from '../../../../shared/models/file-meta';

@Component({
	selector: 'app-attachment-card-big',
	templateUrl: './attachment-card-big.component.html',
	styleUrls: ['./attachment-card-big.component.scss'],
})
export class AttachmentCardBigComponent implements OnInit {
	@Input() fileMeta: FileMeta;
	@Output() delete: EventEmitter<any> = new EventEmitter<any>();
	public fileType: string;

	constructor(private modalService: NgbModal) {}

	ngOnInit() {
		const fileNameSplit = this.fileMeta.file.name().split('.');
		this.fileType = fileNameSplit[fileNameSplit.length - 1];
	}

	openFile() {
		window.open(this.fileMeta.file.url(), '_blank');
	}

	async removeFile() {
		const modalRef = this.modalService.open(RemoveModalComponent, {
			size: 'lg',
		});
		modalRef.componentInstance.element = 'attachment';

		let shouldBeRemoved = await modalRef.result;
		if (shouldBeRemoved) {
			//remove attachment
			await this.fileMeta.destroy();
			this.delete.emit(this.fileMeta);
		}
	}
}
