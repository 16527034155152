<div class="row align-items-center">
	<div class="col-8">
		<p *ngIf="experiment.type === 'learn'">{{ experiment.learninggoal }}</p>
		<p *ngIf="experiment.type === 'confirm'">Test "{{ experiment.sticky?.text }}"</p>
	</div>
	<div class="col-4 text-right">
		<span *ngIf="experiment.type === 'learn'" class="badge badge-secondary learn">{{ 'experiment.typeExperiment.learn.title' | translate }}</span>
		<span *ngIf="experiment.type === 'confirm'" class="badge badge-secondary confirm">{{ 'experiment.typeExperiment.confirm.title' | translate }}</span>
		<span class="identifier">{{ abbrev }}-{{ experiment.number }}</span>
	</div>
</div>
