import * as Parse from 'parse';
import { ParseProperty, ParseSubClass } from '../parse.decorators';
import { Experiment } from './experiment';
import { KeyQuestion } from './key-question';
import { Product } from './product';

@ParseSubClass('Learning')
export class Learning extends Parse.Object {
	@ParseProperty() public text: string;
	@ParseProperty() public segment: string;
	@ParseProperty() public createdBy: Parse.User;
	@ParseProperty() public product: Product;
	@ParseProperty() public experiment: Experiment;
	@ParseProperty() public archived: boolean;
	@ParseProperty() public keyQuestion: KeyQuestion;

	constructor() {
		super('Learning');
	}
}
