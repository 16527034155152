import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../../shared/models/user';
import { Router } from '@angular/router';
import { NextTitleService } from 'app/next-title.service';

@Component({
	selector: 'app-validate-email',
	templateUrl: './validate-email.component.html',
	styleUrls: ['./validate-email.component.scss']
})
export class ValidateEmailComponent implements OnInit {
	public email: string;
	public errorMessage: string;
	public resultMessage: string;

	constructor(
		private router: Router,
		private titleService: NextTitleService,
		private translate: TranslateService
	) { }

	async ngOnInit() {
		this.titleService.setTitle('Validate your email address');

		const user = User.current() as User;
		await user.fetch();

		if (user.emailVerified) {
			this.router.navigate(['/']);
		}
	 }

	onRequest() {
		(User as any).requestEmailVerification(this.email)
			.then((result) => {
				this.resultMessage = this.translate.instant('validateEmail.result', { 'email': this.email });
				this.errorMessage = '';
			})
			.catch((error) => {
				this.errorMessage = error.message;
				this.resultMessage = '';
			});
	}

}
