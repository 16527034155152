import { Component, OnInit } from '@angular/core';
import { Portfolio } from '@shared/models/portfolio';
import { PortfolioService } from '../portfolio.service';
import { Cloud, Query } from 'parse';
import { Learning } from '@shared/models/learning';
import { Canvas } from '@shared/models/canvas';
import { KeyQuestion } from '@shared/models/key-question';
import { Product } from '@shared/models/product';
import { NextTitleService } from 'app/next-title.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-portfolio-learnings',
	templateUrl: './portfolio-learnings.component.html',
	styleUrls: ['./portfolio-learnings.component.scss'],
})
export class PortfolioLearningsComponent implements OnInit {
	public portfolio: Portfolio;
	public learnings: Learning[];
	public learningsPerMonth;
	public searchTerms: string;
	public timeFilter: string = 'all';
	public selectedSegment: string;
	public canvas: Canvas;
	public keyQuestion: KeyQuestion;
	public keyQuestionOptions = [];

	public timeFilterOptions = ['all', 'lastMonth', 'lastThreeMonths', 'lastSixMonths', 'lastYear'];

	constructor(private portfolioService: PortfolioService, private titleService: NextTitleService, private translate: TranslateService) {}

	async ngOnInit() {
		this.portfolio = await this.portfolioService.getActivePortfolio();
		this.titleService.setTitle(this.translate.instant('learning.plural') + ' - ' + this.portfolio.name);

		this.canvas = new Canvas();
		this.canvas.type = 'next-canvas';
		this.queryLearnings();
		this.queryKeyQuestions();
	}

	sortLearningsPerMonth() {
		this.learningsPerMonth = [];
		let previousMonth;
		let monthIndex = -1;

		this.learnings.forEach((learning) => {
			if (previousMonth === 'undefined' || learning.createdAt.getMonth() !== previousMonth) {
				previousMonth = learning.createdAt.getMonth();
				const newMonthObject = {
					date: learning.createdAt,
					learnings: [learning],
				};
				this.learningsPerMonth.push(newMonthObject);

				monthIndex++;
			} else {
				this.learningsPerMonth[monthIndex]['learnings'].push(learning);
			}
		});
	}

	async queryLearnings(event?) {
		this.learnings = await Cloud.run('filterLearnings', {
			portfolioId: this.portfolio.id,
			timeFilter: this.timeFilter,
			searchTerms: this.searchTerms,
			segment: this.selectedSegment,
			keyQuestionId: this.keyQuestion?.id,
		});
		this.sortLearningsPerMonth();
	}

	async queryKeyQuestions() {
		const query = new Query(KeyQuestion);
		// check if portfolio uses default questions or not, query accordingly
		if (this.portfolio.defaultKeyQuestions) {
			query.equalTo('default', true);
		} else {
			query.equalTo('portfolio', this.portfolio);
		}

		query.ascending('orderIndex');
		query.equalTo('archived', false);

		this.keyQuestionOptions = await query.find();
		this.sortKeyQuestions();
	}

	async sortKeyQuestions() {
		const filteredQuestions = {};
		const stages = this.portfolioService.getMainAndKickboxStages(this.portfolio);
		for (let stage of stages) {
			filteredQuestions[stage] = [];
		}

		for (let keyQuestion of this.keyQuestionOptions) {
			if (stages.indexOf(keyQuestion.stage) > -1) {
				filteredQuestions[keyQuestion.stage].push(keyQuestion);
			}
		}

		this.keyQuestionOptions = [];
		for (let stage of stages) {
			this.keyQuestionOptions.push(...filteredQuestions[stage]);
		}
	}

	async onSelectSegment(event) {
		if (event.segment === this.selectedSegment) {
			this.selectedSegment = '';
		} else {
			this.selectedSegment = event.segment;
		}
		this.queryKeyQuestions();
		this.queryLearnings();
	}

	toggleCanvasType() {
		if (this.canvas.type === 'next-canvas') {
			this.canvas.type = 'pre-validation';
		} else {
			this.canvas.type = 'next-canvas';
		}
	}
}
