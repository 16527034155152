import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CanvasService } from 'app/canvas/canvas.service';
import { ContentExperimentService } from 'app/content-experiment/content-experiment.service';
import { NextConfigService } from 'app/next-config.service';
import { PortfolioService } from 'app/portfolio/portfolio.service';
import { RemoveModalComponent } from 'app/validating-modals/remove-modal/remove-modal.component';
import * as Parse from 'parse';
import { Canvas } from '../../../../shared/models/canvas';
import { ContentExperiment } from '../../../../shared/models/content-experiment';
import { Experiment } from '../../../../shared/models/experiment';
import { Product } from '../../../../shared/models/product';
import { Sticky } from '../../../../shared/models/sticky';
import { ExperimentService } from '../experiment.service';

@Component({
	selector: 'app-experiment-modal-add',
	templateUrl: './experiment-modal-add.component.html',
	styleUrls: ['./experiment-modal-add.component.scss'],
})
export class ExperimentModalAddComponent implements OnInit {
	@Input() experiment: Experiment;
	@Input() canvas?: Canvas;
	@Input() product?: Product;
	@Input() startStep?: number;

	get ExperimentStatus() {
		return Experiment.Status;
	}

	public exampleCanvas: Canvas;
	public exampleStickies: Sticky[];
	public step: number = 0;
	public experimentType: string;
	public validSegment: boolean = false;
	public validCanvas: boolean = false;
	public canvases: Canvas[];
	public canvasWasNotGiven: boolean = false;
	public segmentStickies: Sticky[];
	public contentExperiments: ContentExperiment[];

	constructor(
		private canvasService: CanvasService,
		public activeModal: NgbActiveModal,
		private experimentService: ExperimentService,
		public configService: NextConfigService,
		private modalService: NgbModal,
		public translate: TranslateService,
		public contentExperimentService: ContentExperimentService,
		private portfolioService: PortfolioService
	) {}

	async ngOnInit() {
		if (this.startStep) {
			this.step = this.startStep;

			this.getExperimentOptions();
		}

		this.exampleStickies = [];
		this.contentExperiments = [];

		// experiment was created in experiments, canvas is not known yet
		if (!this.canvas) {
			this.canvasWasNotGiven = true;

			let canvasQuery = new Parse.Query(Canvas);
			canvasQuery.equalTo('product', this.product);
			canvasQuery.equalTo('archived', false);

			this.canvases = await canvasQuery.find();

			// automatically select first canvas
			this.canvas = this.canvases[0];

			if (this.canvases.length === 1) {
				this.canvasWasNotGiven = false;
			}
		}
	}

	async backStep() {
		this.step = 0;
	}

	async nextStep() {
		// Reload experiment options, they change when segment changes
		this.getExperimentOptions();

		this.step = 1;
	}

	async saveExperiment() {
		this.experiment.product = this.canvas.product;
		await this.experiment.save();

		this.activeModal.close();

		this.experimentService.openModal(this.experiment, this.product);
	}

	createExperiment() {
		this.canvasService.addExperiment(this.experiment, this.canvas).then(() => {
			this.activeModal.close(this.experiment);
		});
	}

	getSegmentName() {
		return this.portfolioService.getSegmentName(this.canvas.product.portfolio, this.experiment.segment);
	}

	onSelectSegment(evt) {
		// Set canvas segment on Experiment, evt.segment comes from mini next/pre-validation
		this.experiment.segment = evt.segment;
	}

	onClearCanvas() {
		this.canvas = undefined;
		this.experiment.segment = undefined;
		this.segmentStickies = undefined;
		this.experiment.sticky = undefined;
	}

	onChangeCanvas() {
		this.experiment.segment = undefined;
		this.segmentStickies = undefined;
		this.experiment.sticky = undefined;
	}

	async getAssumptions() {
		if (this.experiment.segment) {
			const stickyQuery = new Parse.Query(Sticky);
			stickyQuery.equalTo('canvas', this.canvas);
			stickyQuery.equalTo('segment', this.experiment.segment);
			stickyQuery.notEqualTo('validated', true); // do not include already (in)validated stickies, since they don't have to be tested anymore
			stickyQuery.notEqualTo('validated', false); // do not include already (in)validated stickies, since they don't have to be tested anymore
			stickyQuery.equalTo('archived', false);
			stickyQuery.descending('orderIndex');
			this.segmentStickies = await stickyQuery.find();
		}
	}

	async getExperimentOptions() {
		// Load experiment options
		this.contentExperiments = await this.contentExperimentService.getContentExperimentsPerTypeStage(this.experiment.getStage(), this.experiment.type, this.product.portfolio);
	}

	isComplete() {
		if (!this.experiment.segment || !this.experiment.description) {
			return false;
		}

		if (this.experiment.type === 'learn') {
			return !!this.experiment.learninggoal;
		}

		if (this.experiment.type === 'confirm') {
			if (!!this.experiment.sticky && !!this.experiment.successcriteria) {
				return true;
			}
		}

		return false;
	}

	selectContentExperiment(event) {
		if (this.experiment.contentExperiment && this.experiment.contentExperiment.standardExperimentPlan) {

			// do not overwrite experiment plan
			if (this.experiment.description) {
				return;
			}

			this.experiment.description = this.experiment.contentExperiment.standardExperimentPlan;
		}
	}

	async deleteExperiment() {
		const modalRef = this.modalService.open(RemoveModalComponent, {
			size: 'sm',
			centered: true,
		});
		modalRef.componentInstance.element = 'experiment';

		let removed = await modalRef.result;

		// if close button was clicked
		if (!removed) {
			return;
		}

		this.experiment.archived = true;
		await this.experiment.save();

		this.activeModal.close();
	}
}
