import { Component, OnDestroy, OnInit } from '@angular/core';
import { Query, Role } from 'parse';
import { Portfolio } from '../../../../shared/models/portfolio';
import { PortfolioService } from '../../portfolio/portfolio.service';
import { NextTitleService } from 'app/next-title.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-portfolio-settings',
	templateUrl: './portfolio-settings.component.html',
	styleUrls: ['./portfolio-settings.component.scss'],
})
export class PortfolioSettingsComponent implements OnInit, OnDestroy {
	public portfolio: Portfolio;
	public loaded: Boolean;
	public userIsOwner = false;
	public editable: boolean;
	public userInPortfolio: boolean;
	private routeSubscription;

	constructor(public portfolioService: PortfolioService, private titleService: NextTitleService, public router: Router) {}

	async ngOnInit() {
		this.portfolio = await this.portfolioService.getActivePortfolio();

		this.titleService.setTitle('Settings - ' + this.portfolio.name);

		const queryRoles = new Query(Role);
		queryRoles.equalTo('name', 'portfolio-' + this.portfolio.id);
		const role = await queryRoles.first();

		if (!!role) {
			this.userInPortfolio = true;
		}

		try {
			this.userIsOwner = !!(await Parse.Cloud.run('getSubscription'));
		} catch (error) {
			// user does not own subscription
		}
	}

	ngOnDestroy() {
		if (this.routeSubscription) {
			this.routeSubscription.unsubscribe();
		}
	}
}
