<div class="card">
	<p
		[innerHtml]="
			'product.summary'
				| translate
					: {
							startupName: product.name,
							ago: ago,
							formattedFunds: totalMoneyReceived | currency: product.portfolio.currency:'symbol':'1.0-0',
							formattedCost: totalMoneySpent | currency: product.portfolio.currency:'symbol':'1.0-0'
					  }
		"
	></p>
</div>
