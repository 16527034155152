<form class="row">
	<div class="col-12 col-md-3 tab-container" *ngIf="product">
		<ng-select id="selectProduct" name="product" class="mb-4" [(ngModel)]="product" [items]="products" (change)="switchProduct()">
			<ng-template ng-label-tmp let-item="item">
				<p>{{ item.name }}</p>
			</ng-template>
			<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
				{{ item.name }}
			</ng-template>
		</ng-select>

		<div class="tabs">
			<a class="nav-link" [routerLink]="['general']" routerLinkActive="active">{{ 'general.singular' | translate }}</a>
			<a class="nav-link" [routerLink]="['portfolio']" routerLinkActive="active">{{ 'portfolio.singular' | translate }}</a>
			<a class="nav-link" [routerLink]="['collaborators']" routerLinkActive="active">{{ 'product.teamMembers' | translate }}</a>
		</div>
	</div>
	<div class="col-12 col-md-9">
		<router-outlet></router-outlet>
	</div>
</form>
