<div class="container page dashboard" *ngIf="isReady">
	<div class="row justify-content-around">
		<!-- <div class="col-12 col-md-4">
			<h2 class="text-center">I've been invited</h2>
			<p>
				If you were asked to create an account, let the person know they can now add you at <br />
				<u *ngIf="user">{{ user.email }}</u>
			</p>
		</div> -->
		<form class="col-12 col-md-6">
			<div *ngIf="step === 0">
				<h1 name="first product" class="text-center">{{ 'product.addFirst' | translate }}</h1>
				<p class="text-center mb-5" [innerHtml]="'trial.setup' | translate"></p>
				<div class="form-group">
					<label
						>{{ 'product.singular' | translate }} {{ 'product.title' | translate }}
						<input type="text" class="form-control" name="name" [(ngModel)]="product.name" />
					</label>
				</div>
				<div class="form-group">
					<label>{{ 'product.pitch' | translate }} <input type="text" class="form-control" name="pitch" [(ngModel)]="product.pitch" /> </label>
				</div>

				<!-- <div class="form-group">
					<label for="fileInput" class="btn attachment"><i class="bi bi-camera"></i> {{ 'product.uploadLogo' | translate }} <input type="file" class="form-control-file" id="fileInput" accept="image/*" (change)="onLogoChange($event)" #logoInput /> </label>
					<p>
						<ngb-progressbar type="primary" *ngIf="showProgress" [value]="progressUpload" height=".25rem"></ngb-progressbar>
					</p>
				</div>
				<div class="form-group ">
					<img *ngIf="product.logo" [src]="product.logo?.url()" class="img-fluid logo px-0" />
				</div> -->

				<!-- <div class="form-group">
					<label class="mb-0">{{ 'product.currentStageOf' | translate: { startupName: product.name || 'the startup' } }}</label>
					<app-product-stages-selection id="startStage" [portfolio]="portfolio" [(stage)]="product.stage"></app-product-stages-selection>
				</div> -->

				<div class="mt-3 text-right">
					<button name="next" type="button" class="btn btn-primary" (click)="nextStep()">{{ 'general.next' | translate }}</button>
				</div>
			</div>
			<div *ngIf="step === 1" class="text-center">
				<h3>{{ 'team.groundControlWorks' | translate }}</h3>
				<p [innerHtml]="'invite.stakeholders' | translate"></p>
				<div class="row mt-3 justify-content-center">
					<div class="col-6">
						<div class="input-group form-group">
							<input type="email" class="form-control" [placeholder]="'user.email' | translate" name="email1" [(ngModel)]="email1" />
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-6">
						<div class="input-group form-group">
							<input type="email" class="form-control" [placeholder]="'user.email' | translate" name="email2" [(ngModel)]="email2" />
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-6">
						<div class="input-group form-group">
							<input type="email" class="form-control" [placeholder]="'user.email' | translate" name="email3" [(ngModel)]="email3" />
						</div>
					</div>
				</div>

				<div class="mt-3 text-center">
					<button type="button" class="btn btn-primary" (click)="startTrial()">{{ 'trial.start' | translate }}</button><br />
					<button type="button" name="skip and start" class="btn btn-link mt-3" (click)="startTrial()">{{ 'trial.skipInviteStart' | translate }}</button>
				</div>
			</div>

			<div *ngIf="step === 2" class="text-center mt-5">
				<h1 name="creating product title">{{ 'product.creating' | translate }}</h1>
				<p class="mb-5">{{ 'product.wait' | translate }}</p>
				<ngb-progressbar type="primary" [value]="100" [striped]="true" [animated]="true"></ngb-progressbar>
			</div>
		</form>
	</div>
</div>
