import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExperimentTasksDragModalComponent } from 'app/experiment/experiment-tasks-drag-modal/experiment-tasks-drag-modal.component';
import { ExperimentService } from 'app/experiment/experiment.service';
import { ProductService } from 'app/product/product.service';
import { TaskService } from 'app/task/task.service';
import { LiveQuerySubscription, Query } from 'parse';
import { Experiment } from '../../../../shared/models/experiment';
import { Product } from '../../../../shared/models/product';
import { Sprint } from '../../../../shared/models/sprint';
import { Sticky } from '../../../../shared/models/sticky';
import { Task } from '../../../../shared/models/task';
import { SprintModalCompleteComponent } from '../sprint-modal-complete/sprint-modal-complete.component';
import { SprintModalStartComponent } from '../sprint-modal-start/sprint-modal-start.component';
import { AnalyticsService } from 'app/shared/analytics.service';
import { NextTitleService } from 'app/next-title.service';
import { Portfolio } from '../../../../shared/models/portfolio';
import { StickyRiskModalComponent } from 'app/sticky/sticky-risk-modal/sticky-risk-modal.component';
import { StickyService } from 'app/sticky/sticky.service';

@Component({
	selector: 'app-backlog',
	templateUrl: './backlog.component.html',
	styleUrls: ['./backlog.component.scss'],
})
export class BacklogComponent implements OnInit, OnDestroy {
	public product: Product;
	public sprints: Sprint[];
	public abbrev: string;
	public allExperimentDropLists: string[];
	public allTaskDropLists: string[];
	public collapseTracking = {};
	public mostRiskyStickies: Sticky[] = [];
	public riskyStickies: Sticky[] = [];
	public confirmStickies: Sticky[] = [];
	get SprintStatus() {
		return Sprint.Status;
	}
	get PortfolioSprintType() {
		return Portfolio.SprintType;
	}

	private stickies: Sticky[] = [];
	private stickySubscription: LiveQuerySubscription;
	private sprintSubscription: LiveQuerySubscription;
	private taskSubscription: LiveQuerySubscription;
	private experimentSubscription: LiveQuerySubscription;

	constructor(
		private modalService: NgbModal,
		private taskService: TaskService,
		public router: Router,
		private productService: ProductService,
		private experimentService: ExperimentService,
		private analytics: AnalyticsService,
		private stickyService: StickyService,
		private titleService: NextTitleService
	) {}

	async ngOnInit() {
		// this.analytics.track('Opened backlog');

		this.product = await this.productService.getActiveProduct();
		this.allExperimentDropLists = [];
		this.allTaskDropLists = [];

		this.titleService.setTitle('Backlog - ' + this.product.name);

		if (this.product.activeSprint?.status === Sprint.Status.DONE) {
			this.product.activeSprint = null;
		}

		this.fetchData();
		this.abbrev = this.product.getAbbrev();
	}

	ngOnDestroy() {
		if (this.stickySubscription) {
			this.stickySubscription.unsubscribe();
		}

		if (this.sprintSubscription) {
			this.sprintSubscription.unsubscribe();
		}

		if (this.taskSubscription) {
			this.taskSubscription.unsubscribe();
		}

		if (this.experimentSubscription) {
			this.experimentSubscription.unsubscribe();
		}
	}

	async fetchData() {
		// get all active, prepared and backlog sprints of product
		const query = new Query(Sprint);
		query.equalTo('product', this.product);
		query.containedIn('status', [Sprint.Status.RUNNING, Sprint.Status.PREPARING, Sprint.Status.BACKLOG]);
		query.include(['experiments', 'tasks', 'tasks.assignedUsers']); //, experiments.product.portfolio'
		query.ascending('number');

		this.sprintSubscription = await query.subscribe();
		this.sprintSubscription.on('create', (sprint: Sprint) => {
			// make sure sprint is added above backlog
			this.sprints.splice(this.sprints.length - 1, 0, sprint);
		});
		this.sprintSubscription.on('update', (sprint: Sprint) => {});

		this.sprintSubscription.on('leave', (sprint: Sprint) => {
			const index = this.sprints.findIndex((s) => s.id === sprint.id);

			this.sprints.splice(index, 1);
		});

		const sprints = (await query.find()) as Sprint[];

		// remove backlog from the front and put it in the back
		if (sprints.length) {
			const backlog = sprints.shift();
			sprints.push(backlog);
		}

		// Setup livequery for Tasks
		// No need to run, just subscribing already works
		const taskQuery = new Query(Task);
		taskQuery.containedIn('sprint', sprints);
		taskQuery.equalTo('archived', false);
		this.taskSubscription = await taskQuery.subscribe();

		// Setup livequery for Experiments
		const experimentQuery = new Query(Experiment);
		experimentQuery.equalTo('product', this.product);
		experimentQuery.equalTo('archived', false);
		this.experimentSubscription = await experimentQuery.subscribe();

		for (let sprint of sprints) {
			// create the droplists (for drag and drop)
			this.addDroppableSprint(sprint);
		}

		// prevent errors due to collapseTracking
		// set this.sprints only now
		this.sprints = sprints;

		this.fetchStickies();
	}

	addDroppableSprint(sprint: Sprint) {
		this.allExperimentDropLists.push('experimentsSprint-' + sprint.id);
		this.allTaskDropLists.push('tasksSprint-' + sprint.id);

		this.collapseTracking[sprint.id] = false;
	}

	async createNewSprint() {
		let newSprint = new Sprint();
		newSprint.product = this.product;

		await newSprint.save();

		// add droppable
		this.addDroppableSprint(newSprint);

		// Immediately start sprint, but do not redirect to Sprints page
		this.startSprint(newSprint, false);
	}

	async fetchStickies() {
		const stickyQuery = new Query(Sticky);
		stickyQuery.equalTo('product', this.product);
		stickyQuery.include('canvas');
		stickyQuery.greaterThan('confidenceScore', 5);
		stickyQuery.greaterThan('impactScore', 5);
		stickyQuery.equalTo('archived', false);
		stickyQuery.equalTo('validated', undefined);
		// stickyQuery.ascending('orderIndex');

		this.stickySubscription = await stickyQuery.subscribe();

		this.stickySubscription.on('create', (sticky) => {
			// created stickies always get highest index so ordering is not changed.
			this.stickies.push(sticky as Sticky);

			this.filterRiskyness();
		});

		this.stickySubscription.on('update', (sticky: Sticky) => {
			this.filterRiskyness();
		});

		this.stickies = await stickyQuery.find();

		this.filterRiskyness();
	}

	filterRiskyness() {
		const riskyness = this.stickyService.filterRiskyness(this.stickies, this.product);

		this.mostRiskyStickies = riskyness['most-risky'];
		this.riskyStickies = riskyness['risky'];
		this.confirmStickies = riskyness['confirm'];
	}

	async createNewExperiment(sprint: Sprint) {
		const experiment = new Experiment();
		experiment.product = this.product;

		let createdExperiment = await this.experimentService.openAddModal(experiment, this.product);

		if (!createdExperiment) {
			return;
		}

		// add experiment to sprint
		sprint.addUnique('experiments', createdExperiment);
		sprint.save();

		// Start experiment when sprint is running
		if (sprint.status == Sprint.Status.RUNNING && experiment.status !== Experiment.Status.RUNNING) {
			experiment.status = Experiment.Status.RUNNING;
			experiment.startAt = new Date();
			experiment.startSprint = sprint;
			experiment.save();
		}
	}

	async createNewTask(sprint: Sprint) {
		const task = new Task();
		task.status = Task.Status.TODO;

		this.taskService.openModal(task, sprint, this.product);
	}

	openTask(task: Task, sprint: Sprint) {
		this.taskService.openModal(task, sprint, this.product);
	}

	async openExperiment(experiment) {
		const currentStatus = experiment.status;

		const result = await this.experimentService.openModal(experiment, this.product);

		if (currentStatus === experiment.status) {
			return;
		}

		if (experiment.status !== Experiment.Status.RUNNING) {
			return;
		}

		experiment.startSprint = this.product.activeSprint;
		experiment.startAt = new Date();
		experiment.save();

		this.product.activeSprint.addUnique('experiments', experiment);
		this.product.activeSprint.save();

		this.product.backlog.remove('experiments', experiment);
		this.product.backlog.save();
	}

	async startSprint(sprint: Sprint, redirect?: boolean) {
		// check if active sprint is completed
		if (this.product.activeSprint && this.product.activeSprint.status !== Sprint.Status.DONE) {
			return;
		}

		const modalRef = this.modalService.open(SprintModalStartComponent, {
			size: 'md',
		});
		modalRef.componentInstance.sprint = sprint;
		modalRef.componentInstance.product = this.product;

		const result = await modalRef.result;

		// Check whether the close button of the modal was pressed
		if (!result) {
			return;
		}

		// Start sprint
		sprint.status = Sprint.Status.RUNNING;
		await sprint.save();

		// Set active sprint
		this.product.activeSprint = sprint;
		await this.product.save();

		// Start experiments
		for (let experiment of sprint.experiments) {
			if (experiment.startAt) {
				continue;
			}

			experiment.status = Experiment.Status.RUNNING;
			experiment.startSprint = sprint;
			experiment.startAt = sprint.startedAt;

			experiment.save();
		}

		// move to active sprint view
		if (redirect) {
			this.router.navigate(['/portfolio', this.product.portfolio.id, 'startup', this.product.id, 'sprint']);
		}
	}

	async completeSprint() {
		const modalRef = this.modalService.open(SprintModalCompleteComponent, {
			size: 'md',
		});
		modalRef.componentInstance.sprint = this.product.activeSprint;
		modalRef.componentInstance.product = this.product;

		await modalRef.result;

		// reload backlog
		// this.fetchData();
	}

	async dropExperiment(event: CdkDragDrop<any>) {
		if (event.container !== event.previousContainer) {
			// experiment dragged to other sprint
			const source = event.previousContainer.data as unknown as Sprint;
			const destination = event.container.data as unknown as Sprint;
			const experiment = event.item.data;

			// experiment was dragged to another sprint/to backlog
			const tasksLeftInSprint = this.sprintHasExperimentTasks(source, experiment);

			if (tasksLeftInSprint) {
				// experiment was linked to tasks, ask user what to do with them.
				let result = await this.handleTasksExperimentMovement(event, experiment);
				if (!result) {
					// cancel movement of experiment
					return;
				}
			}

			// move experiment to right location.
			destination.addUnique('experiments', experiment);
			destination.save();

			// Start experiment when destination sprint is running
			if (destination.status == Sprint.Status.RUNNING && experiment.status !== Experiment.Status.RUNNING) {
				experiment.status = Experiment.Status.RUNNING;
				experiment.startAt = new Date();
				experiment.startSprint = destination;
				experiment.save();
			}

			if (!this.sprintHasExperimentTasks(source, experiment)) {
				source.remove('experiments', experiment);
				source.save();
			}
		} else if (event.currentIndex !== event.previousIndex) {
			const sprint = event.container.data as unknown as Sprint;
			moveItemInArray(sprint.experiments as any, event.previousIndex, event.currentIndex);
			sprint.save();
		}
	}

	async handleTasksExperimentMovement(event, experiment) {
		const destination = event.container.data;
		const source = event.previousContainer.data;
		let tasks = [];

		for (let task of source.tasks) {
			if (task.experiment && task.experiment.id === experiment.id) {
				tasks.push(task);
			}
		}

		// experiment was linked to tasks, create modal to ask what to do with them.
		const modalRef = this.modalService.open(ExperimentTasksDragModalComponent, {
			size: 'md',
			centered: true,
		});
		modalRef.componentInstance.source = source;
		modalRef.componentInstance.destination = destination;
		modalRef.componentInstance.tasks = tasks;
		modalRef.componentInstance.product = this.product;
		modalRef.componentInstance.abbrev = this.abbrev;

		let result = await modalRef.result;
		if (result === 'addToSprint') {
			// tasks follow experiment, should be disconnected from previous container
			tasks.forEach((task) => {
				// set task to new sprint/backlog
				source.remove('tasks', task);
				destination.addUnique('tasks', task);
				task.sprint = destination;

				task.save();
			});

			// Only save once
			// source.save();
			// destination.save();

			return true;
		}

		if (result === 'moveExperimentOnly') {
			return true;
		}

		return false;
	}

	dropTask(event: CdkDragDrop<any[]>) {
		if (event.previousContainer !== event.container) {
			const task = event.item.data;
			const source = event.previousContainer.data as unknown as Sprint;
			const destination = event.container.data as unknown as Sprint;

			task.sprint = destination;

			if (task.experiment) {
				// task is linked to experiment
				// copy experiment to destination
				destination.addUnique('experiments', task.experiment);

				// if no tasks left in source, remove experiment
				// otherwise, leave it. We don't want tasks in sprint without their experiment
				if (!this.sprintHasExperimentTasks(source, task.experiment)) {
					source.remove('experiments', task.experiment);
				}
			}

			// set task to new sprint/backlog
			transferArrayItem(source.tasks as any, destination.tasks as any, event.previousIndex, event.currentIndex);

			// save here because we cannot merge an add Op and remove Op before saving.
			source.save();
			destination.save();
			task.save();
		} else if (event.currentIndex !== event.previousIndex) {
			const sprint = event.container.data as unknown as Sprint;
			moveItemInArray(sprint.tasks as any, event.previousIndex, event.currentIndex);
			sprint.save();
		}
	}

	sprintHasExperimentTasks(sprint: Sprint, experiment: Experiment): boolean {
		for (let task of sprint.tasks) {
			if (task.experiment && task.experiment.id === experiment.id) {
				// task is part of given experiment
				return true;
			}
		}

		// No tasks as part of given experiment in this sprint.
		return false;
	}

	isActiveSprint(sprint: Sprint) {
		if (this.product.activeSprint && sprint.id == this.product.activeSprint.id && this.product.activeSprint.status === Sprint.Status.RUNNING) {
			return true;
		}

		return false;
	}

	async editSprint(sprint: Sprint) {
		const modalRef = this.modalService.open(SprintModalStartComponent, {
			size: 'md',
		});
		modalRef.componentInstance.sprint = sprint;
		modalRef.componentInstance.product = this.product;

		const result = await modalRef.result;

		// Check whether the close button of the modal was pressed
		if (!result) {
			return;
		}

		// save sprint
		await sprint.save();
	}

	openRiskModal() {
		this.modalService.open(StickyRiskModalComponent, {
			size: 'sm',
		});
	}
}
